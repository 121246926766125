<template>
  <div>
    <v-list flat subheader three-line>
      <v-subheader>
        <h4>Daten-Import</h4>
      </v-subheader>

      <v-list-item>
        <v-list-item-content>
          <v-container>
            <v-card class="mx-auto" max-width="900">
              <v-form class="pa-4" ref="form" lazy-validation>
                <p>Import von Kontakdaten / Sevdesk Kontakt Import</p>
                <h5>
                  Kontakte können entweder als .csv Datei oder als von Sevdesk erstellter
                  Export in onDispatch importiert werden.
                </h5>
                <br />
                <h5>
                  Mehr Informationen erhalten Sie
                  <a href="https://doku.ondispatch.de/docs/uebersicht/" trget="_blank"
                    >hier</a
                  >.
                </h5>
                <br />
                <h5>
                  Eine Beispieldatei für den Kontaktimport können Sie
                  <a href="/static/Kontakt_Import_Template.csv" download trget="_blank"
                    >hier herunterladen</a
                  >.
                </h5>
                <br />
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-select
                      :items="selectItemsDatatype"
                      v-model="datatype"
                      label="Datentyp"
                      data-cy="datatype"
                      :rules="requieredRule"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-select
                      :items="selectItemsImporttype"
                      v-model="importtype"
                      @input="changedImporttype"
                      label="Import-Typ"
                      data-cy="importtype"
                      :rules="requieredRule"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-file-input
                      show-size
                      label="Datei auswählen"
                      data-cy="uploadFile"
                      v-if="showFileInput"
                      :rules="requieredRule"
                      v-model="uploadFile"
                      accept=".csv"
                    ></v-file-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-btn @click="sendImport" class="mx-auto mb-2" color="primary" dark
                    >Importieren</v-btn
                  >
                </v-row>
              </v-form>
              <v-dialog v-model="load" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                  <v-card-text>
                    Daten werden importiert...
                    <v-progress-linear
                      indeterminate
                      color="white"
                      class="mb-0"
                    ></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-card>
          </v-container>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  data: () => ({
    selectItemsDatatype: ["Kontakte"],
    selectItemsImporttype: ["Datei"],
    importtype: null,
    datatype: null,
    showFileInput: false,
    load: false,
    uploadFile: null,
    requieredRule: [(v) => !!v || "Dieses Feld wird benötigt"],
  }),

  created() {
    this.initialize();
  },

  computed: {
    ...mapGetters(["backendServer", "licenseData"]),

    computedDateFormattedStartDate() {
      return this.formatDate(this.start);
    },
    computedDateFormattedEndDate() {
      return this.formatDate(this.end);
    },
  },

  watch: {
    licenseData: {
      handler(val) {
        if (val.data[0]) {
          if (val.data[0].value.modules.sevdesk) {
            this.selectItemsImporttype.push("Sevdesk");
          }
        }
      },
    },
    deep: true,
  },

  methods: {
    initialize() {
      this.$store.dispatch("getLicenseData").then((result) => {});
    },
    yearCheck() {
      if (this.start.substring(0, 4) != this.end.substring(0, 4)) {
        return "Start- und Enddatum müssen im selben Jahr liegen.";
      }
    },
    changedImporttype() {
      if (this.importtype == "Datei") {
        this.showFileInput = true;
      } else {
        this.showFileInput = false;
      }
    },
    sendImport(item) {
      if (this.$refs.form.validate()) {
        switch (this.datatype) {
          case "Kontakte":
            if (this.importtype == "Datei") {
              this.load = true;
              let formData = new FormData();

              formData.append("file", this.uploadFile);

              let postUrl = this.backendServer + "import/contact/csv";
              let config = {
                headers: {
                  Authorization: localStorage.getItem("token"),
                },
              };

              axios
                .post(postUrl, formData, config)
                .then((response) => {
                  this.load = false;
                })
                .catch((err) => {
                  this.$store.dispatch("triggerError", err);
                  this.load = false;
                });
            } else {
              this.load = true;

              let postUrl = this.backendServer + "import/contact/sevdesk";
              let config = {
                headers: {
                  Authorization: localStorage.getItem("token"),
                },
              };

              axios
                .post(postUrl, {}, config)
                .then((response) => {
                  this.load = false;
                  //console.log(response);
                  this.this.$refs.form.reset();
                })
                .catch((err) => {
                  this.$store.dispatch("triggerError", err);
                  this.load = false;
                  this.this.$refs.form.reset();
                });
            }
            break;
        }
      }
    },
  },
};
</script>
