import axios from "axios";
const actions = {
  getInvoiceList(context) {
    context.commit("SET_INVOICE_LIST");
    const { page, itemsPerPage, search } = context.state.dataInvoice.pagination;
    let getUrl = null;
    if (search != null && search != "") {
      getUrl =
        context.getters.backendServer +
        "invoice?page=" +
        page +
        "&perPage=" +
        itemsPerPage +
        "&q=" +
        search;
    } else {
      getUrl =
        context.getters.backendServer +
        "invoice?page=" +
        page +
        "&perPage=" +
        itemsPerPage;
    }
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_INVOICE_LIST", response.data.data);
        context.commit("SET_TOTAL_ITEMS", response.data.count);
      })
      .catch(function(e) {
        context.commit("SET_INVOICE_LIST", e);
        context.dispatch("triggerError", e);
      });
  },

  getNextInvoiceId(context) {
    let getUrl = context.getters.backendServer + "invoice/getNextInvoiceNumber";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    return axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_NEXT_INVOICE_ID", response.data);
        return response.data;
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },

  getInvoiceNumberValid(context, nextInvoiceNumber) {
    let getUrl =
      context.getters.backendServer +
      "invoice/getInvoiceNumberValid?invoiceNumber=" +
      nextInvoiceNumber;
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    return axios
      .get(getUrl, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },

  getSingleInvoice(context, id) {
    let getUrl = context.getters.backendServer + "invoice/" + id;
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    return axios
      .get(getUrl, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },

  getOfferList(context) {
    context.commit("SET_OFFER_LIST");
    const { page, itemsPerPage, search } = context.state.dataOffers.pagination;
    let getUrl = null;
    if (search != null && search != "") {
      getUrl =
        context.getters.backendServer +
        "offer?page=" +
        page +
        "&perPage=" +
        itemsPerPage +
        "&q=" +
        search;
    } else {
      getUrl =
        context.getters.backendServer +
        "offer?page=" +
        page +
        "&perPage=" +
        itemsPerPage;
    }
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_OFFER_LIST", response.data.data);
        context.commit("SET_TOTAL_ITEMS_OFFER", response.data.count);
      })
      .catch(function(e) {
        context.commit("SET_OFFER_LIST", e);
        context.dispatch("triggerError", e);
      });
  },

  getSingleOffer(context, id) {
    let getUrl = context.getters.backendServer + "offer/" + id;
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    return axios
      .get(getUrl, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },

  getNextOfferId(context) {
    let getUrl = context.getters.backendServer + "offer/getNextOfferNumber";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_NEXT_OFFER_ID", response.data);
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },

  getOfferValid(context, offerNumber) {
    let getUrl =
      context.getters.backendServer +
      "offer/getOfferNumberValid?offerNumber=" +
      offerNumber;
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    return axios
      .get(getUrl, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },

  getDeliveryNotesList(context) {
    context.commit("SET_DELIVERY_NOTES_LIST");
    const {
      page,
      itemsPerPage,
      search
    } = context.state.dataDeliveryNotes.pagination;
    let getUrl = null;
    if (search != null && search != "") {
      getUrl =
        context.getters.backendServer +
        "deliveryNote?page=" +
        page +
        "&perPage=" +
        itemsPerPage +
        "&q=" +
        search;
    } else {
      getUrl =
        context.getters.backendServer +
        "deliveryNote?page=" +
        page +
        "&perPage=" +
        itemsPerPage;
    }
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_DELIVERY_NOTES_LIST", response.data.data);
        context.commit("SET_TOTAL_ITEMS_DELIVERY_NOTES", response.data.count);
      })
      .catch(function(e) {
        context.commit("SET_DELIVERY_NOTES_LIST", e);
        context.dispatch("triggerError", e);
      });
  },

  getSingleDeliveryNote(context, id) {
    let getUrl = context.getters.backendServer + "deliveryNote/" + id;
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    return axios
      .get(getUrl, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },

  getNextDeliveryNoteId(context) {
    let getUrl =
      context.getters.backendServer + "deliveryNote/getNextDeliveryNoteNumber";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    return axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_NEXT_DELIVERY_NOTE_ID", response.data);
        return response.data;
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },

  getDeliveryNoteValid(context, nextDeliveryNoteNumber) {
    let getUrl =
      context.getters.backendServer +
      "deliveryNote/getDeliveryNoteNumberValid?deliveryNoteNumber=" +
      nextDeliveryNoteNumber;
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    return axios
      .get(getUrl, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },

  addDeliveryNotes(context, value) {
    context.commit("SET_DELIVERY_NOTES_ADD");
    let postUrl = context.getters.backendServer + "deliveryNote";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    return axios
      .post(postUrl, value, config)
      .then(function(response) {
        context.commit("SET_DELIVERY_NOTES_ADD", response.data);
        context.commit(
          "CREATE_SNACKBAR",
          "Lieferschein erfolgreich hinzugefügt"
        );
        context.dispatch("getDeliveryNotesList");
        return response.data;
      })
      .catch(function(e) {
        context.commit("SET_DELIVERY_NOTES_ADD", e);
        context.dispatch("triggerError", e);
      });
  },

  deleteDeliveryNotes(context, value) {
    context.commit("SET_DELIVERY_NOTES_DELETE");
    let postUrl = context.getters.backendServer + "deliveryNote/" + value.id;

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .delete(postUrl, config)
      .then(function() {
        context.commit("SET_DELIVERY_NOTES_DELETE", "[]");
        context.commit("CREATE_SNACKBAR", "Lieferschein erfolgreich gelöscht");
        context.dispatch("getDeliveryNotesList");
      })
      .catch(function(e) {
        context.commit("SET_DELIVERY_NOTES_DELETE", e);
        context.dispatch("triggerError", e);
      });
  },

  updateDeliveryNotes(context, value) {
    context.commit("SET_DELIVERY_NOTES_UPDATE");

    let postUrl = context.getters.backendServer + "deliveryNote/" + value.id;

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    return axios
      .put(postUrl, value, config)
      .then(function(response) {
        context.commit("SET_DELIVERY_NOTES_UPDATE", response.data);
        context.commit(
          "CREATE_SNACKBAR",
          "Lieferschein " +
            response.data.deliveryNoteNumber +
            " erfolgreich verändert"
        );
        context.dispatch("getDeliveryNotesList");
        return response.data;
      })
      .catch(function(e) {
        context.commit("SET_DELIVERY_NOTES_UPDATE", e);
        context.dispatch("triggerError", e);
      });
  },

  addInvoice(context, value) {
    context.commit("SET_INVOICE_ADD");
    let postUrl = context.getters.backendServer + "invoice";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    return axios
      .post(postUrl, value, config)
      .then(function(response) {
        context.commit("SET_INVOICE_ADD", response);
        context.commit("CREATE_SNACKBAR", "Rechnung erfolgreich hinzugefügt");
        context.dispatch("getInvoiceList");
        return response.data;
      })
      .catch(function(e) {
        context.commit("SET_INVOICE_ADD", e);
        context.dispatch("triggerError", e);
      });
  },

  deleteInvoice(context, value) {
    context.commit("SET_INVOICE_DELETE");
    let postUrl = context.getters.backendServer + "invoice/" + value.id;

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .delete(postUrl, config)
      .then(function() {
        context.commit("SET_INVOICE_DELETE", "[]");
        context.commit("CREATE_SNACKBAR", "Rechnung erfolgreich gelöscht");
        context.dispatch("getInvoiceList");
      })
      .catch(function(e) {
        context.commit("SET_INVOICE_DELETE", e);
        context.dispatch("triggerError", e);
      });
  },

  updateInvoice(context, value) {
    context.commit("SET_INVOICE_UPDATE");

    let postUrl = context.getters.backendServer + "invoice/" + value.id;

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    return axios
      .put(postUrl, value, config)
      .then(function(response) {
        context.commit("SET_INVOICE_UPDATE", response.data);
        context.commit(
          "CREATE_SNACKBAR",
          "Rechnung " + response.data.invoiceNumber + " erfolgreich verändert"
        );
        context.dispatch("getInvoiceList");
        return response.data;
      })
      .catch(function(e) {
        context.commit("SET_INVOICE_UPDATE", e);
        context.dispatch("triggerError", e);
      });
  },

  addOffers(context, value) {
    context.commit("SET_OFFER_ADD");
    let postUrl = context.getters.backendServer + "offer";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    return axios
      .post(postUrl, value, config)
      .then(function(response) {
        context.commit("SET_OFFER_ADD", response.data);
        context.commit("CREATE_SNACKBAR", "Angebot erfolgreich hinzugefügt");
        context.dispatch("getOfferList");
        return response.data;
      })
      .catch(function(e) {
        context.commit("SET_OFFER_ADD", e);
        context.dispatch("triggerError", e);
      });
  },

  deleteOffer(context, value) {
    context.commit("SET_OFFER_DELETE");
    let postUrl = context.getters.backendServer + "offer/" + value.id;

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .delete(postUrl, config)
      .then(function() {
        context.commit("SET_OFFER_DELETE", "[]");
        context.commit("CREATE_SNACKBAR", "Angebot erfolgreich gelöscht");
        context.dispatch("getOfferList");
      })
      .catch(function(e) {
        context.commit("SET_OFFER_DELETE", e);
        context.dispatch("triggerError", e);
      });
  },

  updateOffers(context, value) {
    context.commit("SET_OFFER_UPDATE");

    let postUrl = context.getters.backendServer + "offer/" + value.id;

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    return axios
      .put(postUrl, value, config)
      .then(function(response) {
        context.commit("SET_OFFER_UPDATE", response.data);
        context.commit(
          "CREATE_SNACKBAR",
          "Angebot " + response.data.offerNumber + " erfolgreich verändert"
        );
        context.dispatch("getOfferList");
        return response.data;
      })
      .catch(function(e) {
        context.commit("SET_OFFER_UPDATE", e);
        context.dispatch("triggerError", e);
      });
  },

  //doctype: "deliveryNote" / "invoice" / "offer"
  sendViaMail(context, item) {
    let postUrl =
      context.getters.backendServer +
      item.doctype +
      "/" +
      item.id +
      "/sendViaMail";

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .post(postUrl, item.email, config)
      .then(response => {
        context.commit(
          "CREATE_SNACKBAR",
          "Dokument " /*+ item.deliveryNoteNumber */ +
            "wurde erfolgreich per Mail versendet"
        );
        //RELOAD LISTS
        context.dispatch("getDeliveryNotesList");
        context.dispatch("getInvoiceList");
        context.dispatch("getOfferList");
      })
      .catch(e => {
        context.dispatch("triggerError", e);
      });
  },

  sendViaMailToSelf(context, item) {
    let postUrl =
      context.getters.backendServer +
      item.doctype +
      "/" +
      item.id +
      "/sendViaMail";

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    let emailToSelf = {
      recipient: item.myMail,
      msg: item.email.msg,
      subject: item.email.subject,
      photos: item.email.photos
    };

    axios
      .post(postUrl, emailToSelf, config)
      .then(response => {
        context.commit(
          "CREATE_SNACKBAR",
          "Dokument " /*+ item.deliveryNoteNumber */ +
            "wurde erfolgreich per Mail versendet"
        );
      })
      .catch(e => {
        context.dispatch("triggerError", e);
      });
  },

  getLetterPrice(context, item) {
    let postUrl = context.getters.backendServer + "letter/price";

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    return axios
      .post(postUrl, item, config)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        context.dispatch("triggerError", e);
      });
  },

  sendLetter(context, item) {
    let postUrl = context.getters.backendServer + "letter";

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    return axios
      .post(postUrl, item, config)
      .then(response => {
        context.commit(
          "CREATE_SNACKBAR",
          "Dokument " /*+ item.deliveryNoteNumber */ +
            "wurde erfolgreich per Post / Brief versendet"
        );
        return response.data;
      })
      .catch(e => {
        context.dispatch("triggerError", e);
      });
  },

  sendActivityPrinted(context, item) {
    let postUrl = context.getters.backendServer + "logs/activity/printed";

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    return axios
      .post(postUrl, item, config)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        context.dispatch("triggerError", e);
      });
  }
};

export default actions;
