const getters = {
    invoiceListGetter: state => state.dataInvoice,
    invoiceStatusPending: state => state.dataInvoice.invoiceList.status.pending,
    nextInvoiceId: state => state.dataInvoice.nextInvoiceId,

    deliveryNotesListGetter: state => state.dataDeliveryNotes,
    deliveryNotesListGetterPagination: state => state.dataDeliveryNotes.pagination,
    deliveryNotesStatusPending: state => state.dataDeliveryNotes.deliveryNotesList.status.pending,
    nextDeliveryNoteId: state => state.dataDeliveryNotes.nextDeliveryNoteId,

    offerListGetter: state => state.dataOffers,
    offerStatusPending: state => state.dataOffers.offerList.status.pending,
    nextOfferId: state => state.dataOffers.nextOfferId,

    
}

export default getters;