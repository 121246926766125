const axios = require("axios");
import { StoreUtil } from "../utils";

const mutations = {
  SET_COMPANY_DATA(state, payload) {
    state.companyData = StoreUtil.updateState(state.companyData, payload);
  },
  SET_COMPANY_DATA_UPDATE(state, payload) {
    StoreUtil.updateState(state.companyData, payload);
  },
  SET_LICENSE_DATA(state, payload) {
    state.licenseData = StoreUtil.updateState(state.licenseData, payload);
  },
  SET_MAIL_DATA(state, payload) {
    state.mailData = StoreUtil.updateState(state.mailData, payload);
  },
  SET_MAIL_DATA_UPDATE(state, payload) {
    StoreUtil.updateState(state.mailData, payload);
  },
  SET_SEVDESK_DATA(state, payload) {
    state.sevdeskData = StoreUtil.updateState(state.sevdeskData, payload);
  },
  SET_CUSTOMERPORTAL_DATA(state, payload) {
    state.customerPortalData = StoreUtil.updateState(state.customerPortalData, payload);
  },
  SET_LICENSE_DATA_UPDATE(state, payload) {
    StoreUtil.updateState(state.licenseData, payload);
  },
  SET_SEVDESK_DATA_UPDATE(state, payload) {
    StoreUtil.updateState(state.datevData, payload);
  },
  SET_DATEV_DATA(state, payload) {
    state.datevData = StoreUtil.updateState(state.datevData, payload);
  },
  SET_DATEV_DATA_UPDATE(state, payload) {
    StoreUtil.updateState(state.mailData, payload);
  },
  SET_CUSTOMERPORTAL_DATA_UPDATE(state, payload) {
    StoreUtil.updateState(state.customerPortalData, payload);
  },
  SET_LOGO(state, payload) {
    state.logo = StoreUtil.updateState(state.logo, payload);
  },
  SET_DELIVERYNOTEPOSITIONTEMPLATES_DATA(state, payload) {
    state.deliveryNotePositionTemplates = StoreUtil.updateState(state.deliveryNotePositionTemplates, payload);
  },
  THROW_ERROR(state, payload) {
    state.error.visible = true;
    state.error.message = payload.err;
    state.errors.push(state.error);

    let postUrl = payload.con.getters.backendServer + "logs/error";
    let config = {
      headers: {
        authorization: localStorage.getItem("token")
      }
    };

    let reqData = {
      statusCode: 500,
      message: payload.err.message
    };

    if (payload.err.response.status) {
      reqData.statusCode = payload.err.response.status;
    }

    axios
      .post(postUrl, reqData, config)
      .then(function(response) {})
      .catch(function(e) {
        console.error(e);
      });
  },
  CREATE_SNACKBAR(state, payload) {
    state.snackbar.visible = true;
    state.snackbar.message = payload;
  },
  CREATE_UNIVERSAL_ALERT(state, value) {
    state.universalAlert.visible = true;
    state.universalAlert.text = value.text;
    state.universalAlert.heading = value.heading;
    state.universalAlert.type = value.type;
  },
  CLOSE_UNIVERSAL_ALERT(state) {
    state.universalAlert.visible = false;
    state.universalAlert.text = null;
    state.universalAlert.heading = null;
    state.universalAlert.type = null;
  }
};

export default mutations;
