<template>
  <v-data-table
    :headers="headers"
    :items="userList"
    :loading="userStatusPending"
    class="pa-5"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Benutzer</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-text-field
          v-model="search"
          label="Suche..."
          data-cy="search"
          single-line
          hide-details
          dense
          @keyup.enter.native="searchData"
        ></v-text-field>
        <v-btn text icon color="secondary" @click="searchData">
          <v-icon>search</v-icon>
        </v-btn>
        <div class="flex-grow-1"></div>
        <v-dialog v-model="dialog" max-width="800px">
          <template v-slot:activator="{ on }">
            <v-btn color="secondary" dark class="mb-2" v-on="on"
              >Neuer Benutzer</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.email"
                        label="Email"
                        data-cy="email"
                        :rules="requieredRule"
                        dense
                        prepend-icon="email"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="editedItem.prename"
                        label="Vorname"
                        data-cy="prename"
                        :rules="requieredRule"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="editedItem.surname"
                        label="Nachname"
                        data-cy="surname"
                        :rules="requieredRule"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-select
                        :items="roles"
                        item-text="name"
                        item-value="type"
                        label="Nutzertyp"
                        data-cy="role"
                        v-model="editedItem.role"
                        :rules="requieredRule"
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.password"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="requieredRule"
                        :type="show1 ? 'text' : 'password'"
                        data-cy="input-10-1"
                        label="Passwort"
                        hint="Minimum 8 Zeichen"
                        counter
                        :error="passwordError"
                        @click:append="show1 = !show1"
                        dense
                      ></v-text-field>
                      <p v-if="passwordError">
                        Passwörter stimmen nicht überein.
                      </p>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.password1"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="requieredRule"
                        :type="show1 ? 'text' : 'password'"
                        data-cy="input-10-1"
                        label="Passwort wiederholen"
                        hint="Minimum 8 Zeichen"
                        counter
                        :error="passwordError"
                        @click:append="show1 = !show1"
                        dense
                      ></v-text-field>
                      <p v-if="passwordError">
                        Passwörter stimmen nicht überein.
                      </p>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn color="secondary" text @click="close">Abbrechen</v-btn>
              <v-btn color="secondary" text @click="save" data-cy="saveEvent"
                >Speichern</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogEdit" max-width="800px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.email"
                        label="Email"
                        data-cy="email"
                        :rules="requieredRule"
                        dense
                        prepend-icon="email"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="5">
                      <v-text-field
                        v-model="editedItem.name"
                        label="Name"
                        data-cy="name"
                        :rules="requieredRule"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-select
                        :items="roles"
                        item-text="name"
                        item-value="type"
                        label="Nutzertyp"
                        data-cy="role"
                        v-model="editedItem.role"
                        :rules="requieredRule"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn color="secondary" text @click="close">Abbrechen</v-btn>
              <v-btn color="secondary" text @click="save" data-cy="saveEvent"
                >Speichern</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogPW" max-width="800px">
          <v-card>
            <v-card-title>
              <span class="headline">neues Passwort vergeben</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.password"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="requieredRule"
                        :type="show1 ? 'text' : 'password'"
                        data-cy="password"
                        label="Passwort"
                        hint="Minimum 8 Zeichen"
                        counter
                        :error="passwordError"
                        @click:append="show1 = !show1"
                        dense
                      ></v-text-field>
                      <p v-if="passwordError">
                        Passwörter stimmen nicht überein.
                      </p>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.password1"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="requieredRule"
                        :type="show1 ? 'text' : 'password'"
                        data-cy="password1"
                        label="Passwort wiederholen"
                        hint="Minimum 8 Zeichen"
                        counter
                        :error="passwordError"
                        @click:append="show1 = !show1"
                        dense
                      ></v-text-field>
                      <p v-if="passwordError">
                        Passwörter stimmen nicht überein.
                      </p>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn color="secondary" text @click="dialogPW = false"
                >Abbrechen</v-btn
              >
              <v-btn
                color="secondary"
                text
                @click="updatePassword"
                data-cy="saveEvent"
                >Speichern</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.type="{ item }">
      <div v-if="item.role === 'Admin'">Wartungsbenutzer</div>
      <div v-if="item.role === 'Worker'">Fahrer</div>
      <div v-if="item.role === 'User'">Benutzer</div>
    </template>
    <template v-slot:item.action="{ item }">
      <v-dialog v-model="deleteDialog" max-width="500">
        <v-card>
          <loading
            :active.sync="userStatusPending"
            :can-cancel="false"
            :is-full-page="true"
          ></loading>
          <v-card-title class="headline">Datensatz löschen?</v-card-title>
          <v-card-text>Soll der Benutzer wirklich gelöscht werden?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="deleteDialog = false"
              >Abbrechen</v-btn
            >
            <v-btn
              color="secondary"
              text
              @click="deleteItem()"
              data-cy="deleteEvent"
              >Jetzt löschen</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            medium
            class="mr-2"
            v-on="on"
            @click="editItem(item)"
            data-cy="edit"
            >edit</v-icon
          >
        </template>
        <span>Bearbeiten</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            medium
            class="mr-2"
            v-on="on"
            @click="changePassword(item)"
            data-cy="changePassword"
            >vpn_key</v-icon
          >
        </template>
        <span>neues Passwort vergeben</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            medium
            v-on="on"
            @click="openDeleteDialog(item)"
            data-cy="delete"
            >delete</v-icon
          >
        </template>
        <span>Löschen</span>
      </v-tooltip>
    </template>
    <template v-slot:no-data> keine Daten vorhanden </template>
  </v-data-table>
</template>
<script>
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
export default {
  components: {
    Loading,
  },
  data: () => ({
    search: "",
    menu: false,
    snackbar: {
      visible: false,
      text: "Snackbar ...",
    },
    dialog: false,
    dialogPW: false,
    dialogEdit: false,
    show1: false,
    passwordError: false,
    headers: [
      {
        text: "Email",
        align: "left",
        sortable: false,
        value: "email",
      },
      { text: "Name", value: "name", sortable: false },
      { text: "Typ", value: "type", sortable: false },
      {
        text: "Erstellungsdatum",
        value: "formattedCreatedAt",
        sortable: false,
      },
      { text: "", value: "action", align: "right", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      email: "",
      password: "",
      password1: "",
      role: "",
      prename: "",
      surname: "",
    },
    roles: [
      { name: "Fahrer (nur für App)", type: "Worker" },
      { name: "Benutzer (Disponent für Weboberfläche)", type: "User" },
    ],
    valid: true,
    requieredRule: [(v) => !!v || "Dieses Feld wird benötigt"],
    deleteDialog: false,
    toDeleteItem: null,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Neuer Benutzer" : "Benutzer bearbeiten";
    },
    ...mapGetters(["userList", "userStatusPending"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize(searchKey) {
      this.$store.dispatch("getUserList", searchKey);
    },

    editItem(item) {
      this.editedIndex = 1;
      this.editedItem = Object.assign({}, item);
      this.dialogEdit = true;
    },
    openDeleteDialog(item) {
      this.toDeleteItem = Object.assign({}, item);
      this.deleteDialog = true;
    },
    changePassword(item) {
      this.editedIndex = 1;
      this.editedItem = Object.assign({}, item);
      this.editedItem.password = "";
      this.editItem.password1 = "";
      this.dialogPW = true;
    },
    deleteItem() {
      let vm = this;
      this.$store.dispatch("deleteUser", this.toDeleteItem).then((result) => {
        vm.deleteDialog = false;
        vm.toDeleteItem = null;
      });
    },

    close() {
      this.dialog = false;
      this.dialogEdit = false;
      this.dialogPW = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
      this.$refs.form.reset();
    },

    updatePassword() {
      let vm = this;
      if (this.editedItem.password === this.editedItem.password1) {
        if (vm.$refs.form.validate()) {
          vm.$store
            .dispatch("updateUser", {
              password: vm.editedItem.password.trim(),
              id: vm.editedItem.id,
            })
            .then((result) => {
              vm.close();
            });
        }
      } else {
        this.passwordError = true;
      }
    },

    save() {
      let vm = this;

      if (this.editedIndex > -1) {
        if (vm.$refs.form.validate()) {
          vm.$store
            .dispatch("updateUser", {
              email: vm.editedItem.email.trim(),
              role: vm.editedItem.role,
              name: vm.editedItem.name,
              id: vm.editedItem.id,
            })
            .then((result) => {
              vm.close();
            });
        }
      } else {
        if (this.editedItem.password === this.editedItem.password1) {
          if (vm.$refs.form.validate()) {
            vm.editedItem.name =
              vm.editedItem.prename + " " + vm.editedItem.surname;
            //trim passwords
            vm.editedItem.password = vm.editedItem.password.trim();
            vm.editedItem.password1 = vm.editedItem.password1.trim();
            vm.$store.dispatch("addUser", vm.editedItem).then((r) => {
              vm.close();
            });
          }
        } else {
          this.passwordError = true;
        }
      }
    },
    searchData() {
      this.initialize(this.search);
    },
  },
};
</script>
<style>
.expanded__content {
  box-shadow: none !important;
  background: white !important;
}
</style>
