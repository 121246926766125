<template>
  <v-data-table
    :headers="headers"
    :options.sync="errorListGetter.pagination"
    :server-items-length="errorListGetter.pagination.totalItems"
    :items="errorListGetter.errorList.data"
    :loading="errorStatusPending"
    :search="search"
    :footer-props="{
      itemsPerPageOptions: errorListGetter.pagination.rowsPerPageItems,
      itemsPerPageText: 'Zeilen pro Seite'
    }"
    class="pa-5"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Fehlermeldungen</v-toolbar-title>
      </v-toolbar>
    </template>

    <template v-slot:no-data>
      keine Daten vorhanden
    </template>
  </v-data-table>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    menu: false,
    snackbar: {
      visible: false,
      text: "Snackbar ..."
    },
    headers: [
      {
        text: "Statuscode",
        align: "left",
        sortable: false,
        value: "statusCode"
      },
      { text: "ErrorMessage", value: "message", sortable: false },
      {
        text: "Zeitpunkt des Auftretens",
        value: "formattedLastModified",
        sortable: false
      },
      { text: "erzeugt von", value: "user.email", sortable: false }
    ],
    editedIndex: -1,
    valid: true
  }),

  computed: {
    pagination: {
      get: function() {
        return this.$store.getters.errorListGetter.pagination;
      },
      set: function(value) {
        this.$store.commit("SET_PAGINATION_ERROR", value);
      }
    },
    ...mapGetters(["errorListGetter", "errorStatusPending"])
  },

  watch: {
    pagination: {
      handler() {
        this.$store.dispatch("getErrorList");
      }
    },
    dialog(val) {
      val || this.close();
    }
  },
  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$store.dispatch("getErrorList");
    }
  }
};
</script>
<style>
.expanded__content {
  box-shadow: none !important;
  background: white !important;
}
</style>
