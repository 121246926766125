<template>
  <div>
    <v-list flat subheader three-line>
      <v-list-item>
        <v-list-item-content>
          <v-container>
            <v-card class="mx-auto" max-width="900">
              <v-form class="pa-10" ref="form" lazy-validation>
                <p>Vordefinierte Lieferscheinpositionen Vorlagen festlegen</p>
                <h5>
                  Achtung, Vorlagen können nicht bearbeitet, nur gelöscht/ neu
                  angelegt werden.
                </h5>
                <br />
                <v-row>
                  <v-card min-width="800px" flat>
                    <div style="height: 100%">
                      <v-toolbar flat color="white">
                        <div class="flex-grow-1"></div>
                        <v-dialog v-model="dialog" max-width="900px">
                          <template v-slot:activator="{ on }">
                            <v-btn color="secondary" dark class="mb-2" v-on="on"
                              >Neue Vorlage</v-btn
                            >
                          </template>
                          <v-card>
                            <v-card-title>
                              <span class="headline">{{ formTitle }}</span>
                            </v-card-title>
                            <v-card-text>
                              <v-container>
                                <v-form
                                  ref="form"
                                  v-model="valid"
                                  lazy-validation
                                >
                                  <v-row>
                                    <v-col cols="12" sm="12" md="8">
                                      <v-text-field
                                        v-model="defaultItem.name"
                                        label="Name / Bezeichnung der Vorlage"
                                        data-cy="name"
                                        :rules="requieredRule"
                                        dense
                                        outlined
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <itemPosForm
                                      v-model="defaultItem.positions"
                                      :prices="false"
                                      :onlyInventoryItems="true"
                                    />
                                  </v-row>
                                </v-form>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <div class="flex-grow-1"></div>
                              <v-btn color="secondary" text @click="close"
                                >Abbrechen</v-btn
                              >
                              <v-btn color="secondary" text @click="save"
                                >Speichern</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-toolbar>
                      <v-simple-table v-if="deliveryNoteTemplates.length > 0">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Nr.</th>
                              <th class="text-left">Bezeichnung</th>
                              <th class="text-left">Anzahl Artikel</th>
                              <th class="text-left">Erstellt am</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(item, index) in deliveryNoteTemplates"
                              :key="item.reference"
                            >
                              <td>{{ item.objectId }}</td>
                              <td>{{ item.name }}</td>
                              <td>{{ item.positions.length }}</td>
                              <td>{{ item.formattedCreatedAt }}</td>
                              <td>
                                <v-dialog
                                  v-model="deleteDialog"
                                  max-width="500"
                                >
                                  <v-card>
                                    <v-card-title class="headline"
                                      >Datensatz löschen?</v-card-title
                                    >
                                    <v-card-text v-if="deleteItem">
                                      Soll die Vorlage "{{ deleteItem.name }}"
                                      wirklich gelöscht werden?
                                    </v-card-text>
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        color="secondary"
                                        text
                                        @click="deleteDialog = false"
                                        >Abbrechen</v-btn
                                      >
                                      <v-btn
                                        color="secondary"
                                        text
                                        @click="triggerDeleteItem(item, index)"
                                        >Jetzt löschen</v-btn
                                      >
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                                <!--v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-icon
                                      medium
                                      class="mr-2"
                                      v-on="on"
                                      @click="editItem(item, index)"
                                      >edit</v-icon
                                    >
                                  </template>
                                  <span>Bearbeiten</span>
                                </v-tooltip-->
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-icon
                                      medium
                                      class="mr-2"
                                      v-on="on"
                                      @click="triggerDeleteDialog(item, index)"
                                      >delete</v-icon
                                    >
                                  </template>
                                  <span>Löschen</span>
                                </v-tooltip>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <br />
                    </div>
                  </v-card>
                </v-row>
              </v-form>
            </v-card>
          </v-container>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import itemPosForm from "../../components/documents/itemPosForm.vue";
export default {
  components: {
    itemPosForm,
  },
  data: () => ({
    dialog: false,
    deleteDialog: false,
    deleteItem: null,
    editedIndex: -1,
    defaultItem: {
      name: "",
      positions: [
        {
          name: "",
          description: "",
          quantity: 1,
          unity: "Stk",
          posId: 1,
          price: 0.0,
          taxRate: 0,
          inventory: true,
        },
      ],
    },
    defaultIndex: null,
    requieredRule: [(v) => !!v || "Dieses Feld wird benötigt"],
    valid: true,
    templates: [],
    deliveryNoteTemplates: {},
  }),
  created() {
    this.initialize();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Neue Vorlage" : "Vorlage bearbeiten";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    initialize() {
      this.$store
        .dispatch("getDeliveryNotePositionTemplates")
        .then((result) => {
          this.deliveryNoteTemplates = result.data;
        });
    },
    close() {
      this.dialog = false;
      this.editedIndex = -1;
      //this.$refs.form.reset();
    },
    editItem(item, index) {
      this.defaultItem = Object.assign({}, item);
      item.positions.forEach((element, index) => {
        this.defaultItem.positions[index] = element.inventoryItem;
        this.defaultItem.positions[index].quantity =
          element.inventoryItem.quantity;
        this.defaultItem.positions[index].inventory = true;
      });
      console.error(this.defaultItem);
      this.defaultIndex = index;
      this.editedIndex = 1;
      this.dialog = true;
    },
    triggerDeleteDialog(item, index) {
      this.deleteDialog = true;
      this.deleteItem = item;
      this.defaultIndex = index;
    },
    triggerDeleteItem(item, index) {
      this.deliveryNoteTemplates.splice(this.defaultIndex, 1);
      this.$store
        .dispatch("deleteSingleDeliveryNotePositionTemplates", item)
        .then((result) => {
          this.deleteDialog = false;
          this.deleteItem = null;
        });
    },
    save() {
      let vm = this;
      if (this.editedIndex > -1) {
        if (vm.$refs.form.validate()) {
          //on edit
          //replace item
          let postTemplate = vm.deliveryNoteTemplates;
          deliveryNoteTemplates[this.defaultIndex] = vm.defaultItem;
          vm.$store
            .dispatch("updateSingleDeliveryNotePositionTemplates", postTemplate)
            .then((result) => {
              vm.close();
            });
        }
      } else {
        if (vm.$refs.form.validate()) {
          //on new item
          vm.deliveryNoteTemplates.push(vm.defaultItem);
          //format data to data model
          vm.defaultItem.positions.forEach((element, index) => {
            vm.defaultItem.positions[index].inventoryItem = element.name;
            vm.defaultItem.positions[index].quantity = element.quantity;
          });
          vm.$store
            .dispatch("addSingleDeliveryNotePositionTemplates", vm.defaultItem)
            .then((result) => {
              vm.close();
            });
        }
      }
    },
  },
};
</script>
<style>
.expanded__content {
  box-shadow: none !important;
  background: white !important;
}
</style>
