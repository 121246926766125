<script>
import Loading from "vue-loading-overlay";
import { mapGetters } from "vuex";
import { Doughnut } from "vue-chartjs";

export default {
  name: "revenuecontactchart",
  extends: Doughnut,
  components: {
    Loading
  },
  props: [],
  data: () => ({
    revenueContactSet: false
  }),
  created() {
    this.initialze();
  },
  computed: {
    ...mapGetters(["revenueContactData"])
  },
  watch: {
    revenueContactData: {
      handler(val) {
        if (val.data.length != 0) {
          this.revenueContactSet = true;
        }
      }
    },
    revenueContactSet: {
      handler(val) {
        if (val && this.revenueContactData) {
          this.createChart();
        }
      }
    }
  },
  methods: {
    initialze() {
      this.$store.dispatch("getRevenuePerContact");
    },
    generateColorScheme(data) {
      let start = 100;
      let colors = [];

      let step = Math.round(80 / data.length);
      let op = Math.round((0.4 / data.length) * 100) / 100;

      for (let i = 0; i < data.length; i++) {
        colors.push(
          "rgba(00, " +
            (start + i * step) +
            ", 00, " +
            (1 - (i * op + 0.3)) +
            ")"
        );
      }

      return colors;
    },
    createChart() {
      let labelsData = this.revenueContactData.data[0].labels;
      let revenueData = this.revenueContactData.data[0].data;
      this.renderChart(
        {
          labels: labelsData,
          datasets: [
            {
              label: "Umsatz in EUR",
              data: revenueData,
              backgroundColor: this.generateColorScheme(
                this.revenueContactData.data[0].data
              )
            }
          ]
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          layout: {
            padding: {
              left: 50,
              right: 0,
              top: 0,
              bottom: 0
            }
          },
          tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                      var dataset = data.datasets[tooltipItem.datasetIndex];
                      var d = dataset.data[tooltipItem.index];
                      return (Math.round(d * 100) / 100).toFixed(2).toString().replace('.', ',').replace(',00', '') + ' EUR';
                    }
                }
            }
        }
      );
    }
  }
};
</script>
