import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VList,{attrs:{"flat":"","subheader":"","three-line":""}},[_c(VSubheader,[_c('h4',[_vm._v("Projekt-Einstellungen")])])],1),_c(VListItem,[_c(VListItemContent,[_c(VContainer,[_c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"900"}},[_c(VContainer,[_c('p',[_vm._v("Projekt-Einstellungen")])]),_c(VForm,{ref:"form",staticClass:"pa-4",attrs:{"lazy-validation":""}},[_c('p',[_vm._v("Lieferscheinbetreff in Projektübersicht anzeigen")]),_c('h5',[_vm._v(" Ist diese Option aktiviert, wird der komplette Lieferscheinbetreff innerhalb der Projekttabelle angezeigt. ")]),_c('br'),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('p',{staticStyle:{"margin-top":"25px"}},[_vm._v(" Lieferscheinbetreff in Leistungsübersicht anzeigen ")])]),_c(VCol,{attrs:{"cols":"12","sm":"6","md":"6"}},[_c(VSwitch,{on:{"change":_vm.deliveryNoteTitleToProject},model:{value:(_vm.copyDeliveryNoteTitleToProject),callback:function ($$v) {_vm.copyDeliveryNoteTitleToProject=$$v},expression:"copyDeliveryNoteTitleToProject"}})],1)],1),_c('hr'),_c('br'),_c('br'),_c('p',[_vm._v("Kennzeichen in Projektübersicht anzeigen")]),_c('h5',[_vm._v(" Ist diese Option aktiviert, wird das Kennzeichen eines Lieferscheins, falls vorhanden angezeigt. ")]),_c('br'),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('p',{staticStyle:{"margin-top":"25px"}},[_vm._v(" Kennzeichen in Projektübersicht anzeigen ")])]),_c(VCol,{attrs:{"cols":"12","sm":"6","md":"6"}},[_c(VSwitch,{on:{"change":_vm.licenseToProject},model:{value:(_vm.displayLicenseToProject),callback:function ($$v) {_vm.displayLicenseToProject=$$v},expression:"displayLicenseToProject"}})],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }