<template>
  <div>
    <v-toolbar>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon :to="backUrl" data-cy="back">
            <v-icon v-on="on">arrow_back</v-icon>
          </v-btn>
        </template>
        <span>zurück</span>
      </v-tooltip>
      <v-toolbar-title>{{ this.$route.params.header }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-row justify="space-around" class="pt-4 pb-4">
      <v-col cols="12" sm="6" md="8">
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="6" md="12">
                    <v-text-field
                      v-model="item.email.recipient"
                      label="Empfängeradresse"
                      data-cy="recipient"
                      :rules="requieredRule"
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="6" md="12">
                    <v-text-field
                      v-model="item.email.subject"
                      label="Betreff"
                      data-cy="subject"
                      :rules="requieredRule"
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="12">
                    <p>Nachricht</p>
                    <quill-editor
                      v-model="item.email.msg"
                      :options="editorOption"
                      outlined
                      dense
                    ></quill-editor>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="12">
                    <p>Anhänge</p>
                    <v-row>
                      <v-chip class="ma-2" label>
                        {{ attachment }}
                      </v-chip>
                      <v-chip
                        class="ma-2"
                        label
                        v-if="item.email.attachDeliveryNote && hasDeliveryNote"
                      >
                        {{ delNote + ".pdf" }}
                      </v-chip>
                      <div v-if="photos.length > 0">
                        <div v-for="(image, index) in photos" :key="image.data">
                          <v-chip
                            close
                            close-icon="mdi-delete"
                            class="ma-2"
                            label
                            @click:close="removeImage(index)"
                            @click="openPhotoDialog = true"
                          >
                            <v-avatar left>
                              <v-img
                                :src="
                                  image.data.substring(0, 15) ==
                                  'data:image/jpeg'
                                    ? image.data
                                    : 'data:image/jpeg;base64,' + image.data
                                "
                              ></v-img>
                            </v-avatar>
                            {{ image.name }}
                          </v-chip>
                        </div>
                      </div>
                    </v-row>
                  </v-col>
                  <v-dialog
                    v-model="openPhotoDialog"
                    max-width="1400px"
                    persistent
                  >
                    <photoDialog
                      @close="closePhotoDialog"
                      :editItem="{ photos: photos }"
                    />
                  </v-dialog>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-checkbox
                      v-if="doctype == 'invoice' && hasDeliveryNote"
                      v-model="item.email.attachDeliveryNote"
                      label="Lieferschein als Anhang versenden"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="item.copyToSelf"
                      label="Kopie an mich selbst senden"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="secondary" text :to="backUrl">Zurück</v-btn>
            <v-btn color="secondary" text @click="send" data-cy="sendEvent"
              >Senden</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";

import photoDialog from "../planer/components/eventForm/photoDialog.vue";
export default {
  components: {
    photoDialog
  },
  data: () => ({
    attachment: "",
    item: {
      copyToSelf: false,
      id: null,
      doctype: null,
      email: {
        attachDeliveryNote: false,
        recipient: "",
        subject: "",
        msg: "",
        photos: []
      }
    },
    editorOption: {
      modules: {
        toolbar: true
      }
    },
    valid: true,
    requieredRule: [v => !!v || "Dieses Feld wird benötigt"],
    openPhotoDialog: false,
    hasDeliveryNote: false,
    delNote: "",
    formTitle: "",
    backUrl: null,
    defaultText: null,
    doctype: null,
    photos: []
  }),
  mounted() {
    this.mountedMethod(JSON.parse(this.$route.params.document));
  },
  computed: {
    ...mapGetters(["backendServer"])
  },
  watch: {
    "$route.params": function(params) {
      this.mountedMethod(JSON.parse(params.document));
    }
  },
  methods: {
    mountedMethod(doc) {
      let vm = this;
      this.doctype = this.$route.params.doctype;
      this.defaultText = this.$route.params.defaultText;
      switch (this.doctype) {
        case "deliveryNote":
          this.formTitle = "Lieferschein per Mail versenden";

          // load single delivery note to get photos delivered to frontend
          this.$store.dispatch("getSingleDeliveryNote", doc.id).then(r => {
            if (r.event.photos) {
              vm.photos = r.event.photos;
            }
          });
          this.backUrl = "/documents/1/lieferscheine";
          this.resetData(doc, JSON.parse(this.$route.params.header));
          break;
        case "invoice":
          this.formTitle = "Rechnung per Mail versenden";
          this.backUrl = "/documents/2/rechnungen";
          this.resetData(doc, JSON.parse(this.$route.params.header));
          break;
        case "offer":
          this.formTitle = "Angebot per Mail versenden";
          this.backUrl = "/documents/3/angebote";
          this.resetData(doc, JSON.parse(this.$route.params.header));
          break;
        case "project":
          this.formTitle = "Projektübersicht per Mail versenden";
          this.backUrl = "/projects";
          this.resetData(doc, JSON.parse(this.$route.params.header));
          break;
      }
    },
    resetData(doc, header) {
      this.clearData();
      this.item.id = doc.id;
      this.item.email.subject = header;
      this.attachment = header + ".pdf";

      if (
        (doc.deliveryNote || doc.isProjectInvoice) &&
        this.doctype == "invoice"
      ) {
        this.delNote = doc.deliveryNote
          ? doc.deliveryNote.formattedDeliveryNoteNumber
          : "Leistungsübersicht Projekt Nr. " +
            doc.project.formattedProjectNumber;
        this.hasDeliveryNote = true;
      }

      this.$store.dispatch("getUserInfo").then(val => {
        this.$store.dispatch("userNameInEmailTemplate").then(r => {
          if (r) {
            //set user name
            this.item.email.msg = this.defaultText + val.name;
          } else {
            //do not set user name
            this.item.email.msg = this.defaultText;
          }
        });
        this.item.myMail = val.email;

        if (this.item.email.recipient === "" && doc) {
          //set email from contact
          let contactID = null;
          doc.contact.id
            ? (contactID = doc.contact.id)
            : (contactID = doc.contact);
          this.$store.dispatch("getSingleContact", contactID).then(e => {
            if (e.email || e.invoicingEmail) {
              //normal email in contact
              this.item.email.recipient = e.email;
              if (this.doctype == "invoice" && e.invoicingEmail) {
                //invoice email
                this.item.email.recipient = e.invoicingEmail;
              } else if (this.doctype == "deliveryNote") {
                //set delivery note email from event
                doc.event.email
                  ? (this.item.email.recipient = doc.event.email)
                  : "";
              }
            }
          });
        }
      });
    },
    send() {
      if (this.$refs.form.validate()) {
        //set doctype
        this.item.doctype = this.doctype;
        //set images if present
        if (this.photos.length > 0) {
          this.item.email.photos = this.photos;
        }
        this.$store.dispatch("sendViaMail", this.item);
        if (this.item.copyToSelf) {
          this.$store.dispatch("sendViaMailToSelf", this.item);
        }
        this.$router.push({ path: this.backUrl }).then(r => {
          this.clearData();
        });
      }
    },
    clearData() {
      this.item = {
        copyToSelf: false,
        id: null,
        email: {
          recipient: "",
          subject: "",
          msg: "",
          photos: []
        }
      };
    },
    removeImage(index) {
      this.photos.splice(index, 1);
    },
    closePhotoDialog() {
      this.openPhotoDialog = false;
    }
  }
};
</script>
