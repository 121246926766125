<template>
  <v-data-table
    :headers="headers"
    :items="customerList"
    :loading="customerStatusPending"
    class="pa-5"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Kundenportal-Benutzer</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-text-field
          v-model="search"
          label="Suche..."
          data-cy="search"
          single-line
          hide-details
          dense
          @keyup.enter.native="searchData"
        ></v-text-field>
        <v-btn text icon color="secondary" @click="searchData">
          <v-icon>search</v-icon>
        </v-btn>
        <div class="flex-grow-1"></div>
        <v-dialog v-model="dialog" max-width="800px">
          <template v-slot:activator="{ on }">
            <v-btn color="secondary" dark class="mb-2" v-on="on">Neuer Benutzer</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row>
                    <v-col cols="12" sm="6" md="8">
                      <v-text-field
                        v-model="item.name"
                        label="Name"
                        :rules="requieredRule"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="item.accountID"
                        label="Nutzerkennung"
                        :rules="requieredRule"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="8">
                      <v-autocomplete
                        v-model="item.contact"
                        :items="contactDataMap"
                        :loading="allStatusPendingWithoutPagnation"
                        hide-no-data
                        item-text="Description"
                        item-value="id"
                        label="Auftraggeber"
                        data-cy="contact"
                        prepend-icon="mdi-database-search"
                        :rules="requieredRule"
                        dense
                        outlined
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn color="secondary" text @click="close">Abbrechen</v-btn>
              <v-btn color="secondary" text @click="save">Speichern</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <div style="width: 25px"></div>

        <v-dialog v-model="dialogSend" max-width="600px">
          <template v-slot:activator="{ on }">
            <v-btn color="secondary" dark class="mb-2" v-on="on">Einladungsemail</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Einladungsemail versenden</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="customerMail"
                        label="Email"
                        data-cy="email"
                        @input="emailError = false"
                        :rules="requieredRule"
                        :error="emailError"
                        dense
                        prepend-icon="email"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn color="secondary" text @click="close">Abbrechen</v-btn>
              <v-btn color="secondary" text @click="sendMail">Senden</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.action="{ item }">
      <v-dialog v-model="deleteDialog" max-width="500">
        <v-card>
          <loading
            :active.sync="customerStatusPending"
            :can-cancel="false"
            :is-full-page="true"
          ></loading>
          <v-card-title class="headline">Datensatz löschen?</v-card-title>
          <v-card-text>Soll der Zugang wirklich gelöscht werden?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="deleteDialog = false">Abbrechen</v-btn>
            <v-btn color="secondary" text @click="deleteItem()">Jetzt löschen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon medium v-on="on" @click="openDeleteDialog(item)">delete</v-icon>
        </template>
        <span>Löschen</span>
      </v-tooltip>
    </template>
    <template v-slot:item.formattedContactName="{ item }">
      <div v-if="item.contact.type == 'Person'">
        {{ item.surname + " " }} {{ item.contact.familyname + " " }}
        {{ item.contact.name ? item.name + " " : "" }}
        {{ item.contact.name2 ? item.contact.name2 : "" }}
      </div>
      <div v-if="item.contact.type == 'Company'">
        {{ item.contact.name ? item.contact.name + " " : "" }}
        {{ item.contact.name2 ? item.contact.name2 : "" }}
      </div>
    </template>
    <template v-slot:no-data> keine Daten vorhanden </template>
  </v-data-table>
</template>
<script>
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import axios from "axios";
import contactFormWrapper from "./../contact/createContact/contactFormWrapper.vue";

export default {
  components: {
    Loading,
    contactFormWrapper,
  },
  data: () => ({
    search: "",
    menu: false,
    snackbar: {
      visible: false,
      text: "Snackbar ...",
    },
    dialog: false,
    dialogSend: false,
    show1: false,
    passwordError: false,
    emailError: false,
    customerMail: "",
    headers: [
      { text: "Name", value: "name", sortable: false },
      {
        text: "Kunde",
        align: "left",
        sortable: false,
        value: "formattedContactName",
      },
      {
        text: "Erstellungsdatum",
        value: "formattedCreatedAt",
        sortable: false,
      },
      { text: "Kennung", value: "accountID", sortable: false },
      { text: "", value: "action", align: "right", sortable: false },
    ],
    editedIndex: -1,
    valid: true,
    requieredRule: [(v) => !!v || "Dieses Feld wird benötigt"],
    deleteDialog: false,
    toDeleteItem: null,
    item: {},
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Neuer Kundenzugang" : "Benutzer bearbeiten";
    },
    ...mapGetters([
      "customerList",
      "customerStatusPending",
      "backendServer",
      "allListGetterWithoutPagnation",
      "allStatusPendingWithoutPagnation",
    ]),
    contactDataMap() {
      return this.allListGetterWithoutPagnation.allList.data.map((contact) => {
        let Description;
        if (contact.name) {
          if (contact.name2) {
            Description = contact.name + " " + contact.name2;
          } else {
            Description = contact.name;
          }
        } else {
          Description = contact.familyname;
        }

        contact.address.city != null
          ? (Description = Description + " | " + contact.address.city)
          : "";
        Description = Description + " - " + contact.customerNumber;
        const id = contact.id;
        return Object.assign({}, contact, {
          Description,
          id,
        });
      });
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize(searchKey) {
      this.$store.dispatch("getCustomerList", searchKey);
      this.$store.dispatch("getAllListWithoutPagination");
    },

    openDeleteDialog(item) {
      this.toDeleteItem = Object.assign({}, item);
      this.deleteDialog = true;
    },
    deleteItem() {
      let vm = this;
      this.$store.dispatch("deleteCustomer", this.toDeleteItem).then((result) => {
        vm.deleteDialog = false;
        vm.toDeleteItem = null;
      });
    },

    close() {
      this.dialog = false;
      this.dialogEdit = false;
      this.dialogPW = false;
      this.dialogSend = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
      this.$refs.form.reset();
    },

    save() {
      let vm = this;

      if (vm.$refs.form.validate()) {
        vm.$store.dispatch("addCustomer", vm.item).then((r) => {
          vm.close();
        });
      }
    },
    searchData() {
      this.initialize(this.search);
    },
    sendMail() {
      let vm = this;

      if (!this.customerMail) {
        this.emailError = true;
      } else {
        vm.$store.dispatch("sendCustomerInviteMail", this.customerMail).then((r) => {});
        vm.customerMail = "";
        vm.emailError = false;
        vm.close();
      }
    },
  },
};
</script>
<style>
.expanded__content {
  box-shadow: none !important;
  background: white !important;
}
</style>
