const getters = {
    contactListGetter: state => state.dataContact,
    contactStatusPending: state => state.dataContact.contactList.status.pending,

    allListGetter: state => state.dataAll,
    allStatusPending: state => state.dataAll.allList.status.pending,

    allListGetterWithoutPagnation: state => state.dataAllPagination,
    allStatusPendingWithoutPagnation: state => state.dataAllPagination.allList.status.pending,
    
    kundeListGetter: state => state.dataKunde,
    kundeStatusPending: state => state.dataKunde.kundeList.status.pending,

    lieferantListGetter: state => state.dataLieferant,
    lieferantStatusPending: state => state.dataLieferant.lieferantList.status.pending,

    partnerListGetter: state => state.dataPartner,
    partnerStatusPending: state => state.dataPartner.partnerList.status.pending,

    interessentListGetter: state => state.dataInteressent,
    interessentStatusPending: state => state.dataInteressent.interessentList.status.pending,

    nextContactId: state => state.dataContact.nextContactId
}

export default getters;