<template>
  <div>
    <v-list flat subheader three-line>
      <v-subheader><h4>Prozess-Einstellungen</h4></v-subheader>
    </v-list>
    <v-list-item>
      <v-list-item-content>
        <v-container>
          <v-card class="mx-auto" max-width="900">
            <v-container>
              <p>App-Einstellungen</p>
            </v-container>
            <v-form class="pa-4" ref="form" lazy-validation>
              <p>Tage in der Anzeige</p>
              <h5>
                Hier können Sie einstellen welche Tage Ihren Fahrern angezeigt
                werden, sowohl in die Vergangenheit als auch in die Zukunft. der
                heutige Tag wird immer angezeigt.
              </h5>
              <br />
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    type="number"
                    label="Tage in die Vergangenheit"
                    data-cy="daysBack"
                    v-model="daysBack"
                    outlined
                    dense
                  />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    type="number"
                    label="Tage in die Zukunft"
                    data-cy="daysForward"
                    v-model="daysForward"
                    outlined
                    dense
                  />
                </v-col>
                <v-card-actions>
                  <div class="flex-grow-1"></div>
                  <v-btn color="secondary" text @click="saveAppSettings"
                    >Speichern</v-btn
                  >
                </v-card-actions>
              </v-row>
            </v-form>
          </v-card>
        </v-container>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  data: () => ({
    daysBack: 0,
    daysForward: 0
  }),
  computed: {
    ...mapGetters(["backendServer"])
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      let config = {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      };

      axios
        .get(this.backendServer + "meta/daysInApp", config)
        .then(response => {
          this.daysBack = response.data.value.back;
          this.daysForward = response.data.value.forward;
        });
    },
    saveAppSettings() {
      let vm = this;
      let config = {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      };

      axios
        .put(
          this.backendServer + "meta/daysInApp",
          { back: this.daysBack, forward: this.daysForward },
          config
        )
        .then(response => {
          vm.$store.dispatch("triggerUniversalAlert", {
            text: "Einstellung wurde erfolgreich aktualisiert",
            heading: "Erfolgreich aktualisiert",
            type: "info"
          });
        })
        .catch(error => {
          vm.$store.dispatch("triggerUniversalAlert", {
            text:
              "Es ist ein unerwarteter Fehler aufgetreten bitte kontaktieren Sie den Support",
            heading: "Unerwarteter Fehler",
            type: "error"
          });
        });
    }
  }
};
</script>
