<template>
  <v-card class="mx-auto my-5" max-width="1200">
    <loading
      :active.sync="dialogLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <v-card-title>
      <span class="headline">{{ formTitle }}</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row justify="space-between">
            <v-col cols="12" sm="6" md="7">
              <v-text-field
                v-model="item.header"
                label="Betreff"
                data-cy="header"
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="space-between">
            <contactselectForm
              ref="contactSelect"
              @postAdressValue="postAdressValue"
              v-model="item"
            />
            <!--v-col cols="12" sm="6" md="3">
              <v-text-field
                v-model="item.reference"
                label="Bestellnummer / Referenz"
                data-cy="reference"
                dense
                outlined
              ></v-text-field>
            </v-col-->
          </v-row>
          <v-row justify="space-between">
            <v-col cols="12" sm="5" md="5">
              <quill-editor v-model="item.address" :options="editorOption">
              </quill-editor>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <singleDateForm
                v-model="item.deliveryNoteDate"
                label="Lieferscheindatum"
                data-cy="deliveryNoteDate"
                prependIcon="event"
              />
              <a
                style="float: right"
                href="javascript:void(0);"
                v-if="!item.deliveryEndDate"
                @click="item.deliveryEndDate = true"
                >Lieferzeitraum</a
              >
              <a
                style="float: right"
                href="javascript:void(0);"
                v-if="item.deliveryEndDate"
                @click="
                  item.deliveryEndDate = null;
                  item.deliveryDate = new Date();
                "
                >Lieferdatum</a
              >
              <singleDateForm
                v-if="!item.deliveryEndDate"
                v-model="item.deliveryDate"
                label="Lieferdatum"
                data-cy="deliveryDate"
                prependIcon="event"
              />
              <dateRangeForm
                v-if="item.deliveryEndDate"
                v-model="item.deliveryDate"
                label="Lieferzeitraum"
                data-cy="deliveryDate"
                prependIcon="event"
              />
              <v-text-field
                v-model="item.clientName"
                label="Ansprechpartner"
                data-cy="clientName"
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <br />
          <v-row>
            <v-col cols="12" sm="6" md="12">
              <p>Kopf-Text</p>
              <quill-editor
                v-model="item.headText"
                :options="editorOptionAvail"
              >
              </quill-editor>
            </v-col>
          </v-row>
          <itemPosForm
            v-model="item.deliveryNotePos"
            :contact="item.contact"
            :prices="false"
          />
          <v-row>
            <v-col cols="12" sm="6" md="12">
              <p>Fuß-Text</p>
              <quill-editor
                v-model="item.footText"
                :options="editorOptionAvail"
              >
              </quill-editor>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions class="mb-4">
      <div class="flex-grow-1"></div>
      <v-btn color="secondary" text to="/documents/1/lieferscheine"
        >Zurück</v-btn
      >
      <v-btn
        color="secondary"
        text
        @click="saveWrapper(item)"
        data-cy="saveEvent"
        >Speichern</v-btn
      >
    </v-card-actions>
    <preventDataLossForm
      :dialog="dataLossDialog"
      @dismissData="reactToBeforeRouteLeave"
    />
    <v-dialog
      v-model="activateCheckTitleDialog.visible"
      max-width="600"
      persistent
    >
      <checkTitleDialog
        :item="item"
        :activateCheckTitleDialog="activateCheckTitleDialog"
        @continueSave="save(item)"
        @close="closeCheckTitleDialog()"
      />
    </v-dialog>
    <v-dialog v-model="confirmSaveDialog" max-width="600" persistent>
      <v-card>
        <v-card-title class="headline"> Beleg überschreiben </v-card-title>
        <v-card-text>
          <v-alert dense border="left" type="error">
            Achtung, wenn Sie die Änderungen speichern wird der aktuelle Beleg
            überschrieben und der Lieferschein in den Status "Entwurf" zurück
            versetzt.
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="save(item)">Fortfahren</v-btn>
          <v-btn color="error" text @click="cancelConfirmSave()"
            >Änderungen Verwerfen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
import Loading from "vue-loading-overlay";
import VueQuillEditor, { Quill } from "vue-quill-editor";
import singleDateForm from "../../_utils/singleDateForm.vue";
import dateRangeForm from "../../_utils/dateRangeForm.vue";
import preventDataLossForm from "../preventDataLossForm.vue";
import itemPosForm from "../itemPosForm.vue";
import contactselectForm from "../contactSelectform.vue";
import checkTitleDialog from "../checkTitleDialog.vue";

function getDefaultData() {
  return {
    address: "Kontakt auswählen oder Adresse eingeben",
    deliveryNoteDate: new Date().toISOString().substr(0, 10),
    header: "",
    headText: "",
    footText: "",
    contact: "",
    contactPerson: "",
    clientName: "",
    deliveryDate: new Date().toISOString().substr(0, 10),
    reference: "",
    deliveryEndDate: null,
    deliveryNotePos: [
      {
        name: "",
        description: "",
        quantity: 1,
        unity: "Stk",
        posId: 1
      }
    ]
  };
}

export default {
  components: {
    Loading,
    singleDateForm,
    dateRangeForm,
    preventDataLossForm,
    itemPosForm,
    contactselectForm,
    checkTitleDialog
  },
  data: () => ({
    formTitle: "Lieferschein erstellen",
    item: getDefaultData(),
    valid: true,
    requieredRule: [v => !!v || "Dieses Feld wird benötigt"],
    panel: [false],
    dialogLoading: false,
    confirmSaveDialog: false,
    editorOption: {
      modules: {
        toolbar: false
      }
    },
    editorOptionAvail: {
      modules: {
        toolbar: true
      }
    },
    dataLossDialog: false,
    to: false,
    activateCheckTitleDialog: { valid: true, nextNumber: null, visible: false }
  }),
  mounted() {
    this.initSwitch(this.$route.params);
  },
  watch: {
    "$route.params": function(params) {
      this.initSwitch(params);
    },
    userInfo: {
      handler(val) {
        if (val != null && this.item.contactPerson === "") {
          //on create set username of logged in person to contactperson
          this.item.contactPerson = val.name;
        }
      }
    },
    nextDeliveryNoteId: {
      handler(val) {
        if (val != null && this.item.header === "") {
          //on create set next Invoice Number
          this.item.header = "Lieferschein LI-" + val.deliveryNoteNumber;
        }
      }
    }
  },
  computed: {
    ...mapGetters(["units", "backendServer", "userInfo", "nextDeliveryNoteId"]),
    fields() {
      if (!this.item.resource) return [];

      return Object.keys(this.item.resource).map(key => {
        return {
          key,
          value: this.item.resource[key] || "n/a"
        };
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.params.action == "close") {
      next();
    } else {
      if (this.item != getDefaultData()) {
        if (this.to) {
          next();
        } else {
          this.to = to;
          this.dataLossDialog = true;
        }
      }
    }
  },
  methods: {
    initSwitch(params) {
      if (params.id.length > 1) {
        this.$store.dispatch("getSingleDeliveryNote", params.id).then(r => {
          this.mountedMethod(r);
        });
      } else if (params.id == 0) {
        this.mountedMethod();
      } else if (params.eventData) {
        this.mountedMethod(params.eventData);
      }
    },
    mountedMethod(item) {
      this.resetData();
      // load api data
      this.$store.dispatch("getNextDeliveryNoteId");
      this.$store.dispatch("getUserInfo");
      if (item.status != "fertig") {
        this.formTitle = "Lieferschein bearbeiten";
        this.item = item;
        if (
          item.deliveryEndDate != null &&
          !Array.isArray(this.item.deliveryDate)
        ) {
          this.item.deliveryDate = [item.deliveryDate, item.deliveryEndDate];
        }
      } else if (item.status == "fertig") {
        //if request comes from scheduler:
        this.prepareDataFromScheduler(item);
        this.loadDefaultText();
      } else {
        //set header / footer Text
        this.loadDefaultText();
      }
    },
    loadDefaultText() {
      this.$store.dispatch("getDeliveryNoteText").then(res => {
        this.item.headText = res.value.head;
        this.item.footText = res.value.foot;
      });
    },
    prepareDataFromScheduler(event) {
      let config = {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      };

      axios
        .get(this.backendServer + "resource/" + event.resourceId, config)
        .then(response => {
          let i = this.item;
          let e = event;
          i.event = e._id;
          i.contact = e.contact;
          i.deliveryDate = e.startDate;
          i.photos = e.photos;
          i.license = response.data.license;
          i.deliveryNoteDate = new Date().toISOString().substr(0, 10);
          i.signature = e.signature;
          i.signatureDate = e.signatureDate;
          i.photos = e.photos;
          i.clientName = e.clientName;
          i.reference = e.formattedStartDate + " - " + e.formattedEndDate;
          i.deliveryNotePos = e.eventPos;
          i.deliveryNotePos.forEach(function(element, index) {
            element.deliveryNotePosId = index++;
          });

          this.$refs.contactSelect.createAdressFromContact({
            contact: { id: i.contact }
          });

          //set header like the backend does
          if (this.item.header === "") {
            this.$store.dispatch("getNextDeliveryNoteId").then(r => {
              this.item.header =
                "Lieferschein LI-" + r.deliveryNoteNumber + " | " + e.name;
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    close(val) {
      this.resetData();
      this.$refs.form.reset();
      if (val.id) {
        this.$router.push({
          name: "documentDetails",
          params: { action: "close", id: val.id, from: "lieferscheine" }
        });
      } else {
        this.$router.push({
          name: "deliveryNote",
          params: { action: "close", tab: 1 }
        });
      }
    },
    checkHeader(header) {
      //check header title if document number is correct
      const rx = /LI-[0-9]*/g;
      let arr = header.match(rx);
      arr == null ? (arr = ["LI-0"]) : "";
      return this.$store
        .dispatch("getDeliveryNoteValid", arr[0].substring(3))
        .then(r => {
          return r;
        });
    },
    closeCheckTitleDialog() {
      this.activateCheckTitleDialog = {
        valid: true,
        nextNumber: null,
        visible: false
      };
    },
    saveWrapper(item) {
      if (item.status == "Versendet") {
        this.confirmSaveDialog = true;
      } else {
        this.save(item);
      }
    },
    cancelConfirmSave() {
      this.confirmSaveDialog = false;
      this.close({});
    },
    save(item) {
      let vm = this;
      //check Header
      return vm.checkHeader(item.header).then(function(value) {
        //reset dialog
        vm.activateCheckTitleDialog.visible = false;
        //checkheader values will only be used when invoice is created
        if (!value.valid && vm.formTitle == "Lieferschein erstellen") {
          //open check title dialog header not ok
          vm.activateCheckTitleDialog = value;
          vm.activateCheckTitleDialog.visible = true;
          //return;
        } else {
          //header ok
          //format date from daterange picker
          if (Array.isArray(item.deliveryDate)) {
            let cpdate = item.deliveryDate;
            item.deliveryDate = cpdate[0];
            item.deliveryEndDate = cpdate[1];
          }
          if (vm.$refs.form.validate()) {
            //format Data for Api
            if (vm.formTitle === "Lieferschein erstellen") {
              vm.$store.dispatch("addDeliveryNotes", item).then(r => {
                let event = vm.$route.params.eventData;
                if (vm.$route.params.eventData != null) {
                  //change color of event, when delivery note is created from it
                  event.name = event.name + " -> " + vm.item.header;
                  event.eventColor = "green";
                  event.deliveryNote = r.id;
                  vm.$store.dispatch("updateEvent", event);
                }
                // when project is added in event, put deliveryNote to project
                if (event.project != null) {
                  const value = {
                    projectId: event.project,
                    deliveryNote: r.id
                  };
                  vm.$store.dispatch("addDeliveryNoteToProject", value);
                }
                vm.close(r);
              });
            } else {
              vm.$store.dispatch("updateDeliveryNotes", item).then(r => {
                vm.close(r);
              });
            }
          }
        }
      });
    },
    resetData() {
      this.item = getDefaultData();
    },
    reactToBeforeRouteLeave(val) {
      if (val) {
        this.dataLossDialog = false;
        this.$router.push(this.to);
      } else {
        this.dataLossDialog = false;
        this.to = null;
      }
    },
    postAdressValue(val) {
      //set adress emitted from child adress select
      this.item.address = val;
    }
  }
};
</script>
<style scoped>
.ql-editor {
  min-height: 120px !important;
  font-size: 16px;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px;
}

.theme--light .v-tabs-items {
  background-color: #f3f3fa;
}
</style>
