import axios from "axios";
const actions = {
  queryItemsEvents(context) {
    context.commit("CHANGE_LOADING_STATE_EVENTS", true);

    let items = new Promise((resolve, reject) => {
      let getUrl = context.getters.backendServer + "event";

      let config = {
        headers: {
          authorization: localStorage.getItem("token")
        }
      };
      axios
        .get(getUrl, config)
        .then(function(response) {
          resolve(response.data.data);
        })
        .catch(function(error) {
          context.dispatch("triggerError", error);
          context.commit("CHANGE_LOADING_STATE_EVENTS", false);
          reject();
        });
    });

    Promise.all([items]).then(function(value) {
      context.commit("SET_EVENTS", { items: value[0] });
      context.commit("CHANGE_LOADING_STATE_EVENTS", false);
    });
  },
  queryItemsEventsWithDates(context, dates) {
    context.commit("CHANGE_LOADING_STATE_EVENTS_DATES", true);
    let items = new Promise((resolve, reject) => {
      let getUrl =
        context.getters.backendServer +
        "event?start=" +
        dates.start +
        "&end=" +
        dates.end +
        "&resource=" +
        dates.resource +
        "&worker=" +
        dates.worker;

      let config = {
        headers: {
          authorization: localStorage.getItem("token")
        }
      };
      axios
        .get(getUrl, config)
        .then(function(response) {
          resolve(response.data.data);
        })
        .catch(function(error) {
          context.dispatch("triggerError", error);
          context.commit("CHANGE_LOADING_STATE_EVENTS_DATES", false);
          reject();
        });
    });

    return Promise.all([items]).then(function(value) {
      context.commit("SET_EVENTS_DATES", { items: value[0] });
      context.commit("CHANGE_LOADING_STATE_EVENTS_DATES", false);
      return value[0];
    });
  },

  getSingleEvent(context, id) {
    let getUrl = context.getters.backendServer + "event/" + id;
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    return axios
      .get(getUrl, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },

  getNextEventId(context) {
    let getUrl = context.getters.backendServer + "event/getNextEventId";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_NEXT_EVENT_ID", response.data.eventId);
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },

  eventIsOccupied(context, item) {
    let getUrl =
      context.getters.backendServer +
      "event/isOccupied?start=" +
      item.start +
      "&resource=" +
      item.resource +
      "&duration=" +
      item.duration +
      "&event=" +
      item.id;

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    return axios
      .get(getUrl, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },

  async addEvent(context, value) {
    context.commit("SET_EVENT_ADD");
    let postUrl = context.getters.backendServer + "event";

    let config = {
      headers: {
        authorization: localStorage.getItem("token")
      }
    };
    //check if timeslot is occuppied and cant be used
    let occItem = {
      start: value.startDate.toISOString(),
      resource: value.resourceId,
      duration: value.duration,
      id: value.id
    };

    return context
      .dispatch("eventIsOccupied", occItem)
      .then(async function(val) {
        if (val.isOccupied) {
          return false;
        } else {
          return await axios
            .post(postUrl, value, config)
            .then(function(response) {
              context.commit("SET_EVENT_ADD", response.data);
              context.commit(
                "CREATE_SNACKBAR",
                "Auftrag erfolgreich hinzugefügt"
              );
              //context.dispatch("queryItemsEvents");
              return response.data;
            })
            .catch(function(e) {
              context.commit("SET_EVENT_ADD", e);
              context.dispatch("triggerError", e);
            });
        }
      });
  },
  updateEvent(context, value) {
    context.commit("SET_EVENT_UPDATE");
    let postUrl = context.getters.backendServer + "event/" + value.id;

    let config = {
      headers: {
        authorization: localStorage.getItem("token")
      }
    };
    //check if timeslot is occuppied and cant be used
    let occItem = {
      start: new Date(value.startDate).toISOString(),
      resource: value.resourceId,
      duration: value.duration,
      id: value.id
    };
    return context.dispatch("eventIsOccupied", occItem).then(function(val) {
      if (val.isOccupied) {
        return val.isOccupied;
      } else {
        return axios
          .put(postUrl, value, config)
          .then(function(response) {
            context.commit("CREATE_SNACKBAR", "Auftrag erfolgreich geändert");
            //context.dispatch("queryItemsEvents");
            context.commit("SET_EVENT_UPDATE", response.data);
            return response.data;
          })
          .catch(function(e) {
            context.commit("SET_EVENT_UPDATE", e);
            context.dispatch("triggerError", e);
          });
      }
    });
  },
  deleteEvent(context, value) {
    context.commit("SET_EVENT_DELETE");
    let postUrl = context.getters.backendServer + "event/" + value;

    let config = {
      headers: {
        authorization: localStorage.getItem("token")
      }
    };

    return axios
      .delete(postUrl, config)
      .then(function(ret) {
        context.commit("SET_EVENT_DELETE", "[]");
        context.commit("CREATE_SNACKBAR", "Auftrag erfolgreich gelöscht");
        return true;
      })
      .catch(function(e) {
        context.commit("SET_EVENT_DELETE", e);
        context.dispatch("triggerError", e);
      });
  },

  queryItemsResources(context) {
    context.commit("SET_RESOURCE_LIST");
    let getUrl = context.getters.backendServer + "resource";
    let config = {
      headers: {
        authorization: localStorage.getItem("token")
      }
    };

    return axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_RESOURCE_LIST", response.data.data);
        return response.data.data;
      })
      .catch(function(e) {
        context.commit("SET_RESOURCE_LIST", e);
        context.dispatch("triggerError", e);
      });
  },
  queryItemsWorker(context) {
    context.commit("SET_WORKER_LIST");
    let getUrl = context.getters.backendServer + "user?role=Worker";
    let config = {
      headers: {
        authorization: localStorage.getItem("token")
      }
    };

    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_WORKER_LIST", response.data.data);
      })
      .catch(function(e) {
        context.commit("SET_WORKER_LIST", e);
        context.dispatch("triggerError", e);
      });
  },
  getCurrentEventList(context) {
    context.commit("SET_CURRENT_EVENTS");
    let getUrl = context.getters.backendServer + "resource/event";
    let config = {
      headers: {
        authorization: localStorage.getItem("token")
      }
    };

    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_CURRENT_EVENTS", response.data.data);
      })
      .catch(function(e) {
        context.commit("SET_CURRENT_EVENTS", e);
        context.dispatch("triggerError", e);
      });
  }
};

export default actions;
