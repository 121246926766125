<template>
  <div>
    <v-list flat subheader three-line>
      <v-subheader><h4>Projekt-Einstellungen</h4></v-subheader>
    </v-list>
    <v-list-item>
      <v-list-item-content>
        <v-container>
          <v-card class="mx-auto" max-width="900">
            <v-container>
              <p>Projekt-Einstellungen</p>
            </v-container>
            <v-form class="pa-4" ref="form" lazy-validation>
              <p>Lieferscheinbetreff in Projektübersicht anzeigen</p>
              <h5>
                Ist diese Option aktiviert, wird der komplette
                Lieferscheinbetreff innerhalb der Projekttabelle angezeigt.
              </h5>
              <br />
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <p style="margin-top: 25px">
                    Lieferscheinbetreff in Leistungsübersicht anzeigen
                  </p>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-switch
                    v-model="copyDeliveryNoteTitleToProject"
                    @change="deliveryNoteTitleToProject"
                  ></v-switch>
                </v-col>
              </v-row>
              <hr />
              <br />
              <br />
              <p>Kennzeichen in Projektübersicht anzeigen</p>
              <h5>
                Ist diese Option aktiviert, wird das Kennzeichen eines
                Lieferscheins, falls vorhanden angezeigt.
              </h5>
              <br />
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <p style="margin-top: 25px">
                    Kennzeichen in Projektübersicht anzeigen
                  </p>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-switch
                    v-model="displayLicenseToProject"
                    @change="licenseToProject"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-container>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  data: () => ({
    copyDeliveryNoteTitleToProject: false,
    displayLicenseToProject: false,
  }),
  computed: {
    ...mapGetters(["backendServer"]),
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };
      axios
        .get(this.backendServer + "settings", config)
        .then((response) => {
          for (let setting of response.data.data) {
            if (setting.name == "copyDeliveryNoteTitleToProject") {
              this.copyDeliveryNoteTitleToProject = setting.value;
            }
            if (setting.name == "displayLicenseToProject") {
              this.displayLicenseToProject = setting.value;
            }
          }
        })
        .catch((err) => {});
    },
    licenseToProject() {
      let vm = this;
      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };

      let putURL =
        this.backendServer + "settings/displayLicenseToProject";

      axios
        .put(putURL, { value: this.displayLicenseToProject }, config)
        .then((response) => {
          vm.$store.dispatch("triggerUniversalAlert", {
            text: "Einstellung wurde erfolgreich aktualisiert",
            heading: "Erfolgreich aktualisiert",
            type: "info",
          });
        })
        .catch((err) => {
          vm.$store.dispatch("triggerUniversalAlert", {
            text:
              "Es ist ein unerwarteter Fehler aufgetreten bitte kontaktieren Sie den Support",
            heading: "Unerwarteter Fehler",
            type: "error",
          });
        });
    },
    deliveryNoteTitleToProject() {
      let vm = this;
      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };

      let putURL =
        this.backendServer + "settings/copyDeliveryNoteTitleToProject";

      axios
        .put(putURL, { value: this.copyDeliveryNoteTitleToProject }, config)
        .then((response) => {
          vm.$store.dispatch("triggerUniversalAlert", {
            text: "Einstellung wurde erfolgreich aktualisiert",
            heading: "Erfolgreich aktualisiert",
            type: "info",
          });
        })
        .catch((err) => {
          vm.$store.dispatch("triggerUniversalAlert", {
            text:
              "Es ist ein unerwarteter Fehler aufgetreten bitte kontaktieren Sie den Support",
            heading: "Unerwarteter Fehler",
            type: "error",
          });
        });
    },
  },
};
</script>
