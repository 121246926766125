import { StoreUtil } from "../utils";

const mutations = {
  SET_PAGINATION_INVOICES(state, payload) {
    state.dataInvoice.pagination = payload;
  },
  SET_TOTAL_ITEMS(state, payload) {
    state.dataInvoice.pagination.totalItems = payload;
  },
  SET_INVOICE_LIST(state, payload) {
    state.dataInvoice.invoiceList = StoreUtil.updateState(
      state.dataInvoice.invoiceList,
      payload
    );
  },

  SET_PAGINATION_DELIVERY_NOTES(state, payload) {
    state.dataDeliveryNotes.pagination = payload;
  },
  SET_ITEMS_DELIVERY_NOTES(state, { items, totalItems }) {
    state.dataDeliveryNotes.items = items;
    state.dataDeliveryNotes.pagination.totalItems = totalItems;
  },
  SET_TOTAL_ITEMS_DELIVERY_NOTES(state, payload) {
    state.dataDeliveryNotes.pagination.totalItems = payload;
  },
  CHANGE_LOADING_STATE_DELIVERY_NOTES(state, payload) {
    state.dataDeliveryNotes.loading = payload;
  },
  SET_DELIVERY_NOTES_LIST(state, payload) {
    state.dataDeliveryNotes.deliveryNotesList = StoreUtil.updateState(
      state.dataDeliveryNotes.deliveryNotesList,
      payload
    );
  },

  SET_PAGINATION_OFFER(state, payload) {
    state.dataOffers.pagination = payload;
  },
  SET_ITEMS_OFFER(state, { items, totalItems }) {
    state.dataOffers.items = items;
    state.dataOffers.pagination.totalItems = totalItems;
  },
  SET_TOTAL_ITEMS_OFFER(state, payload) {
    state.dataOffers.pagination.totalItems = payload;
  },
  CHANGE_LOADING_STATE_OFFER(state, payload) {
    state.dataOffers.loading = payload;
  },
  SET_NEXT_OFFER_ID(state, payload) {
    state.dataOffers.nextOfferId = payload;
  },
  SET_OFFER_LIST(state, payload) {
    state.dataOffers.offerList = StoreUtil.updateState(
      state.dataOffers.offerList,
      payload
    );
  },

  SET_INVOICE_ADD(state, payload) {
    StoreUtil.updateState(state.dataInvoice.invoiceList, payload);
  },
  SET_INVOICE_DELETE(state, payload) {
    state.dataInvoice.invoiceList = StoreUtil.updateState(
      state.dataInvoice.invoiceList,
      payload
    );
  },
  SET_INVOICE_UPDATE(state, payload) {
    StoreUtil.updateState(state.dataInvoice.invoiceList, payload);
  },
  SET_NEXT_INVOICE_ID(state, payload) {
    state.dataInvoice.nextInvoiceId = payload;
  },

  SET_DELIVERY_NOTES_ADD(state, payload) {
    StoreUtil.updateState(state.dataDeliveryNotes.deliveryNotesList, payload);
  },
  SET_DELIVERY_NOTES_DELETE(state, payload) {
    StoreUtil.updateState(state.dataDeliveryNotes.deliveryNotesList, payload);
  },
  SET_DELIVERY_NOTES_UPDATE(state, payload) {
    StoreUtil.updateState(state.dataDeliveryNotes.deliveryNotesList, payload);
  },
  SET_NEXT_DELIVERY_NOTE_ID(state, payload) {
    state.dataDeliveryNotes.nextDeliveryNoteId = payload;
  },

  SET_OFFER_ADD(state, payload) {
    StoreUtil.updateState(state.dataOffers.offerList, payload);
  },
  SET_OFFER_DELETE(state, payload) {
    StoreUtil.updateState(state.dataOffers.offerList, payload);
  },
  SET_OFFER_UPDATE(state, payload) {
    StoreUtil.updateState(state.dataOffers.offerList, payload);
  }

  /*DELETE_DATA(state) {
        state.items = [];
    }*/
};

export default mutations;
