<template>
  <v-col cols="12" sm="6" md="3">
    <v-row>
      <v-autocomplete
        v-model="inputVal.kto"
        :items="ktoDataMap"
        :loading="accountStatusPending"
        hide-no-data
        item-text="Description"
        item-value="ktoNr"
        label="Buchungskonten-Nummer"
        data-cy="kto"
        dense
        outlined
      ></v-autocomplete>
    </v-row>
  </v-col>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  props: ["value"],
  data: () => ({
    panel: [false],
    addContact: false
  }),
  mounted() {
    this.$store.dispatch("getAllAccount");
    //get standart Buchungskontennummern if not set
    if (this.inputVal.kto === undefined || this.inputVal.kto === null) {
      this.$store.dispatch("getStandardKto").then(r => {
        this.inputVal.kto = r;
      });
    }
  },
  computed: {
    ...mapGetters([
      "accountDataGetter",
      "accountStatusPending",
      "backendServer"
    ]),
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    ktoDataMap() {
      return this.createKtoDataMap();
    }
  },
  methods: {
    createKtoDataMap() {
      return this.accountDataGetter.map(kto => {
        let Description = kto.name + " - " + kto.accountNumber;
        const ktoNr = kto.accountNumber;
        return Object.assign({}, kto, { Description, ktoNr });
      });
    }
  }
};
</script>
