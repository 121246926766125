import { StoreUtil } from "../utils";

const mutations = {
  SET_EVENTS(state, { items }) {
    state.events = items;
  },
  SET_EVENT(state, { item }) {
    state.event = item;
  },
  SET_NEXT_EVENT_ID(state, payload) {
    state.nextEventId = payload;
  },
  CHANGE_LOADING_STATE_EVENTS(state, payload) {
    state.loading = payload;
  },
  CHANGE_LOADING_STATE_EVENTS_DATES(state, payload) {
    state.loading = payload;
    state.loadingDates = payload;
  },
  SET_EVENTS_DATES(state, { items }) {
    state.eventsDates = items;
  },
  SET_EVENT_ADD(state, payload) {
    StoreUtil.updateState(state.eventList, payload);
  },
  SET_EVENT_UPDATE(state, payload) {
    StoreUtil.updateState(state.eventList, payload);
  },
  SET_EVENT_DELETE(state, payload) {
    StoreUtil.updateState(state.eventList, payload);
  },
  SET_RESOURCE_LIST(state, payload) {
    state.resourcesList = StoreUtil.updateState(state.resourcesList, payload);
  },
  SET_WORKER_LIST(state, payload) {
    state.workerList = StoreUtil.updateState(state.workerList, payload);
  },
  SET_CURRENT_EVENTS(state, payload) {
    state.currentEventList = StoreUtil.updateState(state.currentEventList, payload);
  }
};

export default mutations;
