<template>
  <v-container>
    <br />
    <p>API-Key abrufen</p>
    <br />
    <v-row>
      <v-col cols="12" sm="12" md="3">
        <v-text-field
          v-model="key"
          label="API-Key"
          data-cy="ApiKey"
          outlined
          dense
          disabled
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  data: () => ({
    key: null
  }),

  created() {
    this.initialize();
  },

  computed: { ...mapGetters(["backendServer"]) },

  methods: {
    initialize() {
      let config = {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      };

      axios
        .get(this.backendServer + "meta/billing", config)
        .then(response => {
          this.key = response.data.apiKey;
        })
        .catch(error => {
          this.$store.dispatch("triggerError", error);
        });
    }
  }
};
</script>
