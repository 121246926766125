import axios from "axios";

const actions = {
  getRequestCount(context) {
    context.commit("SET_REQUEST_COUNT");
    let getUrl = context.getters.backendServer + "request/unseen";
    let config = {
      headers: {
        authorization: localStorage.getItem("token")
      }
    };

    axios
      .get(getUrl, config)
      .then(function (response) {
        context.commit("SET_REQUEST_COUNT", response.data.count);
      })
      .catch(function (e) {
        context.dispatch('triggerError', e);
      });
  },
};

export default actions;
