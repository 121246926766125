<template>
  <div>
    <v-data-table
      :headers="headers"
      :options.sync="pagination"
      :server-items-length="pagination.totalItems"
      :loading="offerStatusPending"
      :items="offerListGetter.offerList.data"
      @click:row="clickedRow"
      :footer-props="{
        itemsPerPageOptions: pagination.rowsPerPageItems,
        itemsPerPageText: 'Zeilen pro Seite'
      }"
      class="pa-5"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Angebote</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
            v-model="pagination.search"
            label="Suche..."
            data-cy="search"
            single-line
            hide-details
            dense
            clearable
          ></v-text-field>
          <v-btn text icon color="secondary">
            <v-icon>search</v-icon>
          </v-btn>
          <div class="flex-grow-1"></div>
          <v-btn
            color="secondary"
            dark
            class="mb-2"
            to="angebote/edit/0/new"
            data-cy="newOffer"
            >Neues Angebot</v-btn
          >
        </v-toolbar>
      </template>
      <template v-slot:item.iconStatus="{ item }">
        <div v-if="item.status == 'Entwurf'">
          <v-icon color="grey darken-2">assignment</v-icon>
          {{ item.status }}
        </div>
        <div v-if="item.status == 'Versendet'">
          <v-icon color="secondary">send</v-icon>
          {{ item.status }}
        </div>
        <div v-if="item.status == 'Storniert'">
          <v-icon color="red darken-2">clear</v-icon>
          {{ item.status }}
        </div>
        <div v-if="item.status == 'Auftrag erhalten'">
          <v-icon color="green darken-2">done</v-icon> {{ item.status }}
        </div>
        <div v-if="item.status == 'Angebot abgelehnt'">
          <v-icon color="red darken-2">clear</v-icon> {{ item.status }}
        </div>
      </template>
      <template v-slot:item.recipient="{ item }">
        <!-- display only the first row of the Adress Field -->
        {{
          item.address
            .split("/p>")[0]
            .slice(3)
            .slice(0, -1)
        }}
      </template>
      <template v-slot:item.formattedSumNetto="{ item }">
        {{
          item.netTotal
            .toFixed(2)
            .toString()
            .replace(".", ",")
        }}
        EUR
      </template>
      <template v-slot:item.formattedSumBrutto="{ item }">
        {{
          item.brutTotal
            .toFixed(2)
            .toString()
            .replace(".", ",")
        }}
        EUR
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }" v-if="item.status == 'Entwurf'">
            <v-icon
              class="mr-2"
              v-on="on"
              @click.stop="openMailDialog(item)"
              :disabled="
                item.status === 'Auftrag erhalten' ||
                  item.status === 'Angebot abgelehnt'
              "
              >email</v-icon
            >
          </template>
          <span>Dokument per Email versenden</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              medium
              class="mr-2"
              v-on="on"
              @click.stop="createEventHandler(item)"
              :disabled="item.status === 'Angebot abgelehnt'"
              data-cy="createAuftrag"
              >file_copy</v-icon
            >
          </template>
          <span>Auftrag aus Angebot erstellen</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              medium
              class="mr-2"
              v-on="on"
              @click.stop="acceptedOffer(item, true)"
              :disabled="
                item.status === 'Auftrag erhalten' ||
                  item.status === 'Angebot abgelehnt'
              "
              data-cy="accept"
              >done</v-icon
            >
          </template>
          <span>Auftrag erhalten</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              medium
              class="mr-2"
              v-on="on"
              @click.stop="acceptedOffer(item, false)"
              :disabled="
                item.status === 'Auftrag erhalten' ||
                  item.status === 'Angebot abgelehnt'
              "
              data-cy="decline"
              >clear</v-icon
            >
          </template>
          <span>Angebot abgelehnt</span>
        </v-tooltip>
        <v-tooltip bottom v-if="item.status !== 'Versendet'">
          <template v-slot:activator="{ on }">
            <v-icon
              medium
              class="mr-2"
              v-on="on"
              @click.stop="editItem(item)"
              :disabled="item.status === 'Exportiert'"
              data-cy="edit"
              >edit</v-icon
            >
          </template>
          <span>Bearbeiten</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              medium
              class="mr-2"
              v-on="on"
              @click.stop="clickedRow(item)"
              data-cy="preview"
              >preview</v-icon
            >
          </template>
          <span>Detailansicht / Vorschau</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>keine Daten vorhanden</template>
    </v-data-table>
    <v-dialog v-model="loadPdf" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          PDF wird erstellt...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import { mapFields } from "vuex-map-fields";
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  components: {
    Loading
  },
  data: () => ({
    menu: false,
    snackbar: {
      visible: false,
      text: "Snackbar ..."
    },
    singleExpand: false,
    expanded: [],
    headers: [
      {
        text: "Status",
        align: "left",
        sortable: false,
        value: "iconStatus"
      },
      {
        text: "Angebotsnummer",
        value: "formattedOfferNumber",
        sortable: false
      },
      { text: "Empfänger / Kunde", value: "recipient", sortable: false },
      { text: "Betreff", value: "header", sortable: false },
      { text: "Ansprechpartner", value: "contactPerson", sortable: false },
      { text: "Angebotsdatum", value: "formattedOfferDate", sortable: false },
      { text: "Betrag (netto)", value: "formattedSumNetto", sortable: false },
      { text: "Betrag (brutto)", value: "formattedSumBrutto", sortable: false },
      { text: "", value: "action", align: "right", sortable: false }
    ],
    editedIndex: -1,
    editedItem: null,
    states: ["Entwurf", "Versandt"],
    valid: true,
    requieredRule: [v => !!v || "Dieses Feld wird benötigt"],
    deleteDialog: false,
    toDeleteItem: null,
    loadPdf: false,
    subTablePdf: null
  }),
  computed: {
    pagination: {
      get: function() {
        return this.offerListGetter.pagination;
      },
      set: function(value) {
        this.$store.commit("SET_PAGINATION_OFFER", value);
        this.$store.dispatch("getOfferList");
      }
    },
    ...mapGetters(["offerListGetter", "offerStatusPending", "backendServer"])
  },
  created() {
    this.getAllContacts();
  },
  watch: {
    expanded(val) {
      let vm = this;
      // load pdf data when section is expandet
      if (val[0]) {
        this.getBase64PDF(val[0]).then(function(result) {
          vm.subTablePdf = "data:application/pdf;base64," + result;
        });
      }
    }
  },

  methods: {
    clickedRow(value) {
      this.$router.push({
        name: "documentDetails",
        params: { id: value.id, from: "angebote" }
      });
    },
    getAllContacts() {
      this.$store.dispatch("getAllList");
    },
    editItem(item) {
      this.$router.push({ path: "angebote/edit/" + item.id +"/edit" });
    },

    openDeleteDialog(item) {
      this.toDeleteItem = item;
      this.deleteDialog = true;
    },
    openMailDialog(item) {
      const header = "Angebot AN-" + item.offerNumber;
      this.$store.dispatch("getOfferText").then(res => {
        this.$router.push({
          name: "sendEmail",
          params: {
            document: JSON.stringify(item),
            doctype: "offer",
            defaultText: res.value.mail,
            header: JSON.stringify(header)
          }
        });
      });
    },
    deleteItem(item) {
      let vm = this;
      this.$store.dispatch("deleteOffer", this.toDeleteItem).then(result => {
        vm.deleteDialog = false;
        vm.toDeleteItem = null;
      });
    },

    close() {
      this.dialog = false;
      this.expanded = [];
    },

    createPDF(item) {
      //create pdf for download
      this.loadPdf = true;
      this.getBase64PDF(item).then(function(result) {
        const linkSource = `data:application/pdf;base64,${result}`;
        const downloadLink = document.createElement("a");
        const fileName = "Angebot-" + item.offerNumber + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });
    },
    getBase64PDF(item) {
      let vm = this;
      let postUrl = this.backendServer + "offer/" + item.id + "/base64";
      let config = {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      };
      return axios
        .get(postUrl, config)
        .then(function(response) {
          vm.loadPdf = false;
          return response.data.pdf;
        })
        .catch(function(e) {
          vm.$store.dispatch("triggerError", e);
          vm.loadPdf = false;
        });
    },
    acceptedOffer(item, val) {
      if (val) {
        item.status = "Auftrag erhalten";
      } else {
        item.status = "Angebot abgelehnt";
      }
      this.$store.dispatch("updateOffers", item).then(r => {});
    },
    createEventHandler(item) {
      this.$router.push({
        name: "eventForm",
        params: { offerData: item, id: 1, mode: "fromOffer", back: 1 }
      });
    }
  }
};
</script>
<style></style>
