<template>
  <div style="height: 100%">
    <v-toolbar flat color="white">
      <v-toolbar-title>Buchungskonten</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <!--v-text-field
        v-model="search"
        label="Suche..."
        data-cy="search"
        single-line
        hide-details
        dense
        @keyup.enter.native="searchData"
      ></v-text-field>
      <v-btn text icon color="secondary" @click="searchData">
        <v-icon>search</v-icon>
      </v-btn-->
      <div class="flex-grow-1"></div>
      <v-dialog v-model="dialog" max-width="800px">
        <template v-slot:activator="{ on }">
          <v-btn color="secondary" dark class="mb-2" v-on="on">Neues Buchungskonto</v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.name"
                      label="Name"
                      data-cy="name"
                      :rules="requieredRule"
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.accountNumber"
                      label="Konto Nr."
                      data-cy="accountNumber"
                      :rules="requieredRule"
                      dense
                      outlined
                      type="number"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="secondary" text @click="close">Abbrechen</v-btn>
            <v-btn color="secondary" text @click="save">Speichern</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :options.sync="accountListGetter.pagination"
      :server-items-length="accountListGetter.pagination.totalItems"
      :items="accountDataGetter"
      :loading="accountStatusPending"
      :sort-desc="['true', 'false']"
      item-key="id"
      class="pa-5"
      :footer-props="{
        itemsPerPageOptions: accountListGetter.pagination.rowsPerPageItems,
        itemsPerPageText: 'Zeilen pro Seite',
      }"
    >
      <template v-slot:no-data> keine Daten vorhanden </template>
      <template v-slot:item.action="{ item }">
        <v-dialog v-model="deleteDialog" max-width="500">
          <v-card>
            <loading
              :active.sync="accountStatusPending"
              :can-cancel="false"
              :is-full-page="true"
            ></loading>
            <v-card-title class="headline">Datensatz löschen?</v-card-title>
            <v-card-text v-if="deleteItem">
              Soll das Konto "{{ deleteItem.name }}" wirklich gelöscht werden?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="deleteDialog = false"
                >Abbrechen</v-btn
              >
              <v-btn color="secondary" text @click="triggerDeleteItem(item)"
                >Jetzt löschen</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon medium class="mr-2" v-on="on" @click="editItem(item)">edit</v-icon>
          </template>
          <span>Bearbeiten</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              medium
              v-on="on"
              @click="triggerDeleteDialog(item)"
              :disabled="item.standard"
              >delete</v-icon
            >
          </template>
          <span>Löschen</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  components: {
    Loading,
  },
  data: () => ({
    dialog: false,
    deleteDialog: false,
    deleteItem: null,
    search: "",
    snackbar: {
      visible: false,
      text: "Snackbar ...",
    },
    headers: [
      { text: "Bezeichnung", align: "left", value: "name", sortable: false },
      { text: "Konto Nr.", align: "left", value: "accountNumber", sortable: false },
      { text: "", value: "action", align: "right", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      name: null,
      accountNumber: null,
    },
    requieredRule: [(v) => !!v || "Dieses Feld wird benötigt"],
    valid: true,
  }),

  computed: {
    pagination: {
      get: function () {
        return this.$store.getters.accountListGetter.pagination;
      },
      set: function (value) {
        this.$store.commit("SET_PAGINATION_INVENTORY", value);
      },
    },
    formTitle() {
      return this.editedIndex === -1 ? "Neues Buchungskonto" : "Buchungskonto bearbeiten";
    },
    ...mapGetters([
      "accountListGetter",
      "accountDataGetter",
      "accountStatusPending",
      "backendServer",
    ]),
  },
  watch: {
    pagination: {
      handler() {
        this.$store.dispatch("getAccountList");
      },
    },
    dialog(val) {
      val || this.close();
      let vm = this;
      if (this.editedItem.accountNumber == null) {
        let getUrl = this.backendServer + "accounting/account/getNextAccountNumber";

        let config = {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        };

        axios
          .get(getUrl, config)
          .then((response) => {
            vm.editedItem.accountNumber = parseInt(response.data.accountNumber);
          })
          .catch((err) => {
            this.$store.dispatch("triggerError", err);
          });
      }
    },
  },

  mounted() {
    this.$store.dispatch("getAllAccount");
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch("getAccountList");
    },
    close() {
      this.dialog = false;
      this.editedItem = {};
      this.editedIndex = {
        name: null,
        accountNumber: null,
      };
      this.$refs.form.reset();
    },
    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.editedIndex = 1;
      this.dialog = true;
    },
    /*searchData() {
      this.$store.dispatch("getAccountList", this.search);
    },*/
    triggerDeleteDialog(item) {
      this.deleteDialog = true;
      this.deleteItem = item;
    },
    triggerDeleteItem(item) {
      let vm = this;
      this.$store.dispatch("deleteAccountItem", this.deleteItem).then((result) => {
        vm.deleteDialog = false;
        vm.deleteItem = null;
      });
    },
    save() {
      let vm = this;
      if (this.editedIndex > -1) {
        if (vm.$refs.form.validate()) {
          vm.$store.dispatch("updateAccount", vm.editedItem).then((result) => {
            vm.close();
          });
        }
      } else {
        if (vm.$refs.form.validate()) {
          vm.$store.dispatch("addAccountItem", vm.editedItem).then((result) => {
            vm.close();
          });
        }
      }
    },
  },
};
</script>
<style>
.expanded__content {
  box-shadow: none !important;
  background: white !important;
}
</style>
