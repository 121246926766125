<template>
  <v-container>
    <v-form class="pa-4" ref="form" v-model="valid" lazy-validation>
      <p>Sevdesk Integration</p>
      <h5>
        Wenn Sie Sevdesk mit onDispatch verbinden möchten, muss hier der API-Key,
        die ID der Kontaktperson sowie das Zahlungsziel festgelegt werden.<br />
        Gerne unterstützen wir Sie bei der Sevdesk Integration.
      </h5>
      <br />
      <v-row>
        <v-col cols="12" sm="12" md="4">
          <v-text-field
            v-model="item.contactPerson"
            label="ID Kontaktperson"
            data-cy="contactPerson"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <v-text-field
            v-model="item.apiKey"
            label="API-Key"
            data-cy="apiKey"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show = !show"
            :type="show ? 'text' : 'password'"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="2">
          <v-text-field
            v-model="item.timeToPay"
            type="number"
            label="Zahlungsziel"
            data-cy="timeToPay"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-btn color="secondary" text @click="save">Speichern</v-btn>
    </v-form>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    item: {
      apiKey: null,
      password: null,
      timeToPay: null
    },
    show: false,
    valid: true,
    requieredRule: [v => !!v || "Dieses Feld wird benötigt"]
  }),

  created() {
    this.initialize();
  },

  computed: {
    ...mapGetters(["sevdeskData"])
  },

  watch: {
    sevdeskData: {
      handler(val) {
        if (val.data[0]) {
          this.item = val.data[0].value ? val.data[0].value : this.item;
        }
      }
    }
  },

  methods: {
    save() {
      let vm = this;

      this.$store.dispatch("updateSevdeskData", this.item).then(result => {});
    },
    initialize() {
      this.$store.dispatch("getSevdeskData").then(result => {});
    }
  }
};
</script>
