const getters = {
    companyData: state => state.companyData,
    licenseData: state => state.licenseData,
    mailData: state => state.mailData,
    sevdeskData: state => state.sevdeskData,
    datevData: state => state.datevData,
    customerPortalData: state => state.customerPortalData,
    supportMail: state => state.supportMail,
    version: state => state.version,
    backendServer: state => state.backendServer,
    error: state => state.error,
    errors: state => state.errors,
    defaultMwst: state => state.defaultMwst,
    snackbar: state => state.snackbar,
    universalAlert: state => state.universalAlert,
    units: state => state.units,
    logo: state => state.logo,
    deliveryNotePositionTemplatesData: state => state.deliveryNotePositionTemplates.data,
    deliveryNotePositionTemplatesStatusPending: state => state.deliveryNotePositionTemplates.status.pending
}

export default getters;