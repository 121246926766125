import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"1400"}},[_c(VToolbar,{attrs:{"color":"primary","dark":""}},[(_vm.editItem.name)?_c(VToolbarTitle,[_vm._v("Bilder | "+_vm._s(_vm.editItem.name))]):_vm._e(),_c(VSpacer),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,_vm._g({on:{"click":function($event){return _vm.downloadImages()}}},on),[_vm._v("get_app")])],1)]}}])},[_c('span',[_vm._v("Alle Bilder herunterladen")])])],1),_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"dense":""}},_vm._l((_vm.editItem.photos),function(card,index){return _c(VCol,{key:card.name,attrs:{"cols":"6"}},[_c(VCard,[_c(VImg,{staticClass:"white--text align-end",attrs:{"src":card.data.substring(0, 15) == 'data:image/jpeg'? card.data :  'data:image/jpeg;base64,' + card.data,"height":"600px"}},[_c(VCardTitle,{domProps:{"textContent":_vm._s(card.name)}})],1),_c(VCardActions,[_c(VSpacer),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,_vm._g({on:{"click":function($event){return _vm.downloadImage(index)}}},on),[_vm._v("get_app")])],1)]}}],null,true)},[_c('span',[_vm._v("Bild herunterladen")])])],1)],1)],1)}),1)],1),_c(VCardActions,[_c('div',{staticClass:"flex-grow-1"}),_c(VBtn,{attrs:{"color":"secondary","text":""},on:{"click":_vm.close}},[_vm._v("zurück")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }