<template>
  <v-container>
    <v-form class="pa-4" ref="form" v-model="valid" lazy-validation>
      <p>onDispatch-Kundenportal</p>
      <h5>
        Hier können Sie Ihre onDispatch Instanz mit Ihrem onDispatch-Kundenportal verbinden.
        Einen API-Key erhalten Sie von einem unserer Mitarbeiter.
      </h5>
      <br />
      <v-row>
        <v-col cols="12" sm="12" md="8">
          <v-text-field
            v-model="item.apiKey"
            label="API-Key"
            data-cy="apiKey"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show = !show"
            :type="show ? 'text' : 'password'"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-btn color="secondary" text @click="save">Speichern</v-btn>
    </v-form>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    item: {
        apiKey: ""
    },
    show: false,
    valid: true,
    requieredRule: [v => !!v || "Dieses Feld wird benötigt"]
  }),

  created() {
    this.initialize();
  },

    computed: {
    ...mapGetters(["customerPortalData"])
  },

  watch: {
    customerPortalData: {
      handler(val) {
        if (val.data[0]) {
          this.item = val.data[0].value ? val.data[0].value : this.item;
        }
      }
    }
  },
  methods: {
    save() {
      let vm = this;

      this.$store.dispatch("updateCustomerPortalData", this.item).then(result => {});
    },
    initialize() {
      this.$store.dispatch("getCustomerPortalData").then(result => {});
    }
  }
};
</script>
