import router from "../../router.js";
const actions = {
  triggerError(context, error) {
    if (error == "Error: Request failed with status code 401") {
      context.dispatch("logout");
      router.push("login");
    } else {
      context.commit("THROW_ERROR", {
        err: error,
        con: context
      });
    }
  },
  triggerSnackbar(context, text) {
    context.commit("CREATE_SNACKBAR", text);
  }
};

export default actions;
