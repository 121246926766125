<template>
  <div style="height: 100%">
    <v-toolbar flat color="white">
      <v-toolbar-title>Artikelverwaltung</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-text-field
        v-model="search"
        label="Suche..."
        data-cy="search"
        single-line
        hide-details
        dense
        @keyup.enter.native="searchData"
      ></v-text-field>
      <v-btn text icon color="secondary" @click="searchData">
        <v-icon>search</v-icon>
      </v-btn>
      <div class="flex-grow-1"></div>
      <v-dialog v-model="dialog" max-width="800px">
        <template v-slot:activator="{ on }">
          <v-btn color="secondary" dark class="mb-2" v-on="on">Neuer Artikel</v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.name"
                      label="Artikelname"
                      data-cy="name"
                      :rules="requieredRule"
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="2">
                    <v-text-field
                      v-model.number="editedItem.price"
                      label="Preis"
                      data-cy="price"
                      :rules="priceRule"
                      dense
                      append-icon="euro_symbol"
                      outlined
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="2">
                    <v-select
                      :items="units"
                      v-model="editedItem.unity"
                      :rules="requieredRule"
                      outlined
                      label="Einheit"
                      data-cy="unity"
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="2">
                    <v-text-field
                      v-model="editedItem.taxRate"
                      label="USt. in %"
                      data-cy="taxRate"
                      :rules="priceRule"
                      dense
                      outlined
                      type="number"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      v-model="editedItem.description"
                      label="Infos"
                      data-cy="description"
                      dense
                      outlined
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="secondary" text @click="close">Abbrechen</v-btn>
            <v-btn color="secondary" text @click="save" data-cy="saveEvent">Speichern</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :options.sync="inventoryListGetter.pagination"
      :server-items-length="inventoryListGetter.pagination.totalItems"
      :items="inventoryDataGetter"
      :loading="inventoryStatusPending"
      :sort-desc="['true', 'false']"
      item-key="id"
      class="pa-5"
      :footer-props="{
        itemsPerPageOptions: inventoryListGetter.pagination.rowsPerPageItems,
        itemsPerPageText: 'Zeilen pro Seite',
      }"
    >
      <template v-slot:no-data> keine Daten vorhanden </template>
      <template v-slot:item.price="{ item }">
        {{
          parseFloat(Math.round(item.price * 100) / 100)
            .toFixed(2)
            .toString()
            .replace(".", ",")
            .replace(",00", "") + "€"
        }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-dialog v-model="deleteDialog" max-width="500">
          <v-card>
            <loading
              :active.sync="inventoryStatusPending"
              :can-cancel="false"
              :is-full-page="true"
            ></loading>
            <v-card-title class="headline">Datensatz löschen?</v-card-title>
            <v-card-text v-if="deleteItem">
              Soll der Artikel "{{ deleteItem.name }}" wirklich gelöscht werden?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="deleteDialog = false"
                >Abbrechen</v-btn
              >
              <v-btn color="secondary" text @click="triggerDeleteItem(item)" data-cy="deleteEvent"
                >Jetzt löschen</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon medium class="mr-2" v-on="on" @click="editItem(item)" data-cy="edit">edit</v-icon>
          </template>
          <span>Bearbeiten</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon medium v-on="on" @click="triggerDeleteDialog(item)" data-cy="delete">delete</v-icon>
          </template>
          <span>Löschen</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import { mapGetters } from "vuex";
export default {
  components: {
    Loading,
  },
  data: () => ({
    dialog: false,
    deleteDialog: false,
    deleteItem: null,
    search: "",
    snackbar: {
      visible: false,
      text: "Snackbar ...",
    },
    headers: [
      { text: "Artikelnummer", value: "objectId", sortable: false },
      { text: "Bezeichnung", align: "left", value: "name", sortable: false },
      { text: "Preis (Netto)", value: "price", sortable: false },
      { text: "Einheit", value: "unity", sortable: false },
      { text: "Umsatzsteuer in %", value: "taxRate", sortable: false },
      { text: "", value: "action", align: "right", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      taxRate: 0,
    },
    defaultItem: {
      taxRate: 0,
    },
    requieredRule: [(v) => !!v || "Dieses Feld wird benötigt"],
    priceRule: [(v) => v.toString().length >= 1 || "Dieses Feld wird benötigt"],
    valid: true,
  }),

  computed: {
    pagination: {
      get: function () {
        return this.$store.getters.inventoryListGetter.pagination;
      },
      set: function (value) {
        this.$store.commit("SET_PAGINATION_INVENTORY", value);
      },
    },
    formTitle() {
      return this.editedIndex === -1 ? "Neuer Artikel" : "Artikel bearbeiten";
    },
    ...mapGetters([
      "inventoryListGetter",
      "inventoryDataGetter",
      "inventoryStatusPending",
      "defaultMwst",
      "units",
    ]),
  },
  watch: {
    pagination: {
      handler() {
        this.$store.dispatch("getInventoryList");
      },
    },
    dialog(val) {
      if (val) {
        this.initialize();
      } else {
        this.close();
      }
    },
  },

  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch("getInventoryList");
      //load mwst
      this.editedItem.taxRate = this.defaultMwst;
      this.defaultItem.taxRate = this.defaultMwst;
    },
    close() {
      this.$store.dispatch("getInventoryList");
      this.dialog = false;
      this.editedItem = this.defaultItem;
      this.editedIndex = -1;
      this.$refs.form.reset();
    },
    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.editedIndex = 1;
      this.dialog = true;
    },
    searchData() {
      this.$store.dispatch("getInventoryList", { key: this.search });
    },
    triggerDeleteDialog(item) {
      this.deleteDialog = true;
      this.deleteItem = item;
    },
    triggerDeleteItem(item) {
      let vm = this;
      this.$store.dispatch("deleteInventoryItem", this.deleteItem).then((result) => {
        vm.deleteDialog = false;
        vm.deleteItem = null;
      });
    },
    save() {
      let vm = this;
      if (this.editedIndex > -1) {
        if (vm.$refs.form.validate()) {
          vm.$store.dispatch("updateInventory", vm.editedItem).then((result) => {
            vm.close();
          });
        }
      } else {
        if (vm.$refs.form.validate()) {
          vm.$store.dispatch("addInventoryItem", vm.editedItem).then((result) => {
            vm.close();
          });
        }
      }
    },
  },
};
</script>
<style>
.expanded__content {
  box-shadow: none !important;
  background: white !important;
}
</style>
