<template>
  <v-tabs>
      <v-tab exact>
        Anfragen
      </v-tab>

      <v-tab-item :transition="false" :reverse-transition="false">
        <requestTable></requestTable>
      </v-tab-item>
    </v-tabs>
</template>
<script>
import requestTable from "./../components/requests/requestTable.vue";

export default {
  components: {
    requestTable
  },
  data() {
    return {
      
    };
  }
};
</script>