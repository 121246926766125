<template>
  <v-card>
    <loading
      :active.sync="dialogLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <v-card-title>
      <span class="headline">Lieferschein zu Projekt hinzufügen</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <p>Projekt auswählen:</p>
        <loading
          :active.sync="loadingVal"
          :can-cancel="false"
          :is-full-page="true"
        ></loading>
        <v-data-table
          :headers="headers"
          :items="projects"
          :footer-props="{
            itemsPerPageText: 'Zeilen pro Seite'
          }"
          data-cy="projectTable"
        >
          <template v-slot:item.itemContact="{ item }">
            {{ formattedName(item) }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  color="secondary"
                  class="mr-2"
                  v-on="on"
                  @click.stop="send(item)"
                  data-cy="addDeliveryNote"
                  >done</v-icon
                >
              </template>
              <span>Lieferschein zu diesem Projekt hinzufügen</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data>
            keine Daten vorhanden
          </template>
        </v-data-table>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <div class="flex-grow-1"></div>
      <v-btn color="secondary" text @click="close">Zurück</v-btn>
      <!--v-btn color="blue darken-1" text @click="send">Hinzufügen</v-btn-->
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
import Loading from "vue-loading-overlay";
import VueQuillEditor, { Quill } from "vue-quill-editor";
import contactFormWrapper from "../../contact/createContact/contactFormWrapper.vue";

function getDefaultData() {
  return {
    formattedDeliveryNoteNumber: ""
  };
}

export default {
  components: {
    Loading
  },
  props: ["deliveryNote"],
  data: () => ({
    dialogLoading: false,
    valid: true,
    requieredRule: [v => !!v || "Dieses Feld wird benötigt"],
    item: getDefaultData(),
    selectedItem: {},
    projects: [],
    loadingVal: false,
    headers: [
      {
        text: "Projekt Nr.",
        align: "start",
        value: "objectId"
      },
      { text: "Name", value: "name" },
      { text: "Beginn", value: "formattedStart" },
      { text: "Ende", value: "formattedEnd" },
      { text: "Kontakt", value: "itemContact" },
      { text: "", value: "action", align: "right", sortable: false }
    ]
  }),

  computed: {
    ...mapGetters(["backendServer", "userInfo"])
  },

  watch: {
    deliveryNote: {
      handler(val) {
        //call mounted method when prop changes
        this.initialize();
      }
    }
  },

  mounted() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.loadingVal = true;
      let vm = this;
      this.item = this.deliveryNote;

      let url =
        this.backendServer +
        "project?status=Aktiv&contact=" +
        this.item.contact.id;
      let config = {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      };

      axios.get(url, config).then(response => {
        vm.projects = response.data.data;
        vm.loadingVal = false;
      });
      this.loadingVal = false;
    },
    close() {
      this.resetData();
      this.$emit("exit");
    },
    send(item) {
      this.selectedItem = item;
      let url =
        this.backendServer +
        "project/" +
        this.selectedItem.id +
        "/addDeliveryNote";
      let config = {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      };

      axios
        .put(url, { deliveryNote: this.item.id }, config)
        .then(response => {
          this.$store.dispatch("getDeliveryNotesList");
        })
        .catch(err => {
          this.$store.dispatch("triggerError", err);
        });

      this.resetData();
      this.$emit("exit");
    },
    resetData() {
      this.item = getDefaultData();
      this.selectedItem = {};
    },
    formattedName(item) {
      // format name for table
      let Description;
      if (item.contact.name) {
        if (item.contact.name2) {
          Description = item.contact.name + " " + item.contact.name2;
        } else {
          Description = item.contact.name;
        }
      } else {
        Description = item.contact.familyname;
      }
      return Description;
    }
  }
};
</script>
<style>
.ql-editor {
  min-height: 120px !important;
  font-size: 16px;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px;
}

.selected-row {
  background-color: lightgrey !important;
}
</style>
