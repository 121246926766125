<template>
  <div>
    <v-data-table
      :headers="headers"
      :options.sync="pagination"
      :server-items-length="pagination.totalItems"
      :loading="deliveryNotesStatusPending"
      :items="deliveryNotesListGetter.deliveryNotesList.data"
      @click:row="clickedRow"
      :footer-props="{
        itemsPerPageOptions: pagination.rowsPerPageItems,
        itemsPerPageText: 'Zeilen pro Seite',
      }"
      class="pa-5"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Lieferscheine</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
            v-model="pagination.search"
            label="Suche..."
            data-cy="search"
            single-line
            hide-details
            dense
            clearable
          ></v-text-field>
          <v-btn text icon color="secondary">
            <v-icon>search</v-icon>
          </v-btn>
          <div class="flex-grow-1"></div>
        </v-toolbar>
      </template>
      <template v-slot:item.frDeliveryNoteNumber="{ item }">
        {{ item.formattedDeliveryNoteNumber }}
        <v-icon v-if="item.event.hasPhoto" medium disabled>photo_camera</v-icon>
      </template>
      <template v-slot:item.iconStatus="{ item }">
        <div v-if="item.status == 'Entwurf'">
          <v-icon color="grey darken-2">assignment</v-icon>
          {{ item.status }}
        </div>
        <div v-if="item.status == 'Abgerechnet'">
          <v-icon color="green darken-2">mdi-clipboard-check</v-icon>
          {{ item.status }}
        </div>
        <div
          v-if="
            item.status == 'Rechnung erzeugt' || item.status == 'zu Projekt hinzugefügt'
          "
        >
          <v-icon color="yellow darken-2">file_copy</v-icon> {{ item.status }}
        </div>
        <div v-if="item.status == 'Versendet'">
          <v-icon color="secondary">send</v-icon>
          {{ item.status }}
        </div>
        <div v-if="item.status == 'Storniert'">
          <v-icon color="red darken-2">clear</v-icon>
          {{ item.status }}
        </div>
      </template>
      <template v-slot:item.recipient="{ item }">
        <!-- display only the first row of the Adress Field -->
        {{ item.address.split("/p>")[0].slice(3).slice(0, -1) }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }" v-if="item.status == 'Entwurf'">
            <v-icon class="mr-2" v-on="on" @click.stop="openMailDialog(item)"
              >email</v-icon
            >
          </template>
          <span>Dokument per Email versenden</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template
            v-slot:activator="{ on }"
            v-if="item.status == 'Versendet' || item.status == 'Entwurf'"
          >
            <v-icon medium class="mr-2" v-on="on" @click.stop="addToProject(item)" data-cy="addDeliveryNoteToProject"
              >mdi-ballot</v-icon
            >
          </template>
          <span>Lieferschein zu Projekt hinzufügen</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template
            v-slot:activator="{ on }"
            v-if="item.status == 'Versendet' || item.status == 'Entwurf'"
          >
            <v-icon medium class="mr-2" v-on="on" @click.stop="createInvoiceHandler(item)"
              >file_copy</v-icon
            >
          </template>
          <span>Rechnung aus Lieferschein erstellen</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }" v-if="item.status === 'Entwurf'">
            <v-icon medium class="mr-2" v-on="on" @click.stop="editItem(item)"
              >edit</v-icon
            >
          </template>
          <span>Bearbeiten</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon medium class="mr-2" v-on="on" @click.stop="clickedRow(item)" data-cy="preview"
              >preview</v-icon
            >
          </template>
          <span>Detailansicht / Vorschau</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>keine Daten vorhanden</template>
    </v-data-table>
    <v-dialog v-model="loadPdf" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          PDF wird erstellt...
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Add Delivery Note to Project-->
    <v-dialog v-model="addToProjectForm" max-width="1200px">
      <deliveryNoteProjectForm
        @exit="close"
        :deliveryNote="itemToProject"
      ></deliveryNoteProjectForm>
    </v-dialog>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import { mapFields } from "vuex-map-fields";
import { mapGetters } from "vuex";
import axios from "axios";
import deliveryNoteProjectForm from "./deliveryNoteProjectForm.vue";

export default {
  components: {
    Loading,
    deliveryNoteProjectForm,
  },
  data: () => ({
    menu: false,
    snackbar: {
      visible: false,
      text: "Snackbar ...",
    },
    singleExpand: false,
    expanded: [],
    header: "",
    headers: [
      {
        text: "Status",
        align: "left",
        sortable: false,
        value: "iconStatus",
      },
      {
        text: "Lieferscheinnummer",
        value: "frDeliveryNoteNumber",
        sortable: false,
      },
      { text: "Empfänger / Kunde", value: "recipient", sortable: false },
      { text: "Betreff", value: "header", sortable: false },
      { text: "Ansprechpartner", value: "contactPerson", sortable: false },
      {
        text: "Lieferscheindatum",
        value: "formattedDeliveryNoteDate",
        sortable: false,
      },
      { text: "", value: "action", align: "right", sortable: false },
    ],
    states: ["Entwurf", "Versandt"],
    valid: true,
    requieredRule: [(v) => !!v || "Dieses Feld wird benötigt"],
    deleteDialog: false,
    toDeleteItem: null,
    loadPdf: false,
    subTablePdf: null,
    addToProjectForm: false,
    itemToProject: null,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Lieferschein erstellen"
        : "Lieferschein bearbeiten";
    },
    pagination: {
      get: function () {
        return this.deliveryNotesListGetter.pagination;
      },
      set: function (value) {
        this.$store.commit("SET_PAGINATION_DELIVERY_NOTES", value);
        this.$store.dispatch("getDeliveryNotesList");
      },
    },
    //CHANGE
    ...mapGetters([
      "deliveryNotesListGetter",
      "deliveryNotesStatusPending",
      "backendServer",
      "userInfo",
    ]),
  },
  created() {
    this.$store.dispatch("getAllList");
  },
  watch: {
    expanded(val) {
      let vm = this;
      // load pdf data when section is expandet
      if (val[0]) {
        this.getBase64PDF(val[0]).then(function (result) {
          vm.subTablePdf = "data:application/pdf;base64," + result;
        });
      }
    },
  },

  methods: {
    clickedRow(value) {
      this.$router.push({
        name: "documentDetails",
        params: { id: value.id, from: "lieferscheine" },
      });
    },
    openDeleteDialog(item) {
      this.toDeleteItem = item;
      this.deleteDialog = true;
    },
    deleteItem() {
      let vm = this;
      this.$store.dispatch("deleteDeliveryNotes", this.toDeleteItem).then((result) => {
        vm.deleteDialog = false;
        vm.toDeleteItem = null;
      });
    },
    editItem(item) {
      this.$router.push({ path: "lieferscheine/edit/" + item.id + "/edit"});
    },
    openMailDialog(item) {
      const header = "Lieferschein LI-" + item.deliveryNoteNumber;
      this.$store.dispatch("getDeliveryNoteText").then((res) => {
        this.$router.push({
          name: "sendEmail",
          params: {
            document: JSON.stringify(item),
            doctype: "deliveryNote",
            defaultText: res.value.mail,
            header: JSON.stringify(header),
          },
        });
      });
    },
    close() {
      this.addToProjectForm = false;
      this.dialog = false;
      this.expanded = [];
    },
    createInvoiceHandler(item) {
      this.$router.push({
        name: "invoiceEdit",
        params: { deliveryNoteData: item, id: 1 },
      });
    },
    addToProject(item) {
      this.itemToProject = item;
      this.addToProjectForm = true;
    },
    createPDF(item) {
      //create pdf for download
      this.loadPdf = true;
      this.getBase64PDF(item).then(function (result) {
        const linkSource = `data:application/pdf;base64,${result}`;
        const downloadLink = document.createElement("a");
        const fileName = "Lieferschein-" + item.deliveryNoteNumber + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });
    },
    getBase64PDF(item) {
      let vm = this;
      let postUrl = this.backendServer + "deliveryNote/" + item.id + "/base64";
      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };
      return axios
        .get(postUrl, config)
        .then(function (response) {
          vm.loadPdf = false;
          return response.data.pdf;
        })
        .catch(function (e) {
          vm.$store.dispatch("triggerError", e);
          vm.loadPdf = false;
        });
    },
    searchData() {
      //this.$store.dispatch("getDeliveryNotesList");
    },
  },
};
</script>
<style>
.v-data-table__expanded__content {
  box-shadow: none !important;
  background: white !important;
}
</style>
