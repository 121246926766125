<template>
  <v-data-table
    :headers="headers"
    :options.sync="vehiclesListGetter.pagination"
    :server-items-length="vehiclesListGetter.pagination.totalItems"
    :loading="vehiclesStatusPending"
    :items="vehiclesListGetter.vehiclesList.data"
    :footer-props="{
      itemsPerPageOptions: vehiclesListGetter.pagination.rowsPerPageItems,
      itemsPerPageText: 'Zeilen pro Seite',
    }"
    class="pa-5"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Fahrzeuge</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-text-field
          v-model="vehiclesListGetter.pagination.search"
          label="Suche..."
          data-cy="search"
          single-line
          hide-details
          dense
          @keyup.enter.native="searchData"
        ></v-text-field>
        <v-btn text icon color="secondary" @click="searchData">
          <v-icon>search</v-icon>
        </v-btn>
        <div class="flex-grow-1"></div>
        <v-dialog v-model="dialog" max-width="800px">
          <template v-slot:activator="{ on }">
            <v-btn color="secondary" dark class="mb-2" v-on="on">Neues Fahrzeug</v-btn>
          </template>
          <v-card>
            <loading
              :active.sync="vehiclesStatusPending"
              :can-cancel="false"
              :is-full-page="true"
            ></loading>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row>
                    <v-col cols="12" sm="6" md="5">
                      <v-select
                        :items="vehicles"
                        label="Fahrzeugtyp"
                        data-cy="type"
                        v-model="editedItem.type"
                        :rules="requieredRule"
                        dense
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.license"
                        label="Kennzeichen"
                        data-cy="license"
                        :rules="requieredRule"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="5">
                      <v-text-field
                        v-model="editedItem.manufacturer"
                        label="Hersteller"
                        data-cy="manufacturer"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.name"
                        label="Bezeichnung (wird im Planer angezeigt)"
                        data-cy="name"
                        :rules="requieredRule"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-tabs>
                    <v-tab>Details</v-tab>
                    <v-tab-item class="mt-4">
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="editedItem.chassisNumber"
                            label="Fahrzeug-Ident-Nr (FIN)"
                            data-cy="chassisNumber"
                            dense
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="editedItem.tireSize"
                            label="Reifengröße"
                            data-cy="tireSize"
                            dense
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <!--v-col cols="12" sm="6" md="6">
                          <singleDateForm
                            v-model="editedItem.firstRegistration"
                            label="Erstzulassung"
                            data-cy="firstRegistration"
                            prependIcon="event"
                            required="false"

                          />
                        </v-col-->

                        <!--v-checkbox
                          color="primary"
                          v-model="editedItem.maintenanceContract"
                          label="Wartungsvertrag"
                          data-cy="maintenanceContract"
                        ></v-checkbox-->

                        <v-col
                          cols="12"
                          sm="6"
                          md="12"
                          style="padding-top: 0px; padding-bottom: 0px"
                        >
                          <v-textarea
                            v-model="editedItem.description"
                            label="Beschreibung"
                            data-cy="description"
                            rows="2"
                            dense
                            outlined
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                    <v-tab data-cy="insuranceTab">Versicherung</v-tab>
                    <v-tab-item class="mt-4">
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="editedItem.insurance"
                            label="Versicherung"
                            data-cy="insurance"
                            dense
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="editedItem.insuranceType"
                            label="Versicherungstarif"
                            data-cy="insuranceType"
                            dense
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                  </v-tabs>
                  <!--v-col cols="12" sm="6" md="12">
                      <v-text-field
                        v-model="editedItem.events"
                        label="Termine"
                        data-cy="events"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col-->
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn color="secondary" text @click="close">Abbrechen</v-btn>
              <v-btn color="secondary" text @click="save" data-cy="saveEvent">Speichern</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <!--template v-slot:item.events="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div v-on="on">
            {{ item.events }} {{ item.events }}
            <v-icon>expand_more</v-icon>
          </div>
        </template>
        Weitere Termine
        <hr />
        <ul v-for="date in item.events" v-bind:key="date.license">
          <li></li>
        </ul>
      </v-tooltip>
    </template-->
    <template v-slot:item.maintenanceContract="{ item }">
      <v-icon class="mr-2" v-if="item.maintenanceContract">check</v-icon>
      <v-icon v-else>clear</v-icon>
    </template>
    <template v-slot:item.displayScheduler="{ item }">
      <v-switch v-model="item.display" @change="changePlanerStatus(item)" data-cy="changePlanerStatus"></v-switch>
    </template>
    <template v-slot:item.action="{ item }">
      <v-dialog v-model="deleteDialog" max-width="500">
        <v-card>
          <loading
            :active.sync="vehiclesStatusPending"
            :can-cancel="false"
            :is-full-page="true"
          ></loading>
          <v-card-title class="headline">Datensatz löschen?</v-card-title>
          <v-card-text
            >Soll der Datensatz {{ item.id }} wirklich gelöscht werden?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="deleteDialog = false">Abbrechen</v-btn>
            <v-btn color="secondary" text @click="deleteItem(item)" data-cy="deleteEvent">Jetzt löschen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon medium class="mr-2" v-on="on" @click="editItem(item)" data-cy="edit">edit</v-icon>
        </template>
        <span>Bearbeiten</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon medium v-on="on" @click="openDeleteDialog(item)" data-cy="delete">delete</v-icon>
        </template>
        <span>Löschen</span>
      </v-tooltip>
    </template>
    <template v-slot:no-data>
      <v-btn color="secondary" @click="initialize">Reset</v-btn>
    </template>
    <template v-slot:no-data> keine Daten vorhanden </template>
  </v-data-table>
</template>

<script>
import Loading from "vue-loading-overlay";
import singleDateForm from "../_utils/singleDateForm.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Loading,
    singleDateForm,
  },
  data: () => ({
    menu: false,
    snackbar: {
      visible: false,
      text: "Snackbar ...",
    },
    dialog: false,
    headers: [
      {
        text: "Kennzeichen",
        align: "left",
        sortable: false,
        value: "license",
      },
      {
        text: "Bezeichnung (Planer)",
        value: "name",
        sortable: false,
      },
      {
        text: "Fahrzeugart",
        value: "type",
        sortable: false,
      },
      {
        text: "Hersteller",
        value: "manufacturer",
        sortable: false,
      },
      {
        text: "Fahrzeug-Ident-Nr. (FIN)",
        value: "chassisNumber",
        sortable: false,
      },
      {
        text: "Reifengröße",
        value: "tireSize",
        sortable: false,
      },
      /*{
                    text: "Erstzulassung",
                    value: "formattedFirstRegistration",
                    sortable: false
                  },*/
      {
        text: "Versicherung",
        value: "insurance",
        sortable: false,
      },
      {
        text: "Versicherungstarif",
        value: "insuranceType",
        sortable: false,
      },
      {
        text: "Wartungsvertrag",
        value: "maintenanceContract",
        sortable: false,
      },
      //{ text: "Termine (nächster Termin)", value: "events", sortable: false },
      {
        text: "Sichtbar in Planer",
        value: "displayScheduler",
        sortable: false,
      },
      {
        text: "",
        value: "action",
        align: "right",
        sortable: false,
      },
    ],
    data: [],
    editedIndex: -1,
    editedItem: {
      license: "",
      type: "",
      name: "",
      manufacturer: "",
      chassisNumber: "",
      tireSize: "",
      //firstRegistration: new Date().toISOString().substr(0, 10),
      insurance: "",
      insuranceType: "",
      maintenanceContract: false,
      events: [],
    },
    date: null,
    vehicles: [
      "Kipper",
      "Tieflader",
      "Container",
      "Kehrmaschine",
      "Mobilkran",
      "Sonstiges",
    ],
    valid: true,
    requieredRule: [(v) => !!v || "Dieses Feld wird benötigt"],
    deleteDialog: false,
    deletItem: null,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Neues Fahrzeug" : "Fahrzeug bearbeiten";
    },
    pagination: {
      get: function () {
        return this.$store.getters.vehiclesListGetter.pagination;
      },
      set: function (value) {
        this.$store.commit("SET_PAGINATION_VEHICLES", value);
      },
    },
    /*computedDateFormattedVehicleDate() {
              return this.formatDate(this.editedItem.firstRegistration);
            },*/
    ...mapGetters(["vehiclesListGetter", "vehiclesStatusPending"]),
  },

  watch: {
    pagination: {
      handler() {
        this.$store.dispatch("getVehiclesList");
      },
    },
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$store.dispatch("getVehiclesList");
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.editedIndex = 1;
      this.dialog = true;
    },
    openDeleteDialog(item) {
      this.deletItem = item;
      this.deleteDialog = true;
    },
    deleteItem() {
      this.$store.dispatch("deleteVehicles", this.deletItem).then((result) => {
        this.deleteDialog = false;
        this.deletItem = null;
      });
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
      this.$refs.form.reset();
    },

    save() {
      let vm = this;
      if (this.editedIndex > -1) {
        if (vm.$refs.form.validate()) {
          vm.$store.dispatch("updateVehicles", vm.editedItem).then((result) => {
            vm.close();
          });
        }
      } else {
        if (vm.$refs.form.validate()) {
          vm.$store.dispatch("addVehicles", vm.editedItem).then((result) => {
            vm.close();
          });
        }
      }
    },
    changePlanerStatus(item) {
      this.$store.dispatch("setVisibleInSchedulerVehicle", item);
    },
    searchData() {
      this.$store.dispatch("getVehiclesList");
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
  },
};
</script>

<style>
.expanded__content {
  box-shadow: none !important;
  background: white !important;
}
</style>
