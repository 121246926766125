<template>
  <div>
    <v-row allign="center" justify="space-around">
      <v-col cols="12" sm="11" md="3" class="ma-3">
        <analyticsCard
          type="Project"
          />
      </v-col>
      <v-col cols="12" sm="11" md="3" class="ma-3">
        <analyticsCard
          type="DeliveryNote"
          />
      </v-col>
      <v-col cols="12" sm="11" md="3" class="ma-3">
        <analyticsCard
          type="Invoice"
          />
      </v-col>
    </v-row>
    <v-row allign="center" justify="center">
      <v-col cols="12" sm="6" md="11">
        <eventTable></eventTable>
      </v-col>
    </v-row>
    <v-row allign="center" justify="center" class="mb-10">
      <v-col cols="12" sm="6" md="6">
        <activityTable />
      </v-col>
      <v-col cols="12" sm="0" md="1"> </v-col>
      <v-col cols="12" sm="6" md="4">
        <helpSection />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import activityTable from "../../components/dashboardTables/activityTable.vue";
import eventTable from "../../components/dashboardTables/eventTable.vue";
import helpSection from "../../components/dashboardTables/helpSection.vue";
import analyticsCard from "../../components/dashboardTables/analyticsCard.vue";

export default {
  components: {
    activityTable,
    eventTable,
    helpSection,
    analyticsCard
  }
};
</script>
