<template>
  <v-tabs>
      <v-tab exact>
        Buchungskonten
      </v-tab>

      <v-tab-item :transition="false" :reverse-transition="false">
        <accountingTable></accountingTable>
      </v-tab-item>
    </v-tabs>
</template>
<script>
import accountingTable from "./../components/accounting/accountingTable.vue";

export default {
  components: {
    accountingTable
  },
  data() {
    return {
      
    };
  }
};
</script>