import axios from "axios";
const actions = {
  getAccountList(context, searchKey) {
    context.commit("SET_ALL_ACCOUNT");
    const { page, itemsPerPage } = context.state.accountData.pagination;
    let getUrl;
    if (searchKey != null && searchKey != "") {
      getUrl =
        context.getters.backendServer +
        "accounting/account?page=" +
        page +
        "&perPage=" +
        itemsPerPage +
        "&q=" +
        searchKey;
      context.commit("RESET_PAGINATION_PAGES_ACCOUNT");
    } else {
      getUrl =
        context.getters.backendServer +
        "accounting/account?page=" +
        page +
        "&perPage=" +
        itemsPerPage;
    }
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .get(getUrl, config)
      .then(function (response) {
        context.commit("SET_ALL_ACCOUNT", response.data.data);
        context.commit(
          "SET_TOTAL_ACCOUNT_ALL",
          parseInt(response.data.count)
        );
      })
      .catch(function (e) {
        context.commit("SET_ACCOUNT_LIST", e);
        context.dispatch("triggerError", e);
      });
  },

  getAllAccount(context) {
    context.commit("SET_ALL_ACCOUNT");
    let getUrl = context.getters.backendServer + "accounting/account";

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .get(getUrl, config)
      .then(function (response) {
        context.commit("SET_ALL_ACCOUNT", response.data.data);
      })
      .catch(function (e) {
        context.commit("SET_ACCOUNT_LIST", e);
        context.dispatch("triggerError", e);
      });
  },

  getSingleAccountItem(context, id) {
    let getUrl = context.getters.backendServer + "accounting/account/" + id;
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    return axios
      .get(getUrl, config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  getStandardKto(context) {
    let getUrl = context.getters.backendServer + "accounting/account/standard";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    return axios
      .get(getUrl, config)
      .then(function (response) {
        return response.data.accountNumber;
      })
      .catch(function (e) {
        context.dispatch("triggerError", e);
      });
  },

  addAccountItem(context, value) {
    context.commit("SET_ACCOUNT_ADD");
    let postUrl = context.getters.backendServer + "accounting/account";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    return axios
      .post(postUrl, value, config)
      .then(function (response) {
        context.commit("SET_ACCOUNT_ADD", response.data);
        context.commit(
          "CREATE_SNACKBAR",
          "Konto Nr. " + response.data.objectId + " erfolgreich hinzugefügt"
        );
        context.dispatch("getAccountList");
        return response.data;
      })
      .catch(function (e) {
        context.commit("SET_ACCOUNT_ADD", e);
        context.dispatch("triggerError", e);
      });
  },

  deleteAccountItem(context, value) {
    context.commit("SET_ACCOUNT_DELETE");
    let postUrl = context.getters.backendServer + "accounting/account/" + value.id;

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .delete(postUrl, config)
      .then(function (response) {
        context.commit("SET_ACCOUNT_DELETE", "[]");
        context.commit(
          "CREATE_SNACKBAR",
          "Buchungskonto Nr. " + response.data.accountNumber + " erfolgreich gelöscht"
        );
        context.dispatch("getAccountList");
      })
      .catch(function (e) {
        context.commit("SET_ACCOUNT_DELETE", e);
        context.dispatch("triggerError", e);
      });
  },

  updateAccount(context, value) {
    context.commit("SET_ACCOUNT_UPDATE");

    let putUrl = context.getters.backendServer + "accounting/account/" + value.id;

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    value.formattedBirthday = null;
    axios
      .put(putUrl, value, config)
      .then(function (response) {
        context.commit("SET_ACCOUNT_UPDATE", response.data);
        context.commit(
          "CREATE_SNACKBAR",
          "Konto Nr. " + response.data.accountNumber + " erfolgreich aktualisiert"
        );
        context.dispatch("getAccountList");
      })
      .catch(function (e) {
        context.commit("SET_ACCOUNT_UPDATE", e);
        context.dispatch("triggerError", e);
      });
  }
};

export default actions;
