import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VForm,{ref:"form",staticClass:"pa-4",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('p',[_vm._v("DATEV CSV Schnitstelle Stammdaten")]),_c('h5',[_vm._v(" Um den Export im DATEV CSV Format zu nutzen, müssen Sie hier ihre DATEV Mandantennummer sowie Beraternummer eintragen. "),_c('br'),_vm._v(" Weitere Informationen zu diesen Nummern erhalten Sie von Ihrem Buchhalter / Steuerberater. ")]),_c('br'),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"12","md":"4"}},[_c(VTextField,{attrs:{"label":"Mandantennummer","data-cy":"client","outlined":"","dense":""},model:{value:(_vm.item.client),callback:function ($$v) {_vm.$set(_vm.item, "client", $$v)},expression:"item.client"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"12","md":"4"}},[_c(VTextField,{attrs:{"label":"Beraternummer","data-cy":"consultant","outlined":"","dense":""},model:{value:(_vm.item.consultant),callback:function ($$v) {_vm.$set(_vm.item, "consultant", $$v)},expression:"item.consultant"}})],1)],1),_c(VBtn,{attrs:{"color":"secondary","text":""},on:{"click":_vm.save}},[_vm._v("Speichern")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }