import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VList,{attrs:{"flat":"","subheader":"","three-line":""}},[_c(VSubheader,[_c('h4',[_vm._v("Prozess-Einstellungen")])])],1),_c(VListItem,[_c(VListItemContent,[_c(VContainer,[_c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"900"}},[_c(VContainer,[_c('p',[_vm._v("Rechnungs-Einstellungen für SevDesk")])]),_c(VForm,{ref:"form",staticClass:"pa-4",attrs:{"lazy-validation":""}},[_c('p',[_vm._v("Rechnung nach versenden automatisch exportieren")]),_c('h5',[_vm._v(" Gibt an ob Rechnungen nach dem Versenden automatisch zu Sevdesk exportiert werden sollen. ")]),_c('br'),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('p',{staticStyle:{"margin-top":"25px"}},[_vm._v(" Rechnungen automatisch exportieren ")])]),_c(VCol,{attrs:{"cols":"12","sm":"6","md":"6"}},[_c(VSwitch,{on:{"change":_vm.changeExportInvoice},model:{value:(_vm.autoExportInvoice),callback:function ($$v) {_vm.autoExportInvoice=$$v},expression:"autoExportInvoice"}})],1)],1),_c('hr'),_c('br'),_c('br'),_c('p',[_vm._v("Rechnungen in SevDesk festschreiben")]),_c('h5',[_vm._v(" Ist diese Option aktiviert, werden Rechnungen in SevDesk festgeschrieben. Wenn diese Option deaktiviert ist werden Rechnungen als Entwurf zu SevDesk exportiert. ")]),_c('br'),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('p',{staticStyle:{"margin-top":"25px"}},[_vm._v(" Rechnungen festschreiben ")])]),_c(VCol,{attrs:{"cols":"12","sm":"6","md":"6"}},[_c(VSwitch,{on:{"change":_vm.changeCommitSevDeskInvoice},model:{value:(_vm.commitSevDeskInvoice),callback:function ($$v) {_vm.commitSevDeskInvoice=$$v},expression:"commitSevDeskInvoice"}})],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }