<template>
  <div>
    <v-list flat subheader three-line>
      <v-subheader>
        <h4>Datev-Export</h4>
      </v-subheader>

      <v-list-item>
        <v-list-item-content>
          <v-container>
            <v-card class="mx-auto" max-width="900">
              <v-form class="pa-4" ref="form" v-model="valid" lazy-validation>
                <p>Buchungsdaten / Rechnungen Exportieren</p>
                <h5>
                  Ein Export umfasst alle Personenkonten, Buchungskonten und
                  Rechnungen im .pdf Format im angegebenen Zeitraum.
                </h5>
                <br />
                <h5>
                  Bitte prüfen Sie vor dem ersten Export ob Ihre DATEV
                  Mandantennummer und Beraternummer unter
                  <router-link to="/license"
                    >Einstellungen > Lizenz -> DATEV
                  </router-link>
                  sind. Diese Nummern erhalten Sie von Ihrem Steuerberater.
                </h5>
                <br />
                <h5>
                  Mehr Informationen erhalten Sie
                  <a
                    href="https://doku.ondispatch.de/docs/datevExportWeb/"
                    trget="_blank"
                    >hier</a
                  >.
                </h5>
                <br />
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="computedDateFormattedStartDate"
                          label="Startdatum des Buchungsstapels"
                          data-cy="computedDateFormattedStartDate"
                          prepend-icon="event"
                          readonly
                          :rules="[yearCheck]"
                          v-on="on"
                          outlined
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="start"
                        @input="menu = false"
                        locale="de-de"
                        first-day-of-week="1"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="computedDateFormattedEndDate"
                          label="Enddatum des Buchungsstapels"
                          data-cy="computedDateFormattedEndDate"
                          prepend-icon="event"
                          readonly
                          :rules="[yearCheck]"
                          v-on="on"
                          outlined
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="end"
                        @input="menu2 = false"
                        locale="de-de"
                        first-day-of-week="1"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="12">
                    <input
                      type="checkbox"
                      v-model="setExport"
                      true-value="1"
                      false-value="0"
                    />
                    Exportierten Rechnungen den Status "exportiert" zuweisen
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="12">
                    <input
                      type="checkbox"
                      v-model="onlyNonExported"
                      true-value="1"
                      false-value="0"
                    />
                    Exportierte Rechnungen nicht abrufen
                  </v-col>
                </v-row>
                <v-row>
                  <v-btn @click="createPDF" class="mx-auto mb-2" color="primary" dark
                    >Download</v-btn
                  >
                </v-row>
              </v-form>
              <v-dialog v-model="loadPdf" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                  <v-card-text>
                    Export wird erstellt...
                    <v-progress-linear
                      indeterminate
                      color="white"
                      class="mb-0"
                    ></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-card>
          </v-container>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  data: () => ({
    start: "",
    end: "",
    year: "",
    setExport: "0",
    onlyNonExported: "0",
    menu: false,
    menu2: false,
    valid: true,
    loadPdf: false,
    requieredRule: [v => !!v || "Dieses Feld wird benötigt"]
  }),

  created() {},

  computed: {
    ...mapGetters(["backendServer"]),

    computedDateFormattedStartDate() {
      return this.formatDate(this.start);
    },
    computedDateFormattedEndDate() {
      return this.formatDate(this.end);
    }
  },

  watch: {
    deep: true
  },

  methods: {
    yearCheck() {
      if (this.start.substring(0, 4) != this.end.substring(0, 4)) {
        return "Start- und Enddatum müssen im selben Jahr liegen.";
      }
    },

    formatDate(date) {
      if (!date || date == null) {
        //init dates
        let newDate = new Date().toISOString().substr(0, 10);
        date = newDate;
        this.start = date;
        this.end = date;
      }
      if (date.split("-").length < 3) {
        let dayFirst = date.slice(0, 1);
        let monthFirst = date.slice(3, 4);
        if (dayFirst == 0 || monthFirst == 0) {
          let year = date.slice(-4);
          let withoutYear = date.slice(0, 6);
          withoutYear = withoutYear.replace(/0/g, "");
          date = withoutYear + year;
        }
        return date;
      } else {
        const [year, month, day] = date.split("-");
        return `${day}.${month}.${year}`;
      }
    },

    formatDateTTMMYYY(date) {
      if (!date || date == null) {
        //init dates
        let newDate = new Date();
        date =
          newDate.getFullYear() + (newDate.getMonth() + 1) + newDate.getDate();

        this.start = date;
        this.end = date;
      }
      if (date.split("-").length < 3) {
        let dayFirst = date.slice(0, 1);
        let monthFirst = date.slice(3, 4);
        if (dayFirst == 0 || monthFirst == 0) {
          let year = date.slice(-4);
          let withoutYear = date.slice(0, 6);
          withoutYear = withoutYear.replace(/0/g, "");
          date = withoutYear + year;
        }
        return date;
      } else {
        let [year, month, day] = date.split("-");
        return `${day}${month}${year}`;
      }
    },

    createPDF(item) {
      //check if module is activated
      this.$store.dispatch("getLicenseData").then(result => {
        if (result.value.modules.datev) {
          //module active
          let year = this.start.substring(0, 4);
          let vm = this;
          this.loadPdf = true;
          let getUrl =
            this.backendServer +
            "invoice/export/datev?start=" +
            this.start +
            "&end=" +
            this.end +
            "&year=" +
            year +
            "&setExport=" +
            this.setExport +
            "&onlyNonExported=" +
            this.onlyNonExported;
          let config = {
            headers: {
              Authorization: localStorage.getItem("token")
            }
          };
          return axios
            .get(getUrl, config)
            .then(function(response) {
              const linkSource = `data:application/zip;base64,${response.data.data}`;
              const downloadLink = document.createElement("a");
              const fileName =
                "Export_" +
                vm.formatDateTTMMYYY(vm.start) +
                "_bis_" +
                vm.formatDateTTMMYYY(vm.end) +
                ".zip";
              downloadLink.href = linkSource;
              downloadLink.download = fileName;
              downloadLink.click();
              vm.loadPdf = false;
            })
            .catch(function(e) {
              this.$store.dispatch("triggerError", e);
              vm.loadPdf = false;
            });
        } else {
          //module not active
          this.$store.dispatch("triggerUniversalAlert", {
            text:
              "Um den Export Ihrer Buchungsdaten für Ihre Instanz zu buchen, kontaktieren Sie einfach unser Vertriebsteam unter info@ondispatch.de.",
            heading: "DATEV Export buchen",
            type: "info"
          });
        }
      });
    }
  }
};
</script>
