<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="background fill-height" fluid>
        <v-row justify="center">
          <v-col cols="12" sm="6" md="7" xl="7">
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Auftragsanfrage stellen</v-toolbar-title>
                <div class="flex-grow-1"></div>
              </v-toolbar>
              <v-card-text>
                <v-card-subtitle><h4>Kontakt</h4></v-card-subtitle>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row>
                    <v-col cols="12" sm="6" md="6" xl="6">
                      <v-text-field
                        data-cy="name"
                        label="Name*"
                        prepend-icon="person"
                        type="text"
                        v-model="item.name"
                        :rules="requieredRule"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" xl="6">
                      <v-text-field
                        data-cy="company"
                        label="Firma"
                        prepend-icon="mdi-domain"
                        type="text"
                        v-model="item.company"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="6" xl="6">
                      <v-text-field
                        data-cy="email"
                        label="Email*"
                        type="email"
                        v-model="item.email"
                        :rules="requieredRule"
                        dense
                        outlined
                        prepend-icon="email"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" xl="6">
                      <v-text-field
                        data-cy="phone"
                        label="Telefonnummer*"
                        prepend-icon="phone"
                        type="text"
                        v-model="item.phone"
                        :rules="requieredRule"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-card-subtitle><h4>Auftragsort</h4></v-card-subtitle>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      xl="12"
                      v-if="item.isAdressDescription"
                    >
                      <v-textarea
                        data-cy="plz"
                        label="Beschreibung"
                        type="text"
                        v-model="item.addressDescription"
                        dense
                        outlined
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" xl="6">
                      <v-text-field
                        data-cy="city"
                        label="Stadt"
                        prepend-icon="mdi-city"
                        type="text"
                        v-if="!item.isAdressDescription"
                        v-model="item.city"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" xl="6">
                      <v-text-field
                        data-cy="street"
                        label="Straße, Hausnummer"
                        prepend-icon="mdi-home-city"
                        type="text"
                        v-model="item.street"
                        v-if="!item.isAdressDescription"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="!item.isAdressDescription">
                    <v-col cols="12" sm="6" md="6" xl="6">
                      <v-text-field
                        data-cy="plz"
                        label="Postleitzahl"
                        prepend-icon="mdi-email-mark-as-unread"
                        type="text"
                        v-model="item.plz"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-checkbox
                      style="padding-left: 45px"
                      v-model="item.isAdressDescription"
                      label="Auftragsort hat keine Adresse (z.B. bei Autobahneinsätzen)"
                    ></v-checkbox>
                  </v-row>
                  <v-card-subtitle><h4>Auftragszeitraum</h4></v-card-subtitle>
                  <v-row>
                    <v-col cols="12" sm="6" md="6" xl="6">
                      <singleDateForm
                        data-cy="city"
                        label="Auftragsdatum Frühestens"
                        prepend-icon="mdi-calendar"
                        v-model="item.startDate"
                        dense
                        outlined
                      ></singleDateForm>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" xl="6">
                      <singleDateForm
                        data-cy="street"
                        label="Auftragsdatum Spätestens"
                        prepend-icon="mdi-calendar"
                        type="text"
                        v-model="item.startDateEnd"
                        dense
                        outlined
                      ></singleDateForm>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="6" xl="6">
                      <v-text-field
                        data-cy="street"
                        label="Vermutete Auftragsdauer in Stunden"
                        prepend-icon="mdi-calendar-cursor"
                        type="number"
                        v-model="item.duration"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-card-subtitle><h4>Weiteres</h4></v-card-subtitle>
                  <v-row>
                    <v-col cols="12" sm="12" md="12" xl="12">
                      <v-textarea
                        data-cy="plz"
                        label="Beschreibung des Auftrags ggf. der benötigten Leistungen"
                        type="text"
                        v-model="item.description"
                        dense
                        outlined
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-container>
                <p>* Diese Felder werden benötigt</p>
              </v-container>
              <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn v-on:click="sendRequest" color="primary">Anfrage senden</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-dialog v-model="alertData.visible" persistent max-width="550">
            <v-card>
              <v-card-title class="headline">{{ alertData.heading }}</v-card-title>
              <v-card-text>
                <v-alert dense border="left" :type="alertData.type">
                  {{ alertData.text }}
                </v-alert>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" text @click="alertData.visible = false"
                  >Zurück</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-container>
    </v-main>
    <appFooter />
  </v-app>
</template>

<script>
import Loading from "vue-loading-overlay";
import { mapGetters } from "vuex";
import "vue-loading-overlay/dist/vue-loading.css";
import appFooter from "../components/styling/footer";
import singleDateForm from "../components/_utils/singleDateForm.vue";
import Vuex from "vuex";
import axios from "axios";
export default {
  data: () => ({
    drawer: null,
    show: false,
    valid: false,
    requieredRule: [(v) => !!v || "Dieses Feld wird benötigt"],
    item: {
      name: "",
      email: "",
      company: "",
      phone: "",
      street: "",
      city: "",
      plz: "",
      duration: "",
      description: "",
      startDate: new Date(),
      startDateEnd: new Date(),
      addressDescription: "",
      isAdressDescription: false,
    },
    alertData: {
      visible: false,
      heading: "",
      type: "success",
      text: "",
    },
  }),
  components: {
    Loading,
    appFooter,
    singleDateForm,
  },
  computed: {
    ...mapGetters(["loading", "loggedIn", "error", "snackbar", "backendServer"]),
  },
  methods: {
    sendRequest() {
      let vm = this;
      if (this.$refs.form.validate()) {
        let postURL = this.backendServer + "request";
        axios
          .post(postURL, this.item, {})
          .then((response) => {
            vm.alertData = {
              visible: true,
              heading: "Erfolg",
              type: "success",
              text: "Ihre Anfrage wurde erfolgreich abgesendet",
            };
          })
          .catch((err) => {
            vm.alertData = {
              visible: true,
              heading: "Fehler",
              type: "error",
              text:
                "Ihre Anfrage konnte nicht abgesendet werden, bitte versuchen Sie es später erneut.",
            };
          });
      }
    },
  },
};
</script>
<style scoped>
.background {
  background-color: #f3f3fa;
}
</style>
