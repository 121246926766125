<template>
  <v-card class="mx-auto my-5" max-width="1000">
    <loading
      :active.sync="dialogLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <v-app-bar flat>
      <span class="headline">{{ formTitle }}</span>

      <v-spacer></v-spacer>

      <v-btn icon v-if="item.hasPhoto">
        <v-icon color="primary" @click="openPhotoDialog = true"
          >photo_camera</v-icon
        >
      </v-btn>
      <v-dialog v-model="openPhotoDialog" max-width="1400px" persistent>
        <photoDialog @close="closePhotoDialog" :editItem="item" />
      </v-dialog>
    </v-app-bar>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="eventName"
              label="Referenz / Baustelle / Kostenstelle"
              data-cy="eventName"
              :rules="requieredRule"
              dense
              outlined
            ></v-text-field>
            <v-text-field
              v-model="item.email"
              v-if="item.contact && templates.length > 0"
              label="Lieferschein - Email"
              data-cy="email"
              dense
              outlined
              prepend-icon="email"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="5">
            <v-autocomplete
              v-model="item.contact"
              :items="contactDataMap"
              :loading="allStatusPendingWithoutPagnation"
              @input="loadConstructionSites(item.contact)"
              hide-no-data
              item-text="Description"
              item-value="id"
              label="Auftraggeber"
              data-cy="contact"
              prepend-icon="mdi-database-search"
              :rules="requieredRule"
              dense
              outlined
            ></v-autocomplete>
            <v-autocomplete
              v-model="selectTemplate"
              v-if="item.contact && templates.length > 0"
              :items="templatesDataMap"
              @input="selectConstructionSites(selectTemplate)"
              :loading="templatesLoading"
              hide-no-data
              item-text="Description"
              item-value="data"
              label="Baustelle"
              data-cy="constructionSite"
              dense
              outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="1">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon @click="addContact = true">add</v-icon>
                </v-btn>
              </template>
              <span>Kontakt erstellen</span>
            </v-tooltip>
            <contactFormWrapper
              v-if="addContact"
              :dialog="addContact"
              @contactClose="contactClose"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-datetime-picker
              @input="loadResources()"
              v-model="item.startDate"
              label="Start"
              data-cy="startDate"
              :date-picker-props="dateProps"
              :time-picker-props="timeProps"
              clearText="zurück"
              dense
              outlined
              :disabled="disableFieldsForWholeDayEvent"
            ></v-datetime-picker>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-datetime-picker
              v-if="useEndDate"
              v-model="item.endDate"
              @input="loadResources()"
              label="Ende"
              data-cy="endDate"
              :date-picker-props="dateProps"
              :time-picker-props="timeProps"
              clearText="zurück"
              dense
              outlined
              :disabled="disableFieldsForWholeDayEvent"
            ></v-datetime-picker>
            <v-text-field
              @change="loadResources()"
              v-if="!useEndDate"
              v-model="duration"
              label="Dauer"
              data-cy="duration"
              value="12:30:00"
              type="time"
              suffix="Std"
              :rules="requieredRule"
              dense
              outlined
              :disabled="disableFieldsForWholeDayEvent"
            ></v-text-field>
            <a
              style="float: right"
              href="javascript:void(0);"
              v-if="!useEndDate"
              @click="useEndDate = true"
              >Enddatum verwenden</a
            >
            <a
              style="float: right"
              href="javascript:void(0);"
              v-if="useEndDate"
              @click="useEndDate = false"
              >Dauer verwenden</a
            >
          </v-col>
        </v-row>
        <v-expansion-panels v-model="settingsPanel" flat>
          <v-expansion-panel>
            <v-expansion-panel-header
              >Weitere Optionen</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-col cols="12" sm="6" md="12">
                <repeatEvent
                  v-if="formTitle === 'Auftrag anlegen'"
                  :eventToRepeat="item"
                  @exit="close()"
                  @finishLoading="finishLoading()"
                  @mark="setRepeatEventVal"
                  ref="repeatEvent"
                  class="mt-n2"
                />
                <wholeDayEvent
                  :wholeDayStartDate="item.startDate"
                  :wholeDayDuration="item.duration"
                  @setWholeDayValues="useWholeDayValues"
                  @resetWholeDayValues="disableFieldsForWholeDayEvent = false"
                  class="mt-n10"
                />
              </v-col>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="item.email"
                    label="Lieferschein - Email"
                    data-cy="email"
                    dense
                    outlined
                    prepend-icon="email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    v-model="item.project"
                    :items="projectDataMap"
                    :loading="allStatusPendingWithoutPagnation"
                    hide-no-data
                    item-text="Description"
                    item-value="id"
                    label="Projekt"
                    data-cy="project"
                    prepend-icon="mdi-database-search"
                    dense
                    outlined
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-textarea
              v-model="item.location"
              label="Adresse / Arbeitsort"
              data-cy="location"
              :rules="requieredRule"
              dense
              outlined
            ></v-textarea>
            <a
              :href="'https://www.google.de/maps/search/' + item.location"
              target="_blank"
            >
              <v-btn small color="primary"
                >Adresse / Arbeitsort in Google Maps öffnen
                <v-icon small right dark> open_in_new</v-icon>
              </v-btn>
            </a>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-autocomplete
              v-model="item.resource"
              :items="resourcesDataMap"
              :loading="resourcesStatusPending"
              hide-no-data
              item-text="Description"
              item-value="id"
              label="Fahrzeug"
              data-cy="resource"
              prepend-icon="mdi-database-search"
              :rules="requieredRule"
              dense
              outlined
            >
              <!--template v-slot:item="{ item }">
                <v-list-item-avatar>
                  <v-icon color="green" dark>
                    verified
                  </v-icon>
                  <v-icon color="red">
                    block
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-action style="font-size: 14px">
                  Test
                </v-list-item-action>
              </template-->
            </v-autocomplete>
            <v-autocomplete
              v-model="item.workers"
              :items="workerDataMap"
              :loading="workerStatusPending"
              hide-no-data
              item-text="Description"
              item-value="id"
              label="Fahrer"
              data-cy="workers"
              prepend-icon="mdi-database-search"
              multiple
              chips
              :rules="requieredRule"
              dense
              outlined
            ></v-autocomplete>
            <v-text-field
              v-model="item.clientName"
              label="Ansprechpartner"
              data-cy="clientName"
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="12"
            style="padding-top: 0px; padding-bottom: 0px"
          >
            <v-textarea
              v-model="item.description"
              label="Beschreibung"
              data-cy="description"
              rows="2"
              dense
              outlined
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="6"
            ><v-text-field
              v-model="item.phone"
              label="Ansprechpartner Telefon/ Mobil"
              prepend-icon="mdi-phone"
              data-cy="phone"
              dense
              outlined
            ></v-text-field
          ></v-col>
          <v-col cols="12" sm="6" md="6">
            <v-autocomplete
              v-model="positionTemplate"
              :items="positionTemplateDataMap"
              :loading="deliveryNotePositionTemplatesStatusPending"
              hide-no-data
              item-text="Description"
              item-value="id"
              label="Lieferscheinpositionen Vorlagen"
              data-cy="positionTemplateDataMap"
              @input="selectpositionTemplateData(positionTemplate)"
              dense
              outlined
            ></v-autocomplete> </v-col
        ></v-row>
        <itemPosForm
          ref="itemPosForm"
          class="pa-2"
          v-model="item.eventPos"
          :contact="item.contact"
          :prices="false"
        />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <div class="flex-grow-1"></div>
      <v-btn data-cy="closeEvent" color="blue darken-1" text @click="close"
        >Zurück</v-btn
      >
      <v-btn data-cy="saveEvent" color="blue darken-1" text @click="save(item)"
        >Speichern</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
function getDefaultData() {
  return {
    name: null,
    eventColor: "blue",
    contact: null,
    startDate: new Date(),
    endDate: new Date(),
    duration: "01:00",
    unit: "min",
    workers: [],
    photos: [],
    description: null,
    resourceId: null,
    resource: null,
    id: null,
    location: null,
    contactPerson: null,
    email: null,
    eventPos: [
      {
        index: 1,
        name: null,
        quantity: 1,
        posId: 1,
        unity: "Std"
      }
    ]
  };
}
import { mapGetters } from "vuex";
import axios from "axios";
import Loading from "vue-loading-overlay";
import repeatEvent from "./repeatEvent.vue";
import photoDialog from "./photoDialog.vue";
import wholeDayEvent from "./wholeDayEvent.vue";
import contactFormWrapper from "../../../contact/createContact/contactFormWrapper.vue";
import itemPosForm from "../../../documents/itemPosForm.vue";

export default {
  components: {
    Loading,
    repeatEvent,
    photoDialog,
    wholeDayEvent,
    contactFormWrapper,
    itemPosForm
  },
  data: () => ({
    formTitle: null,
    item: getDefaultData(),
    valid: true,
    requieredRule: [v => !!v || "Dieses Feld wird benötigt"],
    menu: false,
    panel: 0,
    dialogLoading: false,
    settingsPanel: 1,
    disableFieldsForWholeDayEvent: false,
    addContact: false,
    useEndDate: false,
    dateProps: {
      locale: "de-de",
      "first-day-of-week": "1"
    },
    timeProps: {
      format: "24hr"
    },
    repeatEventVal: false,
    openPhotoDialog: false,
    templates: [],
    templatesLoading: false,
    selectTemplate: null,
    positionTemplate: null
  }),
  mounted() {
    this.initSwitch(this.$route.params);
  },
  watch: {
    "$route.params": function(params) {
      this.initSwitch(params);
    }
  },
  computed: {
    ...mapGetters([
      "resourcesData",
      "resourcesStatusPending",
      "workerData",
      "workerStatusPending",
      "allListGetterWithoutPagnation",
      "allStatusPendingWithoutPagnation",
      "backendServer",
      "units",
      "nextEventId",
      "projectDataGetter",
      "deliveryNotePositionTemplatesData",
      "deliveryNotePositionTemplatesStatusPending"
    ]),
    fields() {
      if (!this.item.resource) return [];

      return Object.keys(this.item.resource).map(key => {
        return {
          key,
          value: this.item.resource[key] || "n/a"
        };
      });
    },
    resourcesDataMap() {
      return this.resourcesData.map(resource => {
        const Description = resource.name + " - " + resource.license;
        const id = resource.id;
        return Object.assign({}, resource, {
          Description,
          id
        });
      });
    },
    workerDataMap() {
      return this.workerData.map(worker => {
        const Description = worker.name + " - " + worker.short;
        const id = worker.id;
        return Object.assign({}, worker, {
          Description,
          id
        });
      });
    },
    projectDataMap() {
      return this.projectDataGetter.map(project => {
        let Description;
        const contact = project.contact;
        if (contact.name) {
          if (contact.name2) {
            Description =
              project.name +
              " - " +
              contact.name +
              " " +
              contact.name2 +
              " | " +
              project.formattedStart +
              " - " +
              project.formattedEnd;
          } else {
            Description = project.name + " - " + contact.name;
            " | " + project.formattedStart + " - " + project.formattedEnd;
          }
        } else {
          Description = project.name + " - " + contact.familyname;
          " | " + project.formattedStart + " - " + project.formattedEnd;
        }
        const id = project.id;
        return Object.assign({}, project, {
          Description,
          id
        });
      });
    },
    contactDataMap() {
      return this.allListGetterWithoutPagnation.allList.data.map(contact => {
        let Description;
        if (contact.name) {
          if (contact.name2) {
            Description = contact.name + " " + contact.name2;
          } else {
            Description = contact.name;
          }
        } else {
          Description = contact.familyname;
        }

        contact.address.city != null
          ? (Description = Description + " | " + contact.address.city)
          : "";
        Description = Description + " - " + contact.customerNumber;
        const id = contact.id;
        return Object.assign({}, contact, {
          Description,
          id
        });
      });
    },
    templatesDataMap() {
      return this.templates.map(template => {
        let Description = template.reference;
        template.location
          ? (Description = Description + " - " + template.location)
          : "";
        template.contactPerson
          ? (Description = Description + " - " + template.contactPerson)
          : "";
        const data = template;
        return Object.assign({}, template, {
          Description,
          data
        });
      });
    },
    duration: {
      get() {
        if (this.item.duration != null && this.item.duration.length >= 5) {
          return this.item.duration.slice(0, 5);
        } else {
          return "01:00";
        }
      },
      set(val) {
        this.item.duration = val;
      }
    },
    positionTemplateDataMap() {
      return this.deliveryNotePositionTemplatesData.map(template => {
        const Description = template.objectId + " - " + template.name;
        const id = template.id;
        return Object.assign({}, template, {
          Description,
          id
        });
      });
    },
    eventName: {
      get() {
        if (this.item.name == null) {
          //on create set next event Number
          this.item.name = this.nextEventId;
          return this.nextEventId;
        } else {
          return this.item.name;
        }
      },
      set(val) {
        this.item.name = val;
      }
    }
  },
  methods: {
    initSwitch(params) {
      if (params.mode == "edit" || params.mode == "copy") {
        this.$store.dispatch("getSingleEvent", params.id).then(r => {
          this.mountedMethod(r);
        });
      } else if (params.mode == "fromOffer") {
        this.mountedMethod(params.offerData);
      } else {
        this.mountedMethod();
      }
    },
    mountedMethod(item) {
      this.$store.dispatch("getAllProjectList");
      if (
        this.$route.params.mode == "edit" ||
        this.$route.params.mode == "copy"
      ) {
        //update / edit existing event
        this.formTitle = "Auftrag bearbeiten";
        this.item = item;
        if (this.$route.params.mode == "copy") {
          this.formTitle = "Auftrag kopieren";
          delete this.item.durationUnit;
          delete this.item.id;
          delete this.item._id;
          delete this.item.createdAt;
          delete this.item.lastModified;
          delete this.item.lastModified;
          delete this.item.deliveryNote;
          delete this.item.signatureDate;
          delete this.item.signature;
          delete this.item.clientName;
          this.item.photos = [];
          this.item.hasPhoto = false;
          this.item.eventColor = "blue";
          this.item.status = "Auftrag";
        }
        //format duration from hours into foramtted duration for users or endDate
        let hours = Math.floor(item.duration / 60);
        let minutes = item.duration % 60;
        if (hours > 23) {
          //format endDate
          this.item.endDate = new Date(
            new Date(item.startDate).getTime() + item.duration * 60000
          );
          this.useEndDate = true;
        }
        if (hours < 10) {
          hours = "0" + hours;
        }
        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        this.item.duration = "" + hours + ":" + minutes + "";

        this.item.resource = item.resourceId;
        this.item.startDate = new Date(item.startDate);
        this.queryData();
      } else if (this.$route.params.mode == "preset") {
        this.$store.dispatch("getNextEventId");
        this.formTitle = "Auftrag anlegen";
        this.queryData();
        let presetData = JSON.parse(this.$route.params.id);
        //format data
        if (presetData.data.rowId) {
          this.item.resourceId = presetData.data.rowId;
          this.item.resource = presetData.data.rowId;
        }
        this.item.startDate = new Date(presetData.data.eventDate);
        this.getStandardItems();
      } else if (this.$route.params.mode == "fromOffer") {
        this.$store.dispatch("getNextEventId");
        this.formTitle = "Auftrag aus Angebot anlegen";
        this.queryData();
        //format data from Offer
        let i = this.item;
        i.contact = item.contact.id;
        i.clientName = item.clientName;
        i.name = "Auftrag aus " + item.header;
        i.eventPos = item.offerPos;
        i.eventPos.forEach(function(element, index) {
          element.posId = index++;
        });
      } else {
        //crete new Event
        this.$store.dispatch("getNextEventId");
        this.formTitle = "Auftrag anlegen";
        this.getStandardItems();
        this.queryData();
      }
    },
    resetData() {
      this.item = getDefaultData();
      this.$refs.form.reset();
    },
    queryData() {
      this.$store.dispatch("getDeliveryNotePositionTemplates");
      //this.$store.dispatch("queryItemsEvents");
      this.$store.dispatch("queryItemsResources");
      if (this.workerData.length === 0) {
        this.$store.dispatch("queryItemsWorker");
      }
      if (this.allListGetterWithoutPagnation.allList.data.length === 0) {
        this.$store.dispatch("getAllListWithoutPagination");
      }
    },
    close() {
      this.dialogLoading = false;
      this.resetData();

      //if mode is copy reset link + back parameter is not set (from scheduler)
      if (
        this.$route.params.mode === "copy" &&
        (this.$route.params.back === "1" || this.$route.params.back === 1)
      ) {
        this.$router.push({
          path: "/planer/1/planer/1/1/1/1"
        });
      } else {
        //if back data is set return the data back to component
        if (this.$route.params.back != 1) {
          const backItem = JSON.parse(this.$route.params.back);
          this.$router
            .push({
              name: backItem.to,
              params: {
                back: JSON.stringify(backItem.data),
                tab: backItem.data.tab,
                deliveryNoteId: 1
              }
            })
            .catch(err => {});
        } else {
          this.$router.back();
        }
      }
    },
    save(item) {
      this.dialogLoading = true;
      item.resourceId = item.resource;
      if (this.$refs.form.validate()) {
        //format duration
        item.duration = this.formatDurationtoMinutes(item);
        if (
          this.$route.params.mode === "new" ||
          this.$route.params.mode === "preset" ||
          this.$route.params.mode === "copy" ||
          this.$route.params.mode === "fromOffer"
        ) {
          if (this.repeatEventVal) {
            this.$refs.repeatEvent.createRepeatingEvents();
          } else {
            this.$store.dispatch("addEvent", item).then(r => {
              if (r == false) {
                this.dialogLoading = false;
                this.$store.dispatch("triggerUniversalAlert", {
                  text:
                    "Der Auftrag kann nicht erstellt werden, weil es sich mit einem anderen überschneidet.",
                  heading: "Fehler - Auftrag anlegen",
                  type: "warning"
                });
              } else {
                this.close();
              }
            });
          }
        } else {
          this.$store.dispatch("updateEvent", item).then(r => {
            if (r.id) {
              this.close();
            } else {
              this.dialogLoading = false;
              this.$store.dispatch("triggerUniversalAlert", {
                text:
                  "Der Auftrag kann nicht verschoben / geändert werden, weil es sich mit einem anderen überschneidet.",
                heading: "Fehler - Auftrag bearbeiten",
                type: "warning"
              });
            }
          });
        }
      } else {
        this.dialogLoading = false;
      }
    },
    useWholeDayValues(val) {
      this.disableFieldsForWholeDayEvent = true;
      this.item.duration = val.duration;
      this.item.startDate = val.date;
    },
    contactClose(val) {
      this.$store.dispatch("getAllListWithoutPagination");
      const Description = val.name + " - " + val.customerNumber;
      const id = val.id;
      const contact = val;
      this.item.contact = Object.assign(contact, {
        Description,
        id
      });
      this.addContact = false;
    },
    //set repeat value emitted by repeat event form
    setRepeatEventVal(val) {
      this.repeatEventVal = val;
    },
    closePhotoDialog() {
      this.openPhotoDialog = false;
    },
    finishLoading() {
      this.dialogLoading = false;
    },
    getStandardItems() {
      this.$store.dispatch("getStandardItems").then(res => {
        if (res.value.length != 0) {
          this.item.eventPos = res.value;
        }
      });
    },
    loadConstructionSites(contact) {
      if (contact) {
        this.templatesLoading = true;
        this.$store.dispatch("getSingleContact", contact).then(e => {
          this.templates = e.templates;
          this.templatesLoading = false;
        });
        this.templatesLoading = false;
      }
    },
    loadResources() {
      this.$store.dispatch("queryItemsResources");
    },
    selectConstructionSites(selectTemplate) {
      //set parameters to preset from construciton site template
      let itm = this.item;
      itm.clientName
        ? (itm.clientName =
            itm.clientName + " | " + selectTemplate.contactPerson)
        : (itm.clientName = selectTemplate.contactPerson);
      itm.description
        ? (itm.description =
            itm.description + " | " + selectTemplate.description)
        : (itm.description = selectTemplate.description);
      selectTemplate.phone ? (itm.phone = selectTemplate.phone) : "";
      itm.location
        ? (itm.location = itm.location + " | " + selectTemplate.location)
        : (itm.location = selectTemplate.location);
      this.eventName
        ? (this.eventName = this.eventName + " " + selectTemplate.reference)
        : (this.eventName = selectTemplate.reference);
      itm.email = selectTemplate.email;
    },
    selectpositionTemplateData(selectTemplate) {
      if (selectTemplate) {
        let vm = this;
        //set preset from predefined delivery note positions
        vm.$store
          .dispatch("getSingleDeliveryNotePositionTemplates", {
            id: selectTemplate
          })
          .then(result => {
            vm.item.eventPos = [];
            result.positions.forEach(element => {
              const formattedElem = element.inventoryItem;
              formattedElem.quantity = element.quantity;
              vm.item.eventPos.push(formattedElem);
            });
            //reload indiv prices in itemPos component when contact is set
            if (this.item.contact != null) {
              this.$refs.itemPosForm.getIndivItemsContactPrices(
                this.item.contact
              );
            }
          });
      }
    },
    formatDurationtoMinutes(value) {
      //format Hours to minutes
      if (this.useEndDate) {
        const dif = value.endDate - value.startDate;
        value.durationUnit = "minute";
        return Math.round(dif / 1000 / 60);
      }
      if (
        this.$route.params.mode === "new" ||
        this.$route.params.mode === "preset" ||
        this.$route.params.mode === "copy" ||
        this.$route.params.mode === "fromOffer"
      ) {
        //on create
        if (
          value.durationUnit == null &&
          value.duration != null &&
          value.duration.length > 2
        ) {
          const timeToMinutes = value.duration;
          value.duration = timeToMinutes.substring(0, 2) * 60;
          return (
            parseInt(value.duration) + parseInt(timeToMinutes.substring(3, 5))
          );
        } else {
          if (value.duration == null) {
            return 60;
          } else {
            //duration value is already set
            return value.duration;
          }
        }
      } else {
        // on edit
        const timeToMinutes = value.duration;
        value.duration = timeToMinutes.substring(0, 2) * 60;
        return (
          parseInt(value.duration) + parseInt(timeToMinutes.substring(3, 5))
        );
      }
    }
  }
};
</script>
<style>
.v-application .v-date-picker-table .accent {
  background-color: #2c72e7 !important;
  border-color: #2c72e7 !important;
}

.v-application .v-time-picker-clock .accent {
  background-color: #2c72e7 !important;
  border-color: #2c72e7 !important;
}

.v-application .v-date-picker-table .accent--text {
  color: #2c72e7 !important;
  caret-color: #2c72e7 !important;
}

.v-application .green--text.text--darken-1 {
  color: #2c72e7 !important;
}
</style>
