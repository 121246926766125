<template>
  <div>
    <v-list flat subheader three-line> </v-list>
    <v-list-item>
      <v-list-item-content>
        <v-container>
          <v-container>
            <p>Nummernkreise</p>

            <h5>
              Hier können Sie festlegen wo ihre Dokumentnummern starten sollen.
            </h5>
          </v-container>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="2" style="margin-top: 8px">
                Angebote
              </v-col>
              <v-col cols="12" sm="6" md="1">
                <v-text-field
                  type="number"
                  label="Startnummer"
                  data-cy="offer"
                  v-model="offer"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-container>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="2" style="margin-top: 8px">
                Rechnungen
              </v-col>
              <v-col cols="12" sm="6" md="1">
                <v-text-field
                  type="number"
                  label="Startnummer"
                  data-cy="invoice"
                  v-model="invoice"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-container>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="2" style="margin-top: 8px">
                Lieferscheine
              </v-col>
              <v-col cols="12" sm="6" md="1">
                <v-text-field
                  type="number"
                  label="Startnummer"
                  data-cy="deliveryNote"
                  v-model="deliveryNote"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-container>
          <div class="flex-grow-1"></div>
          <v-btn color="secondary" text @click="save">Speichern</v-btn>
        </v-container>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  data: () => ({
    deliveryNote: 1000,
    offer: 1000,
    invoice: 1000
  }),
  computed: {
    ...mapGetters(["backendServer"])
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      let config = {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      };
      axios
        .get(this.backendServer + "settings/startAt", config)
        .then(response => {
          for (let startAt of response.data.data) {
            if (startAt.name == "DeliveryNote") {
              this.deliveryNote = startAt.start;
            } else if (startAt.name == "Invoice") {
              this.invoice = startAt.start;
            } else if (startAt.name == "Offer") {
              this.offer = startAt.start;
            }
          }
        })
        .catch(err => {
          this.$store.dispatch("triggerError", e);
        });
    },
    save() {
      let possible = ["Offer", "Invoice", "DeliveryNote"];
      let config = {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      };

      let update = {
        Offer: this.offer,
        Invoice: this.invoice,
        DeliveryNote: this.deliveryNote
      };

      for (let p of possible) {
        axios
          .put(
            this.backendServer + "settings/startAt/" + p,
            { start: update[p] },
            config
          )
          .then(response => {})
          .catch(err => {
            this.$store.dispatch("triggerError", err);
          });
      }

      this.$store.dispatch("triggerUniversalAlert", {
        text: "Einstellung wurde erfolgreich aktualisiert",
        heading: "Erfolgreich aktualisiert",
        type: "info"
      });
    }
  }
};
</script>
