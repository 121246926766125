<template>
  <div>
    <v-simple-table :height="300">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Name</th>
            <th class="text-left">Auslastung</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.resource.name">
            <td>{{ item.resource.name }}</td>
            <td>{{ item.workload.toFixed(2).toString().replace('.', ',') }} %</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row>
      <v-col cols="12" md="3">
        <v-text-field
          v-model="config.hours"
          class="mx-4"
          label="Sollstunden pro Tag"
          data-cy="hours"
          max="24"
          min="1"
          step="1"
          type="number"
          style="width: 125px"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4" style="padding-top:30px">
        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="formattedStartDate"
              label="Startdatum"
              data-cy="formattedStartDate"
              prepend-icon="event"
              readonly
              v-on="on"
              outlined
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="config.start"
            @input="menu1 = false"
            locale="de-de"
            first-day-of-week="1"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="4" style="padding-top:30px">
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="formattedEndDate"
              label="Enddatum"
              data-cy="formattedEndDate"
              prepend-icon="event"
              readonly
              v-on="on"
              outlined
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="config.end"
            @input="menu2 = false"
            locale="de-de"
            first-day-of-week="1"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import { mapGetters } from "vuex";

export default {
  name: "revenuechart",
  components: {
    Loading
  },
  props: [],
  data: () => ({
    config: {
      start: "",
      end: "",
      hours: 8
    },
    menu1: false,
    menu2: false,
    items: []
  }),
  created() {
    this.initialze();
  },
  computed: {
    formattedStartDate() {
      return this.formatDate(this.config.start);
    },
    formattedEndDate() {
      return this.formatDate(this.config.end);
    },
    ...mapGetters(["workloadData"])
  },
  watch: {
    workloadData: {
      handler(val) {
        if (val.data.length != 0) {
          this.items = val.data;
        }
      }
    },
    config: {
      handler(val) {
        if (val.hours) {
          this.$store.dispatch("getWorkloadData", this.config);
        }
      },
      deep: true
    }
  },
  methods: {
    initialze() {
      this.config.start = new Date().setDate(1);
      this.formatDate(this.config.end);
      this.formatDate(this.config.start);
      this.$store.dispatch("getWorkloadData", this.config);
    },
    formatDate(date) {
      if (!date || date == null || date instanceof Date) {
        //init dates
        let today = new Date();
        this.config.start = today.toISOString().substr(0, 10);
        this.config.end = new Date(today.setDate(today.getDate() + 1))
          .toISOString()
          .substr(0, 10);
      }
      if (date.split("-").length < 3) {
        let dayFirst = date.slice(0, 1);
        let monthFirst = date.slice(3, 4);
        if (dayFirst == 0 || monthFirst == 0) {
          let year = date.slice(-4);
          let withoutYear = date.slice(0, 6);
          withoutYear = withoutYear.replace(/0/g, "");
          date = withoutYear + year;
        }
        return date;
      } else {
        const [year, month, day] = date.split("-");
        return `${day}.${month}.${year}`;
      }
    }
  }
};
</script>
