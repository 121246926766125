<template>
  <div>
    <v-list flat subheader three-line>
      <v-subheader><h4>Prozess-Einstellungen</h4></v-subheader>
    </v-list>
    <v-list-item>
      <v-list-item-content>
        <v-container>
          <v-card class="mx-auto" max-width="900">
            <v-container>
              <p>Rechnungs-Einstellungen für SevDesk</p>
            </v-container>
            <v-form class="pa-4" ref="form" lazy-validation>
              <p>Rechnung nach versenden automatisch exportieren</p>
              <h5>
                Gibt an ob Rechnungen nach dem Versenden automatisch zu Sevdesk
                exportiert werden sollen.
              </h5>
              <br />
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <p style="margin-top: 25px">
                    Rechnungen automatisch exportieren
                  </p>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-switch
                    v-model="autoExportInvoice"
                    @change="changeExportInvoice"
                  ></v-switch>
                </v-col>
              </v-row>
              <hr />
              <br />
              <br />
              <p>Rechnungen in SevDesk festschreiben</p>
              <h5>
                Ist diese Option aktiviert, werden Rechnungen in SevDesk
                festgeschrieben. Wenn diese Option deaktiviert ist werden
                Rechnungen als Entwurf zu SevDesk exportiert.
              </h5>
              <br />
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <p style="margin-top: 25px">
                    Rechnungen festschreiben
                  </p>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-switch
                    v-model="commitSevDeskInvoice"
                    @change="changeCommitSevDeskInvoice"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-container>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  data: () => ({
    autoExportInvoice: false,
    commitSevDeskInvoice: false
  }),
  computed: {
    ...mapGetters(["backendServer"])
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch("getLicenseData");
      let config = {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      };
      axios
        .get(this.backendServer + "settings", config)
        .then(response => {
          for (let setting of response.data.data) {
            if (setting.name == "autoExportInvoice") {
              this.autoExportInvoice = setting.value;
            } else if (setting.name == "commitSevDeskInvoice") {
              this.commitSevDeskInvoice = setting.value;
            }
          }
        })
        .catch(err => {});
    },
    changeExportInvoice() {
      let vm = this;
      let config = {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      };

      axios
        .put(
          this.backendServer + "settings/autoExportInvoice",
          { value: this.autoExportInvoice },
          config
        )
        .then(response => {
          vm.$store.dispatch("triggerUniversalAlert", {
            text: "Einstellung wurde erfolgreich aktualisiert",
            heading: "Erfolgreich aktualisiert",
            type: "info"
          });
        })
        .catch(error => {
          vm.$store.dispatch("triggerUniversalAlert", {
            text:
              "Es ist ein unerwarteter Fehler aufgetreten bitte kontaktieren Sie den Support",
            heading: "Unerwarteter Fehler",
            type: "error"
          });
        });
    },
    changeCommitSevDeskInvoice() {
      let vm = this;
      let config = {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      };

      axios
        .put(
          this.backendServer + "settings/commitSevDeskInvoice",
          { value: this.commitSevDeskInvoice },
          config
        )
        .then(response => {
          vm.$store.dispatch("triggerUniversalAlert", {
            text: "Einstellung wurde erfolgreich aktualisiert",
            heading: "Erfolgreich aktualisiert",
            type: "info"
          });
        })
        .catch(error => {
          vm.$store.dispatch("triggerUniversalAlert", {
            text:
              "Es ist ein unerwarteter Fehler aufgetreten bitte kontaktieren Sie den Support",
            heading: "Unerwarteter Fehler",
            type: "error"
          });
        });
    }
  }

};
</script>
