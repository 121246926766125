import axios from "axios";

const actions = {
  login({ commit, getters, dispatch }, loginData) {
    commit("LOGIN_PENDING");
    return axios
      .post(getters.backendServer + "login", {
        email: loginData.email,
        password: loginData.password
      })
      .then(response => {
        if (response.data.role === "Worker") {
          commit("CREATE_SNACKBAR", "Berechtigungsfehler - Fahrer Anmeldung nur per App möglich");
          commit("LOGIN_FAILED");
          return false;
        } else {
          localStorage.setItem("token", response.data.token);
          commit("SET_TOKEN", response.data.token);
          commit("SET_USER_ROLE", response.data.role);
          commit("LOGIN_SUCCESS");
          dispatch("getUserInfo");
          return true;
        }
      })
      .catch(err => {
        dispatch("triggerError", err);
        commit("CREATE_SNACKBAR", "Passwort oder Email nicht korrekt.");
        commit("LOGIN_FAILED");
      });
  },
  logout({ commit }) {
    return new Promise(resolve => {
      localStorage.removeItem("token");
      commit("SET_TOKEN", null);
      resolve();
    });
  },
  getUserInfo(context){
    let getUrl =
      context.getters.backendServer +
      "user/info";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    return axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_USER_INFO", response.data);
        return response.data;
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });

  }
};

export default actions;
