<script>
import Loading from "vue-loading-overlay";
import { mapGetters } from "vuex";
import { Bar } from 'vue-chartjs';

export default {
  name: 'revenuechart',
  extends: Bar,
  components: {
    Loading
  },
  props:[],
  data: () => ({
      netRevenueSet: false,
      brutRevenueSet: false
  }),
  created(){
     this.initialze();
  },
  computed: {
      ...mapGetters(['netRevenueData', 'brutRevenueData'])
  },
  watch: {
      netRevenueData: {
          handler(val){
            if(val.data.length != 0){
                this.netRevenueSet = true;
            }
        }
    },
    brutRevenueData: {
        handler(val){
            if(val.data.length != 0){
                this.brutRevenueSet = true;
            }
        }
    },
    netRevenueSet: {
        handler(val){
            if(val && this.brutRevenueSet){
                this.createChart();
            }
        }
    },
    brutRevenueSet: {
        handler(val){
            if(val && this.netRevenueSet){
                this.createChart();
            }
        }
    }

  },
  methods: {
      initialze(){
          this.$store.dispatch("getNetRevenueData");
          this.$store.dispatch("getBrutRevenueData");
      },
      createChart(){
          let netRevenue = this.netRevenueData.data[0]
          let brutRevenue = this.brutRevenueData.data[0]

        let labels = Object.keys(netRevenue).reverse();

        let netData = Object.values(netRevenue).reverse();
        let brutData = Object.values(brutRevenue).reverse();

        for(let i=0; i<netData.length; i++){
            brutData[i] = Math.round((brutData[i] - netData[i])*100)/100;
        }
        this.renderChart({
            'labels': labels,
            'datasets':[{
                'label': 'Umsatz in EUR',
                backgroundColor: "#2c72e7",
                'data': netData
            },
            {
                'label': 'Umsatzsteuer in EUR',
                backgroundColor: "#D36582",
                'data': brutData
            }]
        },
        {
            responsive: true,
            maintainAspectRatio: false,
            layout: {
                padding: {
                    left: 50,
                    right: 0,
                    top: 0,
                    bottom: 0
                }
            },
            scales: {
                xAxes: [{
                    stacked: true
                }],
                yAxes: [{
                    stacked: true
                }]
            },
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        return (Math.round(tooltipItem.yLabel * 100) / 100).toFixed(2).toString().replace('.', ',').replace(',00', '') + ' EUR';
                    }
                }
            }
        });
      }
  }

}
</script>