<template>
  <header class="app-header">
    <v-toolbar elevation="0">
      <v-row>
        <v-col cols="12" sm="6" md="5" class="spacing pt-11">
          <singleDateForm
            v-model="schedulerDate"
            label="Datum"
            data-cy="schedulerDate"
            prependIcon="event"
            @input="prepareChangeSchedulerDate(schedulerDate)"
          />
        </v-col>
      </v-row>
      <b>{{ toolbarTitle }}</b>
      <div class="flex-grow-1"></div>
      <v-btn
        fab
        small
        @click="$emit('step', 'back')"
        class="mr-2"
        color="primary"
        dark
        ><v-icon right> arrow_back_ios</v-icon></v-btn
      >
      <v-btn
        fab
        small
        @click="$emit('step', 'forward')"
        class="mr-1"
        color="primary"
        dark
        ><v-icon>arrow_forward_ios</v-icon></v-btn
      >
      <template v-if="$vuetify.breakpoint.smAndUp">
        <v-btn-toggle
          v-model="toggle_exclusive_view_mode"
          mandatory
          dense
          outlined
          class="pa-3"
          ><v-btn>
            Tag
          </v-btn>
          <v-btn>
            Woche
          </v-btn>
          <v-btn>
            Monat
          </v-btn>
        </v-btn-toggle>
        <v-divider inset vertical></v-divider>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="$emit('today')">
              <v-icon>settings_backup_restore</v-icon>
            </v-btn>
          </template>
          <span>Plantafel zurücksetzen</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
              @click="createEventWithoutPreset"
              data-cy="createAuftrag"
            >
              <v-icon>note_add</v-icon>
            </v-btn>
          </template>
          <span>Auftrag anlegen</span>
        </v-tooltip>
      </template>
    </v-toolbar>
  </header>
</template>

<script>
import singleDateForm from "../../_utils/singleDateForm.vue";

export default {
  props: ["datepickerDate", "toolbarTitle"],
  components: {
    singleDateForm
  },
  data: () => ({
    schedulerDate: new Date().toISOString().substr(0, 10),
    time: "Tag",
    toggle_exclusive_view_mode: 0
  }),
  watch: {
    toggle_exclusive_view_mode(val) {
      //set view modes in planer and adjust dates according to them
      switch (val) {
        case 0:
          //tag
          this.$emit("changeViewMode", "day");
          break;
        case 1:
          //woche
          this.$emit("changeViewMode", "week");
          break;
        case 2:
          //monat
          this.$emit("changeViewMode", "month");
          break;
      }
    },
    datepickerDate(val) {
      //set datepicker date from function
      this.schedulerDate = this.formatToISODate(val);
    }
  },
  methods: {
    prepareChangeSchedulerDate(val) {
      this.$emit("changeDate", val);
    },
    createEventWithoutPreset() {
      this.$router.push({
        name: "eventForm",
        params: { id: 1, mode: "new", back: 1 }
      });
    },
    formatToISODate(date) {
      return (
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + date.getDate()).slice(-2)
      );
    }
  }
};
</script>

<style></style>
