import Vue from "vue";
import Vuex from "vuex";
import config from "./config/index.js";
import login from "./login/index.js";
import contacts from "./contacts/index.js";
import documents from "./documents/index.js";
import events from "./events/index.js";
import fleet from "./fleet/index.js";
import user from "./user/index.js";
import activity from "./activity/index.js";
import errors from "./errors/index.js";
import staticData from "./staticData/index.min.js";
import analytics from "./analytics/index.js";
import inventory from "./inventory/index.js";
import project from "./projects/index.js";
import accounting from './accounting/index.js';
import websiteRequest from './websiteRequests/index.js';
import customer from './customer/index.js';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    config,
    login,
    contacts,
    documents,
    events,
    fleet,
    user,
    activity,
    errors,
    staticData,
    analytics,
    inventory,
    project,
    accounting,
    websiteRequest,
    customer
  }
});
