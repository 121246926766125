<template>
  <div>
    <v-btn bottom color="secondary" dark fab fixed right @click="dialog = !dialog">
      <v-icon>mdi-settings</v-icon>
    </v-btn>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Admin - Einstellungen</v-toolbar-title>
          <div class="flex-grow-1"></div>
          <!--v-toolbar-items>
            <v-btn dark text @click="dialog = false">Speichern</v-btn>
          </v-toolbar-items-->
        </v-toolbar>
        <v-card ma-2>
          <v-tabs>
            <v-tab>Einstellungen</v-tab>
            <v-tab-item>
              <adminSettings />
            </v-tab-item>
            <v-tab>Error Log</v-tab>
            <v-tab-item>
              <errorLog />
            </v-tab-item>
            <v-tab>API-Key</v-tab>
            <v-tab-item>
              <apiKeyAdmin />
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import errorLog from "../../views/settings/errors.vue";
import adminSettings from "../../views/settings/adminSettings.vue";
import apiKeyAdmin from "../../views/settings/apiKeyAdmin.vue";
export default {
  components: {
    errorLog,
    adminSettings,
    apiKeyAdmin,
  },
  data: () => ({
    dialog: false,
    notifications: false,
    sound: false,
    widgets: false,
  }),
};
</script>
