import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VList,{attrs:{"flat":"","subheader":"","three-line":""}}),_c(VListItem,[_c(VListItemContent,[_c(VContainer,[_c(VContainer,[_c('p',[_vm._v("Nummernkreise")]),_c('h5',[_vm._v(" Hier können Sie festlegen wo ihre Dokumentnummern starten sollen. ")])]),_c(VContainer,[_c(VRow,[_c(VCol,{staticStyle:{"margin-top":"8px"},attrs:{"cols":"12","sm":"6","md":"2"}},[_vm._v(" Angebote ")]),_c(VCol,{attrs:{"cols":"12","sm":"6","md":"1"}},[_c(VTextField,{attrs:{"type":"number","label":"Startnummer","data-cy":"offer","outlined":"","dense":""},model:{value:(_vm.offer),callback:function ($$v) {_vm.offer=$$v},expression:"offer"}})],1)],1)],1),_c(VContainer,[_c(VRow,[_c(VCol,{staticStyle:{"margin-top":"8px"},attrs:{"cols":"12","sm":"6","md":"2"}},[_vm._v(" Rechnungen ")]),_c(VCol,{attrs:{"cols":"12","sm":"6","md":"1"}},[_c(VTextField,{attrs:{"type":"number","label":"Startnummer","data-cy":"invoice","outlined":"","dense":""},model:{value:(_vm.invoice),callback:function ($$v) {_vm.invoice=$$v},expression:"invoice"}})],1)],1)],1),_c(VContainer,[_c(VRow,[_c(VCol,{staticStyle:{"margin-top":"8px"},attrs:{"cols":"12","sm":"6","md":"2"}},[_vm._v(" Lieferscheine ")]),_c(VCol,{attrs:{"cols":"12","sm":"6","md":"1"}},[_c(VTextField,{attrs:{"type":"number","label":"Startnummer","data-cy":"deliveryNote","outlined":"","dense":""},model:{value:(_vm.deliveryNote),callback:function ($$v) {_vm.deliveryNote=$$v},expression:"deliveryNote"}})],1)],1)],1),_c('div',{staticClass:"flex-grow-1"}),_c(VBtn,{attrs:{"color":"secondary","text":""},on:{"click":_vm.save}},[_vm._v("Speichern")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }