import { StoreUtil } from "../utils";

const mutations = {
  SET_PAGINATION_VEHICLES(state, payload) {
    state.dataVehicles.pagination = payload;
  },
  RESET_PAGINATION_PAGES_VEHICLES(state) {
    state.dataVehicles.pagination.page = 1;
    state.dataVehicles.pagination.itemsPerPage = 10;
  },
  SET_TOTAL_ITEMS_VEHICLES(state, payload) {
    state.dataVehicles.pagination.totalItems = payload;
  },
  SET_VEHICLES_LIST(state, payload) {
    state.dataVehicles.vehiclesList = StoreUtil.updateState(
      state.dataVehicles.vehiclesList,
      payload
    );
  },
  SET_VEHICLES_ADD(state, payload) {
    StoreUtil.updateState(state.dataVehicles.vehiclesList, payload);
  },
  SET_VEHICLES_DELETE(state, payload) {
    StoreUtil.updateState(state.dataVehicles.vehiclesList, payload);
  },
  SET_VEHICLES_UPDATE(state, payload) {
    StoreUtil.updateState(state.dataVehicles.vehiclesList, payload);
  },
  SET_VISIBLE_IN_SCHEDULER_VEHICLE(state, payload) {
    StoreUtil.updateState(state.dataVehicles.vehiclesList, payload);
  },

  SET_PAGINATION_MACHINERY(state, payload) {
    state.dataMachinery.pagination = payload;
  },
  RESET_PAGINATION_PAGES_MACHINERY(state) {
    state.dataMachinery.pagination.page = 1;
    state.dataMachinery.pagination.itemsPerPage = 10;
  },
  SET_TOTAL_ITEMS_MACHINERY(state, payload) {
    state.dataMachinery.pagination.totalItems = payload;
  },
  SET_MACHINERY_LIST(state, payload) {
    state.dataMachinery.machineryList = StoreUtil.updateState(
      state.dataMachinery.machineryList,
      payload
    );
  },
  SET_MACHINERY_ADD(state, payload) {
    StoreUtil.updateState(state.dataMachinery.machineryList, payload);
  },
  SET_MACHINERY_DELETE(state, payload) {
    StoreUtil.updateState(state.dataMachinery.machineryList, payload);
  },
  SET_MACHINERY_UPDATE(state, payload) {
    StoreUtil.updateState(state.dataMachinery.machineryList, payload);
  },
  SET_VISIBLE_IN_SCHEDULER_MACHINERY(state, payload) {
    StoreUtil.updateState(state.dataMachinery.machineryList, payload);
  }
};

export default mutations;
