import axios from "axios";
const actions = {
  getAllList(context) {
    context.commit("SET_ALL_LIST");
    const { page, itemsPerPage, search } = context.state.dataAll.pagination;
    let getUrl;
    if (search != "" && search != null) {
      getUrl =
        context.getters.backendServer +
        "contact?page=" +
        page +
        "&perPage=" +
        itemsPerPage +
        "&q=" +
        search;
      context.commit("RESET_PAGINATION_PAGES_ALL");
    } else {
      getUrl =
        context.getters.backendServer +
        "contact?page=" +
        page +
        "&perPage=" +
        itemsPerPage;
    }
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_ALL_LIST", response.data.data);
        context.commit("SET_TOTAL_ITEMS_ALL", parseInt(response.data.count));
      })
      .catch(function(e) {
        context.commit("SET_ALL_LIST", e);
        context.dispatch("triggerError", e);
      });
  },

  getAllListWithoutPagination(context) {
    context.commit("SET_ALL_LIST_WITHOUT_PAGINATION");
    let getUrl = context.getters.backendServer + "contact";

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_ALL_LIST_WITHOUT_PAGINATION", response.data.data);
      })
      .catch(function(e) {
        context.commit("SET_ALL_LIST_WITHOUT_PAGINATION", e);
        context.dispatch("triggerError", e);
      });
  },

  getKundeList(context) {
    context.commit("SET_KUNDE_LIST");
    const { page, itemsPerPage, search } = context.state.dataKunde.pagination;
    let getUrl;
    if (search != null && search != "") {
      getUrl =
        context.getters.backendServer +
        "contact?page=" +
        page +
        "&perPage=" +
        itemsPerPage +
        "&category=Kunde" +
        "&q=" +
        search;
      context.commit("RESET_PAGINATION_PAGES_KUNDE");
    } else {
      getUrl =
        context.getters.backendServer +
        "contact?page=" +
        page +
        "&perPage=" +
        itemsPerPage +
        "&category=Kunde";
    }
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_KUNDE_LIST", response.data.data);
        context.commit("SET_TOTAL_ITEMS_KUNDE", parseInt(response.data.count));
      })
      .catch(function(e) {
        context.commit("SET_KUNDE_LIST", e);
        context.dispatch("triggerError", e);
      });
  },

  getLieferantList(context) {
    context.commit("SET_LIEFERANT_LIST");
    const {
      page,
      itemsPerPage,
      search
    } = context.state.dataLieferant.pagination;
    let getUrl;
    if (search != null && search != "") {
      getUrl =
        context.getters.backendServer +
        "contact?page=" +
        page +
        "&perPage=" +
        itemsPerPage +
        "&category=Lieferant" +
        "&q=" +
        search;
      context.commit("RESET_PAGINATION_PAGES_LIEFERANT");
    } else {
      getUrl =
        context.getters.backendServer +
        "contact?page=" +
        page +
        "&perPage=" +
        itemsPerPage +
        "&category=Lieferant";
    }
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_LIEFERANT_LIST", response.data.data);
        context.commit(
          "SET_TOTAL_ITEMS_LIEFERANT",
          parseInt(response.data.count)
        );
      })
      .catch(function(e) {
        context.commit("SET_LIEFERANT_LIST", e);
        context.dispatch("triggerError", e);
      });
  },

  getPartnerList(context) {
    context.commit("SET_PARTNER_LIST");
    const { page, itemsPerPage, search } = context.state.dataPartner.pagination;
    let getUrl;
    if (search != null && search != "") {
      getUrl =
        context.getters.backendServer +
        "contact?page=" +
        page +
        "&perPage=" +
        itemsPerPage +
        "&category=Partner" +
        "&q=" +
        search;
      context.commit("RESET_PAGINATION_PAGES_PARTNER");
    } else {
      getUrl =
        context.getters.backendServer +
        "contact?page=" +
        page +
        "&perPage=" +
        itemsPerPage +
        "&category=Partner";
    }
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_PARTNER_LIST", response.data.data);
        context.commit(
          "SET_TOTAL_ITEMS_PARTNER",
          parseInt(response.data.count)
        );
      })
      .catch(function(e) {
        context.commit("SET_PARTNER_LIST", e);
        context.dispatch("triggerError", e);
      });
  },

  getInteressentList(context) {
    context.commit("SET_INTERESSENT_LIST");
    const {
      page,
      itemsPerPage,
      search
    } = context.state.dataInteressent.pagination;
    let getUrl;
    if (search != null && search != "") {
      getUrl =
        context.getters.backendServer +
        "contact?page=" +
        page +
        "&perPage=" +
        itemsPerPage +
        "&category=Interessent" +
        "&q=" +
        search;
      context.commit("RESET_PAGINATION_PAGES_INTERESSENT");
    } else {
      getUrl =
        context.getters.backendServer +
        "contact?page=" +
        page +
        "&perPage=" +
        itemsPerPage +
        "&category=Interessent";
    }
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_INTERESSENT_LIST", response.data.data);
        context.commit(
          "SET_TOTAL_ITEMS_INTERESSENT",
          parseInt(response.data.count)
        );
      })
      .catch(function(e) {
        context.commit("SET_INTERESSENT_LIST", e);
        context.dispatch("triggerError", e);
      });
  },

  getNextContactId(context) {
    let getUrl =
      context.getters.backendServer + "contact/getNextCustomerNumber";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    return axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_NEXT_CONTACT_ID", response.data.customerNumber);
        return response.data;
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },

  addContact(context, value) {
    context.commit("SET_CONTACT_ADD");
    let postUrl = context.getters.backendServer + "contact";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    return axios
      .post(postUrl, value, config)
      .then(function(response) {
        context.commit("SET_CONTACT_ADD", response.data);
        context.commit(
          "CREATE_SNACKBAR",
          "Kontakt " + response.data.customerNumber + " erfolgreich hinzugefügt"
        );
        context.dispatch("getAllList");
        context.dispatch("get" + response.data.category + "List");
        return response.data;
      })
      .catch(function(e) {
        context.commit("SET_CONTACT_ADD", e);
        context.dispatch("triggerError", e);
      });
  },

  deleteContact(context, value) {
    context.commit("SET_CONTACT_DELETE");
    let postUrl = context.getters.backendServer + "contact/" + value.id;

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .delete(postUrl, config)
      .then(function() {
        context.commit("SET_CONTACT_DELETE", "[]");
        context.commit(
          "CREATE_SNACKBAR",
          "Kontakt " + value.customerNumber + " erfolgreich gelöscht"
        );
        context.dispatch("getAllList");
        context.dispatch("get" + value.category + "List");
      })
      .catch(function(e) {
        context.commit("SET_CONTACT_DELETE", e);
        if (
          e.response.data.message ==
          "Please delete all invoices associated with this contact before deleting the contact"
        ) {
          context.dispatch("triggerUniversalAlert", {
            text:
              "Der Kontakt kann nicht gelöscht werden, da noch Rechnungen zu diesem Kontakt vorhanden sind.",
            heading: "Fehler - Kontakt Löschen",
            type: "error"
          });
        } else {
          context.dispatch("triggerError", e);
        }
      });
  },

  updateContact(context, value) {
    context.commit("SET_CONTACT_UPDATE");

    let postUrl = context.getters.backendServer + "contact/" + value.id;

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    value.formattedBirthday = null;
    axios
      .put(postUrl, value, config)
      .then(function(response) {
        context.commit("SET_CONTACT_UPDATE", response.data);
        context.commit(
          "CREATE_SNACKBAR",
          "Kontakt " + response.data.customerNumber + " erfolgreich verändert"
        );
        context.dispatch("getAllList");
        context.dispatch("get" + response.data.category + "List");
      })
      .catch(function(e) {
        context.commit("SET_CONTACT_UPDATE", e);
        context.dispatch("triggerError", e);
      });
  },

  getSingleContact(context, item) {
    let getUrl = context.getters.backendServer + "contact/" + item;
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    return axios
      .get(getUrl, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  }
};

export default actions;
