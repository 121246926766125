<template>
  <v-container>
    <v-form class="pa-4" ref="form" v-model="valid" lazy-validation>
      <p>Lizenzeinstellungen</p>
      <br />
      <v-row>
        <v-col cols="12" sm="12" md="1">
          <v-text-field
            v-model="item.resources"
            label="Resourcen"
            data-cy="resources"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="1">
          <v-text-field
            v-model="item.user"
            label="Benutzer"
            data-cy="user"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="1">
          <v-text-field
            v-model="item.worker"
            label="Fahrer"
            data-cy="worker"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="1">
          <v-switch
            v-model="item.modules.datev"
            label="Datev"
            data-cy="datev"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="12" md="1">
          <v-switch
            v-model="item.modules.analytics"
            label="Analytics"
            data-cy="analytics"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="12" md="1">
          <v-switch
            v-model="item.modules.sevdesk"
            label="Sevdesk"
            data-cy="sevdesk"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="12" md="1">
          <v-switch
            v-model="item.modules.customerPortal"
            label="Kundenportal"
            data-cy="customerPortal"
          ></v-switch>
        </v-col>
      </v-row>
      <v-btn color="secondary" text @click="save">Speichern</v-btn>
    </v-form>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    item: {
      client: null,
      consultant: null,
      modules: {}
    },
    valid: true,
    requieredRule: [v => !!v || "Dieses Feld wird benötigt"]
  }),

  created() {
    this.initialize();
  },

  computed: {
    ...mapGetters(["licenseData"])
  },

  watch: {
    licenseData: {
      handler(val) {
        if (val.data[0]) {
          this.item = val.data[0].value ? val.data[0].value : this.item;
        }
      }
    }
  },

  methods: {
    save() {
      let vm = this;

      this.$store.dispatch("updateLicenseData", this.item).then(result => {});
    },
    initialize() {
      this.$store.dispatch("getLicenseData").then(result => {});
    }
  }
};
</script>
