<template>
  <v-card class="mx-auto my-5" max-width="1200">
    <loading
      :active.sync="dialogLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <v-card-title>
      <span class="headline">{{ formTitle }}</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row justify="space-between">
            <v-col cols="12" sm="6" md="7">
              <v-text-field
                v-model="item.header"
                label="Betreff"
                data-cy="header"
                dense
                outlined
                :rules="requieredRule"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="space-between">
            <contactselectForm v-model="item" />
            <!--v-col cols="12" sm="6" md="3">
              <v-text-field
                v-model="item.reference"
                label="Bestellnummer / Referenz"
                data-cy="reference"
                dense
                outlined
              ></v-text-field>
            </v-col-->
          </v-row>
          <v-row justify="space-between">
            <v-col cols="12" sm="5" md="5">
              <quill-editor v-model="item.address" :options="editorOption">
              </quill-editor>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <singleDateForm
                v-model="item.offerDate"
                label="Angebotsdatum"
                data-cy="offerDate"
                prependIcon="event"
              />
            </v-col>
          </v-row>
          <br />
          <v-row>
            <v-col cols="12" sm="6" md="12">
              <p>Kopf-Text</p>
              <quill-editor
                v-model="item.headText"
                :options="editorOptionAvail"
              >
              </quill-editor>
            </v-col>
          </v-row>
          <itemPosForm
            v-model="item.offerPos"
            :contact="item.contact"
            :prices="true"
            :optionalPos="true"
          />
          <calculateSums :item="item.offerPos" />
          <v-row class="ma-0">
            <v-col cols="12" sm="6" md="12">
              <p>Fuß-Text</p>
              <quill-editor
                v-model="item.footText"
                :options="editorOptionAvail"
              >
              </quill-editor>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions class="mb-4">
      <div class="flex-grow-1"></div>
      <v-btn color="secondary" text to="/documents/3/angebote">Zurück</v-btn>
      <v-btn
        color="secondary"
        text
        @click="saveWrapper(item)"
        data-cy="saveEvent"
        >Speichern</v-btn
      >
    </v-card-actions>
    <preventDataLossForm
      :dialog="dataLossDialog"
      @dismissData="reactToBeforeRouteLeave"
    />
    <v-dialog
      v-model="activateCheckTitleDialog.visible"
      max-width="600"
      persistent
    >
      <checkTitleDialog
        :item="item"
        :activateCheckTitleDialog="activateCheckTitleDialog"
        @continueSave="save(item)"
        @close="closeCheckTitleDialog()"
      />
    </v-dialog>
    <v-dialog v-model="confirmSaveDialog" max-width="600" persistent>
      <v-card>
        <v-card-title class="headline"> Beleg überschreiben </v-card-title>
        <v-card-text>
          <v-alert dense border="left" type="error">
            Achtung, wenn Sie die Änderungen speichern wird der aktuelle Beleg
            überschrieben und das Angebot in den Status "Entwurf" zurück
            versetzt.
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="save(item)">Fortfahren</v-btn>
          <v-btn color="error" text @click="cancelConfirmSave()"
            >Änderungen Verwerfen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
import Loading from "vue-loading-overlay";
import VueQuillEditor, { Quill } from "vue-quill-editor";
import contactFormWrapper from "../../contact/createContact/contactFormWrapper.vue";
import singleDateForm from "../../_utils/singleDateForm.vue";
import preventDataLossForm from "../preventDataLossForm.vue";
import itemPosForm from "../itemPosForm.vue";
import calculateSums from "../calculateSums.vue";
import contactselectForm from "../contactSelectform.vue";
import checkTitleDialog from "../checkTitleDialog.vue";

function getDefaultData() {
  return {
    address: "Kontakt auswählen oder Adresse eingeben",
    offerDate: new Date().toISOString().substr(0, 10),
    header: "",
    headText: "",
    footText: "",
    contact: "",
    contactPerson: "",
    reference: "",
    offerPos: [
      {
        name: "",
        description: "",
        quantity: 1,
        unity: "Stk",
        posId: 1,
        price: 0.0,
        taxRate: 0,
        optional: false,
      },
    ],
  };
}

export default {
  components: {
    Loading,
    contactFormWrapper,
    singleDateForm,
    preventDataLossForm,
    itemPosForm,
    calculateSums,
    contactselectForm,
    checkTitleDialog,
  },
  data: () => ({
    formTitle: "Angebot erstellen",
    item: getDefaultData(),
    valid: true,
    requieredRule: [(v) => !!v || "Dieses Feld wird benötigt"],
    panel: [false],
    dialogLoading: false,
    editorOption: {
      modules: {
        toolbar: false,
      },
    },
    editorOptionAvail: {
      modules: {
        toolbar: true,
      },
    },
    dateTimespan: false,
    dataLossDialog: false,
    to: false,
    confirmSaveDialog: false,
    activateCheckTitleDialog: { valid: true, nextNumber: null, visible: false },
  }),
  mounted() {
    this.initSwitch(this.$route.params);
  },
  watch: {
    "$route.params": function(params) {
      this.initSwitch(params);
    },
    userInfo: {
      handler(val) {
        if (val != null && this.item.contactPerson === "") {
          //on create set username of logged in person to contactperson
          this.item.contactPerson = val.name;
        }
      },
    },
    nextOfferId: {
      handler(val) {
        if (
          (val != null && this.item.header === "") ||
          this.$route.params.mode == "copy"
        ) {
          //on create set next Offer Number
          this.item.header = "Angebot AN-" + val.offerNumber;
          return val.offerNumber;
        }
      },
    },
  },
  computed: {
    ...mapGetters([
      "units",
      "backendServer",
      "userInfo",
      "nextOfferId",
      "defaultMwst",
    ]),
    fields() {
      if (!this.item.resource) return [];

      return Object.keys(this.item.resource).map((key) => {
        return {
          key,
          value: this.item.resource[key] || "n/a",
        };
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.params.action == "close") {
      next();
    } else {
      if (this.item != getDefaultData()) {
        if (this.to) {
          next();
        } else {
          this.to = to;
          this.dataLossDialog = true;
        }
      }
    }
  },
  methods: {
    initSwitch(params) {
      if (params.mode === "edit") {
        //edit offer
        this.$store.dispatch("getSingleOffer", params.id).then((r) => {
          this.mountedMethod(r);
        });
      } else if (params.mode === "new") {
        //new offer
        this.mountedMethod();
      } else if (params.mode === "copy") {
        //copy offer
        this.$store.dispatch("getSingleOffer", params.id).then((r) => {
          this.copyOffer(r);
        });
      }
    },
    mountedMethod(item) {
      this.$store.dispatch("getNextOfferId");
      this.$store.dispatch("getUserInfo");
      if (item != null && item.offerNumber != null) {
        //edit offer
        this.formTitle = "Angebot bearbeiten";
        this.item = item;
      } else {
        //set header / footer Text -> create new offer
        //load mwst
        this.item.offerPos[0].taxRate = this.defaultMwst;
        this.formTitle = "Angebot erstellen";
        this.loadDefaultText();
      }
    },
    copyOffer(item) {
      this.$store.dispatch("getNextOfferId");
      this.formTitle = "Angebot kopieren";
      //delete params
      delete item.offerNumber;
      delete item.formattedofferNumber;
      delete item.createdAt;
      delete item.lastModified;
      delete item.id;
      delete item.reference;
      delete item.status;
      delete item._id;
      this.item = item;
    },
    loadDefaultText() {
      this.$store.dispatch("getOfferText").then((res) => {
        this.item.headText = res.value.head;
        this.item.footText = res.value.foot;
      });
    },
    close(val) {
      this.resetData();
      this.$refs.form.reset();
      if (val.id) {
        this.$router.push({
          name: "documentDetails",
          params: { action: "close", id: val.id, from: "angebote" },
        });
      } else {
        this.$router.push({
          name: "offer",
          params: { action: "close" },
        });
      }
    },
    checkHeader(header) {
      //check header title if document number is correct
      const rx = /AN-[0-9]*/g;
      let arr = header.match(rx);
      arr == null ? (arr = ["AN-0"]) : "";
      return this.$store
        .dispatch("getOfferValid", arr[0].substring(3))
        .then((r) => {
          return r;
        });
    },
    closeCheckTitleDialog() {
      this.activateCheckTitleDialog = {
        valid: true,
        nextNumber: null,
        visible: false,
      };
    },
    saveWrapper(item) {
      if (item.status == "Versendet") {
        this.confirmSaveDialog = true;
      } else {
        this.save(item);
      }
    },
    cancelConfirmSave() {
      this.confirmSaveDialog = false;
      this.close({});
    },
    save(item) {
      let vm = this;
      //check Header
      return vm.checkHeader(item.header).then(function(value) {
        //reset dialog
        vm.activateCheckTitleDialog.visible = false;
        //checkheader values will only be used when invoice is created
        if (
          !value.valid &&
          (vm.formTitle == "Angebot erstellen" ||
            vm.formTitle == "Angebot kopieren")
        ) {
          //open check title dialog header not ok
          vm.activateCheckTitleDialog = value;
          vm.activateCheckTitleDialog.visible = true;
          //return;
        } else {
          //header ok
          if (vm.$refs.form.validate()) {
            if (
              vm.formTitle == "Angebot erstellen" ||
              vm.formTitle == "Angebot kopieren"
            ) {
              vm.$store.dispatch("addOffers", item).then((r) => {
                vm.close(r);
              });
            } else {
              vm.$store.dispatch("updateOffers", item).then((r) => {
                vm.close(r);
              });
            }
          }
        }
      });
    },
    resetData() {
      this.item = getDefaultData();
    },
    reactToBeforeRouteLeave(val) {
      if (val) {
        this.dataLossDialog = false;
        this.$router.push(this.to);
      } else {
        this.dataLossDialog = false;
        this.to = null;
      }
    },
  },
};
</script>
<style scoped>
.ql-editor {
  min-height: 120px !important;
  font-size: 16px;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px;
}

.theme--light .v-tabs-items {
  background-color: #f3f3fa;
}
</style>
