import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { StoreUtil } from "../utils";

const state = {

  inventoryData: {
    pagination: {
      page: 1,
      itemsPerPage: 10,
      rowsPerPageItems: [10, 15, 25],
      totalItems: 0,
      search: ""
    },
    inventoryList: StoreUtil.state()
  }
}

const contactsModule = {
  state,
  mutations,
  actions,
  getters
}

export default contactsModule;