<template>
  <v-tabs v-model="activeTab">
      <v-tab v-for="tab of tabs" :key="tab.id" :to="tab.route" exact>
        {{ tab.name }}
      </v-tab>

      <v-tab-item v-for="tab of tabs" :key="tab.id" :value="tab.route" :transition="false" :reverse-transition="false">
        <router-view></router-view>
      </v-tab-item>
    </v-tabs>
</template>
<script>
export default {
  data() {
    return {
      activeTab: this.$route.params.id,
      tabs: [
        { id: 1, name: "Fahrzeuge", route: `/fleet/1` },
        //{ id: 2, name: "Maschinen", route: `/fleet/2/machinery` }
      ]
    };
  }
};
</script>