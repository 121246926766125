<template>
  <v-navigation-drawer
    v-model="openCloseSidebar"
    :height="800"
    width="300"
    fixed
    app
    clipped
    right
    overlay-opacity="0"
  >
    <v-card class="mt-0 mb-o" flat>
      <template slot="progress">
        <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>
      <!-- display buttons on top when coordinates not avialiable -->
      <div v-if="!(coordinates[0] != 0 && coordinates[1] != 0)" class="mb-0">
        <v-row>
          <v-btn
            fab
            color="secondary"
            class="mt-4 ml-auto"
            v-if="rightSidebarProp"
            :href="
              'https://www.google.de/maps/search/' + rightSidebarProp.location
            "
            target="_blank"
            small
          >
            <v-icon>map</v-icon>
          </v-btn>
          <v-btn
            fab
            color="secondary"
            class="mt-4 ml-4 mr-8"
            @click="$emit('close')"
            light
            small
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-row>
      </div>
      <!-- only display map when coordinates avialiable -->
      <div class="mapStyle" v-if="coordinates[0] != 0 && coordinates[1] != 0">
        <MglMap
          :accessToken="accessToken"
          :mapStyle="mapStyle"
          :center="coordinates"
          :zoom="14"
        >
          <MglMarker :coordinates="coordinates" color="#2c72e7" />
          <v-btn
            fab
            color="secondary"
            class="mt-9"
            v-if="rightSidebarProp"
            :href="
              'https://www.google.de/maps/search/' + rightSidebarProp.location
            "
            target="_blank"
            right
            bottom
            small
            absolute
          >
            <v-icon>map</v-icon>
          </v-btn>
        </MglMap>
        <v-btn
          fab
          color="secondary"
          class="mt-9"
          @click="$emit('close')"
          light
          top
          right
          small
          absolute
        >
          <v-icon>close</v-icon>
        </v-btn>
      </div>

      <v-card-title class="pb-0" v-if="rightSidebarProp"
        >{{ rightSidebarProp.name }}
        <v-btn
          icon
          class="ml-1"
          color="light-grey"
          @click="$emit('editEvent', rightSidebarProp)"
        >
          <v-icon>edit</v-icon>
        </v-btn></v-card-title
      >
      <v-card-text v-if="rightSidebarProp">
        <div class="my-0 pb-1 subtitle-1">
          {{ rightSidebarProp.status | capitalize }}
        </div>
        <div v-if="rightSidebarProp.formattedStartDate">
          <v-list-item class="listItemPopup">
            <v-list-item-action class="actionSpacing">
              <v-icon>date_range</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{
                rightSidebarProp.formattedStartDate + "h - "
              }}</v-list-item-title>
              <v-list-item-title class="pt-0.5">{{
                rightSidebarProp.formattedEndDate + "h"
              }}</v-list-item-title>
              <v-list-item-subtitle>Zeitraum</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
        <div v-if="rightSidebarProp.workers">
          <v-list-item class="listItemPopup">
            <v-list-item-action class="actionSpacing">
              <v-icon>person</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <div v-for="item in rightSidebarProp.workers" :key="item.id">
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
              </div>
              <v-list-item-subtitle>Fahrer</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
        <div v-if="rightSidebarProp.location">
          <v-list-item class="listItemPopup">
            <v-list-item-action class="actionSpacing">
              <v-icon>business</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{
                rightSidebarProp.location
              }}</v-list-item-title>
              <v-list-item-subtitle>Adresse</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
        <div v-if="rightSidebarProp.contact">
          <v-list-item class="listItemPopup">
            <v-list-item-action class="actionSpacing">
              <v-icon>group</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{
                contactName(rightSidebarProp.contact)
              }}</v-list-item-title>
              <v-list-item-subtitle>Kunde</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
        <div v-if="rightSidebarProp.description">
          {{ rightSidebarProp.description }}
        </div>
      </v-card-text>
      <v-card-text v-if="rightSidebarProp">
        <div class="font-weight-bold ml-8 mb-2">
          Status
        </div>

        <v-timeline align-top dense>
          <v-timeline-item small>
            <div>
              <div class="font-weight-normal">
                <strong>Auftrag erstellt</strong>
              </div>
              <div>{{ rightSidebarProp.formattedCreatedAt }}h</div>
            </div>
          </v-timeline-item>
          <v-timeline-item small v-if="rightSidebarProp.hasPhoto">
            <div>
              <div class="font-weight-normal">
                <strong>Bilder hinzugefügt</strong>
              </div>
            </div>
          </v-timeline-item>
          <v-timeline-item small v-if="rightSidebarProp.signatureDate != null">
            <div>
              <div class="font-weight-normal">
                <strong>Auftrag unterschrieben</strong>
              </div>
              <div>{{ rightSidebarProp.signatureDate | removeComma }}h</div>
            </div>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>

      <v-divider class="mx-4"></v-divider>
      <div class="ma-3">
        <v-btn
          color="primary"
          text
          @click="$emit('deleteEvent', rightSidebarProp)"
        >
          Auftrag löschen
        </v-btn>
        <br />
        <v-btn
          color="primary"
          text
          @click="$emit('copyEvent', rightSidebarProp)"
        >
          Auftrag kopieren
        </v-btn>
        <br />
        <v-btn
          color="primary"
          text
          @click="$emit('createDeliveryNote', rightSidebarProp)"
        >
          Lieferschein erstellen
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="$emit('handleDownloadOrderConfirmation', rightSidebarProp)"
        >
          Bestätigung herunterladen
        </v-btn>
      </div>
    </v-card>
  </v-navigation-drawer>
</template>
<script>
import axios from "axios";
import Mapbox from "mapbox-gl";
import { MglMap, MglMarker } from "vue-mapbox";
import { mapGetters } from "vuex";

export default {
  components: {
    MglMap,
    MglMarker
  },
  props: ["rightSidebarProp"],
  data() {
    return {
      accessToken:
        "pk.eyJ1Ijoib25kaXNwYXRjaCIsImEiOiJja2xpNThnOWswY3VmMndxaWZtZzZreDNtIn0.O8Gh2Tf9KH2wjWNqOQOqBA",
      mapStyle: "mapbox://styles/ondispatch/ckli5ihq1183j18rxah320rxk",
      coordinates: [0, 0]
    };
  },
  created() {
    this.mapbox = Mapbox;
    if (this.rightSidebarProp) {
      this.getCoordinates(this.rightSidebarProp.location, true);
    }
  },
  watch: {
    rightSidebarProp(val) {
      if (val) {
        this.getCoordinates(val.location, true);
      }
    }
  },
  computed: {
    openCloseSidebar: {
      get() {
        if (this.rightSidebarProp) {
          return true;
        } else {
          return false;
        }
      },
      set(val) {
        return val;
      }
    }
  },
  filters: {
    capitalize: function(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    removeComma: function(value) {
      return value.split(",").join("");
    }
  },
  methods: {
    contactName(contact) {
      let Description;
      if (contact.name) {
        if (contact.name2) {
          Description = contact.name + " " + contact.name2;
        } else {
          Description = contact.name;
        }
      } else {
        Description = contact.familyname;
      }

      return Description;
    },
    getCoordinates(location, geofencing) {
      let vm = this;
      const getUrl =
        "https://nominatim.openstreetmap.org/search?format=json&q=" + location;
      axios
        .get(getUrl)
        .then(function(response) {
          if (response.data.length > 0) {
            if (geofencing) {
              //check if locations is in the perimeters central europe/ germany
              if (
                response.data[0].lat < 60 &&
                response.data[0].lat > 40 &&
                response.data[0].lon < 20 &&
                response.data[0].lon > 3
              ) {
                vm.coordinates = [response.data[0].lon, response.data[0].lat];
              } else {
                vm.coordinates = [0, 0];
              }
            } else {
              vm.coordinates = [response.data[0].lon, response.data[0].lat];
            }
          } else {
            vm.coordinates = [0, 0];
          }
        })
        .catch(function(e) {
          console.error(e);
        });
    }
  }
};
</script>
<style>
.listItemPopup {
  padding-left: 0px;
  padding-right: 0px;
}
.actionSpacing {
  margin-right: 14px !important;
}
.mapStyle {
  height: 200px;
  width: 361px;
  margin-left: -100%;
  -webkit-box-flex: 1;
  flex: 1 0 0px;
  max-width: 100%;
  padding: 0;
  margin: 0;
}

.mapboxgl-compact {
  display: none !important;
}
</style>
