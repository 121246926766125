<template>
  <v-card>
    <!--loading
    :active.sync="allStatusPending"
    :can-cancel="false"
    :is-full-page="true"
  ></loading-->
    <v-card-title>
      <span v-if="!item.customerNumber" class="headline">{{ formTitle }}</span>
      <span v-if="item.customerNumber" class="headline"
        >{{ formTitle }} - {{ item.customerNumber }}</span
      >
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-tabs>
          <v-tab @click="activeTab = 0" v-model="activeTab" :key="0" id="businessTab">
            <v-icon>business</v-icon>Organisation / Firma
          </v-tab>
          <v-tab-item :key="0">
            <contactForm
              :method="method"
              :contactType="contactType"
              v-model="item"
              @close="close"
              ref="contactForm"
            ></contactForm>
          </v-tab-item>
          <v-tab @click="activeTab = 1" v-model="activeTab" :key="1" id="personTab">
            <v-icon>person</v-icon>Person
          </v-tab>
          <v-tab-item :key="1">
            <contactForm
              :method="method"
              :contactType="contactType"
              v-model="item"
              @close="close"
              ref="contactForm"
            ></contactForm>
          </v-tab-item>
        </v-tabs>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <div class="flex-grow-1"></div>
      <v-btn color="secondary" text @click="close">Abbrechen</v-btn>
      <v-btn color="secondary" text @click="save" data-cy="saveEvent">Speichern</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
function getDefaultData() {
  return {
    type: "Company",
    category: null,
    name: null,
    name2: null,
    surname: null,
    familyname: null,
    title: null,
    birthday: null,
    gender: null,
    description: null,
    iban: null,
    bic: null,
    taxNumber: null,
    ustId: null,
    mobile: null,
    fax: null,
    invoicingEmail: null,
    website: null,
    address: {
      zip: null,
      street: null,
      city: null,
      country: "Deutschland"
    },
    invoiceAddress: {
      zip: null,
      street: null,
      city: null,
      country: "Deutschland"
    },
    email: null,
    phone: null,
    discount: 0,
    discountTime: 0,
    paymentTarget: 0
  };
}

import contactForm from "./contactForm.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    contactForm
  },
  props: ["method", "contactType", "formTitle", "editItem"],
  data: () => ({
    item: getDefaultData,
    formType: null,
    showCompany: true,
    showPerson: true,
    activeTabVal: 0
  }),
  mounted() {
    this.presetData(this.editItem);
  },
  watch: {
    editItem: {
      handler(val) {
        this.presetData(val);
      },
      deep: true
    },
    activeTab: {
      handler(val) {
        // set contact type according to selected tab
        if (val == 0) {
          this.item.type = "Company";
        } else {
          this.item.type = "Person";
        }
      }
    }
  },
  computed: {
    activeTab: {
      get() {
        if (this.editItem) {
          if (this.editItem.type == "Company") {
            return 0;
          } else {
            return 1;
          }
        } else {
          return this.activeTabVal;
        }
      },
      set(val) {
        if (val == 0) {
          this.item.type = "Company";
        } else {
          this.item.type = "Person";
        }
        this.activeTabVal = val;
      }
    }
  },
  methods: {
    resetData() {
      this.item = getDefaultData();
      this.item.customerNumber = null;
    },
    presetData(data) {
      this.resetData();
      // preset values
      if (this.method == "edit" && data != null) {
        this.item = data;
      }
    },
    save() {
      let vm = this;
      if (this.method == "new") {
        if (vm.$refs.contactForm.validateForm()) {
          vm.$store.dispatch("addContact", this.item).then(result => {
            vm.close(result);
            vm.resetData();
          });
        }
        //when method is edit :
      } else {
        if (vm.$refs.contactForm.validateForm()) {
          vm.$store.dispatch("updateContact", this.item).then(r => {
            vm.close(r);
            vm.resetData();
          });
        }
      }
    },
    close(val) {
      this.$emit("close", val);
      this.resetData();
    }
  }
};
</script>
<style scoped>
.theme--light .v-tabs-items {
  background-color: #ffffff;
}
</style>
