<template>
  <v-row>
    <v-col cols="12" sm="6" md="4">
      <v-checkbox
        v-model="checkbox"
        label="ganztägiger Auftrag (7:00h - 17:00h)"
        data-cy="checkbox"
        dense
      ></v-checkbox>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  props: ["wholeDayStartDate", "wholeDayDuration"],
  data: () => ({
    checkbox: false
  }),
  mounted() {},
  watch: {
    checkbox(val) {
      if (val) {
        this.formatDataToWholeDate(
          this.wholeDayStartDate,
          this.wholeDayDuration
        );
      } else {
        this.$emit("resetWholeDayValues");
      }
    }
  },
  computed: {
    ...mapGetters([])
  },
  methods: {
    formatDataToWholeDate(startDate, duration) {
      let newStartDate = new Date(startDate);
      newStartDate.setHours(7);
      newStartDate.setMinutes(0);
      newStartDate.setSeconds(0);
      duration = "10:00";
      this.$emit("setWholeDayValues", {
        date: newStartDate,
        duration: duration
      });
    }
  }
};
</script>
