<template>
  <td :colspan="headers.length">
    <v-tabs>
      <v-tab>Infos</v-tab>
      <v-tab-item>
        <contactInfo :itemTable="itemTable" :headers="headers"></contactInfo>
      </v-tab-item>
      <v-tab>Baustellen</v-tab>
      <v-tab-item>
        <constructionSites :contact="itemTable" />
      </v-tab-item>
      <v-tab>Preise</v-tab>
      <v-tab-item>
        <indivArticles :contact="itemTable" />
      </v-tab-item>
    </v-tabs>
  </td>
</template>
<script>
import contactInfo from "./contactInfo.vue";
import indivArticles from "./indivArticles.vue";
import constructionSites from "./constructionSites.vue";
export default {
  props: ["itemTable", "headers"],
  components: {
    contactInfo,
    indivArticles,
    constructionSites
  },
  data: () => ({}),
  methods: {}
};
</script>
