import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c('loading',{attrs:{"active":_vm.dialogLoading,"can-cancel":false,"is-full-page":true},on:{"update:active":function($event){_vm.dialogLoading=$event}}}),_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v("Lieferschein zu Projekt hinzufügen")])]),_c(VCardText,[_c(VContainer,[_c('p',[_vm._v("Projekt auswählen:")]),_c('loading',{attrs:{"active":_vm.loadingVal,"can-cancel":false,"is-full-page":true},on:{"update:active":function($event){_vm.loadingVal=$event}}}),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.projects,"footer-props":{
          itemsPerPageText: 'Zeilen pro Seite'
        },"data-cy":"projectTable"},scopedSlots:_vm._u([{key:"item.itemContact",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formattedName(item))+" ")]}},{key:"item.action",fn:function({ item }){return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VIcon,_vm._g({staticClass:"mr-2",attrs:{"color":"secondary","data-cy":"addDeliveryNote"},on:{"click":function($event){$event.stopPropagation();return _vm.send(item)}}},on),[_vm._v("done")])]}}],null,true)},[_c('span',[_vm._v("Lieferschein zu diesem Projekt hinzufügen")])])]}},{key:"no-data",fn:function(){return [_vm._v(" keine Daten vorhanden ")]},proxy:true}])})],1)],1),_c(VCardActions,[_c('div',{staticClass:"flex-grow-1"}),_c(VBtn,{attrs:{"color":"secondary","text":""},on:{"click":_vm.close}},[_vm._v("Zurück")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }