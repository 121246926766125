import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"height":"100%"}},[_c(VDataTable,{staticClass:"ma-3",attrs:{"headers":_vm.headers,"options":_vm.activityListGetter.pagination,"server-items-length":_vm.activityListGetter.pagination.totalItems,"items":_vm.activityListGetter.activityList.data,"loading":_vm.activityStatusPending,"sort-by":['createdAt'],"sort-desc":['true', 'false'],"footer-props":{
      itemsPerPageOptions: _vm.activityListGetter.pagination.rowsPerPageItems,
      itemsPerPageText: 'Zeilen pro Seite'
    }},on:{"update:options":function($event){return _vm.$set(_vm.activityListGetter, "pagination", $event)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" keine Daten vorhanden ")]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }