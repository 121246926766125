<template>
  <div>
    <v-list flat subheader three-line>
      <v-subheader><h4>Prozess-Einstellungen</h4></v-subheader>
    </v-list>
    <v-list-item>
      <v-list-item-content>
        <v-container>
          <v-card class="mx-auto" max-width="900">
            <v-container>
              <p>Rechnungs-Einstellungen</p>
            </v-container>
            <v-form class="pa-4" ref="form" lazy-validation>
              <p>Forderungsmanagement</p>
              <h5>
                Ist diese Option aktiviert, können Sie Ihr Forderungsmanagement
                ganz einfach in onDispatch erledigen. Ihnen werden Rechnungen
                als Offen/Bezahlt/Überfällig angezeigt.
              </h5>
              <br />
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <p style="margin-top: 25px">
                    Forderungsmanagement in onDispatch erledigen
                  </p>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-switch
                    v-model="useAdvancedInvoiceTable"
                    @change="changeInvoiceTable"
                  ></v-switch>
                </v-col>
              </v-row>
              <hr />
              <br />
              <br />
              <p>Lieferscheinbezeichnung in den Rechnungstitel übernehmen</p>
              <h5>
                Ist diese Option aktiviert, wird standardmäßig der Titel des
                Lieferscheins in den Titel der Rechnung kopiert (zB zur Angabe
                von Kostenstellen).
              </h5>
              <br />
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <p style="margin-top: 25px">
                    Lieferscheinbezeichnung übertragen
                  </p>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-switch
                    v-model="copyFullDeliveryNoteTitle"
                    @change="changeCopyTitle"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-container>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  data: () => ({
    useAdvancedInvoiceTable: false,
    copyFullDeliveryNoteTitle: false
  }),
  computed: {
    ...mapGetters(["backendServer"])
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      let config = {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      };
      axios
        .get(this.backendServer + "settings", config)
        .then(response => {
          for (let setting of response.data.data) {
            if (setting.name == "useAdvancedInvoiceTable") {
              this.useAdvancedInvoiceTable = setting.value;
            } else if (setting.name == "copyFullDeliveryNoteTitle") {
              this.copyFullDeliveryNoteTitle = setting.value;
            }
          }
        })
        .catch(err => {});
    },
    changeInvoiceTable() {
      let vm = this;
      let config = {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      };

      let putURL = this.backendServer + "settings/useAdvancedInvoiceTable";

      axios
        .put(putURL, { value: this.useAdvancedInvoiceTable }, config)
        .then(response => {
          vm.$store.dispatch("triggerUniversalAlert", {
            text: "Einstellung wurde erfolgreich aktualisiert",
            heading: "Erfolgreich aktualisiert",
            type: "info"
          });
        })
        .catch(err => {
          vm.$store.dispatch("triggerUniversalAlert", {
            text:
              "Es ist ein unerwarteter Fehler aufgetreten bitte kontaktieren Sie den Support",
            heading: "Unerwarteter Fehler",
            type: "error"
          });
        });
    },
    changeCopyTitle() {
      let vm = this;
      let config = {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      };

      axios
        .put(
          this.backendServer + "settings/copyFullDeliveryNoteTitle",
          { value: this.copyFullDeliveryNoteTitle },
          config
        )
        .then(response => {
          vm.$store.dispatch("triggerUniversalAlert", {
            text: "Einstellung wurde erfolgreich aktualisiert",
            heading: "Erfolgreich aktualisiert",
            type: "info"
          });
        })
        .catch(error => {
          vm.$store.dispatch("triggerUniversalAlert", {
            text:
              "Es ist ein unerwarteter Fehler aufgetreten bitte kontaktieren Sie den Support",
            heading: "Unerwarteter Fehler",
            type: "error"
          });
        });
    }
  }
};
</script>
