<template>
  <v-card>
    <loading
      :active.sync="dialogLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <v-card-title>
      <span class="headline">Leistungsübersicht per Mail versenden</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" sm="6" md="12">
              <v-text-field
                v-model="item.email.recipient"
                label="Empfänger"
                data-cy="recipient"
                :rules="requieredRule"
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" md="12">
              <v-text-field
                v-model="item.email.subject"
                label="Betreff"
                data-cy="subject"
                :rules="requieredRule"
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <br />
          <v-row>
            <v-col cols="12" sm="6" md="12">
              <p>Nachricht</p>
              <quill-editor
                v-model="item.email.msg"
                :options="editorOption"
                outlined
                dense
              ></quill-editor>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="12">
              <p>Anhänge</p>
              <v-chip class="ma-2" label>
                {{ attachment }}
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <input
                type="checkbox"
                v-model="item.copyToSelf"
                true-value="true"
                false-value="false"
              />
              Kopie an mich selbst senden
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <div class="flex-grow-1"></div>
      <v-btn color="secondary" text @click="close">Zurück</v-btn>
      <v-btn color="secondary" text @click="send">Senden</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
import Loading from "vue-loading-overlay";
import VueQuillEditor, { Quill } from "vue-quill-editor";
import contactFormWrapper from "../contact/createContact/contactFormWrapper.vue";

export default {
  components: {
    Loading
  },
  props: ["document", "defaultText"],
  data: () => ({
    attachment: "",
    item: {
      copyToSelf: false,
      id: null,
      email: {
        recipient: "",
        subject: "",
        msg: ""
      }
    },
    editorOption: {
      modules: {
        toolbar: false
      }
    },
    dialogLoading: false,
    valid: true,
    requieredRule: [v => !!v || "Dieses Feld wird benötigt"]
  }),

  computed: {
    ...mapGetters(["backendServer", "userInfo"])
  },

  watch: {
    userInfo: {
      handler(val) {
        this.$store.dispatch("userNameInEmailTemplate").then(r => {
          if (r) {
            //set user name
            this.item.email.msg = this.defaultText + val.name;
          } else {
            //do not set user name
            this.item.email.msg = this.defaultText;
          }
        });
        this.item.myMail = val.email;

        //set email from contact
        if (this.document.contact.email != "") {
          this.item.email.recipient = this.document.contact.email;
        }
      }
    },
    header: {
      handler(val) {
        this.resetData();
      }
    }
  },

  created() {
    this.resetData();
  },

  methods: {
    resetData() {
      this.clearData();
      this.item.id = this.document.id;
      this.item.email.subject =
        "Leistungsübersicht Projekt Nr. " + this.document.objectId;
      this.attachment =
        "Leistungsübersicht Projekt Nr. " + this.document.objectId + ".pdf";
      this.$store.dispatch("getUserInfo");
    },
    close() {
      this.$emit("exit");
    },

    send() {
      this.$emit("send", this.item);
      this.resetData();
    },

    clearData() {
      this.item = {
        copyToSelf: false,
        id: null,
        email: {
          recipient: "",
          subject: "",
          msg: ""
        }
      };
    }
  }
};
</script>
<style>
.ql-editor {
  min-height: 120px !important;
  font-size: 16px;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px;
}
</style>
