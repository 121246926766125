<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="computedDate"
        :label="label"
        data-cy="computedDate"
        :prepend-icon="prependIcon"
        readonly
        :rules="requieredRule"
        v-on="on"
        outlined
        dense
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="inputVal"
      @input="menu = false"
      locale="de-de"
      first-day-of-week="1"
    ></v-date-picker>
  </v-menu>
</template>
<script>
export default {
  props: ["value", "label", "prependIcon"],
  data: () => ({
    menu: false,
    valid: true,
    requieredRule: [v => !!v || "Dieses Feld wird benötigt"]
  }),

  computed: {
    inputVal: {
      get() {
        return new Date(this.value).toISOString().substr(0, 10);
      },
      set(val) {
        this.$emit("input", new Date(val));
      }
    },
    computedDate() {
      return this.formatDate(this.inputVal);
    }
  },

  methods: {
    formatDate(date) {
      if (date.split("-").length < 3) {
        let dayFirst = date.slice(0, 1);
        let monthFirst = date.slice(3, 4);
        if (dayFirst == 0 || monthFirst == 0) {
          let year = date.slice(-4);
          let withoutYear = date.slice(0, 6);
          withoutYear = withoutYear.replace(/0/g, "");
          date = withoutYear + year;
        }
        return date;
      } else {
        const [year, month, day] = date.split("-");
        return `${day}.${month}.${year}`;
      }
    }
  }
};
</script>
