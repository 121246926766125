<template>
  <!--v-card>
    <v-card-title>
      <span class="headline">test</span>
    </v-card-title>
    <v-card-text>
      <v-container>
     
      </v-container>
    </v-card-text>

    <v-card-actions>
      <div class="flex-grow-1"></div>
      <v-btn color="secondary" text @click="close">Abbrechen</v-btn>
      <v-btn color="secondary" text @click="save">Speichern</v-btn>
    </v-card-actions>
  </v-card-->
  <v-card class="mx-auto" max-width="1400">
    <v-toolbar color="primary" dark>
      <v-toolbar-title v-if="editItem.name"
        >Bilder | {{ editItem.name }}</v-toolbar-title
      >

      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon>
            <v-icon @click="downloadImages()" v-on="on">get_app</v-icon>
          </v-btn>
        </template>
        <span>Alle Bilder herunterladen</span>
      </v-tooltip>
    </v-toolbar>

    <v-container fluid>
      <v-row dense>
        <v-col
          v-for="(card, index) in editItem.photos"
          :key="card.name"
          cols="6"
        >
          <v-card>
            <v-img
              :src="card.data.substring(0, 15) == 'data:image/jpeg'? card.data :  'data:image/jpeg;base64,' + card.data"
              class="white--text align-end"
              height="600px"
            >
              <v-card-title v-text="card.name"></v-card-title>
            </v-img>

            <v-card-actions>
              <v-spacer></v-spacer>

              <!--v-btn icon>
                <v-icon @click="openImageInNewTab(index)">zoom_in</v-icon>
              </v-btn-->
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon>
                    <v-icon @click="downloadImage(index)" v-on="on"
                      >get_app</v-icon
                    >
                  </v-btn>
                </template>
                <span>Bild herunterladen</span>
              </v-tooltip>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions>
      <div class="flex-grow-1"></div>
      <v-btn color="secondary" text @click="close">zurück</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  props: ["editItem"],
  data: () => ({
    item: null,
    formType: null,
    showCompany: true,
    showPerson: true,
    activeTabVal: 0
  }),
  methods: {
    downloadImage(index) {
      const linkSource = `data:image/jpeg;base64,${this.editItem.photos[index].data}`;
      const downloadLink = document.createElement("a");
      const fileName =
        this.editItem.objectId +
        "-" +
        this.editItem.photos[index].name +
        ".jpeg";
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
    downloadImages() {
      this.editItem.photos.forEach((element, index) =>
        this.downloadImage(index)
      );
    },
    close(val) {
      this.$emit("close", val);
    }
  }
};
</script>
<style scoped>
.theme--light .v-tabs-items {
  background-color: #ffffff;
}
</style>
