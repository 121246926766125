<template>
  <v-container>
    <v-form class="pa-4" ref="form" v-model="valid" lazy-validation>
      <p>Mailserver Einstellungen</p>
      <h5>
        Über den hier eingetragenen Mailserver werden alle ausgehenden Emails
        versendet.<br />
        Bitte achten Sie bei der Auswahl des Mailservers auf eine verschlüsselte
        Verbindung.
      </h5>
      <br />
      <v-row>
        <v-col cols="12" sm="12" md="8">
          <v-switch
            v-model="standard"
            label="Standard-Mailserver verwenden (Adresse: noreply@ondispatch.de)"
            data-cy="standard"
            outlined
            dense
          ></v-switch>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="4">
          <v-text-field
            v-model="item.user"
            label="Email"
            data-cy="user"
            v-bind:disabled="standard"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <v-text-field
            v-model="item.password"
            label="Passwort"
            data-cy="password"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show = !show"
            :type="show ? 'text' : 'password'"
            v-bind:disabled="standard"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="4">
          <v-text-field
            v-model="item.host"
            label="Host"
            data-cy="host"
            v-bind:disabled="standard"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <v-text-field
            v-model="item.port"
            label="Port"
            data-cy="port"
            v-bind:disabled="standard"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="8">
          <hr />
          <div class="pa-2">Email Adresse eintragen um ausgehende Emails zu testen:</div>
          <v-row class="pa-2">
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                v-model="testMail"
                label="Testmail an diese Adresse senden"
                data-cy="testMail"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-btn color="secondary" text @click="sendTestmail">Testmail senden</v-btn>
            </v-col>
          </v-row>
          <hr />
        </v-col>
      </v-row>
      <v-btn color="secondary" text @click="save">Speichern</v-btn>
    </v-form>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  data: () => ({
    item: {
      password: null,
      user: null,
      port: null,
      host: null,
    },
    testMail: "",
    show: false,
    valid: true,
    standard: false,
    requieredRule: [(v) => !!v || "Dieses Feld wird benötigt"],
  }),

  created() {
    this.initialize();
  },

  computed: {
    ...mapGetters(["mailData", "backendServer"]),
  },

  watch: {
    mailData: {
      handler(val) {
        if (val.data[0]) {
          this.item = val.data[0].value ? val.data[0].value : this.item;
        }
      },
    },
    standard: {
      handler(val) {
        let vm = this;
        let postUrl = this.backendServer + "settings/useDefaultMail";
        let config = {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        };

        axios
          .put(postUrl, { value: vm.standard }, config)
          .then((response) => {})
          .catch((e) => {
            vm.$store.dispatch("triggerError", e);
          });
      },
    },
  },

  methods: {
    save() {
      let vm = this;

      this.$store.dispatch("updateMailData", this.item).then((result) => {});
    },
    initialize() {
      this.$store.dispatch("getMailData").then((result) => {});

      let getUrl = this.backendServer + "settings/useDefaultMail";
      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };

      this.axios
        .get(getUrl, config)
        .then((response) => {
          this.standard = response.data.value;
        })
        .catch((e) => {
          vm.$store.dispatch("triggerError", e);
        });
    },
    sendTestmail() {
      let vm = this;
      let postUrl = this.backendServer + "meta/mail/test";
      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };

      let item = {
        recipient: this.testMail,
      };
      return axios
        .post(postUrl, item, config)
        .then(function (response) {
          vm.$store.dispatch("triggerUniversalAlert", {
            text: "Die Test-Email wurde erfolgreich versendet.",
            heading: "Email erfolgreich versendet",
            type: "info",
          });
          vm.testMail = "";
        })
        .catch(function (e) {
          vm.$store.dispatch("triggerError", e);
        });
    },
  },
};
</script>
