import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDataTable,{staticClass:"pa-5",attrs:{"headers":_vm.headers,"options":_vm.invoiceListGetter.pagination,"server-items-length":_vm.invoiceListGetter.pagination.totalItems,"loading":_vm.invoiceStatusPending,"items":_vm.invoiceListGetter.invoiceList.data,"footer-props":{
      itemsPerPageOptions: _vm.invoiceListGetter.pagination.rowsPerPageItems,
      itemsPerPageText: 'Zeilen pro Seite'
    }},on:{"update:options":function($event){return _vm.$set(_vm.invoiceListGetter, "pagination", $event)},"click:row":_vm.clickedRow},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":"","color":"white"}},[_c(VToolbarTitle,[_vm._v("Rechnungen")]),_c(VDivider,{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c(VTextField,{attrs:{"label":"Suche...","data-cy":"search","single-line":"","hide-details":"","dense":"","clearable":""},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.searchData.apply(null, arguments)}},model:{value:(_vm.invoiceListGetter.pagination.search),callback:function ($$v) {_vm.$set(_vm.invoiceListGetter.pagination, "search", $$v)},expression:"invoiceListGetter.pagination.search"}}),_c(VBtn,{attrs:{"text":"","icon":"","color":"secondary"},on:{"click":_vm.searchData}},[_c(VIcon,[_vm._v("search")])],1),_c('div',{staticClass:"flex-grow-1"}),_c(VBtn,{staticClass:"mb-2",attrs:{"color":"secondary","dark":"","to":"rechnungen/edit/0/new","data-cy":"newInvoice"}},[_vm._v("Neue Rechnung")])],1)]},proxy:true},{key:"item.iconStatus",fn:function({ item }){return [(item.status == 'Entwurf')?_c('div',[_c(VIcon,{attrs:{"color":"grey darken-2"}},[_vm._v("assignment")]),_vm._v(" "+_vm._s(item.status)+" ")],1):_vm._e(),(
          (item.status == 'Versendet' || item.status == 'Exportiert') &&
            !item.payed &&
            item.payUntil > 0
        )?_c('div',[_c(VIcon,{attrs:{"color":"yellow darken-2"}},[_vm._v("adjust")]),_vm._v(" Offen ")],1):_vm._e(),(
          (item.status == 'Versendet' || item.status == 'Exportiert') &&
            !item.payed &&
            item.payUntil == 0
        )?_c('div',[_c(VIcon,{attrs:{"color":"yellow darken-2"}},[_vm._v("adjust")]),_vm._v(" Heute fällig ")],1):_vm._e(),(
          (item.status == 'Versendet' || item.status == 'Exportiert') &&
            !item.payed &&
            item.payUntil < 0
        )?_c('div',[_c(VIcon,{attrs:{"color":"red"}},[_vm._v("alarm")]),_vm._v("     "),_c('span',[_vm._v("Fällig")])],1):_vm._e(),(item.payed)?_c('div',[_c(VIcon,{attrs:{"color":"green darken-2"}},[_vm._v("mdi-clipboard-check")]),_vm._v(" Bezahlt ")],1):_vm._e()]}},{key:"item.exported",fn:function({ item }){return [(item.status == 'Exportiert')?_c('div',[_c(VIcon,{attrs:{"color":"grey darken-2"}},[_vm._v("check")])],1):_vm._e(),(item.status != 'Exportiert')?_c('div',[_c(VIcon,{attrs:{"color":"grey darken-2"}},[_vm._v("clear")])],1):_vm._e()]}},{key:"item.pay",fn:function({ item }){return [(item.payUntil > 0 && !item.payed)?_c('div',[_vm._v(" in "+_vm._s(item.payUntil)+" Tagen ")]):_vm._e(),(item.payUntil == 0 && !item.payed)?_c('div',[_vm._v("Heute Fällig")]):_vm._e(),(item.payUntil < 0 && !item.payed)?_c('div',[_c('span',{staticClass:"red--text"},[_c('b',[_vm._v("seit "+_vm._s(item.payUntil * -1)+" Tagen")])])]):_vm._e(),(item.payed)?_c('div',[_c('span',{staticStyle:{"text-decoration":"line-through"}},[_vm._v(_vm._s(item.formattedPayUntilDate))])]):_vm._e()]}},{key:"item.recipient",fn:function({ item }){return [_vm._v(" "+_vm._s(item.address .split("/p>")[0] .slice(3) .slice(0, -1))+" ")]}},{key:"item.formattedSumNetto",fn:function({ item }){return [_vm._v(" "+_vm._s(item.netTotal .toFixed(2) .toString() .replace(".", ","))+" EUR ")]}},{key:"item.formattedSumBrutto",fn:function({ item }){return [_vm._v(" "+_vm._s(item.brutTotal .toFixed(2) .toString() .replace(".", ","))+" EUR ")]}},{key:"item.action",fn:function({ item }){return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(
              (item.status == 'Exportiert' || item.status == 'Versendet') &&
                !item.payed
            )?_c(VIcon,_vm._g({staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){$event.stopPropagation();return _vm.markAsPayed(item)}}},on),[_vm._v("euro")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Als bezahlt markieren")])]),(
          (item.status === 'Versendet' || item.status === 'Entwurf') &&
            _vm.licenseData.data[0].value.modules.sevdesk
        )?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VIcon,_vm._g({staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){$event.stopPropagation();return _vm.exportInvoice(item)}}},on),[_vm._v("send")])]}}],null,true)},[_c('span',[_vm._v("Rechnungsexport zu Sevdesk")])]):_vm._e(),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([(item.status == 'Entwurf')?{key:"activator",fn:function({ on }){return [_c(VIcon,_vm._g({staticClass:"mr-2",attrs:{"data-cy":"sendMail"},on:{"click":function($event){$event.stopPropagation();return _vm.openMailDialog(item)}}},on),[_vm._v("email")])]}}:null],null,true)},[_c('span',[_vm._v("Dokument per Email versenden")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VIcon,_vm._g({staticClass:"mr-2",attrs:{"medium":"","disabled":item.status === 'Exportiert' || item.status === 'Versendet',"data-cy":"edit"},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},on),[_vm._v("edit")])]}}],null,true)},[_c('span',[_vm._v("Bearbeiten")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VIcon,_vm._g({staticClass:"mr-2",attrs:{"medium":"","data-cy":"preview"},on:{"click":function($event){$event.stopPropagation();return _vm.clickedRow(item)}}},on),[_vm._v("preview")])]}}],null,true)},[_c('span',[_vm._v("Detailansicht / Vorschau")])])]}},{key:"no-data",fn:function(){return [_vm._v("keine Daten vorhanden")]},proxy:true}])}),_c(VDialog,{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.loadPdf),callback:function ($$v) {_vm.loadPdf=$$v},expression:"loadPdf"}},[_c(VCard,{attrs:{"color":"primary","dark":""}},[_c(VCardText,[_vm._v(" PDF wird erstellt... "),_c(VProgressLinear,{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }