<template>
  <div>
    <schedulerNavbar
      ref="schedulerNavbar"
      @changeDate="changeDateFromNavbar"
      @step="step"
      @changeViewMode="changeViewMode"
      @today="today"
      :datepickerDate="datepickerDate"
      :toolbarTitle="toolbarTitle"
    ></schedulerNavbar>
    <FullCalendar
      ref="fullCalendar"
      class="outerCalendar"
      :options="calendarOptions"
    />
    <rightDetailDrawer
      :rightSidebarProp="rightSidebarProp"
      @close="closeSidebar"
      @editEvent="handleEditEvent"
      @copyEvent="handleCopyEvent"
      @deleteEvent="handleDeleteEvent"
      @createDeliveryNote="handleCreateDeliveryNote"
      @handleDownloadOrderConfirmation="handleDownloadOrderConfirmation"
    />
    <!-- right click menu -->
    <v-menu
      v-model="rightClickDialog.showMenu"
      :position-x="rightClickDialog.x"
      :position-y="rightClickDialog.y"
      absolute
      offset-y
    >
      <v-card outlined tile>
        <v-card-text>Optionen</v-card-text>
        <v-list-item
          v-for="(item, index) in rightClickDialog.items"
          :key="index"
          @click="item.menuAction(rightClickDialog.menuItem)"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-menu>
    <!-- popup menu -->
    <v-menu
      v-model="infoHover.showHover"
      :position-x="infoHover.x"
      :position-y="infoHover.y"
      absolute
      offset-y
    >
      <v-card
        outlined
        tile
        v-if="infoHover.hoverEvent && infoHover.hoverContact"
      >
        <div style="padding: 2px; min-width: 220px; min-height: 45px;">
          <div class="details-container">
            <p style="margin: 0px; margin-bottom: -1px; color: #959da5;">
              {{ infoHover.hoverEvent.name }} {{ infoHover.name }}
            </p>
            <p style="margin: 0px; margin-bottom: -1px; color: #959da5;">
              <span style="font-size: 14px;" class="material-icons"
                >business</span
              >
              {{ infoHover.hoverEvent.location }}
            </p>
            <p style="margin: 0px; margin-bottom: -1px; color: #959da5;">
              <span style="font-size: 14px;" class="material-icons">group</span>
              {{ infoHover.hoverContact }}
            </p>
            <p style="margin: 0px; margin-bottom: -1px; color: #959da5;">
              <span style="font-size: 14px;" class="material-icons"
                >date_range</span
              >
              {{ infoHover.hoverEvent.formattedStartDate }}h -
              {{ infoHover.hoverEvent.formattedEndDate }}h
            </p>
          </div>
        </div>
      </v-card>
    </v-menu>
    <!-- delete dialog -->
    <v-dialog v-model="deleteDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Datensatz löschen?</v-card-title>
        <v-card-text v-if="toDeleteItem">
          Soll der Datensatz "{{ toDeleteItem.name }}" wirklich gelöscht werden?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="deleteDialog = false"
            >Abbrechen</v-btn
          >
          <v-btn color="secondary" text @click="deleteItem(toDeleteItem)"
            >Jetzt löschen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Check if Event update is intentionally -->
    <v-dialog v-model="checkUpdateEventDialog" max-width="500" persistent>
      <v-card>
        <v-card-title class="headline">Verschieben bestätigen</v-card-title>
        <v-card-text>
          Soll der Auftrag wirklich verschoben werden?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="rejectUpdate(eventRevert)"
            >Abbrechen</v-btn
          >
          <v-btn color="secondary" text @click="updateEvent(eventData)"
            >Jetzt verschieben</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loadPdfDialog" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          PDF wird erstellt...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import FullCalendar from "@fullcalendar/vue";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import deLocale from "@fullcalendar/core/locales/de";
import DateClicking from "@bwobbones/fullcalendar5-rightclick";
import Tooltip from "tooltip-js";

import schedulerNavbar from "../components/schedulerNavbar.vue";
import rightDetailDrawer from "./rightDetailDrawer.vue";

const myInteractionPlugin = new Proxy(interactionPlugin, {
  get(target, prop, receiver) {
    if (prop === "componentInteractions") {
      target.componentInteractions[0] = DateClicking;
    }
    return Reflect.get(...arguments);
  }
});

export default {
  components: {
    FullCalendar,
    schedulerNavbar,
    rightDetailDrawer
  },
  data() {
    return {
      calendarOptions: {
        schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
        plugins: [dayGridPlugin, resourceTimelinePlugin, myInteractionPlugin],
        headerToolbar: false,
        locale: deLocale,
        initialView: "resourceTimelineDay",
        scrollTime: "06:00",
        nowIndicator: true,
        aspectRatio: 1.5,
        views: {
          resourceTimelineMonth: {
            slotDuration: "24:00",
            duration: { weeks: 5 },
            slotLabelFormat: [
              { week: "short" },
              { weekday: "short", day: "numeric" }
            ]
          },
          resourceTimelineYear: {
            slotDuration: "24:00"
          },
          resourceTimelineDay: {
            duration: { days: 1 },
            slotDuration: "00:30",
            titleFormat: {
              weekday: "long",
              day: "numeric",
              month: "long",
              year: "numeric"
            }
          },
          resourceTimelineWeek: {
            type: "resourceTimeline",
            duration: { days: 8 },
            slotDuration: "06:00"
          }
        },
        resourceAreaHeaderContent: "Fahrzeuge",
        dateClick: this.handleDateClick,
        eventDidMount: this.eventDidMount,
        eventMouseEnter: this.eventMouseEnter,
        eventMouseLeave: this.eventMouseLeave,
        eventContent: this.handleEventContent,
        resourceLabelContent: this.handleResourceLabelContent,
        eventDrop: this.handleEventDrop,
        eventResize: this.handleEventResize,
        eventClick: this.handleEventClick,
        editable: true,
        selectable: true,
        weekNumbers: true,
        eventOverlap: false,
        resourceAreaWidth: "220px",
        refetchResourcesOnNavigate: true,
        resources: this.queryResources,
        events: this.queryEvents
      },
      loadPdfDialog: null,
      calendarApi: null,
      calendarView: null,
      datepickerDate: null,
      viewMode: null,
      toolbarTitle: null,
      rightClickDialog: {
        showMenu: false,
        menuItem: null,
        x: 0,
        y: 0,
        items: [
          {
            title: "Auftrag löschen",
            menuAction: action => {
              this.deleteEvent(action);
            }
          },
          {
            title: "Auftrag kopieren",
            menuAction: action => {
              this.copyEvent(action);
            }
          },
          {
            title: "Lieferschein erstellen",
            menuAction: action => {
              this.createDeliveryNote(action);
            }
          }
        ]
      },
      infoHover: {
        showHover: false,
        hoverEvent: null,
        hoverContact: null,
        x: 0,
        y: 0
      },
      deleteDialog: false,
      toDeleteItem: null,
      checkUpdateEventDialog: false,
      eventData: null,
      eventRevert: null,
      currentDblClickTime: null,
      rightSidebarProp: null
    };
  },
  computed: {
    ...mapGetters(["backendServer"])
  },
  mounted() {
    //init calendarApi
    this.calendarApi = this.$refs.fullCalendar.getApi();
    this.calendarView = this.$refs.fullCalendar.getApi().currentData.viewApi;
    this.initialize();
    this.reactDateChange();
    //set height from window height
    this.calendarApi.setOption("height", window.innerHeight - 232);
  },
  methods: {
    initialize() {
      //if preset data is set, use it
      if (this.$route.params.back != 1) {
        const backItem = JSON.parse(this.$route.params.back);
        //update date + view Mode
        this.calendarApi.gotoDate(new Date(backItem.dates));
        this.calendarApi.changeView(backItem.viewMode);
        this.reactDateChange();
      } else {
        // set default view mode
      }
    },
    handleEventClick(info) {
      //open right sidebar with event prop
      this.rightSidebarProp = info.event._def.extendedProps;
    },
    handleDateClick(date) {
      this.rightSidebarProp = null;
      let prevTime =
        typeof this.currentDblClickTime === null
          ? new Date().getTime() - 1000000
          : this.currentDblClickTime;
      this.currentDblClickTime = new Date().getTime();
      if (this.currentDblClickTime - prevTime < 500) {
        //doubleclick action
        const item = {
          eventDate: date.date,
          resourceId: date.resource._resource.id,
          rowId: date.resource._resource.id
        };
        const compData = JSON.stringify({ data: item });
        this.$router.push({
          name: "eventForm",
          params: {
            id: compData,
            mode: "preset",
            back: JSON.stringify({
              to: "Planer",
              data: {
                dates: date.date,
                viewMode: this.calendarApi.currentData.currentViewType,
                back: 1,
                tab: 1,
                deliveryNoteId: 1,
                mode: 1,
                id: 1
              }
            })
          }
        });
      }
    },
    eventDidMount(info) {
      //reght click contectmenu
      info.el.addEventListener(
        "contextmenu",
        ev => {
          ev.preventDefault();
          this.rightClickDialog.menuItem = info.event._def.extendedProps;
          this.rightClickDialog.showMenu = false;
          this.rightClickDialog.x = ev.clientX;
          this.rightClickDialog.y = ev.clientY;
          this.$nextTick(() => {
            this.rightClickDialog.showMenu = true;
          });
          return false;
        },
        false
      );
    },
    eventMouseEnter: function(info) {
      // info popup over events
      info.el.addEventListener(
        "mouseenter",
        ev => {
          const eventRect = info.el.getBoundingClientRect();
          this.infoHover.showHover = true;
          this.infoHover.hoverEvent = info.event._def.extendedProps;
          //create worker names
          let name = "";
          this.infoHover.hoverEvent.workers.forEach(function(element, index) {
            name = name + " - " + element.name + " ";
          });
          this.infoHover.name = name;
          this.infoHover.hoverContact = this.formatContactFromTask(
            info.event._def.extendedProps
          );
          //client x - 0,5 * popup width
          this.infoHover.x = ev.x - 60;
          this.infoHover.y = eventRect.y - 105;
          return false;
        },
        false
      );
      // event doubleclick
      info.el.addEventListener(
        "dblclick",
        ev => {
          //only allow event edits when event is not green "Abgeschlossen"
          const event = info.event._def.extendedProps;
          this.editEvent(event);
        },
        false
      );
    },
    editEvent(event) {
      if (event.eventColor != "green" && event.status != "fertig") {
        this.$router.push({
          name: "eventForm",
          params: {
            id: event._id,
            mode: "edit",
            back: JSON.stringify({
              to: "Planer",
              data: {
                dates: event.startDate,
                viewMode: this.calendarApi.currentData.currentViewType,
                back: 1,
                tab: 1,
                deliveryNoteId: 1,
                mode: 1,
                id: 1
              }
            })
          }
        });
      } else {
        //open delivery note if id is saved in event
        if (event.deliveryNote) {
          this.$router.push({
            name: "documentDetails",
            params: { id: event.deliveryNote, from: "lieferscheine" }
          });
        } else {
          this.$store.dispatch("triggerUniversalAlert", {
            text:
              "Der Lieferschein wurde abgeschlossen und kann nicht mehr bearbeitet werden.",
            heading: "Fehler - Lieferschein bearbeiten",
            type: "warning"
          });
        }
      }
    },
    eventMouseLeave: function(info) {
      // info popup over events
      info.el.addEventListener(
        "mouseleave",
        ev => {
          this.infoHover.showHover = false;
          return false;
        },
        false
      );
    },
    handleEventContent(arg) {
      const event = arg.event._def.extendedProps;
      //set standard event props
      //color
      switch (event.eventColor) {
        case "blue":
          arg.backgroundColor = "#2c72e7";
          break;
        case "green":
          arg.editable = false;
          arg.backgroundColor = "#7cb342";
          break;
        case "grey":
          arg.editable = false;
          arg.backgroundColor = "#b8c2cc";
          break;
      }
      //conditional rendiring is event has photo
      let bLine = "<b class='ellipsis'>";
      event.hasPhoto
        ? (bLine =
            bLine +
            "<span style='font-size: 12px; padding-left: 5px;' class='material-icons'>photo_camera</span>")
        : " </div>";
      let title = document.createElement("p");
      title.innerHTML =
        "<div class='ellipsis' style='padding-left: 5px; font-weight: 500;'>" +
        event.name +
        bLine +
        " <br> <div class='ellipsis'>" +
        this.formatContactFromTask(event) +
        "</div>";
      let arrayOfDomNodes = [title];
      return { domNodes: arrayOfDomNodes };
    },
    handleResourceLabelContent(arg) {
      const resource = arg.resource._resource.extendedProps;
      let title = document.createElement("p");
      title.innerHTML =
        "<b style='padding-left: 5px;'>" +
        resource.name +
        " </b><br> <p class='ellipsis' style='font-size: 12px; padding-left: 5px;'>" +
        resource.license +
        " | " +
        resource.manufacturer;
      ("</p>");
      let arrayOfDomNodes = [title];
      return { domNodes: arrayOfDomNodes };
    },
    handleEventDrop(arg) {
      // reformat data back to backend data model
      let eventCopy = Object.assign({}, arg.event.extendedProps);
      eventCopy.startDate = arg.event.start;
      eventCopy.endDate = arg.event.end;
      eventCopy.resourceId = arg.event._def.resourceIds[0];
      eventCopy.id = eventCopy._id;
      this.updateEventHandler(eventCopy, arg.revert);
    },
    handleEventResize(arg) {
      // reformat data back to backend data model
      let eventCopy = Object.assign({}, arg.event.extendedProps);
      eventCopy.startDate = arg.event.start;
      eventCopy.endDate = arg.event.end;
      eventCopy.resourceId = arg.event._def.resourceIds[0];
      eventCopy.id = eventCopy._id;
      this.updateEventHandler(eventCopy, arg.revert);
    },
    updateEventHandler(event, revert) {
      //check if event can be updated (eventColor = blue)
      if (event.eventColor === "blue") {
        this.eventData = event;
        this.eventRevert = revert;
        this.checkUpdateEventDialog = true;
      } else {
        this.$store.dispatch("triggerUniversalAlert", {
          text:
            "Der Auftrag kann nicht verändert werden, da er schon abgebschlossenen bzw. ein Lieferschein erstellt wurde.",
          heading: "Fehler - Auftrag verändern",
          type: "warning"
        });
      }
    },
    rejectUpdate(eventRevert) {
      eventRevert();
      this.checkUpdateEventDialog = false;
    },
    updateEvent(eventData) {
      this.checkUpdateEventDialog = false;
      eventData.duration =
        (Math.abs(eventData.endDate - eventData.startDate) / 36e5) * 60;
      this.$store.dispatch("updateEvent", eventData).then(r => {});
    },
    deleteEvent(arg) {
      this.toDeleteItem = arg;
      this.deleteDialog = true;
    },
    deleteItem(item) {
      this.$store.dispatch("deleteEvent", item._id).then(() => {
        this.deleteDialog = false;
        this.toDeleteItem = null;
        //reload scheduler
        this.calendarApi.refetchEvents();
      });
    },
    copyEvent(arg) {
      this.$router.push({
        name: "eventForm",
        params: {
          id: arg._id,
          mode: "copy",
          back: JSON.stringify({
            to: "Planer",
            data: {
              dates: this.reactDateChange(),
              viewMode: this.calendarApi.currentData.currentViewType,
              back: 1,
              tab: 1,
              deliveryNoteId: 1,
              mode: 1,
              id: 1
            }
          })
        }
      });
    },
    createDeliveryNote(arg) {
      this.$store.dispatch("getSingleEvent", arg._id).then(r => {
        if (r.eventColor == "grey" && r.status == "fertig") {
          this.$router.push({
            name: "deliveryNoteEdit",
            params: { eventData: r, tab: 1, id: "1" }
          });
        } else {
          //create delivery note when event is not signed via app
          r.eventColor = "green";
          r.status = "fertig";
          this.$router.push({
            name: "deliveryNoteEdit",
            params: { eventData: r, tab: 1, id: "1" }
          });
        }
      });
    },
    queryResources(fetchInfo, successCallback, failureCallback) {
      this.$store.dispatch("queryItemsResources").then(r => {
        //rename data model to fit to naming
        r.forEach((e, index) => {
          r[index].title = e.name;
        });
        successCallback(r);
      });
    },
    queryEvents(fetchInfo, successCallback, failureCallback) {
      this.$store
        .dispatch("queryItemsEventsWithDates", {
          start: fetchInfo.start.toISOString(),
          end: fetchInfo.end.toISOString(),
          resource: "",
          worker: ""
        })
        .then(r => {
          //rename data model to fit to naming
          r.forEach((e, index) => {
            r[index].title = e.name;
            r[index].start = e.startDate;
            r[index].end = e.endDate;
            e.eventColor === "blue"
              ? (r[index].editable = true)
              : (r[index].editable = false);
            e.eventColor === "blue"
              ? (r[index].resourceEditable = true)
              : (r[index].resourceEditable = false);
          });
          successCallback(r);
        });
    },
    changeDateFromNavbar(val) {
      //change date from navbar datepicker
      this.calendarApi.gotoDate(val);
      this.reactDateChange();
    },
    step(val) {
      //forward - back buttons from navbar
      if (val === "forward") {
        this.calendarApi.next();
        this.reactDateChange();
      } else if (val === "back") {
        this.calendarApi.prev();
        this.reactDateChange();
      }
    },
    changeViewMode(val) {
      switch (val) {
        case "day":
          //tag
          this.calendarApi.changeView("resourceTimelineDay");
          break;
        case "week":
          //woche
          this.calendarApi.changeView("resourceTimelineWeek");
          break;
        case "month":
          //monat
          this.calendarApi.changeView("resourceTimelineMonth");
          break;
        case "year":
          //jahr
          this.calendarApi.changeView("resourceTimelineYear");
          break;
      }
      this.reactDateChange();
    },
    today() {
      this.calendarApi.today();
      this.reactDateChange();
    },
    reactDateChange() {
      //this method is triggered when the default date was changed
      this.datepickerDate = this.calendarApi.getDate();
      this.toolbarTitle = this.calendarView.title;
      return this.calendarApi.getDate();
    },
    formatContactFromTask(event) {
      return event.contact.name
        ? event.contact.name
        : event.contact.surname +
            " " +
            event.contact.familyname +
            " " +
            (event.contact.name2 ? event.contact.name2 : "");
    },
    truncateWithEllipses(text, max) {
      return text.substr(0, max - 1) + (text.length > max ? "&hellip;" : "");
    },
    // handle requests from right sidebar
    closeSidebar() {
      this.rightSidebarProp = null;
    },
    handleEditEvent(val) {
      this.editEvent(val);
    },
    handleCopyEvent(val) {
      this.copyEvent(val);
    },
    handleDeleteEvent(val) {
      this.rightSidebarProp = null;
      this.deleteEvent(val);
    },
    handleCreateDeliveryNote(val) {
      this.createDeliveryNote(val);
    },
    loadPdf(id) {
      let vm = this;
      vm.loadPdfDialog = true;
      let config = {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      };
      let postUrl = this.backendServer + "event/" + id + "/orderConfirmation";
      return axios
        .get(postUrl, config)
        .then(function(response) {
          vm.loadPdfDialog = false;
          return "data:application/pdf;base64," + response.data.file;
        })
        .catch(function(e) {
          vm.loadPdfDialog = false;
          vm.$store.dispatch("triggerError", e);
        });
    },
    handleDownloadOrderConfirmation(val) {
      //create pdf for download
      this.loadPdf(val._id).then(function(result) {
        //create pdf from blob
        const downloadLink = document.createElement("a");
        const fileName = "Auftragsbestätigung-"+ val.name + ".pdf";
        downloadLink.href = result;
        downloadLink.download = fileName;
        downloadLink.click();
      });
    }
  }
};
</script>
<style>
.outerCalendar {
  padding-top: 10px;
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0.5em;
}
.fc-timeline-event-harness {
  padding-top: 4.5px;
}
.fc-timeline-event {
  border: 2.5px solid #bababa !important;
  border-radius: 5px !important;
}
.fc-event-main {
  height: 36px;
}
.fc-datagrid-header {
  height: 65px !important;
}
.fc-datagrid-cell-frame {
  max-height: 60px !important;
}
.fc-timeline-lane-frame {
  height: 60px !important;
}
.fc-scrollgrid-sync-table tr:nth-of-type(even) {
  background-color: hsla(0, 0%, 94.1%, 0.3);
}
.ellipsis {
  text-overflow: ellipsis;

  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}
</style>
