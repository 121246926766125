import axios from "axios";

const actions = {
  getErrorList(context) {
    context.commit("SET_ERROR_LIST");
    const { page, itemsPerPage } = context.state.pagination;
    let getUrl = context.getters.backendServer + "logs/error?page=" + page + "&perPage=" + itemsPerPage;
    let config = {
      headers: {
        authorization: localStorage.getItem("token")
      }
    };

    axios
      .get(getUrl, config)
      .then(function (response) {
        context.commit("SET_ERROR_LIST", response.data.data);
        context.commit("SET_TOTAL_ITEMS_ERROR", response.data.count);
      })
      .catch(function (e) {
        context.commit("SET_ERROR_LIST", e);
        context.dispatch('triggerError', e);
      });
  },



};

export default actions;
