<template>
  <div>
    <v-list flat subheader three-line>
      <v-subheader><h4>Prozess-Einstellungen</h4></v-subheader>
    </v-list>
    <v-list-item>
      <v-list-item-content>
        <v-container>
          <v-card class="mx-auto" max-width="900">
            <v-container>
              <p>Einstellungen für Dokumenten- / Emailvorlagen</p>
            </v-container>
            <v-form class="pa-4" ref="form" lazy-validation>
              <p>Kundennummern auf Dokumenten anzeigen</p>
              <h5>
                Gibt an ob die Kundennummer auf den Dokumentenvorlagen angezeigt
                werden soll.
              </h5>
              <br />
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <p style="margin-top: 25px">
                    Kundennummer anzeigen
                  </p>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-switch
                    v-model="showCustomerNumberOnDocuments"
                    @change="changeShowCustomerNumberOnDocuments"
                  ></v-switch>
                </v-col>
              </v-row>

              <hr />
              <br />
              <br />
              <p>Nutzername zu Email hinzufügen</p>
              <h5>
                Nutzername des angemeldeten Nutzers automatisch zu Email
                hinzufügen.
              </h5>
              <br />
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <p style="margin-top: 25px">
                    Nutzername einfügen
                  </p>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-switch
                    v-model="userNameInEmailTemplate"
                    @change="changeUserNameInEmailTemplate"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-container>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  data: () => ({
    showCustomerNumberOnDocuments: false,
    userNameInEmailTemplate: false
  }),
  computed: {
    ...mapGetters(["backendServer"])
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch("getLicenseData");
      let config = {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      };
      axios
        .get(this.backendServer + "settings", config)
        .then(response => {
          for (let setting of response.data.data) {
            if (setting.name == "showCustomerNumberOnDocuments") {
              this.showCustomerNumberOnDocuments = setting.value;
            }
            if (setting.name == "userNameInEmailTemplate") {
              this.userNameInEmailTemplate = setting.value;
            }
          }
        })
        .catch(err => {});
    },
    changeShowCustomerNumberOnDocuments() {
      let vm = this;
      let config = {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      };

      axios
        .put(
          this.backendServer + "settings/showCustomerNumberOnDocuments",
          { value: this.showCustomerNumberOnDocuments },
          config
        )
        .then(response => {
          vm.$store.dispatch("triggerUniversalAlert", {
            text: "Einstellung wurde erfolgreich aktualisiert",
            heading: "Erfolgreich aktualisiert",
            type: "info"
          });
        })
        .catch(error => {
          vm.$store.dispatch("triggerUniversalAlert", {
            text:
              "Es ist ein unerwarteter Fehler aufgetreten bitte kontaktieren Sie den Support",
            heading: "Unerwarteter Fehler",
            type: "error"
          });
        });
    },
    changeUserNameInEmailTemplate() {
      let vm = this;
      let config = {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      };

      axios
        .put(
          this.backendServer + "settings/userNameInEmailTemplate",
          { value: this.userNameInEmailTemplate },
          config
        )
        .then(response => {
          vm.$store.dispatch("triggerUniversalAlert", {
            text: "Einstellung wurde erfolgreich aktualisiert",
            heading: "Erfolgreich aktualisiert",
            type: "info"
          });
        })
        .catch(error => {
          vm.$store.dispatch("triggerUniversalAlert", {
            text:
              "Es ist ein unerwarteter Fehler aufgetreten bitte kontaktieren Sie den Support",
            heading: "Unerwarteter Fehler",
            type: "error"
          });
        });
    }
  }
};
</script>
