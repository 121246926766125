import { StoreUtil } from "../utils";
const mutations = {
  SET_PAGINATION_ACCOUNT(state, payload) {
    state.accountData.pagination = payload;
  },
  RESET_PAGINATION_PAGES_ACCOUNT(state) {
    state.accountData.pagination.page = 1;
    state.accountData.pagination.itemsPerPage = 10;
  },
  SET_TOTAL_ACCOUNT_ALL(state, payload) {
    state.accountData.pagination.totalItems = payload;
  },
  SET_ALL_ACCOUNT(state, payload) {
    state.accountData.accountList = StoreUtil.updateState(
      state.accountData.accountList,
      payload
    );
  },
  SET_ACCOUNT_ADD(state, payload) {
    StoreUtil.updateState(state.accountData.accountList, payload);
  },
  SET_ACCOUNT_UPDATE(state, payload) {
    StoreUtil.updateState(state.accountData.accountList, payload);
  },
  SET_ACCOUNT_DELETE(state, payload) {
    StoreUtil.updateState(state.accountData.accountList, payload);
  }
};

export default mutations;
