import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCol,{attrs:{"cols":"12","sm":"6","md":"5"}},[_c(VRow,[_c(VAutocomplete,{attrs:{"items":_vm.contactDataMap,"loading":_vm.allStatusPendingWithoutPagnation,"hide-no-data":"","item-text":"Description","item-value":"id","label":"Kontakt","data-cy":"contact","prepend-icon":"mdi-database-search","rules":_vm.requieredRule,"dense":"","outlined":""},on:{"change":function($event){return _vm.createAdressFromContact(_vm.inputVal)}},model:{value:(_vm.inputVal.contact),callback:function ($$v) {_vm.$set(_vm.inputVal, "contact", $$v)},expression:"inputVal.contact"}}),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"icon":""}},on),[_c(VIcon,{on:{"click":function($event){_vm.addContact = true}}},[_vm._v("add")])],1)]}}])},[_c('span',[_vm._v("Kontakt erstellen")])]),(_vm.addContact)?_c('contactFormWrapper',{attrs:{"dialog":_vm.addContact},on:{"contactClose":_vm.contactClose}}):_vm._e()],1),(_vm.invoiceAddressActive)?_c('p',[_vm._v("Rechnungsadresse wird verwendet")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }