import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VCardTitle,[(!_vm.item.customerNumber)?_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))]):_vm._e(),(_vm.item.customerNumber)?_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle)+" - "+_vm._s(_vm.item.customerNumber))]):_vm._e()]),_c(VCardText,[_c(VContainer,[_c(VTabs,[_c(VTab,{key:0,attrs:{"id":"businessTab"},on:{"click":function($event){_vm.activeTab = 0}},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c(VIcon,[_vm._v("business")]),_vm._v("Organisation / Firma ")],1),_c(VTabItem,{key:0},[_c('contactForm',{ref:"contactForm",attrs:{"method":_vm.method,"contactType":_vm.contactType},on:{"close":_vm.close},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}})],1),_c(VTab,{key:1,attrs:{"id":"personTab"},on:{"click":function($event){_vm.activeTab = 1}},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c(VIcon,[_vm._v("person")]),_vm._v("Person ")],1),_c(VTabItem,{key:1},[_c('contactForm',{ref:"contactForm",attrs:{"method":_vm.method,"contactType":_vm.contactType},on:{"close":_vm.close},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}})],1)],1)],1)],1),_c(VCardActions,[_c('div',{staticClass:"flex-grow-1"}),_c(VBtn,{attrs:{"color":"secondary","text":""},on:{"click":_vm.close}},[_vm._v("Abbrechen")]),_c(VBtn,{attrs:{"color":"secondary","text":"","data-cy":"saveEvent"},on:{"click":_vm.save}},[_vm._v("Speichern")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }