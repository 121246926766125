import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-5"},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","xs":"12","sm":"6","md":"5"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Umsatz")]),_c(VCardText,[_c('revenuechart',{staticStyle:{"max-height":"600px"}})],1)],1)],1),_c(VCol,{attrs:{"cols":"12","xs":"12","sm":"6","md":"5"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Auslastung Maschinen")]),_c(VCardText,[_c('workloadtable',{staticStyle:{"max-height":"600px"}})],1)],1)],1)],1),_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","xs":"12","sm":"6","md":"5"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Umsatz nach Kunden")]),_c(VCardText,[_c('revenuecontactchart',{staticStyle:{"max-height":"600px"}})],1)],1)],1),_c(VCol,{attrs:{"cols":"12","xs":"12","sm":"6","md":"5"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }