import router from "../../router.js";
import axios from "axios";

const actions = {
  getCompanyData(context) {
    context.commit("SET_COMPANY_DATA");
    let getUrl = context.getters.backendServer + "meta/companyData";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_COMPANY_DATA", response.data);
      })
      .catch(function(e) {
        context.commit("SET_COMPANY_DATA", e);
      });
  },
  triggerError(context, error) {
    if (error == "Error: Request failed with status code 401") {
      context.dispatch("logout");
      router.push("login");
    } else if (error == "Error: Request failed with status code 402") {
      context.dispatch("triggerUniversalAlert", {
        text:
          "Diese Aktion kann nicht durchgeführt werden, da Ihre Lizenzlimits erreicht sind. Um diese Beschränkung aufzuheben kontaktieren Sie bitte den Support.",
        heading: "Lizenzlimits erreicht",
        type: "info"
      });
    } else {
      context.commit("THROW_ERROR", {
        err: error,
        con: context
      });
    }
  },
  updateCompanyData(context, value) {
    context.commit("SET_COMPANY_DATA_UPDATE");

    let postUrl = context.getters.backendServer + "meta/companyData";

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    axios
      .put(postUrl, value, config)
      .then(function(response) {
        context.commit("SET_COMPANY_DATA_UPDATE", response.data);
        context.commit(
          "CREATE_SNACKBAR",
          "Stammdaten erfolgreich aktualisiert"
        );
        context.dispatch("getCompanyData");
      })
      .catch(function(e) {
        context.commit("SET_COMPANY_DATA_UPDATE", e);
        context.dispatch("triggerError", e);
      });
  },
  getLicenseData(context) {
    context.commit("SET_LICENSE_DATA");
    let getUrl = context.getters.backendServer + "meta/license";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    return axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_LICENSE_DATA", response.data);
        return response.data;
      })
      .catch(function(e) {
        context.commit("SET_LICENSE_DATA", e);
      });
  },
  updateLicenseData(context, value) {
    context.commit("SET_LICENSE_DATA_UPDATE");

    let postUrl = context.getters.backendServer + "meta/license";

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    axios
      .put(postUrl, value, config)
      .then(function(response) {
        context.commit("SET_LICENSE_DATA_UPDATE", response.data);
        context.commit(
          "CREATE_SNACKBAR",
          "Lizenzdaten erfolgreich aktualisiert"
        );
        context.dispatch("getDatevData");
      })
      .catch(function(e) {
        context.commit("SET_LICENSE_DATA_UPDATE", e);
        context.dispatch("triggerError", e);
      });
  },
  getMailData(context) {
    context.commit("SET_MAIL_DATA");
    let getUrl = context.getters.backendServer + "meta/mail";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_MAIL_DATA", response.data);
      })
      .catch(function(e) {
        context.commit("SET_MAIL_DATA", e);
      });
  },
  updateMailData(context, value) {
    context.commit("SET_MAIL_DATA_UPDATE");

    let postUrl = context.getters.backendServer + "meta/mail";

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    axios
      .put(postUrl, value, config)
      .then(function(response) {
        context.commit("SET_MAIL_DATA_UPDATE", response.data);
        context.commit(
          "CREATE_SNACKBAR",
          "Stammdaten erfolgreich aktualisiert"
        );
        context.dispatch("getMailData");
      })
      .catch(function(e) {
        context.commit("SET_MAIL_DATA_UPDATE", e);
        context.dispatch("triggerError", e);
      });
  },
  getSevdeskData(context) {
    context.commit("SET_SEVDESK_DATA");
    let getUrl = context.getters.backendServer + "meta/sevdesk";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_SEVDESK_DATA", response.data);
      })
      .catch(function(e) {
        context.commit("SET_SEVDESK_DATA", e);
      });
  },
  updateSevdeskData(context, value) {
    context.commit("SET_SEVDESK_DATA_UPDATE");

    let postUrl = context.getters.backendServer + "meta/sevdesk";

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    axios
      .put(postUrl, value, config)
      .then(function(response) {
        context.commit("SET_SEVDESK_DATA_UPDATE", response.data);
        context.commit(
          "CREATE_SNACKBAR",
          "Stammdaten erfolgreich aktualisiert"
        );
        context.dispatch("getSevdeskData");
      })
      .catch(function(e) {
        context.commit("SET_SEVDESK_DATA_UPDATE", e);
        context.dispatch("triggerError", e);
      });
  },
  getCustomerPortalData(context) {
    context.commit("SET_CUSTOMERPORTAL_DATA");
    let getUrl = context.getters.backendServer + "meta/customerPortal";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_CUSTOMERPORTAL_DATA", response.data);
      })
      .catch(function(e) {
        context.commit("SET_CUSTOMERPORTAL_DATA", e);
      });
  },
  updateCustomerPortalData(context, value) {
    context.commit("SET_CUSTOMERPORTAL_DATA_UPDATE");

    let postUrl = context.getters.backendServer + "meta/customerPortal";

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    axios
      .put(postUrl, value, config)
      .then(function(response) {
        context.commit("SET_CUSTOMERPORTAL_DATA_UPDATE", response.data);
        context.commit(
          "CREATE_SNACKBAR",
          "Stammdaten erfolgreich aktualisiert"
        );
        context.dispatch("getCustomerPortalData");
      })
      .catch(function(e) {
        context.commit("SET_CUSTOMERPORTAL_DATA_UPDATE", e);
        context.dispatch("triggerError", e);
      });
  },
  getDatevData(context) {
    context.commit("SET_DATEV_DATA");
    let getUrl = context.getters.backendServer + "meta/datev";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_DATEV_DATA", response.data);
      })
      .catch(function(e) {
        context.commit("SET_DATEV_DATA", e);
      });
  },
  updateDatevData(context, value) {
    context.commit("SET_DATEV_DATA_UPDATE");

    let postUrl = context.getters.backendServer + "meta/datev";

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    axios
      .put(postUrl, value, config)
      .then(function(response) {
        context.commit("SET_DATEV_DATA_UPDATE", response.data);
        context.commit(
          "CREATE_SNACKBAR",
          "Stammdaten erfolgreich aktualisiert"
        );
        context.dispatch("getDatevData");
      })
      .catch(function(e) {
        context.commit("SET_DATEV_DATA_UPDATE", e);
        context.dispatch("triggerError", e);
      });
  },
  getLogo(context) {
    context.commit("SET_LOGO");
    let getUrl = context.getters.backendServer + "meta/logo";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_LOGO", response.data);
      })
      .catch(function(e) {
        context.commit("SET_LOGO", e);
      });
  },
  getDeliveryNoteText(context) {
    let getUrl = context.getters.backendServer + "meta/deliveryNoteText";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    return axios
      .get(getUrl, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },
  updateDeliveryNoteText(context, value) {
    let postUrl = context.getters.backendServer + "meta/deliveryNoteText";

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    axios
      .put(postUrl, value, config)
      .then(function(response) {
        context.commit("CREATE_SNACKBAR", "Texte erfolgreich aktualisiert");
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },
  getInvoiceText(context) {
    let getUrl = context.getters.backendServer + "meta/invoiceText";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    return axios
      .get(getUrl, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },
  updateInvoiceText(context, value) {
    let postUrl = context.getters.backendServer + "meta/invoiceText";

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    axios
      .put(postUrl, value, config)
      .then(function(response) {
        context.commit("CREATE_SNACKBAR", "Texte erfolgreich aktualisiert");
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },
  getOfferText(context) {
    let getUrl = context.getters.backendServer + "meta/offerText";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    return axios
      .get(getUrl, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },
  updateOfferText(context, value) {
    let postUrl = context.getters.backendServer + "meta/offerText";

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    axios
      .put(postUrl, value, config)
      .then(function(response) {
        context.commit("CREATE_SNACKBAR", "Texte erfolgreich aktualisiert");
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },

  getStandardItems(context) {
    let getUrl = context.getters.backendServer + "meta/standardItems";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    return axios
      .get(getUrl, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },
  updateStandardItems(context, value) {
    let postUrl = context.getters.backendServer + "meta/standardItems";

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    axios
      .put(postUrl, value, config)
      .then(function(response) {
        context.commit(
          "CREATE_SNACKBAR",
          "Lieferscheinpositionen erfolgreich aktualisiert"
        );
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },
  getDeliveryNotePositionTemplates(context) {
    context.commit("SET_DELIVERYNOTEPOSITIONTEMPLATES_DATA");
    let getUrl =
      context.getters.backendServer + "inventory/template?populate=1";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    return axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit(
          "SET_DELIVERYNOTEPOSITIONTEMPLATES_DATA",
          response.data.data
        );
        return response.data;
      })
      .catch(function(e) {
        context.commit("SET_DELIVERYNOTEPOSITIONTEMPLATES_DATA", e);
        context.dispatch("triggerError", e);
      });
  },
  getSingleDeliveryNotePositionTemplates(context, item) {
    let getUrl =
      context.getters.backendServer +
      "inventory/template/" +
      item.id +
      "?populate=1";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    return axios
      .get(getUrl, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },
  updateSingleDeliveryNotePositionTemplates(context, item) {
    let putUrl =
      context.getters.backendServer + "inventory/template/" + item.id;

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    return axios
      .put(putUrl, item, config)
      .then(function(response) {
        context.commit("CREATE_SNACKBAR", "Vorlage erfolgreich aktualisiert");
        return response.data;
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },
  deleteSingleDeliveryNotePositionTemplates(context, item) {
    let deleteUrl =
      context.getters.backendServer + "inventory/template/" + item.id;

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    return axios
      .delete(deleteUrl, config)
      .then(function(result) {
        context.commit("CREATE_SNACKBAR", "Vorlage erfolgreich gelöscht");
        return result.data;
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },
  addSingleDeliveryNotePositionTemplates(context, item) {
    let postUrl = context.getters.backendServer + "inventory/template";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    return axios
      .post(postUrl, item, config)
      .then(function(response) {
        context.commit("CREATE_SNACKBAR", "Vorlage erfolgreich hinzugefügt");
        return response.data;
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },
  userNameInEmailTemplate(context) {
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    return axios
      .get(context.getters.backendServer + "settings", config)
      .then(response => {
        for (let setting of response.data.data) {
          if (setting.name == "userNameInEmailTemplate") {
            return setting.value;
          }
        }
      })
      .catch(err => {
        context.dispatch("triggerError", err);
      });
  },

  triggerSnackbar(context, text) {
    context.commit("CREATE_SNACKBAR", text);
  },
  triggerUniversalAlert(context, value) {
    context.commit("CREATE_UNIVERSAL_ALERT", value);
  },
  closeUniversalAlert(context) {
    context.commit("CLOSE_UNIVERSAL_ALERT");
  }
};

export default actions;
