import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VForm,{ref:"form",staticClass:"pa-4",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('p',[_vm._v("Sevdesk Integration")]),_c('h5',[_vm._v(" Wenn Sie Sevdesk mit onDispatch verbinden möchten, muss hier der API-Key, die ID der Kontaktperson sowie das Zahlungsziel festgelegt werden."),_c('br'),_vm._v(" Gerne unterstützen wir Sie bei der Sevdesk Integration. ")]),_c('br'),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"12","md":"4"}},[_c(VTextField,{attrs:{"label":"ID Kontaktperson","data-cy":"contactPerson","outlined":"","dense":""},model:{value:(_vm.item.contactPerson),callback:function ($$v) {_vm.$set(_vm.item, "contactPerson", $$v)},expression:"item.contactPerson"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"12","md":"4"}},[_c(VTextField,{attrs:{"label":"API-Key","data-cy":"apiKey","append-icon":_vm.show ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show ? 'text' : 'password',"outlined":"","dense":""},on:{"click:append":function($event){_vm.show = !_vm.show}},model:{value:(_vm.item.apiKey),callback:function ($$v) {_vm.$set(_vm.item, "apiKey", $$v)},expression:"item.apiKey"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"12","md":"2"}},[_c(VTextField,{attrs:{"type":"number","label":"Zahlungsziel","data-cy":"timeToPay","outlined":"","dense":""},model:{value:(_vm.item.timeToPay),callback:function ($$v) {_vm.$set(_vm.item, "timeToPay", $$v)},expression:"item.timeToPay"}})],1)],1),_c(VBtn,{attrs:{"color":"secondary","text":""},on:{"click":_vm.save}},[_vm._v("Speichern")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }