<template>
  <v-card flat>
    <div style="height: 100%">
      <v-toolbar flat color="white">
        <v-toolbar-title>Baustellen</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <div class="flex-grow-1"></div>
        <v-dialog v-model="dialog" max-width="800px">
          <template v-slot:activator="{ on }">
            <v-btn color="secondary" dark class="mb-2" v-on="on">Neue Baustelle</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="defaultItem.reference"
                        label="Referenz / Baustelle / Kostenstelle"
                        data-cy="reference"
                        :rules="requieredRule"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-textarea
                        v-model="defaultItem.location"
                        label="Adresse / Arbeitsort"
                        data-cy="location"
                        dense
                        outlined
                      ></v-textarea>
                      <a
                        :href="
                          'https://www.google.de/maps/search/' + defaultItem.location
                        "
                        target="_blank"
                      >
                        <v-btn small color="secondary"
                          >Adresse / Arbeitsort in Google Maps öffnen
                          <v-icon small right dark> open_in_new</v-icon>
                        </v-btn>
                      </a>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.contactPerson"
                        label="Ansprechpartner"
                        data-cy="contactPerson"
                        dense
                        outlined
                      ></v-text-field>
                      <v-text-field
                        v-model="defaultItem.email"
                        label="Email (für Lieferscheine)"
                        data-cy="email"
                        dense
                        outlined
                        prepend-icon="email"
                      ></v-text-field>
                      <v-text-field
                        v-model="defaultItem.phone"
                        label="Telefonnummer"
                        data-cy="phone"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-textarea
                        v-model="defaultItem.description"
                        label="Infos"
                        data-cy="description"
                        dense
                        outlined
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn color="secondary" text @click="close">Abbrechen</v-btn>
              <v-btn color="secondary" text @click="save">Speichern</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-simple-table v-if="contact.templates.length > 0">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Referenz / Baustelle</th>
              <th class="text-left">Adresse</th>
              <th class="text-left">Ansprechpartner</th>
              <th class="text-left">Email (Lieferscheine)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in contact.templates" :key="item.reference">
              <td>{{ item.reference }}</td>
              <td>{{ item.location }}</td>
              <td>{{ item.contactPerson }}</td>
              <td>{{ item.email }}</td>
              <td>
                <v-dialog v-model="deleteDialog" max-width="500">
                  <v-card>
                    <v-card-title class="headline">Datensatz löschen?</v-card-title>
                    <v-card-text v-if="deleteItem">
                      Soll die Baustelle "{{ deleteItem.reference }}" wirklich gelöscht
                      werden?
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="secondary" text @click="deleteDialog = false"
                        >Abbrechen</v-btn
                      >
                      <v-btn
                        color="secondary"
                        text
                        @click="triggerDeleteItem(item, index)"
                        >Jetzt löschen</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon medium class="mr-2" v-on="on" @click="editItem(item, index)"
                      >edit</v-icon
                    >
                  </template>
                  <span>Bearbeiten</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      medium
                      class="mr-2"
                      v-on="on"
                      @click="triggerDeleteDialog(item, index)"
                      >delete</v-icon
                    >
                  </template>
                  <span>Löschen</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <br />
    </div>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["contact"],
  data: () => ({
    dialog: false,
    deleteDialog: false,
    deleteItem: null,
    editedIndex: -1,
    defaultItem: {
      email: null,
      location: null,
      phone: null,
      contactPerson: null,
      reference: null,
      description: null,
    },
    defaultIndex: null,
    requieredRule: [(v) => !!v || "Dieses Feld wird benötigt"],
    valid: true,
    templates: [],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Neue Baustelle" : "Baustelle bearbeiten";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.editedIndex = -1;
      this.$refs.form.reset();
    },
    editItem(item, index) {
      this.defaultItem = Object.assign({}, item);
      this.defaultIndex = index;
      this.editedIndex = 1;
      this.dialog = true;
    },
    triggerDeleteDialog(item, index) {
      this.deleteDialog = true;
      this.deleteItem = item;
      this.defaultIndex = index;
    },
    triggerDeleteItem(item, index) {
      let postContact = this.contact;
      postContact.templates.splice(this.defaultIndex, 1);
      this.$store.dispatch("updateContact", postContact).then((result) => {
        this.deleteDialog = false;
        this.deleteItem = null;
      });
    },
    save() {
      let vm = this;
      if (this.editedIndex > -1) {
        if (vm.$refs.form.validate()) {
          //on edit
          //replace item
          let postContact = vm.contact;
          postContact.templates[this.defaultIndex] = vm.defaultItem;
          vm.$store.dispatch("updateContact", postContact).then((result) => {
            vm.close();
          });
        }
      } else {
        if (vm.$refs.form.validate()) {
          //on new item
          let postContact = vm.contact;
          postContact.templates.push(vm.defaultItem);
          vm.$store.dispatch("updateContact", postContact).then((result) => {
            vm.close();
          });
        }
      }
    },
  },
};
</script>
<style>
.expanded__content {
  box-shadow: none !important;
  background: white !important;
}
</style>
