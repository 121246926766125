<template>
  <div>
    <sidemenu v-bind:drawer="drawer"></sidemenu>
    <v-app-bar clipped-right app dark color="primary">
      <v-toolbar-title style="width: 300px">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <span class="hidden-sm-and-down"
          ><strong v-if="companyData.data[0] != null">{{
            companyData.data[0].value.name
          }}</strong></span
        >
        <div class="flex-grow-1"></div>
      </v-toolbar-title>
      <div class="flex-grow-1"></div>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
            @click="createEventWithoutPreset"
            data-cy="createAuftragNavbar"
          >
            <v-icon>note_add</v-icon>
          </v-btn>
        </template>
        <span>Auftrag anlegen</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon @click="linkToDasboard">dashboard</v-icon>
          </v-btn>
        </template>
        <span>Dashboard</span>
      </v-tooltip>
      <!--
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-badge
              bordered
              color="red"
              :content="requests"
              v-if="requests != 0"
            >
              <v-icon @click="linkToWebRequest">mdi-email</v-icon>
            </v-badge>
            <v-icon v-else @click="linkToWebRequest">mdi-email</v-icon>
          </v-btn>
        </template>
        <span>Anfragen</span>
      </v-tooltip>
      -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon @click="dialog = true">help</v-icon>
          </v-btn>
        </template>
        <span>Hilfe erhalten</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon @click="duplicate">library_add</v-icon>
          </v-btn>
        </template>
        <span>Tab duplizieren</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon @click="logout" data-cy="logout">exit_to_app</v-icon>
          </v-btn>
        </template>
        <span>Logout</span>
      </v-tooltip>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-menu open-on-hover bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <span class="mr-3 hidden-sm-and-down" v-bind="attrs" v-on="on">{{
            name
          }}</span>
          <v-avatar color="secondary" size="36" v-bind="attrs" v-on="on">
            <span class="white--text">{{ short }}</span>
          </v-avatar>
        </template>
        <v-list>
          <v-list-item @click="navigateTo('Users', 'noTab')">
            Benutzereinstellungen
          </v-list-item>
          <v-list-item @click="logout()">
            Logout / Abmelden
          </v-list-item>
        </v-list>
      </v-menu>
      <v-dialog v-model="dialog" width="600">
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>
            Support erhalten
          </v-card-title>

          <v-card-text>
            <br />
            <h4>onDispatch - {{ version }}</h4>
            <br />
            <h5>Ihr Instanz-Kennung (für mobile App)</h5>
            <code>{{ backendServer.substring(8, 11) }}</code>
            <br />
            <br />
            <v-row>
              <v-btn
                class="ma-2"
                outlined
                dense
                color="primary"
                href="https://doku.ondispatch.de/docs/uebersicht/"
                target="_blank"
                >Dokumentation / Hilfe</v-btn
              >
              <v-btn
                class="ma-2"
                outlined
                dense
                color="primary"
                @click="createSupportData"
                target="_blank"
                >Supportdatei herunterladen</v-btn
              >
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false">
              ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app-bar>
  </div>
</template>

<script>
import sidemenu from "./sideMenu.vue";
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  components: {
    sidemenu,
  },
  data: () => ({
    drawer: null,
    dialog: false,
    requests: 0,
    short: "",
    name: "",
  }),
  created() {
    this.$store.dispatch("getCompanyData");
    this.$store.dispatch("getLicenseData");
    this.$store.dispatch("getMailData");
    this.$store.dispatch("getSevdeskData");
    this.$store.dispatch("getDatevData");
    this.$store.dispatch("getRequestCount");
    let config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };

    let getURL = this.backendServer + "user/info";
    axios
      .get(getURL, config)
      .then((response) => {
        this.short = response.data.short;
        this.name = response.data.name;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  computed: {
    ...mapGetters([
      "version",
      "companyData",
      "supportMail",
      "errors",
      "backendServer",
      "licenseData",
      "mailData",
      "sevdeskData",
      "datevData",
      "requestCount",
      "backendServer",
    ]),
  },
  watch: {
    requestCount: {
      deep: true,
      handler(val) {
        if (val.requestCount.data[0]) {
          this.requests = val.requestCount.data[0];
        }
      },
    },
  },
  methods: {
    linkToDasboard() {
      window.location.href = "/#/dashboard";
    },
    logout() {
      localStorage.clear();
      this.$router.push("login");
    },
    linkToWebRequest() {
      window.location.href = "/#/requests";
    },
    duplicate() {
      let href =
        window.location.origin + "/#" + this.$router.history.current.path;
      window.open(href, "_blank");
    },
    detectBrowser() {
      var ua = navigator.userAgent,
        tem,
        M =
          ua.match(
            /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
          ) || [];
      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return { name: "IE", version: tem[1] || "" };
      }
      if (M[1] === "Chrome") {
        tem = ua.match(/\bOPR|Edge\/(\d+)/);
        if (tem != null) {
          return { name: "Opera", version: tem[1] };
        }
      }
      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
      if ((tem = ua.match(/version\/(\d+)/i)) != null) {
        M.splice(1, 1, tem[1]);
      }
      return {
        name: M[0],
        version: M[1],
      };
    },
    createSupportData() {
      let supData = {
        version: "onDispatch " + this.version,
        backendServer: this.backendServer,
        companyName: this.companyData.data[0].value.name,
        licenseData: this.licenseData.data,
        mailData: this.mailData.data,
        sevdeskData: this.sevdeskData.data,
        datevData: this.datevData.data,
        browser: this.detectBrowser(),
        screenHeight: window.screen.availHeight,
        screenWidth: window.screen.availWidth,
        errors: this.errors,
        created: new Date(),
      };
      //remove api tokens / passwords
      supData.licenseData[0].value.price = "#ENTFERNT#";
      supData.mailData[0].value.password = "#ENTFERNT#";
      supData.sevdeskData[0].value.apiKey = "#ENTFERNT#";
      supData.sevdeskData[0].value.password = "#ENTFERNT#";
      //create and download json file
      var dataStr =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(supData));
      var downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", dataStr);
      downloadAnchorNode.setAttribute(
        "download",
        "supportData" + Date.now() + ".json"
      );
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    },
    navigateTo(destination, modus) {
      if (modus == "newTab") {
        let href = window.location.origin + "/#" + destination;
        window.open(href, "_blank");
      } else {
        this.$router.replace({ path: "/" + destination });
      }
    },
    createEventWithoutPreset() {
      this.$router.push({
        name: "eventForm",
        params: {
          id: 1,
          mode: "new",
          back: JSON.stringify({
            to: "Planer",
            data: {
              back: 1,
              tab: 1,
              deliveryNoteId: 1,
              mode: 1,
              id: 1,
            },
          }),
        },
      });
    },
  },
};
</script>
