<template>
  <div>
    <v-list flat subheader three-line>
      <v-subheader><h4>Prozess-Einstellungen</h4></v-subheader>
    </v-list>
    <v-list-item>
      <v-list-item-content>
        <v-container>
          <v-card class="mx-auto" max-width="900">
            <v-container>
              <p>Planer-Einstellungen</p>
            </v-container>
            <v-form class="pa-4" ref="form" lazy-validation>
              <p>Disponummer als Standardbetreff</p>
              <h5>
                Ist diese Option aktiviert, wird die Disponummer standardmäßig als Betreff eingetragen
              </h5>
              <br />
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <p style="margin-top: 25px">
                    Disponummer standardmäßig setzen
                  </p>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-switch
                    v-model="setEventNumber"
                    @change="changeSetEventName"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-form>
            <hr />
            <v-form class="pa-4" ref="form" lazy-validation>
              <p>"Undisponiert-Maschinen" im Planer (Maximal 5)</p>
              <h5>
                "Undisponiert-Maschinen" können nicht abgerechnet werden, sondern
                nur zum einfacheren Verwalten von Aufträgen genutzt werden.
                Diese Platzhalter sind kostenfrei und werden nicht von
                den gebuchten Fahrzeugen abgezogen. Fahrer sehen Aufträge auf diesen Maschinen nicht.
              </h5>
              <br />
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-btn color="secondary" dark @click="newDispatcher()"
                    >Neue Maschine</v-btn
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-simple-table class="ma-3">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Nr.</th>
                        <th class="text-left">Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in machines" :key="item.name">
                        <td>{{ index + 1 }}</td>
                        <td>{{ item.name }}</td>
                        <td>
                          <v-icon
                            small
                            @click="deleteItemWrapper(item.id, item.name)"
                          >
                            mdi-delete
                          </v-icon>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-row>
            </v-form>
            <v-card-actions> </v-card-actions>
          </v-card>
        </v-container>
      </v-list-item-content>
    </v-list-item>
    <v-dialog v-model="deleteDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Datensatz löschen?</v-card-title>
        <v-card-text
          >Soll die Maschine {{ machine }} wirklich gelöscht
          werden?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetDeleteDialog()"
            >Abbrechen</v-btn
          >
          <v-btn color="secondary" text @click="deleteItem()"
            >Jetzt löschen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  data: () => ({
    machines: [],
    machine: "",
    deleteId: "",
    deleteDialog: false,
    setEventNumber: false
  }),
  computed: {
    ...mapGetters(["backendServer"])
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      let config = {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      };

      axios
        .get(this.backendServer + "resource/dispatcher", config)
        .then(response => {
          this.machines = response.data;
        });

      axios
        .get(this.backendServer + "settings", config)
        .then(response => {
          for (let setting of response.data.data) {
            if (setting.name == "setEventNumber") {
              this.setEventNumber = setting.value;
            }
          }
        })
        .catch(err => {});
    },
    newDispatcher() {
      let config = {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      };

      let vm = this;
      axios
        .post(this.backendServer + "resource/dispatcher", {}, config)
        .then(() => {
          axios
            .get(this.backendServer + "resource/dispatcher", config)
            .then(response => {
              vm.machines = response.data;
            });
        })
        .catch(() => {
          vm.$store.dispatch("triggerUniversalAlert", {
            text: "Sie können maximal 5 Undisponiert-Maschinen anlegen",
            heading: "Fehler",
            type: "error"
          });
        });
    },
    deleteItemWrapper(id, name) {
      this.machine = name;
      this.deleteId = id;
      this.deleteDialog = true;
    },
    resetDeleteDialog() {
      this.machine = "";
      this.deleteId = "";
      this.deleteDialog = false;
    },
    deleteItem() {
      let config = {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      };

      let vm = this;

      axios
        .delete(
          this.backendServer + "resource/dispatcher/" + this.deleteId,
          config
        )
        .then(response => {
          axios
            .get(this.backendServer + "resource/dispatcher", config)
            .then(response => {
              vm.machines = response.data;
            });
        })
        .catch(() => {
          vm.$store.dispatch("triggerUniversalAlert", {
            text:
              "Bitte entfernen Sie alle Aufträge von der Maschine bevor Sie diese löschen.",
            heading: "Unwerwarteter Fehler",
            type: "error"
          });
        });

      this.resetDeleteDialog();
    },
    changeSetEventName() {
      let vm = this;
      let config = {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      };

      let putURL =
        this.backendServer + "settings/setEventNumber";

      axios
        .put(putURL, { value: this.setEventNumber }, config)
        .then(response => {
          vm.$store.dispatch("triggerUniversalAlert", {
            text: "Einstellung wurde erfolgreich aktualisiert",
            heading: "Erfolgreich aktualisiert",
            type: "info"
          });
        })
        .catch(err => {
          vm.$store.dispatch("triggerUniversalAlert", {
            text:
              "Es ist ein unerwarteter Fehler aufgetreten bitte kontaktieren Sie den Support",
            heading: "Unerwarteter Fehler",
            type: "error"
          });
        });
    }
  }
};
</script>
