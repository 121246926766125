import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VList,{attrs:{"flat":"","subheader":"","three-line":""}},[_c(VSubheader,[_c('h4',[_vm._v("Prozess-Einstellungen")])])],1),_c(VListItem,[_c(VListItemContent,[_c(VContainer,[_c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"900"}},[_c(VContainer,[_c('p',[_vm._v("Einstellungen für Dokumenten- / Emailvorlagen")])]),_c(VForm,{ref:"form",staticClass:"pa-4",attrs:{"lazy-validation":""}},[_c('p',[_vm._v("Kundennummern auf Dokumenten anzeigen")]),_c('h5',[_vm._v(" Gibt an ob die Kundennummer auf den Dokumentenvorlagen angezeigt werden soll. ")]),_c('br'),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('p',{staticStyle:{"margin-top":"25px"}},[_vm._v(" Kundennummer anzeigen ")])]),_c(VCol,{attrs:{"cols":"12","sm":"6","md":"6"}},[_c(VSwitch,{on:{"change":_vm.changeShowCustomerNumberOnDocuments},model:{value:(_vm.showCustomerNumberOnDocuments),callback:function ($$v) {_vm.showCustomerNumberOnDocuments=$$v},expression:"showCustomerNumberOnDocuments"}})],1)],1),_c('hr'),_c('br'),_c('br'),_c('p',[_vm._v("Nutzername zu Email hinzufügen")]),_c('h5',[_vm._v(" Nutzername des angemeldeten Nutzers automatisch zu Email hinzufügen. ")]),_c('br'),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('p',{staticStyle:{"margin-top":"25px"}},[_vm._v(" Nutzername einfügen ")])]),_c(VCol,{attrs:{"cols":"12","sm":"6","md":"6"}},[_c(VSwitch,{on:{"change":_vm.changeUserNameInEmailTemplate},model:{value:(_vm.userNameInEmailTemplate),callback:function ($$v) {_vm.userNameInEmailTemplate=$$v},expression:"userNameInEmailTemplate"}})],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }