import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VList,{attrs:{"flat":"","subheader":"","three-line":""}},[_c(VSubheader,[_c('h4',[_vm._v("Prozess-Einstellungen")])])],1),_c(VListItem,[_c(VListItemContent,[_c(VContainer,[_c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"900"}},[_c(VContainer,[_c('p',[_vm._v("App-Einstellungen")])]),_c(VForm,{ref:"form",staticClass:"pa-4",attrs:{"lazy-validation":""}},[_c('p',[_vm._v("Tage in der Anzeige")]),_c('h5',[_vm._v(" Hier können Sie einstellen welche Tage Ihren Fahrern angezeigt werden, sowohl in die Vergangenheit als auch in die Zukunft. der heutige Tag wird immer angezeigt. ")]),_c('br'),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6","md":"6"}},[_c(VTextField,{attrs:{"type":"number","label":"Tage in die Vergangenheit","data-cy":"daysBack","outlined":"","dense":""},model:{value:(_vm.daysBack),callback:function ($$v) {_vm.daysBack=$$v},expression:"daysBack"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6","md":"6"}},[_c(VTextField,{attrs:{"type":"number","label":"Tage in die Zukunft","data-cy":"daysForward","outlined":"","dense":""},model:{value:(_vm.daysForward),callback:function ($$v) {_vm.daysForward=$$v},expression:"daysForward"}})],1),_c(VCardActions,[_c('div',{staticClass:"flex-grow-1"}),_c(VBtn,{attrs:{"color":"secondary","text":""},on:{"click":_vm.saveAppSettings}},[_vm._v("Speichern")])],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }