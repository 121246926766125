const mutations = {
    SET_TOKEN(state, token) {
        state.token = token;
    },
    SET_STATIC_TOKEN(state, token) {
        state.static_token = token;
    },
    SET_USER_INFO(state, info) {
        state.userInfo = info;
    },
    SET_USER_ROLE(state, role) {
        state.role = role;
    },
    LOGIN_PENDING(state) {
        state.loading = true;
    },
    LOGIN_SUCCESS(state) {
        state.loading = false;
        state.loggedIn = true;
    },
    LOGIN_FAILED(state) {
        state.token = null;
        state.static_token = null;
        state.loggedIn = false;
        state.loading = false;
    },
    LOGOUT_STATE(state) {
        state.loggedIn = false;
    }
}

export default mutations;