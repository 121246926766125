<template>
  <v-col cols="12" sm="6" md="5">
    <v-row>
      <v-autocomplete
        v-model="inputVal.contact"
        :items="contactDataMap"
        :loading="allStatusPendingWithoutPagnation"
        hide-no-data
        item-text="Description"
        item-value="id"
        label="Kontakt"
        data-cy="contact"
        prepend-icon="mdi-database-search"
        :rules="requieredRule"
        dense
        outlined
        @change="createAdressFromContact(inputVal)"
      ></v-autocomplete>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon @click="addContact = true">add</v-icon>
          </v-btn>
        </template>
        <span>Kontakt erstellen</span>
      </v-tooltip>
      <contactFormWrapper
        v-if="addContact"
        :dialog="addContact"
        @contactClose="contactClose"
      />
    </v-row>
    <p v-if="invoiceAddressActive">Rechnungsadresse wird verwendet</p>
  </v-col>
</template>

<script>
import contactFormWrapper from "../contact/createContact/contactFormWrapper.vue";
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  props: ["value"],
  components: {
    contactFormWrapper
  },
  data: () => ({
    panel: [false],
    requieredRule: [v => !!v || "Dieses Feld wird benötigt"],
    addContact: false,
    invoiceAddressActive: false
  }),
  mounted() {
    this.$store.dispatch("getAllListWithoutPagination");
  },
  computed: {
    ...mapGetters([
      "allListGetterWithoutPagnation",
      "allStatusPendingWithoutPagnation",
      "backendServer"
    ]),
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    contactDataMap() {
      return this.createContactDataMap();
    }
  },
  methods: {
    createContactDataMap() {
      return this.allListGetterWithoutPagnation.allList.data.map(contact => {
        let Description;
        if (contact.name) {
          if (contact.name2) {
            Description = contact.name + " " + contact.name2;
          } else {
            Description = contact.name;
          }
        } else {
          Description = contact.familyname;
        }

        contact.address.city != null
          ? (Description = Description + " | " + contact.address.city)
          : "";
        Description = Description + " - " + contact.customerNumber;
        const id = contact.id;
        return Object.assign({}, contact, {
          Description,
          id
        });
      });
    },
    contactClose(val) {
      this.$store.dispatch("getAllListWithoutPagination");
      const Description = val.name + " - " + val.customerNumber;
      const id = val.id;
      const contact = val;
      this.inputVal.contact = Object.assign(contact, {
        Description,
        id
      });

      this.inputVal.address = this.buildAddress(contact);
      this.addContact = false;
      this.transferContactDataToInvoice(val);
    },
    //sets the adress field when a contact is selected
    createAdressFromContact(val) {
      let vm = this;
      let getUrl;
      if (val.contact instanceof Object) {
        //if contact was newly created
        getUrl = this.backendServer + "contact/" + val.contact.id;
      } else {
        getUrl = this.backendServer + "contact/" + val.contact;
      }
      let config = {
        headers: {
          authorization: localStorage.getItem("token")
        }
      };
      // sets data from contact db
      axios
        .get(getUrl, config)
        .then(function(response) {
          val.address = vm.buildAddress(response.data);
          //pass data back to parent (when delivery note is created from planer)
          vm.$emit("postAdressValue", val.address);
          vm.transferContactDataToInvoice(response.data);
        })
        .catch(function(e) {
          vm.$store.dispatch("triggerError", e);
        });
    },
    transferContactDataToInvoice(val) {
      //set skonto + timeto pay to incoide from contact
      if (this.inputVal.invoicePos) {
        this.inputVal.timeToPay = val.paymentTarget;
        this.inputVal.discount = val.discount;
        this.inputVal.discountTime = val.discountTime;
        //use function in invoice form to set data in template
        this.$emit("useContactData");
      }
    },
    //build Adress like the backend
    buildAddress(contact) {
      let adressResult;
      //if document is invoice + invoice adresse set, use it else use normal adress
      if (this.value.invoicePos && contact.invoiceAddress.active) {
        adressResult = contact.invoiceAddress;
        this.invoiceAddressActive = true;
      } else {
        adressResult = contact.address;
      }

      let result = "";

      //build contact name according to contact type
      let name =
        contact.type == "Company"
          ? contact.name
          : contact.surname + " " + contact.familyname;

      name +=
        contact.type == "Person" && contact.name
          ? "<p>" + contact.name + " "
          : "";

      contact.name2 ? (name += " " + contact.name2) : "";

      result += "<p>" + name + "</p>";

      //add street if possible
      adressResult.street
        ? (result += "<p>" + adressResult.street + "</p>")
        : "";

      //add zip and city
      adressResult.zip && adressResult.city
        ? (result +=
            "<p>" + adressResult.zip + " " + adressResult.city + "</p>")
        : "";

      //add country
      //adressResult.country? result += '<p>' + adressResult.country + '</p>': '';

      return result;
    }
  }
};
</script>
