const getters = {
    eventsLoading: state => state.loading,
    eventsData: state => state.events,
    eventData: state => state.event,
    nextEventId: state => state.nextEventId,

    eventsDatesLoading: state => state.loadingDates,
    eventsDatesData: state => state.eventsDates,

    resourcesData: state => state.resourcesList.data,
    resourcesStatusPending: state => state.resourcesList.status.pending,

    workerData: state => state.workerList.data,
    workerStatusPending: state => state.workerList.status.pending,
   
    currentEventList: state => state.currentEventList
}

export default getters;