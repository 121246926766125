import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { StoreUtil } from "../utils";

const state = {
    requestCount: StoreUtil.state(),
}

const activityModule = {
    state,
    mutations,
    actions,
    getters
}

export default activityModule;