import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VBtn,{attrs:{"bottom":"","color":"secondary","dark":"","fab":"","fixed":"","right":""},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_c(VIcon,[_vm._v("mdi-settings")])],1),_c(VDialog,{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1),_c(VToolbarTitle,[_vm._v("Admin - Einstellungen")]),_c('div',{staticClass:"flex-grow-1"})],1),_c(VCard,{attrs:{"ma-2":""}},[_c(VTabs,[_c(VTab,[_vm._v("Einstellungen")]),_c(VTabItem,[_c('adminSettings')],1),_c(VTab,[_vm._v("Error Log")]),_c(VTabItem,[_c('errorLog')],1),_c(VTab,[_vm._v("API-Key")]),_c(VTabItem,[_c('apiKeyAdmin')],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }