import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { StoreUtil } from "../utils";

const state = {
  netRevenueData: StoreUtil.state(),
  brutRevenueData: StoreUtil.state(),
  workloadData: StoreUtil.state(),
  revenueContactData: StoreUtil.state()
}

const analyticsModule = {
  state,
  mutations,
  actions,
  getters
}

export default analyticsModule;