<template>
   <v-card class="mt-3" height="100%" color="primary" dark>
    <v-card-title class="headline"
      >{{ count }}
      <v-spacer></v-spacer>
      <v-icon large right>
        {{ icon }}
      </v-icon>
    </v-card-title>

    <v-card-subtitle
      ><b>{{ header }}</b></v-card-subtitle
    >

    <v-card-actions>
      <v-btn :to="link" text>Übersicht</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  props: ["type"],
  data: () => ({
    name: "analyticsCard",
    header: "",
    link: "",
    count: "",
    icon: ""
  }),
  mounted() {
    this.initialize();
  },
  computed: {
    ...mapGetters(["backendServer"])
  },
  methods: {
    initialize() {
      let getURL = this.backendServer;
      let config = {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      };

      if (this.type == "Project") {
        this.header = "Aktive Projekte";
        this.link = "/projects";
        this.icon = "ballot";
        getURL += "analytics/projects/open";
      } else if (this.type == "DeliveryNote") {
        this.header = "Offene Lieferscheine";
        this.link = "/documents/1/lieferscheine";
        this.icon = "file_copy";
        getURL += "analytics/deliveryNotes/open";
      } else {
        this.header = "Offene Rechnungen";
        this.link = "/documents/2/rechnungen";
        this.icon = "file_copy";
        getURL += "analytics/invoices/open";
      }

      axios
        .get(getURL, config)
        .then(response => {
          this.count = response.data.count;
        })
        .catch(e => {
          this.$store.dispatch("triggerError", e);
        });
    }
  }
};
</script>
