import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(" Achtung falsche/ fehlende Dokumentennummer erkannt!")]),_c(VCardText,[_vm._v(" Bitte korrigieren sie die Nummer im Betreff durch die unten angezeigte Nummer. "),_c('br'),_c('h4',[_vm._v("Nächste Dokumentennummer: "+_vm._s(_vm.activateCheckTitleDialog.nextNumber))]),_c('br'),_c(VCol,{attrs:{"cols":"12","sm":"6","md":"10"}},[_c(VTextField,{attrs:{"label":"Betreff","data-cy":"header","dense":"","outlined":""},model:{value:(_vm.item.header),callback:function ($$v) {_vm.$set(_vm.item, "header", $$v)},expression:"item.header"}})],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"secondary","text":"","data-cy":"cancelEvent"},on:{"click":function($event){return _vm.close()}}},[_vm._v("Abbrechen")]),_c(VBtn,{attrs:{"color":"secondary","text":"","data-cy":"saveEvent"},on:{"click":function($event){return _vm.continueFunction()}}},[_vm._v("Jetzt prüfen und speichern")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }