<template>
  <div>
    <v-tabs>
      <v-tab>
        Info
      </v-tab>
      <v-tab>
        Mail
      </v-tab>
      <v-tab>
        Texte
      </v-tab>
      <v-tab>
        Lieferscheinpositionen
      </v-tab>
      <v-tab>
        Lieferscheinpositionen Vorlagen
      </v-tab>
      <v-tab>
        Nummernkreise
      </v-tab>
      <v-tab v-if="license.modules.sevdesk">
        Sevdesk
      </v-tab>
      <v-tab v-if="license.modules.datev">
        Datev
      </v-tab>
      <v-tab v-if="license.modules.customerPortal">
        Kundenportal
      </v-tab>
      <v-tab-item>
        <companyData />
      </v-tab-item>
      <v-tab-item>
        <emailDataForm></emailDataForm>
      </v-tab-item>
      <v-tab-item>
        <textSettings></textSettings>
      </v-tab-item>
      <v-tab-item>
        <defaultPositions></defaultPositions>
      </v-tab-item>
       <v-tab-item>
        <positionTemplates></positionTemplates>
      </v-tab-item>
      <v-tab-item>
        <numberSettings></numberSettings>
      </v-tab-item>
      <v-tab-item v-if="license.modules.sevdesk">
        <sevdeskDataForm></sevdeskDataForm>
      </v-tab-item>
      <v-tab-item v-if="license.modules.datev">
        <datevDataForm></datevDataForm>
      </v-tab-item>
      <v-tab-item v-if="license.modules.customerPortal">
        <customerPortalForm></customerPortalForm>
      </v-tab-item>
    </v-tabs>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import emailDataForm from "./../../components/license/mailForm.vue";
import sevdeskDataForm from "./../../components/license/sevdeskForm.vue";
import datevDataForm from "./../../components/license/datevForm.vue";
import companyData from "./companyData.vue";
import textSettings from "./textSettings.vue";
import defaultPositions from "./defaultPositions.vue";
import positionTemplates from "./positionTemplates.vue"
import numberSettings from "./numberSettings.vue";
import customerPortalForm from "./../../components/license/customerPortalForm.vue";

export default {
  data: () => ({
    settings: [],
    license: {}
  }),
  components: {
    emailDataForm,
    sevdeskDataForm,
    datevDataForm,
    companyData,
    textSettings,
    defaultPositions,
    positionTemplates,
    numberSettings,
    customerPortalForm
  },
  computed: {
    ...mapGetters(["companyData", "version", "licenseData"])
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch("getCompanyData").then(result => {});
      this.$store.dispatch("getLicenseData").then(result => {});
    }
  },
  watch: {
    licenseData: {
      handler(val) {
        if (val.data[0]) {
          this.license = val.data[0].value;
        }
      }
    }
  }
};
</script>
