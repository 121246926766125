<template>
  <div style="height: 100%">
    <v-toolbar flat color="white">
      <v-toolbar-title>Projektbasierte Abrechnung</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-text-field
        v-model="search"
        label="Suche..."
        data-cy="search"
        single-line
        hide-details
        dense
        @keyup.enter.native="searchData"
      ></v-text-field>
      <v-btn text icon color="secondary" @click="searchData">
        <v-icon>search</v-icon>
      </v-btn>
      <div class="flex-grow-1"></div>
      <v-dialog v-model="dialog" max-width="950px">
        <template v-slot:activator="{ on }">
          <v-btn color="secondary" dark class="mb-2" v-on="on"
            >Neues Projekt</v-btn
          >
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="i.name"
                      label="Projektname"
                      data-cy="name"
                      :rules="requieredRule"
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="i.reference"
                      label="Referenz/Kostenstelle"
                      data-cy="reference"
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-row>
                      <v-autocomplete
                        v-model="i.contact"
                        :items="contactDataMap"
                        :loading="allStatusPendingWithoutPagnation"
                        hide-no-data
                        item-text="Description"
                        item-value="id"
                        label="Kunde"
                        data-cy="contact"
                        prepend-icon="mdi-database-search"
                        :rules="requieredRule"
                        dense
                        outlined
                      ></v-autocomplete>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn icon v-on="on">
                            <v-icon @click="addContact = true">add</v-icon>
                          </v-btn>
                        </template>
                        <span>Kontakt erstellen</span>
                      </v-tooltip>
                      <contactFormWrapper
                        v-if="addContact"
                        :dialog="addContact"
                        @contactClose="contactClose"
                      />
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <dateRangeForm
                      v-model="projectDate"
                      label="Projektzeitraum"
                      data-cy="projectDates"
                      prependIcon="event"
                    />
                  </v-col>
                </v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-model="i.description"
                    label="Notizen"
                    data-cy="description"
                    dense
                    outlined
                  ></v-textarea>
                </v-col>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="secondary" text @click="close">Abbrechen</v-btn>
            <v-btn color="secondary" text @click="save" data-cy="saveEvent"
              >Speichern</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :options.sync="projectListGetter.pagination"
      :server-items-length="projectListGetter.pagination.totalItems"
      :items="projectDataGetter"
      :loading="projectStatusPending"
      :sort-desc="['true', 'false']"
      item-key="id"
      :single-expand="singleExpand"
      :expanded="expanded"
      @click:row="clickedRow"
      class="pa-5"
      :footer-props="{
        itemsPerPageOptions: projectListGetter.pagination.rowsPerPageItems,
        itemsPerPageText: 'Zeilen pro Seite'
      }"
    >
      <template v-slot:no-data> keine Daten vorhanden </template>
      <template v-slot:expanded-item="{ headers, item }">
        <projectSubTable
          :itemTable="item"
          :headers="headers"
          @createPDF="createPDF"
          @openMailDialog="openMailDialog"
          @reload="initialize"
        ></projectSubTable>
      </template>
      <template v-slot:item.iconStatus="{ item }">
        <div v-if="item.status == 'Aktiv'">
          <v-icon color="secondary">assignment</v-icon>
          {{ item.status }}
        </div>
        <div v-if="item.status == 'Abgeschlossen'">
          <v-icon color="primary">mdi-clipboard-check</v-icon>
          {{ item.status }}
        </div>
        <div v-if="item.status == 'Versendet'">
          <v-icon color="secondary">send</v-icon>
          {{ item.status }}
        </div>
        <div v-if="item.status == 'Rechnung erzeugt'">
          <v-icon color="yellow darken-2">mdi-clipboard-check</v-icon>
          {{ item.status }}
        </div>
        <div v-if="item.status == 'Abgerechnet'">
          <v-icon color="green darken-2">mdi-clipboard-check</v-icon>
          {{ item.status }}
        </div>
      </template>
      <template v-slot:item.contactName="{ item }">
        <template v-if="item.contact.type == 'Company'">
          {{
            item.contact.name +
              " " +
              (item.contact.name2 ? item.contact.name2 : "")
          }}
        </template>
        <template v-else>
          {{
            item.contact.surname +
            " " +
            item.contact.familyName +
            " " +
            item.contact.name2
              ? item.contact.name2
              : ""
          }}
        </template>
      </template>
      <template v-slot:item.action="{ item }">
        <v-dialog v-model="deleteDialog" max-width="500">
          <v-card>
            <loading
              :active.sync="projectStatusPending"
              :can-cancel="false"
              :is-full-page="true"
            ></loading>
            <v-card-title class="headline">Datensatz löschen?</v-card-title>
            <v-card-text>
              Soll das Projekt "{{ deleteDialogItem.name }}" wirklich gelöscht
              werden?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="deleteDialog = false"
                >Abbrechen</v-btn
              >
              <v-btn
                color="secondary"
                text
                @click="deleteItem(deleteDialogItem)"
                data-cy="deleteEvent"
                >Jetzt löschen</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }" v-if="item.status == 'Aktiv'">
            <v-icon
              medium
              class="mr-2"
              v-on="on"
              @click="triggerendDialog(item)"
              data-cy="closeProject"
              >mdi-clipboard-check</v-icon
            >
          </template>
          <span>Projekt abschließen</span>
        </v-tooltip>
        <v-dialog v-model="endDialog" max-width="500">
          <v-card>
            <!--loading
            :active.sync="loadingDialog"
            :can-cancel="false"
            :is-full-page="true"
            ></loading-->
            <v-card-title class="headline">Projekt abschließen</v-card-title>
            <v-card-text
              >Soll das Projekt wirklich abgeschlossen werden?</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="endDialog = false"
                >Abbrechen</v-btn
              >
              <v-btn
                color="secondary"
                text
                @click="closeProject(item)"
                data-cy="closeEvent"
                >Jetzt abschließen</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-tooltip bottom>
          <template
            v-slot:activator="{ on }"
            v-if="item.status == 'Abgeschlossen' || item.status == 'Versendet'"
          >
            <v-icon
              medium
              class="mr-2"
              v-on="on"
              @click.stop="createInvoice(item)"
              >file_copy</v-icon
            >
          </template>
          <span>Rechnung erstellen</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon medium class="mr-2" v-on="on" @click.stop="createPDF(item)"
              >picture_as_pdf</v-icon
            >
          </template>
          <span>PDF erstellen</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              medium
              class="mr-2"
              v-on="on"
              @click.stop="triggerPreviewPdf(item)"
              data-cy="triggerPreview"
              >preview</v-icon
            >
          </template>
          <span>PDF Vorschau</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template
            v-slot:activator="{ on }"
            v-if="item.status == 'Abgeschlossen' || item.status == 'Versendet'"
          >
            <v-icon
              medium
              class="mr-2"
              v-on="on"
              @click.stop="openMailDialog(item)"
              >email</v-icon
            >
          </template>
          <span>Dokument per Email versenden</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template
            v-slot:activator="{ on }"
            v-if="item.status == 'Abgeschlossen'"
          >
            <v-icon
              medium
              class="mr-2"
              v-on="on"
              @click.stop="triggerReDialog(item)"
              data-cy="reactivate"
              >mdi-cached</v-icon
            >
          </template>
          <span>Projekt wieder aktivieren</span>
        </v-tooltip>
        <v-dialog v-model="reDialog" max-width="500">
          <v-card>
            <v-card-title class="headline"
              >Projekt wieder aktiveren</v-card-title
            >
            <v-card-text
              >Soll das Projekt wirklich wieder aktiviert werden?</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="reDialog = false"
                >Abbrechen</v-btn
              >
              <v-btn
                color="secondary"
                text
                @click="reProject(item)"
                data-cy="activateEvent"
                >Jetzt aktiveren</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }" v-if="item.status == 'Aktiv'">
            <v-icon
              medium
              class="mr-2"
              v-on="on"
              @click="editItem(item)"
              data-cy="edit"
              >edit</v-icon
            >
          </template>
          <span>Bearbeiten</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }" v-if="item.status == 'Aktiv'">
            <v-icon
              medium
              class="mr-2"
              v-on="on"
              @click="openDeleteItem(item)"
              data-cy="delete"
              >delete</v-icon
            >
          </template>
          <span>Löschen</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog v-model="loadPdf" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          PDF wird erstellt...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import { mapGetters } from "vuex";
import contactFormWrapper from "../contact/createContact/contactFormWrapper.vue";
import singleDateForm from "../_utils/singleDateForm.vue";
import dateRangeForm from "../_utils/dateRangeForm.vue";
import projectSubTable from "./projectSubTable.vue";
import axios from "axios";
import emailForm from "./projectEmailForm.vue";

export default {
  components: {
    Loading,
    contactFormWrapper,
    dateRangeForm,
    projectSubTable,
    emailForm
  },
  data: () => ({
    singleExpand: false,
    expanded: [],
    dialog: false,
    deleteDialog: false,
    deleteDialogItem: { name: null },
    endDialog: false,
    endDialogItem: null,
    reDialog: false,
    reDialogItem: null,
    search: "",
    snackbar: {
      visible: false,
      text: "Snackbar ..."
    },
    headers: [
      {
        text: "Status",
        value: "iconStatus",
        sortable: false
      },
      {
        text: "Projekt Nr.",
        value: "formattedProjectNumber",
        sortable: false
      },
      {
        text: "Bezeichnung",
        value: "name",
        sortable: false
      },
      {
        text: "Start",
        value: "formattedStart",
        sortable: false
      },
      {
        text: "Ende",
        value: "formattedEnd",
        sortable: false
      },
      {
        text: "Kontakt",
        value: "contactName",
        sortable: false
      },
      {
        text: "",
        value: "action",
        align: "right",
        sortable: false
      }
    ],
    editedIndex: -1,
    i: {
      taxRate: 0
    },
    defaultItem: {
      taxRate: 0
    },
    i: {},
    requieredRule: [v => !!v || "Dieses Feld wird benötigt"],
    valid: true,
    addContact: false,
    projectDate: [new Date(), new Date().setDate(new Date().getDate() + 2)],
    dateValid: false,
    loadPdf: false
  }),

  computed: {
    pagination: {
      get: function() {
        return this.$store.getters.projectListGetter.pagination;
      },
      set: function(value) {
        this.$store.commit("SET_PAGINATION_PROJECT", value);
      }
    },
    formTitle() {
      return this.editedIndex === -1 ? "Neues Projekt" : "Projekt bearbeiten";
    },
    ...mapGetters([
      "projectListGetter",
      "projectDataGetter",
      "projectStatusPending",
      "allListGetterWithoutPagnation",
      "allStatusPendingWithoutPagnation",
      "backendServer",
      "defaultMwst"
    ]),
    contactDataMap() {
      return this.createContactDataMap();
    }
  },
  watch: {
    pagination: {
      handler() {
        this.$store.dispatch("getProjectList", this.search);
      }
    },
    dialog(val) {
      val || this.close();
    },
    projectDate: {
      handler(val) {
        if (val) {
          if (val[0] && val[1]) {
            this.i.start = val[0];
            this.i.end = val[1];
            this.dateValid = true;
          }
        } else {
          this.dateValid = false;
        }
      }
    }
  },

  mounted() {
    this.initialize();
  },
  methods: {
    clickedRow(value) {
      let test = [];
      test.push(value);
      if (test[0] === this.expanded[0]) {
        this.expanded = [];
      } else {
        this.expanded = [];
        this.expanded.push(value);
      }
    },
    initialize() {
      this.$store.dispatch("getProjectList");
      this.$store.dispatch("getAllListWithoutPagination");
      //load mwst
      this.i.taxRate = this.defaultMwst;
      this.defaultItem.taxRate = this.defaultMwst;
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.i = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.projectDate = null;
      }, 300);
    },
    triggerendDialog(item) {
      this.endDialog = true;
      this.endDialogItem = item;
    },
    triggerReDialog(item) {
      this.reDialog = true;
      this.reDialogItem = item;
    },
    editItem(item) {
      this.i = Object.assign({}, item);
      this.projectDate = [item.start, item.end];
      this.editedIndex = 1;
      this.dialog = true;
    },
    closeProject(item) {
      this.endDialogItem.status = "Abgeschlossen";
      this.$store.dispatch("updateProject", this.endDialogItem);
      this.endDialog = false;
      this.endDialogItem = null;
    },
    reProject(item) {
      this.reDialogItem.status = "Aktiv";
      this.$store.dispatch("updateProject", this.reDialogItem);
      this.reDialog = false;
      this.reDialogItem = null;
    },
    searchData() {
      this.$store.dispatch("getProjectList", this.search);
    },
    openDeleteItem(item) {
      this.deleteDialog = true;
      this.deleteDialogItem = item;
    },
    deleteItem(item) {
      let vm = this;
      this.$store.dispatch("deleteProject", item).then(result => {
        vm.deleteDialog = false;
        vm.deleteDialogItem = { name: null };
      });
    },
    createContactDataMap() {
      return this.allListGetterWithoutPagnation.allList.data.map(contact => {
        let Description;
        if (contact.name) {
          if (contact.name2) {
            Description = contact.name + " " + contact.name2;
          } else {
            Description = contact.name;
          }
        } else {
          Description = contact.familyname;
        }

        contact.address.city != null
          ? (Description = Description + " | " + contact.address.city)
          : "";
        Description = Description + " - " + contact.customerNumber;
        const id = contact.id;
        return Object.assign({}, contact, {
          Description,
          id
        });
      });
    },
    contactClose(val) {
      const Description = val.name + " - " + val.customerNumber;
      const id = val.id;
      const contact = val;
      this.i.contact = Object.assign(contact, {
        Description,
        id
      });
      this.addContact = false;
    },
    save() {
      let vm = this;
      //check if two dates are present
      if (this.projectDate[0] && this.projectDate[1]) {
        this.i.start = this.projectDate[0];
        this.i.end = this.projectDate[1];
        this.dateValid = true;
      }
      if (this.editedIndex > -1) {
        if (vm.$refs.form.validate() && this.dateValid) {
          vm.$store.dispatch("updateProject", vm.i).then(result => {
            vm.close();
          });
        } else {
          this.$store.dispatch("triggerUniversalAlert", {
            text:
              "Achtung der Projektzeitraum muss mindestens zwei Tage betragen.",
            heading: "Fehler - Projekt erstellen",
            type: "warning"
          });
        }
      } else {
        if (vm.$refs.form.validate() && this.dateValid) {
          vm.$store.dispatch("addProject", vm.i).then(result => {
            vm.close();
          });
        } else {
          this.$store.dispatch("triggerUniversalAlert", {
            text:
              "Achtung der Projektzeitraum muss mindestens zwei Tage betragen.",
            heading: "Fehler - Projekt erstellen",
            type: "warning"
          });
        }
      }
    },
    createPDF(item) {
      //create pdf for download
      this.loadPdf = true;
      this.getBase64PDF(item).then(function(result) {
        const linkSource = `data:application/pdf;base64,${result}`;
        const downloadLink = document.createElement("a");
        const fileName =
          "Leistungsübersicht Projekt " + item.formattedProjectNumber + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });
    },
    getBase64PDF(item) {
      let vm = this;
      let postUrl = this.backendServer + "project/" + item.id + "/base64";
      let config = {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      };
      return axios
        .get(postUrl, config)
        .then(function(response) {
          vm.loadPdf = false;
          return response.data.pdf;
        })
        .catch(function(e) {
          vm.$store.dispatch("triggerError", e);
          vm.loadPdf = false;
        });
    },
    openMailDialog(item) {
      const header = "Leistungsübersicht Projekt Nr. " + item.objectId;
      this.$store.dispatch("getDeliveryNoteText").then(res => {
        this.$router.push({
          name: "sendEmail",
          params: {
            document: JSON.stringify(item),
            doctype: "project",
            defaultText: res.value.mail,
            header: JSON.stringify(header)
          }
        });
      });
    },
    triggerPreviewPdf(item) {
      this.$router.push({
        name: "documentDetails",
        params: { id: item.id, from: "projekte" }
      });
    },
    createInvoice(item) {
      this.$router.push({
        name: "invoiceEdit",
        params: {
          projectData: item,
          id: 1,
          tab: 2
        }
      });
    }
  }
};
</script>

<style>
.expanded__content {
  box-shadow: none !important;
  background: white !important;
}
</style>
