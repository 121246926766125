<template>
  <div style="height: 100%">
    <tableNavbar ref="tableNavbar" @changeDate="changeDate"></tableNavbar>
    <v-data-table
      :headers="headers"
      :items="data"
      :loading="eventsDatesLoading"
      :footer-props="{
        'items-per-page-options': [10, 15, 20]
      }"
      item-key="id"
      class="pa-5"
    >
      <template v-slot:no-data> keine Daten vorhanden </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }" v-if="item.deliveryNote">
            <v-icon
              medium
              class="mr-2"
              v-on="on"
              @click="openDeliveryNote(item.deliveryNote)"
              data-cy="open"
              >launch</v-icon
            >
          </template>
          <span>Lieferschein öffnen</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-if="item.eventColor == 'blue' || item.eventColor == 'grey'"
              medium
              class="mr-2"
              v-on="on"
              @click="createDeliveryNote(item)"
              data-cy="createDeliveryNote"
              >file_copy</v-icon
            >
          </template>
          <span>Lieferschein erstellen</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon medium class="mr-2" v-on="on" @click="copy(item.id)" data-cy="copy"
              >content_copy</v-icon
            >
          </template>
          <span>Auftrag kopieren</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }" v-if="item.eventColor == 'blue'">
            <v-icon medium class="mr-2" v-on="on" @click="editItem(item)" data-cy="edit">edit</v-icon>
          </template>
          <span>Bearbeiten</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon medium v-on="on" @click="triggerDeleteDialog(item)" data-cy="delete">delete</v-icon>
          </template>
          <span>Löschen</span>
        </v-tooltip>
      </template>
      <template v-slot:item.nrFormatted="{ item }">
        <div v-if="!item.past">
          {{ item.objectId }}
          <v-icon v-if="item.hasPhoto" medium disabled>photo_camera</v-icon>
        </div>
        <div v-else style="color: grey">
          {{ item.objectId }}
          <v-icon v-if="item.hasPhoto" medium disabled>photo_camera</v-icon>
        </div>
      </template>
      <template v-slot:item.statusFormatted="{ item }">
        <div v-if="item.status == 'Auftrag'">
          <div v-if="!item.past">
            <v-icon medium color="#1e88e5">radio_button_checked</v-icon> Auftrag
          </div>
          <div v-else style="color: grey">
            <v-icon medium color="#1e88e5">radio_button_checked</v-icon> Auftrag
          </div>
        </div>
        <div v-if="item.status == 'fertig' && item.eventColor == 'green'">
          <div v-if="!item.past">
            <v-icon medium color="#7cb342">attach_money</v-icon> LI erzeugt
          </div>
          <div v-else style="color: grey">
            <v-icon medium color="#7cb342">attach_money</v-icon> LI erzeugt
          </div>
        </div>
        <div v-if="item.status == 'fertig' && item.eventColor == 'grey'">
          <div v-if="!item.past">
            <v-icon medium color="#b8c2cc">done</v-icon> abgeschlossen
          </div>
          <div v-else style="color: grey">
            <v-icon medium color="#b8c2cc">done</v-icon> abgeschlossen
          </div>
        </div>
      </template>
      <template v-slot:item.nameFormatted="{ item }">
        <div v-if="!item.past">
          {{ item.name }}
        </div>
        <div v-else style="color: grey">
          {{ item.name }}
        </div>
      </template>
      <template v-slot:item.datesFormatted="{ item }">
        <div v-if="!item.past">
          <div
            v-if="
              item.formattedStartDate.substring(0, 10) ==
                item.formattedEndDate.substring(0, 10)
            "
          >
            {{ item.weekdayStart }}
            {{ item.formattedStartDate.substring(0, 10) }}
            {{ item.formattedStartDate.substring(10, 16) }} -
            {{ item.formattedEndDate.substring(10, 16) }}
          </div>
          <div v-else>
            {{ item.weekdayStart }} {{ item.formattedStartDate }} -
            {{ item.weekdayEnd }}
            {{ item.formattedEndDate }}
          </div>
        </div>
        <div v-else style="color: grey">
          <div
            v-if="
              item.formattedStartDate.substring(0, 10) ==
                item.formattedEndDate.substring(0, 10)
            "
          >
            {{ item.weekdayStart }}
            {{ item.formattedStartDate.substring(0, 10) }}
            {{ item.formattedStartDate.substring(10, 16) }} -
            {{ item.formattedEndDate.substring(10, 16) }}
          </div>
          <div v-else>
            {{ item.weekdayStart }} {{ item.formattedStartDate }} -
            {{ item.weekdayEnd }}
            {{ item.formattedEndDate }}
          </div>
        </div>
      </template>
      <template v-slot:item.contactFormatted="{ item }">
        <div v-if="!item.past">
          <div v-if="item.contact.type == 'Company'">
            {{ item.contact.name }} {{ item.contact.name2 }}
          </div>
          <div v-else>
            {{ item.contact.surname }} {{ item.contact.familyname }}
          </div>
        </div>
        <div v-else style="color: grey">
          <div v-if="item.contact.type == 'Company'">
            {{ item.contact.name }} {{ item.contact.name2 }}
          </div>
          <div v-else>
            {{ item.contact.surname }} {{ item.contact.familyname }}
          </div>
        </div>
      </template>
      <template v-slot:item.workersFormatted="{ item }">
        <div v-if="!item.past">
          <div v-for="item in item.workers" :key="item.id">
            {{ item.name }} - {{ item.short }}
          </div>
        </div>
        <div v-else style="color: grey">
          <div v-for="item in item.workers" :key="item.id">
            {{ item.name }} - {{ item.short }}
          </div>
        </div>
      </template>
      <template v-slot:item.vehicleFormatted="{ item }">
        <div v-if="!item.past">
          {{ item.vehicle.name }} | {{ item.vehicle.license }}
        </div>
        <div v-else style="color: grey">
          {{ item.vehicle.name }} | {{ item.vehicle.license }}
        </div>
      </template>
      <template v-slot:item.locationFormatted="{ item }">
        <div v-if="!item.past">
          {{ item.location }}
        </div>
        <div v-else style="color: grey">
          {{ item.location }}
        </div>
      </template>
    </v-data-table>
    <v-dialog v-model="deleteDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Datensatz löschen?</v-card-title>
        <v-card-text v-if="toDeleteItem">
          Soll der Datensatz "{{ toDeleteItem.name }}" wirklich gelöscht werden?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="deleteDialog = false">Abbrechen</v-btn>
          <v-btn color="secondary" text @click="deleteItem(toDeleteItem)" data-cy="deleteEvent"
            >Jetzt löschen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import { mapGetters } from "vuex";
import tableNavbar from "./tableNavbar.vue";
export default {
  components: {
    Loading,
    tableNavbar
  },
  data: () => ({
    schedulerDates: null,
    deleteDialog: false,
    toDeleteItem: null,
    search: "",
    snackbar: {
      visible: false,
      text: "Snackbar ..."
    },
    headers: [
      {
        text: "Auftragsnummer",
        align: "left",
        value: "nrFormatted",
        sortable: false
      },
      { text: "Status", value: "statusFormatted", sortable: false },
      { text: "Name", value: "nameFormatted", sortable: false },
      { text: "Zeit", value: "datesFormatted", sortable: false },
      { text: "Kunde", value: "contactFormatted", sortable: false },
      { text: "Fahrer", value: "workersFormatted", sortable: false },
      { text: "Fahrzeug", value: "vehicleFormatted", sortable: false },
      { text: "Auftragsort", value: "locationFormatted", sortable: false },
      { text: "", value: "action", align: "right", sortable: false }
    ],
    editedItem: null
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Neuer Artikel" : "Artikel bearbeiten";
    },
    data: {
      get: function() {
        let vm = this;
        let eventData = this.eventsDatesData;
        //filter events for new planer data
        if (this.schedulerDates && this.eventsDatesData) {
          eventData = this.filterEvents(
            this.eventsDatesData,
            this.schedulerDates[0],
            this.schedulerDates[1]
          );
        }
        //check whick events end date was in the past to set grey propety
        eventData.forEach(function(e) {
          if (Date.parse(e.endDate) < Date.parse(new Date())) {
            e.past = true;
          } else {
            e.past = false;
          }
          // get resources to events:
          vm.resourcesData.forEach(function(element) {
            if (e.resourceId == element.id) {
              e.vehicle = element;
            }
          });
        });
        return eventData;
      }
    },
    ...mapGetters(["eventsDatesData", "eventsDatesLoading", "resourcesData"])
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      //if preset data is set, use it
      if (this.$route.params.back != 1) {
        const backItem = JSON.parse(this.$route.params.back);
        /*this.$store.dispatch("queryItemsEventsWithDates", {
          start: backItem.dates[0],
          end:  backItem.dates[1],
          resource: backItem.resource,
          worker: backItem.worker
        });*/
      } else {
        // set default view mode
        this.$store.dispatch("queryItemsResources");

        /*this.$store.dispatch("queryItemsEventsWithDates", {
          start: start.toISOString(),
          end: end.toISOString(),
          resource: "",
          worker: ""
        });*/
      }
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
      this.$refs.form.reset();
    },
    changeDate(val, query) {
      this.schedulerDates = val;
      this.$store.dispatch("queryItemsEventsWithDates", {
        start: val[0].toISOString(),
        end: val[1].toISOString(),
        resource: query.resource,
        worker: query.worker
      });
    },
    editItem(item) {
      this.$refs.tableNavbar.editEvent(item);
    },
    triggerDeleteDialog(item) {
      this.toDeleteItem = item;
      this.deleteDialog = true;
    },
    deleteItem(item) {
      this.$store.dispatch("deleteEvent", item.id).then(() => {
        this.deleteDialog = false;
        this.toDeleteItem = null;
        //reload table
        this.$refs.tableNavbar.reloadDates();
      });
    },
    createDeliveryNote(item) {
      this.$store.dispatch("getSingleEvent", item.id).then(r => {
        if (r.eventColor == "grey" && r.status == "fertig") {
          this.$router.push({
            name: "deliveryNoteEdit",
            params: { eventData: r, tab: 1, id: "1" }
          });
        } else {
          //create delivery note when event is not signed via app
          r.eventColor = "green";
          r.status = "fertig";
          this.$router.push({
            name: "deliveryNoteEdit",
            params: { eventData: r, tab: 1, id: "1" }
          });
        }
      });
    },
    openDeliveryNote(id) {
      this.$router.push({
        name: "documentDetails",
        params: { id: id, from: "lieferscheine" }
      });
    },
    copy(id) {
      this.$router.push({
        name: "eventForm",
        params: {
          id: id,
          mode: "copy",
          back: JSON.stringify({
            to: "planerTable",
            data: {
              dates: [],
              resource: this.selectResource,
              worker: this.selectWorker
            }
          })
        }
      });
    },
    filterEvents(events, start, end) {
      return events.filter(event => {
        return (
          (new Date(event.startDate) >= start && new Date(event.endDate) <= end) ||
          (new Date(event.startDate) <= start && new Date(event.endDate) >= start) ||
          (new Date(event.startDate) <= end && new Date(event.endDate) >= end) ||
          (new Date(event.startDate) <= start && new Date(event.endDate) >= end)
        );
      });
    }
  }
};
</script>
<style>
.expanded__content {
  box-shadow: none !important;
  background: white !important;
}
</style>
