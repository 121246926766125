import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { StoreUtil } from "../utils";

const state = {
  events: [],
  eventsDates: [],
  event: null,
  nextEventId: null,
  eventList: StoreUtil.state(),
  loading: false,
  loadingDates: false,
  resourcesList: StoreUtil.state(),
  workerList: StoreUtil.state(),
  currentEventList: StoreUtil.state()
}

const eventsModule = {
  state,
  mutations,
  actions,
  getters
}

export default eventsModule;