import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"persistent":"","max-width":"550"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(" Ungespeicherte Daten ")]),_c(VCardText,[_c(VAlert,{attrs:{"dense":"","border":"left","type":"error"}},[_vm._v(" Achtung. Manche Änderungen wurden noch nicht gespeichert. Möchten Sie diese Änderungen wirklich verwerfen? ")])],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"secondary","text":""},on:{"click":function($event){return _vm.cancel(false)}}},[_vm._v("Zurück zur Bearbeitung")]),_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":function($event){return _vm.cancel(true)}}},[_vm._v("Änderungen verwerfen")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }