import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VList,{attrs:{"flat":"","subheader":"","three-line":""}},[_c(VListItem,[_c(VListItemContent,[_c(VContainer,[_c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"900"}},[_c(VForm,{ref:"form",staticClass:"pa-10",attrs:{"lazy-validation":""}},[_c('p',[_vm._v("Vordefinierte Lieferscheinpositionen festlegen")]),_c('h5',[_vm._v(" Die folgenden Lieferscheinpositionen werden bei allen Aufträgen standardmäßig vorausgewählt. ")]),_c('br'),_c(VRow,[_c('itemPosForm',{attrs:{"prices":false},model:{value:(_vm.presetPos),callback:function ($$v) {_vm.presetPos=$$v},expression:"presetPos"}})],1)],1),_c(VCardActions,[_c('div',{staticClass:"flex-grow-1"}),_c(VBtn,{attrs:{"color":"secondary","text":""},on:{"click":_vm.save}},[_vm._v("Speichern")])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }