<template>
  <v-card>
    <v-card-title>Fahrzeuge Übersicht</v-card-title>
    <v-simple-table :height="300" class="ma-3">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Fahrzeug</th>
            <th class="text-left">Kennzeichen</th>
            <th class="text-left">Aktueller Einsatz</th>
            <th class="text-left">Beginn</th>
            <th class="text-left">Ende</th>
            <th class="text-left">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.resource.name">
            <td>{{ item.resource.name }}</td>
            <td>{{ item.resource.license }}</td>
            <td>{{ item.event.name }}</td>
            <td>{{ item.event.formattedStartDate }}</td>
            <td>{{ item.event.formattedEndDate }}</td>
            <td>
              <v-btn
                outlined
                color="#fbc02d"
                to="/planer/1/planer/1/1/1/1"
                v-if="item.event.status == 'Leerlauf'"
                >Leerlauf</v-btn
              >
              <v-btn
                outlined
                color="#7cb342"
                to="/planer/1/planer/1/1/1/1"
                v-if="item.event.status == 'Auftrag' || item.event.status == 'fertig'"
                >Auftrag</v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>
<script>
import Loading from "vue-loading-overlay";
import { mapGetters } from "vuex";

export default {
  name: "eventTable",
  components: {
    Loading
  },
  props: [],
  data: () => ({
    items: null
  }),
  created() {
    this.initialze();
  },
  computed: {
    ...mapGetters(["currentEventList"])
  },
  watch: {
    currentEventList: {
      handler(val) {
        if (val.data.length != 0) {
          this.items = val.data;
        }
      }
    }
  },
  methods: {
    initialze() {
      this.$store.dispatch("getCurrentEventList");
    }
  }
};
</script>
