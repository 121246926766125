import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"mt-3",attrs:{"height":"100%","color":"primary","dark":""}},[_c(VCardTitle,{staticClass:"headline"},[_vm._v(_vm._s(_vm.count)+" "),_c(VSpacer),_c(VIcon,{attrs:{"large":"","right":""}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1),_c(VCardSubtitle,[_c('b',[_vm._v(_vm._s(_vm.header))])]),_c(VCardActions,[_c(VBtn,{attrs:{"to":_vm.link,"text":""}},[_vm._v("Übersicht")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }