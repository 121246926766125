<template>
  <div>
    <v-toolbar>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon :to="backUrl" data-cy="back">
            <v-icon v-on="on">arrow_back</v-icon>
          </v-btn>
        </template>
        <span>zurück</span>
      </v-tooltip>
      <v-toolbar-title>{{ emailHeader }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        icon
        class="mr-2"
        v-if="this.itemType == 'Lieferschein' && item.event.hasPhoto"
      >
        <v-icon color="primary" @click="openPhotoDialog = true"
          >photo_camera</v-icon
        >
      </v-btn>

      <v-dialog v-model="openPhotoDialog" max-width="1400px" persistent>
        <photoDialog @close="closePhotoDialog" :editItem="item.event" />
      </v-dialog>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon class="mr-4" v-on="on" @click="printDocumentDialog = true"
            >print</v-icon
          >
        </template>
        <span>Dokument drucken</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon class="mr-4" v-on="on" @click="createPDF()"
            >picture_as_pdf</v-icon
          >
        </template>
        <span>PDF herunterladen</span>
      </v-tooltip>

      <!-- Only show letter option when not a project -->
      <!--div v-if="itemType != 'Projekt'">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              class="mr-4"
              :disabled="!pdf.numPages"
              v-on="on"
              @click="viaPostDocumentDialog = true"
              >markunread_mailbox</v-icon
            >
          </template>
          <span>Dokument per Post versenden</span>
        </v-tooltip>
      </div-->

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon class="mr-4" v-on="on" @click="openMailDialog()"
            >email</v-icon
          >
        </template>
        <span>Dokument per Email versenden</span>
      </v-tooltip>

      <v-tooltip bottom v-if="!hideEdit">
        <template v-slot:activator="{ on }">
          <v-icon
            class="mr-4"
            v-on="on"
            @click="editItem()"
            :disabled="
              status === 'Exportiert' ||
                status === 'Rechnung erzeugt' ||
                status === 'zu Projekt hinzugefügt'
            "
            >edit</v-icon
          >
        </template>
        <span>Bearbeiten</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            class="mr-4"
            v-on="on"
            @click="deleteDialog = true"
            :disabled="
              status === 'Exportiert' ||
                status === 'Versendet' ||
                status === 'Rechnung erzeugt' ||
                status === 'zu Projekt hinzugefügt'
            "
            >delete</v-icon
          >
        </template>
        <span>Löschen</span>
      </v-tooltip>
      <v-tooltip bottom v-if="doctype != 'deliveryNote'">
        <template v-slot:activator="{ on }">
          <v-icon
            class="mr-4"
            v-on="on"
            @click="copyItem()"
            :disabled="status === 'zu Projekt hinzugefügt'"
            >content_copy</v-icon
          >
        </template>
        <span>Dokument kopieren</span>
      </v-tooltip>
      <v-dialog v-model="loadPdfDialog" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            PDF wird erstellt...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-dialog v-model="deleteDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Datensatz löschen?</v-card-title>
        <v-card-text>{{ deleteMessage }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="deleteDialog = false"
            >Abbrechen</v-btn
          >
          <v-btn color="secondary" text @click="deleteItem()"
            >Jetzt löschen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Print Document dialog -->
    <v-dialog v-model="printDocumentDialog" width="600">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Dokument drucken
        </v-card-title>

        <v-card-text>
          <v-row class="d-flex justify-space-between mb-6 mt-6">
            <v-btn
              class="mx-auto"
              width="500px"
              outlined
              dense
              color="primary"
              @click="printDocument('draft')"
              >Ohne Änderung des Status (zb. Entwurf) Drucken</v-btn
            >
          </v-row>
          <v-row class="d-flex justify-space-between mb-6 mt-6">
            <v-btn
              class="mx-auto"
              width="500px"
              dense
              color="primary"
              target="_blank"
              @click="printDocument('send')"
              >Drucken und als versendet markieren</v-btn
            ></v-row
          >
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="printDocumentDialog = false">
            zurück
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Send via Post Document dialog -->
    <v-dialog v-model="viaPostDocumentDialog" width="900">
      <sendLetter
        :item="item"
        :pdfPages="pdf.numPages"
        :doctype="doctype"
        @close="closeLetterDialog"
      />
    </v-dialog>
    <div class="pt-4 pb-4">
      <v-btn @click="decreasePage" icon small color="secondary">
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-btn @click="increasePage" icon small color="secondary">
        <v-icon>chevron_right</v-icon>
      </v-btn>
      {{ pdf.page }}/{{ pdf.numPages }}
      <v-row>
        <v-col cols="12" sm="12" md="3"> </v-col>
        <v-col cols="12" sm="12" md="5">
          <pdf
            v-if="pdf.src"
            ref="pdf"
            style="border: 1px solid LightGray"
            :src="pdf.src"
            :page="pdf.page"
            @progress="pdf.loadedRatio = $event"
            @error="pdfError"
            @num-pages="pdf.numPages = $event"
            @link-clicked="pdf.page = $event"
          ></pdf>
        </v-col>
        <v-col cols="12" sm="12" md="3">
          <v-card
            ><v-list>
              <div>
                <v-list-item>
                  <v-list-item-action
                    ><div v-if="item.status == 'Entwurf'">
                      <v-icon color="grey darken-2">assignment</v-icon>
                    </div>
                    <div v-if="item.status == 'Abgerechnet'">
                      <v-icon color="green darken-2"
                        >mdi-clipboard-check</v-icon
                      >
                    </div>
                    <div
                      v-if="
                        item.status == 'Rechnung erzeugt' ||
                          item.status == 'zu Projekt hinzugefügt'
                      "
                    >
                      <v-icon color="yellow darken-2">file_copy</v-icon>
                    </div>
                    <div v-if="item.status == 'Versendet'">
                      <v-icon color="secondary">send</v-icon>
                    </div>
                    <div v-if="item.status == 'Storniert'">
                      <v-icon color="red darken-2">clear</v-icon>
                    </div>
                    <div v-if="item.status == 'Exportiert'">
                      <v-icon color="green darken-2"
                        >mdi-clipboard-check</v-icon
                      >
                    </div>
                    <div v-if="item.status == 'Auftrag erhalten'">
                      <v-icon color="green darken-2">done</v-icon>
                    </div>
                    <div v-if="item.status == 'Angebot abgelehnt'">
                      <v-icon color="red darken-2">clear</v-icon>
                    </div>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.status }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Status</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
              <div v-if="sourceDocumentAvail.status">
                <v-list-item>
                  <v-list-item-content>
                    <v-btn
                      outlined
                      color="secondary"
                      @click="openSourceDocument(item)"
                      >{{ sourceDocumentAvail.message }}</v-btn
                    >
                  </v-list-item-content>
                </v-list-item>
              </div>
              <div v-if="itemType != 'Projekt'">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>file_copy</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.header }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
              <div v-if="itemType != 'Projekt' && item.project">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>ballot</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title
                      >Projekt-{{ item.project.objectId }} |
                      {{ item.project.name }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </div>
              <div v-if="item.contact">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>business</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{ contactName }}</v-list-item-title>
                    <v-list-item-subtitle>Kunde</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
              <div v-if="itemType != 'Lieferschein' && itemType != 'Projekt'">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>euro_symbol</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title data-cy="netTotal"
                      >{{
                        item.netTotal
                          .toFixed(2)
                          .toString()
                          .replace(".", ",")
                      }}
                      EUR</v-list-item-title
                    >
                    <v-list-item-subtitle>Betrag (netto)</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
              <div v-if="itemType != 'Lieferschein' && itemType != 'Projekt'">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon></v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title data-cy="brutTotal"
                      >{{
                        item.brutTotal
                          .toFixed(2)
                          .toString()
                          .replace(".", ",")
                      }}
                      EUR</v-list-item-title
                    >
                    <v-list-item-subtitle>Betrag (brutto)</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
              <div v-if="itemType === 'Rechnung' && item.timeToPay">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>account_balance</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title data-cy="timeToPay"
                      >{{ item.timeToPay }} Tage</v-list-item-title
                    >
                    <v-list-item-subtitle>Zahlungsziel</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
              <div v-if="itemType === 'Rechnung' && item.discount">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon></v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title
                      >{{ item.discount }}% innerhalb von
                      {{ item.discountTime }} Tagen</v-list-item-title
                    >
                    <v-list-item-subtitle>Skonto</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
              <div v-if="activities.length > 0">
                <v-divider inset></v-divider>
                <v-subheader inset><b>Ereignisse:</b></v-subheader>

                <v-list-item v-for="liItem in activities" :key="liItem.id">
                  <v-list-item-action> </v-list-item-action>

                  <v-list-item-content>
                    <p>{{ liItem.description }}</p>
                    <v-list-item-subtitle
                      >{{ liItem.formattedCreatedAt }}Uhr |
                      {{ liItem.user.name }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="2"> </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
import pdf from "vue-pdf";
import photoDialog from "../planer/components/eventForm/photoDialog.vue";
import sendLetter from "./sendLetter.vue";
export default {
  components: {
    pdf,
    photoDialog,
    sendLetter
  },
  data: () => ({
    item: {
      event: {
        photos: []
      }
    },
    activities: [],
    itemType: null,
    backUrl: null,
    status: null,
    deleteUrl: null,
    updateUrl: null,
    defaultText: null,
    pdf: {
      src: null,
      loadedRatio: 0,
      page: 1,
      numPages: 0,
      rotate: 0
    },
    doctype: null,
    deleteDialog: false,
    emailHeader: null,
    editedItem: null,
    loadPdfDialog: false,
    openPhotoDialog: false,
    printDocumentDialog: false,
    viaPostDocumentDialog: false,
    hideEdit: false,
    deleteMessage: null,
    sourceDocumentAvail: { status: false, message: "Quelldokument anzeigen" }
  }),
  mounted() {
    this.mountedMethod(this.$route.params.id);
  },
  computed: {
    ...mapGetters(["backendServer"]),
    contactName() {
      let Description;
      const contact = this.item.contact;
      if (contact.name) {
        if (contact.name2) {
          Description = contact.name + " " + contact.name2;
        } else {
          Description = contact.name;
        }
      } else {
        Description = contact.familyname;
      }

      return Description;
    }
  },
  watch: {
    "$route.params": function(params) {
      this.mountedMethod(params.id);
    }
  },
  methods: {
    mountedMethod(id) {
      //get activities
      this.$store.dispatch("getActivityFromId", id).then(r => {
        this.activities = r;
      });

      switch (this.$route.params.from) {
        case "lieferscheine":
          this.$store.dispatch("getSingleDeliveryNote", id).then(r => {
            this.item = r;
            this.status = r.status;
            this.itemType = "Lieferschein";
            this.backUrl = "/documents/1/lieferscheine";
            this.emailHeader =
              "Lieferschein LI-" + this.item.deliveryNoteNumber;
            this.doctype = "deliveryNote";
            this.deleteUrl = "deleteDeliveryNotes";
            this.deleteMessage =
              "Soll der Lieferschein wirklich gelöscht werden?";
            this.updateUrl = "updateDeliveryNotes";
            this.sourceDocumentAvail = {
              status: false,
              message: "Quelldokument anzeigen"
            };
            this.$store.dispatch("getDeliveryNoteText").then(res => {
              this.defaultText = res.value.mail;
            });
            //load pdf
            this.loadPdf(id);
            //add invoice reference button
            if (r.invoice != null) {
              this.sourceDocumentAvail = {
                status: true,
                message:
                  "Rechnung " + r.invoice.formattedInvoiceNumber + " anzeigen"
              };
            }
          });
          break;
        case "rechnungen":
          this.$store.dispatch("getSingleInvoice", id).then(r => {
            this.item = r;
            this.status = r.status;
            this.itemType = "Rechnung";
            this.backUrl = "/documents/2/rechnungen";
            this.emailHeader = "Rechnung RE-" + this.item.invoiceNumber;
            this.doctype = "invoice";
            this.deleteUrl = "deleteInvoice";
            this.deleteMessage = "Soll die Rechnung wirklich gelöscht werden?";
            this.updateUrl = "updateInvoice";
            this.sourceDocumentAvail = {
              status: false,
              message: "Quelldokument anzeigen"
            };
            this.$store.dispatch("getInvoiceText").then(res => {
              this.defaultText = res.value.mail;
            });
            //check if source document is availiable
            if (r.deliveryNote || r.project) {
              this.sourceDocumentAvail = {
                status: true,
                message: "Quelldokument anzeigen"
              };
            }
            //load pdf
            this.loadPdf(id);
          });
          break;
        case "angebote":
          this.$store.dispatch("getSingleOffer", id).then(r => {
            this.item = r;
            this.status = r.status;
            this.itemType = "Angebot";
            this.backUrl = "/documents/3/angebote";
            this.emailHeader = "Angebot AN-" + this.item.offerNumber;
            this.doctype = "offer";
            this.deleteUrl = "deleteOffer";
            this.deleteMessage = "Soll das Angebot wirklich gelöscht werden?";
            this.updateUrl = "updateOffers";
            this.sourceDocumentAvail = {
              status: false,
              message: "Quelldokument anzeigen"
            };
            this.$store.dispatch("getOfferText").then(res => {
              this.defaultText = res.value.mail;
            });
            //load pdf
            this.loadPdf(id);
          });
          break;
        case "projekte":
          this.$store.dispatch("getSingleProject", id).then(r => {
            this.item = r;
            this.status = r.status;
            this.hideEdit = true;
            this.itemType = "Projekt";
            this.backUrl = "/projects";
            this.emailHeader =
              "Leistungsübersicht Projekt PR-" + this.item.objectId;
            this.doctype = "project";
            this.deleteUrl = "deleteProject";
            this.deleteMessage = "Soll das Projekt wirklich gelöscht werden?";
            this.updateUrl = "updateProject";
            this.sourceDocumentAvail = {
              status: false,
              message: "Quelldokument anzeigen"
            };
            this.$store.dispatch("getDeliveryNoteText").then(res => {
              this.defaultText = res.value.mail;
            });
            //load pdf
            this.loadPdf(id);
          });
          break;
      }
    },
    pdfError: function(err) {
      this.$store.dispatch("triggerError", err);
    },
    increasePage() {
      if (this.pdf.page < this.pdf.numPages) {
        this.pdf.page++;
      }
    },
    decreasePage() {
      if (this.pdf.page > 1) {
        this.pdf.page--;
      }
    },
    loadPdf(id) {
      let vm = this;
      vm.loadPdfDialog = true;
      let config = {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      };
      let postUrl = this.backendServer + this.doctype + "/" + id + "/base64";
      return axios
        .get(postUrl, config)
        .then(function(response) {
          vm.pdf.src = "data:application/pdf;base64," + response.data.pdf;
          vm.loadPdfDialog = false;
          return vm.pdf.src;
        })
        .catch(function(e) {
          vm.loadPdfDialog = false;
          vm.$store.dispatch("triggerError", e);
        });
    },
    deleteItem() {
      let vm = this;
      this.$store.dispatch(this.deleteUrl, this.item).then(result => {
        vm.deleteDialog = false;
        this.$router.push({
          path: this.backUrl
        });
      });
    },
    editItem() {
      this.$router.push({
        path: this.backUrl + "/edit/" + this.item.id + "/edit"
      });
    },
    copyItem() {
      this.$router.push({
        path: this.backUrl + "/edit/" + this.item.id + "/copy"
      });
    },
    openMailDialog(item) {
      this.$router.push({
        name: "sendEmail",
        params: {
          document: JSON.stringify(this.item),
          doctype: this.doctype,
          defaultText: this.defaultText,
          header: JSON.stringify(this.emailHeader)
        }
      });
    },
    createPDF() {
      let vm = this;
      //create pdf for download
      this.loadPdf(this.item.id).then(function(result) {
        const downloadLink = document.createElement("a");
        const fileName = vm.emailHeader + ".pdf";
        downloadLink.href = result;
        downloadLink.download = fileName;
        downloadLink.click();
      });
    },
    printDocument(val) {
      if (val === "draft") {
        //keep document as draft
        this.openPrintPdf();
      } else {
        //mark document as sended when status = Entwurf
        if (this.item.status === "Entwurf") {
          this.item.status = "Versendet";
          this.$store.dispatch(this.updateUrl, this.item).then(r => {
            //if invoice is printed, set delivery Note Status to "Abgerechnet" if delivery note is availiable
            if (
              this.$route.params.from === "rechnungen" &&
              this.item.deliveryNote != null
            ) {
              this.$store.dispatch("updateDeliveryNotes", {
                status: "Abgerechnet",
                id: this.item.deliveryNote.id
              });
            }
            this.openPrintPdf();
          });
        } else {
          //if status != Entwurf send warning
          this.openPrintPdf();
          this.$store.dispatch("triggerUniversalAlert", {
            text:
              "Der Status des Dokuments wird nicht verändert, da dieses Dokument nicht den Status Entwurf hat. Die Druckvorschau wird trotzdem geöffnet.",
            heading: "Dokument drucken - Fehler",
            type: "info"
          });
        }
      }
      //set print activity to document
      const postItemActivity = {
        model: this.capitalizeFirstLetter(this.doctype),
        object: this.item.id
      };
      this.$store.dispatch("sendActivityPrinted", postItemActivity);
    },
    openPrintPdf() {
      let vm = this;
      this.printDocumentDialog = false;
      this.loadPdf(this.item.id).then(function(result) {
        let pdfWindow = window.open("");
        pdfWindow.document.write(
          "<head><title>" +
            vm.emailHeader +
            "</title></head><iframe width='100%' height='100%' src='" +
            encodeURI(result) +
            "'></iframe>"
        );
      });
      this.printDocumentDialog = false;
    },
    closePhotoDialog() {
      this.openPhotoDialog = false;
    },
    closeLetterDialog() {
      //set status "versendet"
      this.item.status = "Versendet";
      this.viaPostDocumentDialog = false;
    },
    openSourceDocument(item) {
      switch (this.$route.params.from) {
        case "lieferscheine":
          this.$router.push({
            name: "documentDetails",
            params: { id: item.invoice.id, from: "rechnungen" }
          });
          break;
        case "rechnungen":
          if (item.deliveryNote) {
            this.$router.push({
              name: "documentDetails",
              params: { id: item.deliveryNote.id, from: "lieferscheine" }
            });
          } else {
            this.$router.push({
              name: "documentDetails",
              params: { id: item.project.id, from: "projekte" }
            });
          }
          break;
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  }
};
</script>
<style>
.address-field > p {
  margin: 0;
}
</style>
