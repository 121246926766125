<template>
  <v-footer padless color="primary">
    <v-row justify="center" no-gutters>
      <v-btn
        v-for="link in links"
        :key="link"
        color="white"
        text
        rounded
        class="mt-2"
        target="_blank"
        :href=link.link
      >
        {{ link.name }}
      </v-btn>
      <v-col class="py-4 text-center white--text" cols="12">
        &copy; {{ new Date().getFullYear() }} —
        <strong>onDispatch {{ version }}</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    links: [
      { name: "ondispatch.de", link: "https://ondispatch.de/" },
      { name: "Impressum", link: "https://ondispatch.de/impressum.html" },
      { name: "Datenschutz", link: "https://ondispatch.de/datenschutz.html" }
    ]
  }),
  computed: {
    ...mapGetters(["version"])
  }
};
</script>
