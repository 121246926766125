<template>
  <v-dialog v-model="dialog" max-width="800px" persistent>
    <contactEdit
      method="new"
      contactType="all"
      formTitle="Kontakt erstellen"
      @close="close"
      ref="contactEdit"
    ></contactEdit>
  </v-dialog>
</template>

<script>
//wrapper to integate the reate contact form in components like document forms + event forms

import contactEdit from "./contactEdit.vue";
export default {
  components: {
    contactEdit
  },
  props: ["dialog"],
  data: () => ({}),
  mounted() {},
  watch: {},
  methods: {
    close(val) {
      this.$emit("contactClose", val);
      this.$store.dispatch("getAllList");
    }
  }
};
</script>
