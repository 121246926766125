import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMenu,{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.dateRangeText,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.dateRangeText=$event},"update:return-value":function($event){_vm.dateRangeText=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VTextField,_vm._g({attrs:{"label":_vm.label,"data-cy":"dateRangeText","prepend-icon":_vm.prependIcon,"readonly":"","rules":_vm.requieredRule,"outlined":"","dense":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VDatePicker,{attrs:{"locale":"de-de","first-day-of-week":"1","range":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"secondary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v("Abbrechen")]),_c(VBtn,{attrs:{"text":"","color":"secondary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.dateRangeText)}}},[_vm._v("OK")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }