import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { StoreUtil } from "../utils";

// Définir les URLs d'API
const apiUrls = {
  "ofu": "https://ofu-api.ondispatch.de/",
  "lel": "https://lel-api.ondispatch.de/",
  "wen": "https://wen-api.ondispatch.de/",
  "obh": "https://obh-api.ondispatch.de/",
  "asf": "https://asf-api.ondispatch.de/",
  "dmk": "https://dmk-api.ondispatch.de/",
  "hcd": "https://hcd-api.ondispatch.de/",
  "ksh": "https://ksh-api.ondispatch.de/"
};

const getApiUrl = (instance) => {
  return apiUrls[instance] || '{{ API_URL }}'; 
};

const instanceName = window.location.hostname.split('.')[0];
const backendServer = getApiUrl(instanceName);

const state = {
  companyData: StoreUtil.state(),
  licenseData: StoreUtil.state(),
  mailData: StoreUtil.state(),
  sevdeskData: StoreUtil.state(),
  datevData: StoreUtil.state(),
  customerPortalData: StoreUtil.state(), 
  logo: StoreUtil.state(),
  deliveryNotePositionTemplates: StoreUtil.state(),
  version: "v4.5.12",
  supportMail: "support@ondispatch.de",
  backendServer: backendServer,
  errors: [],
  error: {
    visible: false,
    message: null
  },
  snackbar: {
    visible: false,
    message: null
  },
  universalAlert: {
    visible: false,
    text: null,
    heading: null,
    type: null
  },
  units: ["Stk", "m", "m2", "m3", "kg", "t", "km", "Std", "Tag", "ha", "L", "pauschal"],
  defaultMwst: 19,
};

const configModule = {
  state,
  mutations,
  actions,
  getters
};

export default configModule;
