<template>
  <v-app id="inspire">
    <navbar />
    <v-main>
      <router-view></router-view>
    </v-main>
    <cornerModal v-if="userRole == 'Admin'" />
    <appFooter />
  </v-app>
</template>

<script>
import navbar from "./styling/navbar.vue";
import appFooter from "./styling/footer.vue";
import cornerModal from "./styling/cornerModal.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    appFooter,
    navbar,
    cornerModal,
  },
  props: {
    source: String,
  },
  computed: {
    ...mapGetters(["userRole"]),
  },
  data: () => ({
    drawer: null,
  }),
};
</script>
<style lang="scss">
@import "./src/assets/sass/core-admin.scss";
</style>
