import { StoreUtil } from "../utils";
const mutations = {
  SET_PAGINATION_INVENTORY(state, payload) {
    state.inventoryData.pagination = payload;
  },
  RESET_PAGINATION_PAGES_INVENTORY(state) {
    state.inventoryData.pagination.page = 1;
    state.inventoryData.pagination.itemsPerPage = 10;
  },
  SET_TOTAL_INVENTORY_ALL(state, payload) {
    state.inventoryData.pagination.totalItems = payload;
  },
  SET_ALL_INVENTORY(state, payload) {
    state.inventoryData.inventoryList = StoreUtil.updateState(
      state.inventoryData.inventoryList,
      payload
    );
  },
  SET_INVENTORY_ADD(state, payload) {
    StoreUtil.updateState(state.inventoryData.inventoryList, payload);
  },
  SET_INVENTORY_UPDATE(state, payload) {
    StoreUtil.updateState(state.inventoryData.inventoryList, payload);
  },
  SET_INVENTORY_DELETE(state, payload) {
    StoreUtil.updateState(state.inventoryData.inventoryList, payload);
  },
};

export default mutations;
