<template>
  <v-row>
    <v-col cols="12" sm="6" md="4">
      <v-select
        v-model="repeatTime"
        :items="repeatTimes"
        label="Auftrag wiederholen"
        data-cy="repeatTime"
        dense
        outlined
      ></v-select>
    </v-col>
    <v-col cols="12" sm="6" md="4">
      <v-text-field
        v-model.number="maxRepeatTimes"
        label="Anzahl an Wiederholungen"
        data-cy="maxRepeatTimes"
        dense
        outlined
        type="Number"
      ></v-text-field
    ></v-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  props: ["eventToRepeat"],
  data: () => ({
    repeatTimes: ["keine Wiederholung", "täglich", "wöchentlich", "monatlich"],
    repeatTime: null,
    maxRepeatTimes: null,
    localCopyOfEvent: null
  }),
  mounted() {},
  watch: {
    repeatTime(val) {
      if (val == "keine Wiederholung") {
        this.$emit("mark", false);
        this.maxRepeatTimes = null;
      } else {
        this.$emit("mark", true);
        switch (val) {
          case "täglich":
            this.maxRepeatTimes = 7;
            break;
          case "wöchentlich":
            this.maxRepeatTimes = 4;
            break;
          case "monatlich":
            this.maxRepeatTimes = 2;
            break;
        }
      }
    }
  },
  computed: {
    ...mapGetters([])
  },
  methods: {
    checkRepeatingEvent() {
      if (this.repeatTime == null) {
        return false;
      } else {
        return true;
      }
    },
    createRepeatingEvents() {
      let vm = this;
      let dates = [];
      let baseDate = this.eventToRepeat.startDate;

      //check if event limit of 25 is reached
      if (this.maxRepeatTimes > 25) {
        vm.$store.dispatch("triggerUniversalAlert", {
          text:
            "Das Limit von 25 Aufträgen, welche gleichzeitig erzeugt werden können wurde erreicht.",
          heading: "Fehler - Aufträge Limit",
          type: "warning"
        });
        this.$emit("finishLoading");
        return;
      }

      switch (this.repeatTime) {
        case "täglich":
          for (let i = 0; i < this.maxRepeatTimes; i++) {
            let result;
            if (dates.length == 0) {
              result = new Date(baseDate);
              dates.push(result);
            } else {
              result = new Date(dates[dates.length - 1]);
              result.setDate(result.getDate() + 1);
              dates.push(result);
            }
          }
          break;
        case "wöchentlich":
          for (let i = 0; i < this.maxRepeatTimes; i++) {
            let result;
            if (dates.length == 0) {
              result = new Date(baseDate);
              dates.push(result);
            } else {
              result = new Date(dates[dates.length - 1]);
              result.setDate(result.getDate() + 7);
              dates.push(result);
            }
          }
          break;
        case "monatlich":
          for (let i = 0; i < this.maxRepeatTimes; i++) {
            let result;
            if (dates.length == 0) {
              result = new Date(baseDate);
              dates.push(result);
            } else {
              result = new Date(dates[dates.length - 1]);
              result.setMonth(result.getMonth() + 2);
              dates.push(result);
            }
          }
          break;
        default:
          this.saveEvent(this.eventToRepeat);
          break;
      }
      let finalEvents = [];
      dates.forEach(function(item, index) {
        let localCopyOfEvent = JSON.parse(JSON.stringify(vm.eventToRepeat));
        localCopyOfEvent.repeatedStartDate = item;
        localCopyOfEvent.startDate = new Date(
          localCopyOfEvent.repeatedStartDate
        );
        finalEvents.push(localCopyOfEvent);
        localCopyOfEvent.name = "wh. Auftrag - " + localCopyOfEvent.name;

        vm.saveEvent(localCopyOfEvent);
      });
      this.$emit("exit");
    },
    async saveEvent(event) {
      await this.$store.dispatch("addEvent", event);
    }
  }
};
</script>
