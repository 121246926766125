import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { StoreUtil } from "../utils";

const state = {
  dataVehicles: {
    pagination: {
      page: 1,
      itemsPerPage: 10,
      rowsPerPageItems: [10, 15, 25],
      totalItems: 0,
      search: ""
    },
    vehiclesList: StoreUtil.state()
  },

  dataMachinery: {
    pagination: {
      page: 1,
      itemsPerPage: 10,
      rowsPerPageItems: [10, 15, 25],
      totalItems: 0,
      search: ""
    },
    machineryList: StoreUtil.state()
  }
};

const fleetModule = {
  state,
  mutations,
  actions,
  getters
};

export default fleetModule;
