<template>
  <div>
    <div v-if="viewInvoiceListAccounting == false">
      <invoiceList />
    </div>
    <div v-if="viewInvoiceListAccounting">
      <invoiceListAccounting />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";

import invoiceList from "./invoiceList.vue";
import invoiceListAccounting from "./invoiceListAccounting.vue";

export default {
  components: {
    invoiceList,
    invoiceListAccounting
  },
  data: () => ({
    viewInvoiceListAccounting: false
  }),
  mounted() {
    this.getSetting();
  },
  computed: {
    ...mapGetters(["backendServer"])
  },
  methods: {
    getSetting() {
      let vm = this;
      let getUrl = this.backendServer + "settings/useAdvancedInvoiceTable";
      let config = {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      };

      axios
        .get(getUrl, config)
        .then(response => {
          vm.viewInvoiceListAccounting = response.data.value;
        })
        .catch(e => {
          vm.$store.dispatch("triggerError", e);
        });
    }
  }
};
</script>
