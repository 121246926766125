import axios from "axios";
const actions = {
  getNetRevenueData(context) {
    context.commit("SET_NET_REVENUE_DATA");
    let getUrl =
      context.getters.backendServer +
      "analytics/revenue?type=net";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_NET_REVENUE_DATA", response.data);
      })
      .catch(function(e) {
        context.commit("SET_NET_REVENUE_DATA", e);
        context.dispatch("triggerError", e);
      });
  },
  getBrutRevenueData(context) {
    context.commit("SET_BRUT_REVENUE_DATA");
    let getUrl =
      context.getters.backendServer +
      "analytics/revenue?type=brut";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_BRUT_REVENUE_DATA", response.data);
      })
      .catch(function(e) {
        context.commit("SET_BRUT_REVENUE_DATA", e);
        context.dispatch("triggerError", e);
      });
  },
  getWorkloadData(context, value) {
    context.commit("SET_WORKLOAD_DATA");
    let getUrl =
      context.getters.backendServer +
      "analytics/fleet/workload?start=" + value.start + 
      "&end=" + value.end + "&hours=" + value.hours;
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_WORKLOAD_DATA", response.data);
      })
      .catch(function(e) {
        context.commit("SET_WORKLOAD_DATA", e);
        context.dispatch("triggerError", e);
      });
  },
  getRevenuePerContact(context, value) {
    context.commit("SET_REVENUE_CONTACT_DATA");
    let getUrl =
      context.getters.backendServer +
      "analytics/revenueByContact"
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_REVENUE_CONTACT_DATA", response.data);
      })
      .catch(function(e) {
        context.commit("SET_REVENUE_CONTACT_DATA", e);
        context.dispatch("triggerError", e);
      });
  }
};

export default actions;
