<template>
  <div>
    <v-tabs>
      <v-tab>
        Dokumente
      </v-tab>
      <v-tab>
        Lieferschein
      </v-tab>
      <v-tab>
        Rechnung
      </v-tab>
      <v-tab v-if="license.modules.sevdesk">
        Rechnung für SevDesk
      </v-tab>
      <v-tab>
        Projekt
      </v-tab>
      <v-tab>
        App
      </v-tab>
      <v-tab>
        Planer
      </v-tab>
      <v-tab-item><processSettingsDocuments /></v-tab-item>
      <v-tab-item><processSettingsDeliveryNote /> </v-tab-item>
      <v-tab-item><processSettingsInvoice /> </v-tab-item>
      <v-tab-item v-if="license.modules.sevdesk"
        ><processSettingsInvoiceSevdesk />
      </v-tab-item>
      <v-tab-item> <processSettingsProject /></v-tab-item>
      <v-tab-item> <processSettingsApp /></v-tab-item>
      <v-tab-item> <processSettingsUndispo /> </v-tab-item>
    </v-tabs>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import processSettingsDocuments from "./../../components/processSettings/processSettingsDocuments.vue";
import processSettingsDeliveryNote from "./../../components/processSettings/processSettingsDeliveryNote.vue";
import processSettingsInvoice from "./../../components/processSettings/processSettingsInvoice.vue";
import processSettingsInvoiceSevdesk from "./../../components/processSettings/processSettingsInvoiceSevdesk.vue";
import processSettingsProject from "./../../components/processSettings/processSettingsProject.vue";
import processSettingsApp from "./../../components/processSettings/processSettingsApp.vue";
import processSettingsUndispo from "./../../components/processSettings/processSettingsUndispo.vue"

export default {
  data: () => ({
    settings: [],
    license: { modules: { sevdesk: false } }
  }),
  components: {
    processSettingsDeliveryNote,
    processSettingsInvoice,
    processSettingsInvoiceSevdesk,
    processSettingsProject,
    processSettingsApp,
    processSettingsDocuments,
    processSettingsUndispo
  },
  computed: {
    ...mapGetters(["licenseData"])
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch("getLicenseData").then(result => {});
    }
  },
  watch: {
    licenseData: {
      handler(val) {
        if (val.data[0]) {
          this.license = val.data[0].value;
        }
      }
    }
  }
};
</script>
