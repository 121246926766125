<template>
  <v-tabs>
      <v-tab exact>
        Projekte
      </v-tab>

      <v-tab-item :transition="false" :reverse-transition="false">
        <projectTable></projectTable>
      </v-tab-item>
    </v-tabs>
</template>
<script>
import projectTable from "./../components/projects/projectTable.vue";

export default {
  components: {
      projectTable
  },
  data() {
    return {
      
    };
  }
};
</script>