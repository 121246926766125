<template>
  <v-card class="mx-auto my-5" max-width="1200">
    <loading
      :active.sync="dialogLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <v-card-title>
      <span class="headline">{{ formTitle }}</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row justify="space-between">
            <v-col cols="12" sm="6" md="7">
              <v-text-field
                v-model="item.header"
                label="Betreff"
                data-cy="header"
                dense
                outlined
                :rules="requieredRule"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="space-between">
            <contactselectForm
              v-model="item"
              ref="contactSelect"
              @postAdressValue="postAdressValue"
              @useContactData="useContactData"
            />
            <!--v-col cols="12" sm="6" md="3">
              <v-text-field
                v-model="item.reference"
                label="Bestellnummer / Referenz"
                data-cy="reference"
                dense
                outlined
              ></v-text-field>
            </v-col-->
          </v-row>
          <v-row justify="space-between">
            <v-col cols="12" sm="5" md="5">
              <quill-editor v-model="item.address" :options="editorOption">
              </quill-editor>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <singleDateForm
                v-model="item.invoiceDate"
                label="Rechnungsdatum"
                data-cy="invoiceDate"
                prependIcon="event"
              />
              <a
                style="float: right"
                href="javascript:void(0);"
                v-if="!item.deliveryEndDate"
                @click="item.deliveryEndDate = true"
                >Lieferzeitraum</a
              >
              <a
                style="float: right"
                href="javascript:void(0);"
                v-if="item.deliveryEndDate"
                @click="
                  item.deliveryEndDate = null;
                  item.deliveryDate = new Date();
                "
                >Lieferdatum</a
              >
              <singleDateForm
                v-if="!item.deliveryEndDate"
                v-model="item.deliveryDate"
                label="Lieferdatum"
                data-cy="deliveryDate"
                prependIcon="event"
              />
              <dateRangeForm
                v-if="item.deliveryEndDate"
                v-model="item.deliveryDate"
                label="Lieferzeitraum"
                data-cy="deliveryDate"
                prependIcon="event"
              />
              <v-text-field
                label="Zahlungsziel in Tagen"
                data-cy="timeToPay"
                v-model="item.timeToPay"
                prepend-icon="account_balance"
                type="Number"
                dense
                outlined
                @change="createPayentTarget(item.timeToPay)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-expansion-panels v-model="settingsPanel" flat>
            <v-expansion-panel>
              <v-expansion-panel-header
                >Weitere Optionen</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <ktoSelectForm v-model="item" />
                <p>Skonto:</p>
                <v-row>
                  <v-col cols="12" sm="6" md="3">
                    <v-text-field
                      label="Skonto Tage"
                      data-cy="discountTime"
                      v-model="item.discountTime"
                      type="Number"
                      dense
                      outlined
                      @change="
                        createPayentTarget(item.timeToPay, {
                          discount: item.discount,
                          discountTime: item.discountTime,
                        })
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <v-text-field
                      label="Skonto Prozent"
                      data-cy="discount"
                      v-model="item.discount"
                      type="Number"
                      dense
                      outlined
                      @change="
                        createPayentTarget(item.timeToPay, {
                          discount: item.discount,
                          discountTime: item.discountTime,
                        })
                      "
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-row>
            <v-col cols="12" sm="6" md="12">
              <p>Kopf-Text</p>
              <quill-editor
                v-model="item.headText"
                :options="editorOptionAvail"
              >
              </quill-editor>
            </v-col>
          </v-row>
          <itemPosForm
            v-model="item.invoicePos"
            :contact="item.contact"
            :prices="true"
          />
          <calculateSums :item="item.invoicePos" />
          <v-row class="ma-0">
            <v-col cols="12" sm="6" md="12">
              <p>Fuß-Text</p>
              <quill-editor
                v-model="item.footText"
                :options="editorOptionAvail"
              >
              </quill-editor>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions class="mb-4">
      <div class="flex-grow-1"></div>
      <v-btn color="secondary" text to="/documents/2/rechnungen">Zurück</v-btn>
      <v-btn
        color="secondary"
        text
        @click="saveWrapper(item)"
        data-cy="saveEvent"
        >Speichern</v-btn
      >
    </v-card-actions>
    <preventDataLossForm
      :dialog="dataLossDialog"
      @dismissData="reactToBeforeRouteLeave"
    />
    <v-dialog
      v-model="activateCheckTitleDialog.visible"
      max-width="600"
      persistent
    >
      <checkTitleDialog
        :item="item"
        :activateCheckTitleDialog="activateCheckTitleDialog"
        @continueSave="save(item)"
        @close="closeCheckTitleDialog()"
      />
    </v-dialog>
    <v-dialog v-model="confirmSaveDialog" max-width="600" persistent>
      <v-card>
        <v-card-title class="headline"> Beleg überschreiben </v-card-title>
        <v-card-text>
          <v-alert dense border="left" type="error">
            Achtung, wenn Sie die Änderungen speichern wird der aktuelle Beleg
            überschrieben und die Rechnung in den Status "Entwurf" zurück
            versetzt.
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="save(item)">Fortfahren</v-btn>
          <v-btn color="error" text @click="cancelConfirmSave()"
            >Änderungen Verwerfen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import VueQuillEditor, { Quill } from "vue-quill-editor";
import singleDateForm from "../../_utils/singleDateForm.vue";
import dateRangeForm from "../../_utils/dateRangeForm.vue";
import preventDataLossForm from "../preventDataLossForm.vue";
import itemPosForm from "../itemPosForm.vue";
import calculateSums from "../calculateSums.vue";
import contactselectForm from "../contactSelectform.vue";
import ktoSelectForm from "../ktoSelectForm.vue";
import checkTitleDialog from "../checkTitleDialog.vue";
import axios from "axios";

function getDefaultData() {
  return {
    address: "Kontakt auswählen oder Adresse eingeben",
    invoiceDate: new Date().toISOString().substr(0, 10),
    header: "",
    headText: "",
    footText: "",
    contact: "",
    contactPerson: "",
    deliveryDate: new Date().toISOString().substr(0, 10),
    reference: "",
    timeToPay: 14,
    discount: 0,
    discountTime: 0,
    deliveryEndDate: null,
    invoicePos: [
      {
        name: "",
        description: "",
        quantity: 1,
        unity: "Stk",
        posId: 1,
        price: 0.0,
        taxRate: 0,
      },
    ],
  };
}

export default {
  components: {
    Loading,
    singleDateForm,
    dateRangeForm,
    preventDataLossForm,
    itemPosForm,
    calculateSums,
    contactselectForm,
    ktoSelectForm,
    checkTitleDialog,
  },
  props: [],
  data: () => ({
    formTitle: "Rechnung erstellen",
    item: getDefaultData(),
    valid: true,
    requieredRule: [(v) => !!v || "Dieses Feld wird benötigt"],
    dialogLoading: false,
    editorOption: {
      modules: {
        toolbar: false,
      },
    },
    editorOptionAvail: {
      modules: {
        toolbar: true,
      },
    },
    dateTimespan: false,
    dataLossDialog: false,
    to: false,
    settingsPanel: null,
    deliveryNoteData: null,
    activateCheckTitleDialog: { valid: true, nextNumber: null, visible: false },
    confirmSaveDialog: false,
  }),
  mounted() {
    this.initSwitch(this.$route.params);
  },
  watch: {
    "$route.params": function(params) {
      this.initSwitch(params);
    },
    userInfo: {
      handler(val) {
        if (val != null && this.item.contactPerson === "") {
          //on create set username of logged in person to contactperson
          this.item.contactPerson = val.name;
        }
      },
    },
    nextInvoiceId: {
      handler(val) {
        if (
          (val != null && this.item.header === "") ||
          this.$route.params.mode == "copy"
        ) {
          //on create set next Invoice Number
          this.item.header = "Rechnung RE-" + val.invoiceNumber;
          return val.invoiceNumber;
        }
      },
    },
  },
  computed: {
    ...mapGetters([
      "userInfo",
      "nextInvoiceId",
      "backendServer",
      "defaultMwst",
    ]),
    fields() {
      if (!this.item.resource) return [];

      return Object.keys(this.item.resource).map((key) => {
        return {
          key,
          value: this.item.resource[key] || "n/a",
        };
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.params.action == "close") {
      next();
    } else {
      if (this.item != getDefaultData()) {
        if (this.to) {
          next();
        } else {
          this.to = to;
          this.dataLossDialog = true;
        }
      }
    }
  },
  methods: {
    initSwitch(params) {
      if (params.mode === "edit") {
        this.$store.dispatch("getSingleInvoice", params.id).then((r) => {
          this.mountedMethod(r);
        });
      } else if (params.mode === "new") {
        this.mountedMethod();
      } else if (params.deliveryNoteData) {
        //save copyDeliveryNoteData params
        this.copyDeliveryNoteData = JSON.stringify(params.deliveryNoteData);
        this.mountedMethod(params.deliveryNoteData);
      } else if (params.projectData) {
        this.mountedMethod(params.projectData);
      } else if (params.mode === "copy") {
        //copy invoice
        this.$store.dispatch("getSingleInvoice", params.id).then((r) => {
          this.copyInvoice(r);
        });
      }
    },
    mountedMethod(item) {
      this.$store.dispatch("getNextInvoiceId");
      this.$store.dispatch("getUserInfo");
      if (item != null && item.invoiceNumber != null) {
        //edit invoice
        this.formTitle = "Rechnung bearbeiten";
        this.item = item;
        if (item.deliveryEndDate != null) {
          this.item.deliveryDate = [item.deliveryDate, item.deliveryEndDate];
        }
      } else if (item != null && this.$route.params.projectData) {
        // create invoice from project
        this.prepareDataFromProject(item);
        this.formTitle = "Projektrechnung erstellen";
        this.loadDefaultText();
      } else if (item != null && item.deliveryNoteNumber != null) {
        // create invoice from delivery note
        this.prepareDataFromDeliveryNote(item);
        this.formTitle = "Rechnung aus Lieferschein erstellen";
        this.loadDefaultText();
      } else {
        //set header / footer Text -> create new invoice
        //load mwst
        this.item.invoicePos[0].taxRate = this.defaultMwst;
        this.formTitle = "Rechnung erstellen";
        this.loadDefaultText();
      }
    },
    copyInvoice(item) {
      this.$store.dispatch("getNextInvoiceId");
      this.formTitle = "Rechnung kopieren";
      //delete params
      delete item.invoiceNumber;
      delete item.formattedInvoiceNumber;
      delete item.isCanceled;
      delete item.createdAt;
      delete item.lastModified;
      delete item.id;
      delete item.status;
      delete item._id;
      delete item.payed;
      this.item = item;
    },
    loadDefaultText() {
      this.$store.dispatch("getInvoiceText").then((res) => {
        this.item.headText = res.value.head;
        this.item.footText = res.value.foot;
      });

      //create payment target
      this.createPayentTarget(this.item.timeToPay);
    },
    prepareDataFromDeliveryNote(deliveryNote) {
      let vm = this;
      let i = this.item;
      let d = deliveryNote;
      this.deliveryNoteData = d;

      i.contact = d.contact.id;
      i.address = d.address;
      i.deliveryDate = d.deliveryDate;
      i.deliveryEndDate = null;
      i.deliveryNote = d.id;

      this.$store.dispatch("getNextInvoiceId").then((r) => {
        let config = {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        };

        axios
          .get(
            this.backendServer + "settings/copyFullDeliveryNoteTitle",
            config
          )
          .then((response) => {
            if (response.data.value) {
              i.header = "Rechnung RE-" + r.invoiceNumber + " aus " + d.header;
            } else {
              i.header =
                "Rechnung RE-" +
                r.invoiceNumber +
                " aus Lieferschein " +
                d.formattedDeliveryNoteNumber;
            }
          })
          .catch((error) => {
            this.$store.dispatch("triggerError", error);
          });
      });
      i.reference = "Lieferschein " + d.formattedDeliveryNoteNumber;
      i.invoicePos = Object.assign([], d.deliveryNotePos);
      //get data from contact
      this.$refs.contactSelect.createAdressFromContact({
        contact: { id: d.contact.id },
      });

      // set contact person if delivery note was signet by app
      if (d.contactPerson != "-") {
        i.contactPerson = d.contactPerson;
      } else {
        this.$store.dispatch("getUserInfo").then((r) => {
          i.contactPerson = r.name;
        });
      }
    },
    prepareDataFromProject(project) {
      //create invoice from project
      //format item header
      this.$store.dispatch("getNextInvoiceId").then((r) => {
        this.item.header =
          "Rechnung RE-" +
          r.invoiceNumber +
          " aus Projekt " +
          project.formattedProjectNumber;
        // add reference when set
        project.reference
          ? (this.item.header = this.item.header + " | " + project.reference)
          : "";
      });

      //Format address from contact
      this.$refs.contactSelect.createAdressFromContact({
        contact: { id: project.contact.id },
      });

      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };
      axios
        .get(
          this.backendServer + "project/" + project.id + "/getInvoice",
          config
        )
        .then((response) => {
          let data = response.data;
          this.item.deliveryDate = [new Date(data.start), new Date(data.end)];
          this.item.deliveryEndDate = new Date(data.end);
          this.item.invoicePos = data.positions;
          this.item.contact = data.contact;
          this.item.isProjectInvoice = true;
          this.item.project = data.id;
        })
        .catch((err) => {
          this.$store.dispatch("triggerError", err);
        });
    },
    close(val) {
      this.resetData();
      this.$refs.form.reset();
      if (val.id) {
        this.$router.push({
          name: "documentDetails",
          params: { action: "close", id: val.id, from: "rechnungen" },
        });
      } else {
        this.$router.push({
          name: "invoice",
          params: { action: "close", tab: 2 },
        });
      }
    },
    checkHeader(header) {
      //check header title if document number is correct
      const rx = /RE-[0-9]*/g;
      let arr = header.match(rx);
      arr == null ? (arr = ["RE-0"]) : "";
      return this.$store
        .dispatch("getInvoiceNumberValid", arr[0].substring(3))
        .then((r) => {
          return r;
        });
    },
    closeCheckTitleDialog() {
      this.activateCheckTitleDialog = {
        valid: true,
        nextNumber: null,
        visible: false,
      };
    },
    saveWrapper(item) {
      if (item.status == "Versendet") {
        this.confirmSaveDialog = true;
      } else {
        this.save(item);
      }
    },
    cancelConfirmSave() {
      this.confirmSaveDialog = false;
      this.close({});
    },
    save(item) {
      if (this.$refs.form.validate()) {
        let vm = this;
        //always close confirmSaveDialog
        vm.confirmSaveDialog = false;
        //check Header
        return this.checkHeader(item.header).then(function(value) {
          //reset dialog
          vm.activateCheckTitleDialog.visible = false;
          //checkheader values will only be used when invoice is created
          if (
            !value.valid &&
            (vm.formTitle == "Rechnung erstellen" ||
              vm.formTitle == "Rechnung kopieren")
          ) {
            //open check title dialog header not ok
            vm.activateCheckTitleDialog = value;
            vm.activateCheckTitleDialog.visible = true;
            //return;
          } else {
            //header ok
            //format date from daterange picker
            if (Array.isArray(item.deliveryDate)) {
              let cpdate = item.deliveryDate;
              item.deliveryDate = cpdate[0];
              item.deliveryEndDate = cpdate[1];
            }
            //check if Buchungskontennummer is set
            if (vm.settingsPanel != 0 || item.kto == null) {
              vm.$store.dispatch("getStandardKto").then((r) => {
                item.kto = r;
              });
            }
            if (
              vm.formTitle == "Rechnung erstellen" ||
              vm.formTitle == "Projektrechnung erstellen" ||
              vm.formTitle == "Rechnung kopieren"
            ) {
              vm.$store.dispatch("addInvoice", item).then((r) => {
                if (vm.formTitle == "Projektrechnung erstellen") {
                  vm.$store
                    .dispatch("updateProject", {
                      status: "Rechnung erzeugt",
                      id: item.project,
                    })
                    .then((e) => {
                      vm.close(r);
                    });
                } else {
                  vm.close(r);
                }
              });
            } else if (vm.formTitle == "Rechnung aus Lieferschein erstellen") {
              vm.$store.dispatch("addInvoice", item).then((r) => {
                //change status of delivery note
                let deliveryNote = JSON.parse(vm.copyDeliveryNoteData);
                deliveryNote.status = "Rechnung erzeugt";
                vm.$store.dispatch("updateDeliveryNotes", deliveryNote);
                vm.close(r);
              });
            } else {
              vm.$store.dispatch("updateInvoice", item).then((r) => {
                vm.close(r);
              });
            }
          }
        });
      }
    },
    resetData() {
      this.item = getDefaultData();
    },
    reactToBeforeRouteLeave(val) {
      if (val) {
        this.dataLossDialog = false;
        this.$router.push(this.to);
      } else {
        this.dataLossDialog = false;
        this.to = null;
      }
    },
    createPayentTarget(val, discount) {
      if (val != 0) {
        let paymentTargetText;
        if (!val) {
          paymentTargetText = "";
        } else {
          paymentTargetText =
            "Zahlungsbedingungen: Zahlung innerhalb von " +
            val +
            " Tagen ab Rechnungseingang ohne Abzüge.";

          // check if skonto settings are given
          if (
            discount &&
            discount.discount != 0 &&
            discount.discountTime != 0
          ) {
            paymentTargetText =
              paymentTargetText +
              " Bei einer Zahlung innerhalb von " +
              discount.discountTime +
              " Tagen gewähren wir " +
              discount.discount +
              " % Skonto.";
          }
        }

        this.$store.dispatch("getInvoiceText").then((res) => {
          this.item.footText = paymentTargetText + res.value.foot;
        });
      }
    },
    postAdressValue(val) {
      //set adress emitted from child adress select
      this.item.address = val;
    },
    useContactData() {
      if (this.item.timeToPay != 0) {
        this.createPayentTarget(this.item.timeToPay, {
          discount: this.item.discount,
          discountTime: this.item.discountTime,
        });
      }
    },
  },
};
</script>
<style scoped>
.ql-editor {
  min-height: 120px !important;
  font-size: 16px;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px;
}

.theme--light .v-tabs-items {
  background-color: #f3f3fa;
}
</style>
