<template>
  <div>
    <h4 style="text-align:left;" class="pa-2">
      Gesamtbetrag netto
      <span style="float:right;">{{ gesNet }} EUR</span>
    </h4>
    <h4 style="text-align:left;" class="pa-2">
      Umsatzsteuer
      <span style="float:right;">{{ tax }} EUR</span>
    </h4>
    <h3 style="text-align:left;" class="pa-2">
      <b>Gesamtbetrag brutto</b>
      <span style="float:right;"
        ><b>{{ gesBrut }} EUR</b></span
      >
    </h3>
  </div>
</template>
<script>
export default {
  props: ["item"],
  data: () => ({
    gesNet: 0,
    gesBrut: 0,
    tax: 0
  }),
  mounted() {
    this.format(this.item);
  },
  watch: {
    item: {
      handler(val) {
        this.format(val);
      },
      deep: true
    }
  },
  methods: {
    format(val) {
      let nets = val.map(inPos => {
        return inPos.quantity * inPos.price;
      });
      this.gesNet = this.formatCurrency(
        nets.reduce((total, current) => {
          return total + current;
        })
      );
      let bruts = val.map(inPos => {
        return (
          inPos.quantity * inPos.price +
          inPos.quantity * inPos.price * (inPos.taxRate / 100)
        );
      });
      this.gesBrut = this.formatCurrency(
        bruts.reduce((total, current) => {
          return total + current;
        })
      );
      this.tax = this.formatCurrency(
        bruts.reduce((total, current) => {
          return total + current;
        }) -
          nets.reduce((total, current) => {
            return total + current;
          })
      );
    },
    roundCurrency(number) {
      return parseFloat((Math.round(number * 100) / 100));
    },
    formatCurrency(number) {
      let roundedNumber = this.roundCurrency(number);
      return roundedNumber.toFixed(2).toString().replace(".", ",");
    }
  }
};
</script>
