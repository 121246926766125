import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VList,{attrs:{"flat":"","subheader":"","three-line":""}},[_c(VSubheader,[_c('h4',[_vm._v("Prozess-Einstellungen")])])],1),_c(VListItem,[_c(VListItemContent,[_c(VContainer,[_c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"900"}},[_c(VContainer,[_c('p',[_vm._v("Rechnungs-Einstellungen")])]),_c(VForm,{ref:"form",staticClass:"pa-4",attrs:{"lazy-validation":""}},[_c('p',[_vm._v("Forderungsmanagement")]),_c('h5',[_vm._v(" Ist diese Option aktiviert, können Sie Ihr Forderungsmanagement ganz einfach in onDispatch erledigen. Ihnen werden Rechnungen als Offen/Bezahlt/Überfällig angezeigt. ")]),_c('br'),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('p',{staticStyle:{"margin-top":"25px"}},[_vm._v(" Forderungsmanagement in onDispatch erledigen ")])]),_c(VCol,{attrs:{"cols":"12","sm":"6","md":"6"}},[_c(VSwitch,{on:{"change":_vm.changeInvoiceTable},model:{value:(_vm.useAdvancedInvoiceTable),callback:function ($$v) {_vm.useAdvancedInvoiceTable=$$v},expression:"useAdvancedInvoiceTable"}})],1)],1),_c('hr'),_c('br'),_c('br'),_c('p',[_vm._v("Lieferscheinbezeichnung in den Rechnungstitel übernehmen")]),_c('h5',[_vm._v(" Ist diese Option aktiviert, wird standardmäßig der Titel des Lieferscheins in den Titel der Rechnung kopiert (zB zur Angabe von Kostenstellen). ")]),_c('br'),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('p',{staticStyle:{"margin-top":"25px"}},[_vm._v(" Lieferscheinbezeichnung übertragen ")])]),_c(VCol,{attrs:{"cols":"12","sm":"6","md":"6"}},[_c(VSwitch,{on:{"change":_vm.changeCopyTitle},model:{value:(_vm.copyFullDeliveryNoteTitle),callback:function ($$v) {_vm.copyFullDeliveryNoteTitle=$$v},expression:"copyFullDeliveryNoteTitle"}})],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }