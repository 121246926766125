import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { StoreUtil } from "../utils";

const state = {
    customerList: StoreUtil.state()
}

const customerModule = {
    state,
    mutations,
    actions,
    getters
}

export default customerModule;