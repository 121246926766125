import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { StoreUtil } from "../utils";

const state = {
  dataInvoice: {
    pagination: {
      page: 1,
      itemsPerPage: 10,
      rowsPerPageItems: [10, 15, 25],
      totalItems: 0,
      search: ""
    },
    invoiceList: StoreUtil.state(),
    nextInvoiceId: null,
  },

  dataDeliveryNotes: {
    pagination: {
      page: 1,
      itemsPerPage: 10,
      rowsPerPageItems: [10, 15, 25],
      totalItems: 0,
      search: ""
    },
    deliveryNotesList: StoreUtil.state(),
    nextDeliveryNoteId: null,
  },

  dataOffers: {
    pagination: {
      page: 1,
      itemsPerPage: 10,
      rowsPerPageItems: [10, 15, 25],
      totalItems: 0,
      search: ""
    },
    offerList: StoreUtil.state(),
    nextOfferId: null
  },
}

const documentsModule = {
  state,
  mutations,
  actions,
  getters
}

export default documentsModule;