import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import VueApexCharts from 'vue-apexcharts'
import DatetimePicker from 'vuetify-datetime-picker'

import store from './store/store.js'

import axios from 'axios';
import VueAxios from 'vue-axios';
import Vuex from 'vuex';
import VueQuillEditor from 'vue-quill-editor'

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'


import '@mdi/font/css/materialdesignicons.css'

Vue.config.productionTip = true
Vue.config.devtools = false;

Vue.component('apexchart', VueApexCharts);
Vue.use(VueAxios, axios);
Vue.use(Vuex);
Vue.use(DatetimePicker);
Vue.use(VueQuillEditor, /* { default global options } */);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');

if (window.Cypress) {
  // Add `store` to the window object only when testing with Cypress
  window.store = store
}
