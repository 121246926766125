<template>
  <v-tabs v-model="activeTab">
      <v-tab v-for="tab of tabs" :key="tab.id" :to="tab.route" exact>
        {{ tab.name }}
      </v-tab>

      <v-tab-item v-for="tab of tabs" :key="tab.id" :value="tab.route" :transition="false" :reverse-transition="false">
        <router-view></router-view>
      </v-tab-item>
    </v-tabs>
</template>
<script>
export default {
  data() {
    return {
      activeTab: this.$route.params.tab,
      tabs: [
        { id: 1, name: "Lieferscheine", route: `/documents/1/lieferscheine` },
        { id: 2, name: "Rechnungen", route: `/documents/2/rechnungen` },
        { id: 3, name: "Angebote", route: `/documents/3/angebote` }
      ]
    };
  }
};
</script>