<template>
  <v-card>
    <v-card-title class="headline">
      Achtung falsche/ fehlende Dokumentennummer erkannt!</v-card-title
    >
    <v-card-text>
      Bitte korrigieren sie die Nummer im Betreff durch die unten angezeigte Nummer.
      <br />
      <h4>Nächste Dokumentennummer: {{ activateCheckTitleDialog.nextNumber }}</h4>
      <br />
      <v-col cols="12" sm="6" md="10">
        <v-text-field
          v-model="item.header"
          label="Betreff"
          data-cy="header"
          dense
          outlined
        ></v-text-field>
      </v-col>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="secondary" @click="close()" text data-cy="cancelEvent">Abbrechen</v-btn>
      <v-btn color="secondary" @click="continueFunction()" text data-cy="saveEvent"
        >Jetzt prüfen und speichern</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  props: ["item", "activateCheckTitleDialog"],
  data: () => ({}),
  methods: {
    continueFunction() {
      this.$emit("continueSave", this.item);
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
