<template>
  <div>
    <v-list flat subheader three-line>
      <v-list-item>
        <v-list-item-content>
          <v-container>
            <v-card class="mx-auto" max-width="900">
              <v-form class="pa-4" ref="form" v-model="valid" lazy-validation>
                <p>Info</p>
                <h5>
                  Die folgenden Daten werden standardmäßig zum Erstellen von
                  Lieferscheinen / Rechnungen etc. verwendet.
                </h5>
                <br />
                <v-row>
                  <v-col cols="12" sm="6" md="7">
                    <v-text-field
                      v-model="item.name"
                      label="Firmenname"
                      data-cy="name"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="5">
                    <v-text-field
                      v-model="item.nameAdditional"
                      label="Namenszusatz"
                      data-cy="nameAdditional"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="item.executive"
                      label="Geschäftsführer"
                      data-cy="executive"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="item.telephone"
                      label="Telefon"
                      data-cy="telephone"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="item.fax"
                      label="Fax"
                      data-cy="fax"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="item.email"
                      label="Email"
                      data-cy="email"
                      outlined
                      dense
                      prepend-icon="email"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="item.website"
                      label="Website"
                      data-cy="website"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-container>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-container>
            <v-card class="mx-auto" max-width="900">
              <v-form class="pa-4" ref="form" v-model="valid" lazy-validation>
                <p>Rechtliche Informationen</p>
                <h5>
                  Bitte beachten Sie, dass für eine ordnungsgemäße Rechnung eine
                  Steuernummer bzw. Umsatzsteuer-ID eingetragen werden muss.
                </h5>
                <br />
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="item.legal.court"
                      label="Gericht / Registergericht"
                      data-cy="court"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-row>
                      <v-text-field
                        v-model="item.legal.trType"
                        label="Register"
                        data-cy="trType"
                        outlined
                        dense
                        class="ml-3"
                      ></v-text-field>
                      <v-text-field
                        v-model="item.legal.trNumber"
                        label="Register Nr."
                        data-cy="trNumber"
                        outlined
                        dense
                        class="mr-3"
                      ></v-text-field>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="item.legal.taxNumber"
                      label="Steuernummer"
                      data-cy="taxNumber"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="item.legal.ustId"
                      label="Umsatzsteuer-ID"
                      data-cy="ustId"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-container>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-container>
            <v-card class="mx-auto" max-width="900">
              <v-form class="pa-4" ref="form" v-model="valid" lazy-validation>
                <p>Adresse</p>
                <v-row>
                  <v-col cols="12" sm="6" md="9">
                    <v-text-field
                      v-model="item.address.street"
                      label="Straße"
                      data-cy="street"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <v-text-field
                      v-model="item.address.streetNumber"
                      label="Hausnummer"
                      data-cy="streetNumber"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="!preFormatAddress">
                  <v-col cols="12" sm="6" md="5">
                    <v-autocomplete
                      label="Ort (Plz / Stadt / Bundesland)"
                      data-cy="preFormatAddress"
                      v-model="preFormatAddress"
                      :items="zipMap"
                      item-text="Description"
                      item-value="adressData"
                      dense
                      outlined
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row v-if="preFormatAddress">
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="item.address.city"
                      label="Stadt"
                      data-cy="city"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <v-text-field
                      v-model="item.address.zip"
                      label="Plz"
                      data-cy="zip"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-container>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-container>
            <v-card class="mx-auto" max-width="900">
              <v-form class="pa-4" ref="form" v-model="valid" lazy-validation>
                <p>Zahlungsinformationen</p>
                <h5>Ihre Kontodaten werden zb. für Rechnungen verwendet.</h5>
                <br />
                <v-row>
                  <v-col cols="12" sm="6" md="7">
                    <v-text-field
                      v-model="item.banking.iban"
                      label="IBAN"
                      data-cy="iban"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="5">
                    <v-text-field
                      v-model="item.banking.bic"
                      label="BIC"
                      data-cy="bic"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="7">
                    <v-text-field
                      v-model="item.banking.bank"
                      label="Bank"
                      data-cy="bank"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="7">
                    <v-checkbox
                      v-model="useBanking2Val"
                      label="weitere Kontoverbindung verwenden"
                    ></v-checkbox>
                  </v-col>
                </v-row>

                <v-row v-if="useBanking2Val">
                  <v-col cols="12" sm="6" md="7">
                    <v-text-field
                      v-model="item.banking2.iban"
                      label="IBAN"
                      data-cy="iban"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="5">
                    <v-text-field
                      v-model="item.banking2.bic"
                      label="BIC"
                      data-cy="bic"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="useBanking2Val">
                  <v-col cols="12" sm="6" md="7">
                    <v-text-field
                      v-model="item.banking2.bank"
                      label="Bank"
                      data-cy="bank"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-container>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-container>
            <v-card class="mx-auto" max-width="900">
              <v-form class="pa-4" ref="form" v-model="valid" lazy-validation>
                <p>Logo (maximal 200 x 125 Pixel)</p>
                <h5>
                  Dieses Logo wird standardmäßig für alle Arten von Dokumenten
                  verwendet, bitte beachte Sie unbedingt die Maximalgröße.
                </h5>
                <br />
                <v-col cols="12" sm="6" md="6">
                  <img :src="logoURL" style="max-width: 150px" />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-file-input
                    show-size
                    label="Datei auswählen"
                    data-cy="uploadFile"
                    v-model="uploadFile"
                    accept="image/*"
                  ></v-file-input>
                </v-col>
              </v-form>
            </v-card>
          </v-container>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-container>
            <v-card class="mx-auto" max-width="900">
              <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="secondary" text @click="save">Speichern</v-btn>
              </v-card-actions>
            </v-card>
          </v-container>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  data: () => ({
    testItem: null,
    logoURL: "",
    item: {
      name: "",
      nameAdditional: "",
      executive: "",
      telephone: "",
      email: "",
      fax: "",
      website: "",
      address: {
        street: "",
        streetNumber: "",
        city: "",
        zip: "",
        country: ""
      },
      legal: {
        trType: "",
        trNumber: "",
        ustId: "",
        taxNumber: "",
        court: ""
      },
      banking: {
        iban: "",
        bic: "",
        bank: ""
      },
      banking2: {
        iban: "",
        bic: "",
        bank: ""
      }
    },
    uploadFile: null,
    valid: true,
    requieredRule: [v => !!v || "Dieses Feld wird benötigt"],
    registers: ["HRA", "HRB"],
    useBanking2Val: false
  }),

  created() {
    this.initialize();
  },

  computed: {
    ...mapGetters(["companyData", "zipCodes", "backendServer", "logo"]),
    zipMap() {
      return this.zipCodes.map(zip => {
        const Description = zip.Plz + " - " + zip.Ort + " - " + zip.Bundesland;
        let adressData = zip;
        if (adressData != null) {
          return Object.assign({}, zip, { Description, adressData });
        }
      });
    },
    preFormatAddress: {
      get() {
        if (this.item.address.city == "" && this.item.address.zip == "") {
          return false;
        } else {
          return true;
        }
      },
      set(val) {
        this.item.address.city = val.Ort;
        this.item.address.zip = val.Plz;
        this.item.address.country = "Deutschland";
      }
    }
  },

  watch: {
    companyData: {
      handler(val) {
        if (val.data[0] != null) {
          this.item = !val.data[0].value ? this.item : val.data[0].value;

          //check if banking2 is used then display it
          if (this.item.banking2.iban != "" && this.item.banking2.bank != "") {
            this.useBanking2Val = true;
          }
        }
      }
    },
    logo: {
      handler(val) {
        if (val.data[0] != null) {
          this.logoURL = val.data[0].value.dataURL;
        }
      }
    },
    useBanking2Val: {
      handler(val) {
        if (!val) {
          //remove data when banking2 is not selected
          this.item.banking2 = {
            iban: "",
            bic: "",
            bank: ""
          };
        }
      }
    }
  },

  methods: {
    save() {
      let vm = this;

      if (vm.$refs.form.validate()) {
        vm.$store.dispatch("updateCompanyData", this.item).then(result => {
          vm.$emit("close");
        });

        let formData = new FormData();

        formData.append("file", this.uploadFile);

        let putUrl = this.backendServer + "meta/logo";
        let config = {
          headers: {
            Authorization: localStorage.getItem("token")
          }
        };

        axios
          .put(putUrl, formData, config)
          .then(response => {
            //console.log(response);
            vm.$refs.form.reset();
            this.$store.dispatch("getLogo");
          })
          .catch(err => {
            this.$store.dispatch("triggerError", err);
          });
      }
    },
    initialize() {
      this.$store.dispatch("getCompanyData");
      this.$store.dispatch("getLogo");
    }
  }
};
</script>
