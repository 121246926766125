import { StoreUtil } from "../utils";
const mutations = {
  SET_PAGINATION_PROJECT(state, payload) {
    state.projectData.pagination = payload;
  },
  RESET_PAGINATION_PAGES_PROJECT(state) {
    state.projectData.pagination.page = 1;
    state.projectData.pagination.itemsPerPage = 10;
  },
  SET_TOTAL_PROJECT_ALL(state, payload) {
    state.projectData.pagination.totalItems = payload;
  },
  SET_ALL_PROJECT(state, payload) {
    state.projectData.projectList = StoreUtil.updateState(
      state.projectData.projectList,
      payload
    );
  },
  SET_PROJECT_ADD(state, payload) {
    StoreUtil.updateState(state.projectData.projectList, payload);
  },
  SET_PROJECT_UPDATE(state, payload) {
    StoreUtil.updateState(state.projectData.projectList, payload);
  },
  SET_PROJECT_DELETE(state, payload) {
    StoreUtil.updateState(state.projectData.projectList, payload);
  },
};

export default mutations;
