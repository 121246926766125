import Vue from "vue";
import Router from "vue-router";
import generalLayout from "./components/generalLayout.vue";
import Dashboard from "./views/dashboard/dashboard.vue";
import Login from "./views/Login.vue";

import bookVehicle from "./external/bookVehicle.vue";

import Contacts from "./views/Contacts.vue";
import contactsAll from "./components/contact/overviewPages/contactTableAll.vue";
import contactsKunde from "./components/contact/overviewPages/contactTableKunde.vue";
import contactsLieferant from "./components/contact/overviewPages/contactTableLieferant.vue";
import contactsPartner from "./components/contact/overviewPages/contactTablePartner.vue";
import contactsInteressent from "./components/contact/overviewPages/contactTableInteressent.vue";

import Documents from "./views/Documents.vue";
import documentsLieferscheine from "./components/documents/deliveryNotes/deliveryNoteList.vue";
import documentsLieferscheineForm from "./components/documents/deliveryNotes/deliveryNoteForm.vue";
import documentsRechnungenOverview from "./components/documents/invoices/invoiceListOverview.vue";
import documentsRechnungenForm from "./components/documents/invoices/invoiceForm.vue";
import documentsAngebote from "./components/documents/offers/offerList.vue";
import documentsAngeboteForm from "./components/documents/offers/offerForm.vue";
import documentsDetails from "./components/documents/documentsDetailView.vue";
import sendEmail from "./components/documents/sendEmail.vue";

import Fleet from "./views/Fleet.vue";
import fleetVehicle from "./components/fleet/vehiclesTable.vue";
import fleetMachinery from "./components/fleet/machineryTable.vue";

import Inventory from "./views/Inventory.vue";
import Project from "./views/Project.vue";
import Accounting from './views/Accounting.vue';

import planerPage from "./views/Planer.vue";
import planerScheduler from "./components/planer/planerScheduler/scheduler.vue";
import planerTable from "./components/planer/planerTable/planerTable.vue"
import eventForm from "./components/planer/components/eventForm/eventForm.vue"

import Users from "./views/settings/users.vue";
import companyDataOverview from "./views/settings/companyDataOverview.vue";
import License from "./views/settings/license.vue";
import DatevExport from "./views/settings/datevExport.vue";
import Analytics from "./views/Analytics.vue";
import Importer from "./views/settings/import.vue";
import Process from "./views/settings/processSettings.vue";
import Requests from './views/Requests.vue'

const backendServer = require("./store/config/index").default.state
  .backendServer;
const axios = require("axios");

Vue.use(Router);
const router = new Router({
  routes: [
    { path: "*", redirect: "/Dashboard" },
    {
      path: "/",
      component: generalLayout,
      name: "generalLayout",
      redirect: "/Dashboard",
      children: [
        {
          path: "/Dashboard",
          name: "Dashboard",
          component: Dashboard,
          meta: { requiresAuth: true, requireAdmin: true }
        },
        {
          path: "/contacts/:id/",
          component: Contacts,
          props: true,
          meta: { requiresAuth: true, requireAdmin: true },
          children: [
            {
              path: "",
              component: contactsAll,
              meta: { requiresAuth: true, requireAdmin: true }
            },
            {
              path: "kunde",
              component: contactsKunde,
              meta: { requiresAuth: true, requireAdmin: true }
            },
            {
              path: "lieferant",
              component: contactsLieferant,
              meta: { requiresAuth: true, requireAdmin: true }
            },
            {
              path: "partner",
              component: contactsPartner,
              meta: { requiresAuth: true, requireAdmin: true }
            },
            {
              path: "interessent",
              component: contactsInteressent,
              meta: { requiresAuth: true, requireAdmin: true }
            }
          ]
        },
        {
          path: "documents/:tab/",
          component: Documents,
          props: true,
          meta: { requiresAuth: true, requireAdmin: true },
          children: [
            {
              path: "lieferscheine",
              name: "deliveryNote",
              component: documentsLieferscheine,
              meta: { requiresAuth: true, requireAdmin: true }
            },
            {
              path: "lieferscheine/edit/:id/:mode",
              name: "deliveryNoteEdit",
              component: documentsLieferscheineForm,
              props: true,
              meta: { requiresAuth: true, requireAdmin: true }
            },
            {
              path: "rechnungen",
              name: "invoice",
              component: documentsRechnungenOverview,
              meta: { requiresAuth: true, requireAdmin: true }
            },
            {
              path: "rechnungen/edit/:id/:mode",
              name: "invoiceEdit",
              component: documentsRechnungenForm,
              props: true,
              meta: { requiresAuth: true, requireAdmin: true }
            },
            {
              path: "angebote",
              name: "offer",
              component: documentsAngebote,
              meta: { requiresAuth: true, requireAdmin: true }
            },
            {
              path: "angebote/edit/:id/:mode",
              name: "offerEdit",
              component: documentsAngeboteForm,
              props: true,
              meta: { requiresAuth: true, requireAdmin: true }
            }
          ]
        },
        {
          path: "/documents/details/:id/:from",
          props: true,
          name: "documentDetails",
          component: documentsDetails,
          meta: { requiresAuth: true, requireAdmin: true }
        },
        {
          path: "/sendEmail/:document/:doctype/:defaultText/:header",
          props: true,
          name: "sendEmail",
          component: sendEmail,
          meta: { requiresAuth: true, requireAdmin: true }
        },
        {
          path: "/inventory",
          component: Inventory,
          meta: { requiresAuth: true, requireAdmin: true }
        },
        {
          path: "/projects",
          component: Project,
          meta: { requiresAuth: true, requireAdmin: true }
        },
        {
          path: "/accounting",
          component: Accounting,
          meta: { requiresAuth: true, requireAdmin: true }
        },
        {
          path: "/fleet/:id/",
          component: Fleet,
          props: true,
          meta: { requiresAuth: true, requireAdmin: true },
          children: [
            {
              path: "",
              component: fleetVehicle,
              meta: { requiresAuth: true, requireAdmin: true }
            },
            {
              path: "machinery",
              component: fleetMachinery,
              meta: { requiresAuth: true, requireAdmin: true }
            }
          ]
        },
        {
          path: "planer/:tab/",
          component: planerPage,
          props: true,
          meta: { requiresAuth: true, requireAdmin: true },
          children: [
            {
              path: "planer/:id/:deliveryNoteId/:mode/:back",
              name: "Planer",
              component: planerScheduler,
              props: true,
              meta: { requiresAuth: true, requireAdmin: true }
            },
            {
              path: "tabelle/:back",
              name: "planerTable",
              component: planerTable,
              meta: { requiresAuth: true, requireAdmin: true }
            }
          ]
        },
        {
          path: "/event/edit/:id/:mode/:back",
          props: true,
          name: "eventForm",
          component: eventForm,
          meta: { requiresAuth: true, requireAdmin: true }
        },
        {
          path: "/fleet",
          name: "Fuhrpark",
          component: Fleet,
          meta: { requiresAuth: true, requireAdmin: true }
        },
        {
          path: "/analytics",
          name: "Analytics",
          component: Analytics,
          meta: { requiresAuth: true, requireAdmin: true }
        },
        {
          path: "/users",
          name: "Benutzerverwaltung",
          component: Users,
          meta: { requiresAuth: true, requireAdmin: true }
        },
        {
          path: "/companyData",
          name: "Stammdaten",
          component: companyDataOverview,
          meta: { requiresAuth: true, requireAdmin: true }
        },
        {
          path: "/datev",
          name: "Datev-Export",
          component: DatevExport,
          meta: { requiresAuth: true, requireAdmin: true }
        },
        {
          path: "/license",
          name: "Lizenz",
          component: License,
          meta: { requiresAuth: true, requireAdmin: true }
        },
        {
          path: "/import",
          name: "Daten-Import",
          component: Importer,
          meta: { requiresAuth: true, requireAdmin: true }
        },
        {
          path: "/process",
          name: "Prozesseinstellungen",
          component: Process,
          meta: { requiresAuth: true, requireAdmin: true }
        },
        {
          path: "/requests",
          name: "Anfragen",
          component: Requests,
          meta: { requiresAuth: true, requireAdmin: true }
        }
      ]
    },
    {
      path: "/login",
      name: "Login",
      component: Login
    },
    {
      path: "/fahrzeugReservieren",
      name: "bookVehicle",
      component: bookVehicle,
      meta: { requiresAuth: false, requireAdmin: false }
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    const token = window.localStorage.getItem("token");
    const authUser = {
      role: "admin"
    };
    if (!token) {
      next("/login");
    } else {
      //CHECK TOKEN
      let getUrl = backendServer + "user/hasCredentials";
      let config = {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      };
      axios
        .get(getUrl, config)
        .then(function(response) {
          if (response.status != 204) {
            next("/login");
          } else {
            if (to.meta.requireAdmin) {
              if (authUser.role === "admin") {
                next();
              } else {
                next("/login");
              }
            } else {
              next();
            }
            next();
          }
        })
        .catch(e => {
          // when check token fails, remove token ad log in again
          console.error(e);
          //context.dispatch("triggerError", e);
          localStorage.removeItem("token");
          next("/login");
        });
    }
  } else {
    next();
  }
});

export default router;
