import { StoreUtil } from "../utils";

const mutations = {
  SET_USER_LIST(state, payload) {
    state.userList = StoreUtil.updateState(state.userList, payload);
  },
  SET_USER_ADD(state, payload) {
    StoreUtil.updateState(state.userList, payload);
  },
  SET_USER_DELETE(state, payload) {
    StoreUtil.updateState(state.userList, payload);
  },
  SET_USER_UPDATE(state, payload) {
    StoreUtil.updateState(state.userList, payload);
  }
};

export default mutations;
