import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { StoreUtil } from "../utils";

const state = {

  dataContact: {
    pagination: {
      page: 1,
      itemsPerPage: 10,
      rowsPerPageItems: [10, 15, 25],
      totalItems: 0,
      search: ""
    },
    contactList: StoreUtil.state(),
    nextContactId: null
  },

  dataAll: {
    pagination: {
      page: 1,
      itemsPerPage: 10,
      rowsPerPageItems: [10, 15, 25],
      totalItems: 0,
      search: ""
    },
    allList: StoreUtil.state()
  },

  dataAllPagination: {
    allList: StoreUtil.state()
  },

  dataKunde: {
    pagination: {
      page: 1,
      itemsPerPage: 10,
      rowsPerPageItems: [10, 15, 25],
      totalItems: 0,
      search: ""
    },
    kundeList: StoreUtil.state()
  },

  dataLieferant: {
    pagination: {
      page: 1,
      itemsPerPage: 10,
      rowsPerPageItems: [10, 15, 25],
      totalItems: 0,
      search: ""
    },
    lieferantList: StoreUtil.state()
  },

  dataPartner: {
    pagination: {
      page: 1,
      itemsPerPage: 10,
      rowsPerPageItems: [10, 15, 25],
      totalItems: 0,
      search: ""
    },
    partnerList: StoreUtil.state()
  },

  dataInteressent: {
    pagination: {
      page: 1,
      itemsPerPage: 10,
      rowsPerPageItems: [10, 15, 25],
      totalItems: 0,
      search: ""
    },
    interessentList: StoreUtil.state()
  },
}

const contactsModule = {
  state,
  mutations,
  actions,
  getters
}

export default contactsModule;