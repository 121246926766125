<template>
  <v-tabs>
      <v-tab exact>
        Benutzer
      </v-tab>
      <v-tab v-if="license.modules.customerPortal">
        Kundenportal
      </v-tab>

      <v-tab-item :transition="false" :reverse-transition="false">
        <userTable></userTable>
      </v-tab-item>
      <v-tab-item :transition="false" :reverse-transition="false" v-if="license.modules.customerPortal">
        <customerTable></customerTable>
      </v-tab-item>
    </v-tabs>
</template>
<script>
import userTable from "./../../components/users/userTable.vue";
import customerTable from "./../../components/users/customerTable.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    userTable,
    customerTable
  },
  data() {
    return {
      license: {
        modules: {}
      }
    };
  },
  computed: {
    ...mapGetters(["licenseData"])
  },
  mounted(){
    this.$store.dispatch("getLicenseData").then(result => {});
  },
  watch: {
    licenseData: {
      handler(val) {
        if (val.data[0]) {
          this.license = val.data[0].value;
        }
      }
    }
  }
};
</script>