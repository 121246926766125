import { StoreUtil } from "../utils";

const mutations = {
  SET_ERROR_LIST(state, payload) {
    state.errorList = StoreUtil.updateState(state.errorList, payload);
  },
  SET_PAGINATION_ERROR(state, payload) {
    state.pagination = payload;
  },
  SET_TOTAL_ITEMS_ERROR(state, payload) {
    state.pagination.totalItems = payload;
  }
};

export default mutations;
