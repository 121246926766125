<template>
  <v-tabs>
      <v-tab exact>
        Artikel
      </v-tab>

      <v-tab-item :transition="false" :reverse-transition="false">
        <inventoryTable></inventoryTable>
      </v-tab-item>
    </v-tabs>
</template>
<script>
import inventoryTable from "./../components/inventory/inventoryTable.vue";

export default {
  components: {
    inventoryTable
  },
  data() {
    return {
      
    };
  }
};
</script>