<template>
  <header class="app-header">
    <v-toolbar elevation="0">
      <v-toolbar-title>Aufträge</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-row>
        <v-col cols="12" sm="6" md="3" class="spacing pt-9 pr-0">
          <singleDateForm
            v-model="schedulerDate"
            label="Datum"
            data-cy="schedulerDate"
            prependIcon="event"
            @input="prepareChangeSchedulerDate(schedulerDate)"
          />
        </v-col>
        <v-col
          cols="12"
          sm="1"
          md="3"
          class="spacing pt-9 pr-0"
          v-if="
            !(
              $vuetify.breakpoint.name == 'sm' ||
              $vuetify.breakpoint.name == 'xs'
            )
          "
        >
          <v-select
            v-model="resource"
            :items="resourcesDataMap"
            item-text="Description"
            item-value="id"
            label="nach Farzeug sortieren"
            data-cy="resource"
            clearable
            chips
            dense
            outlined
            persistent-hint
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          sm="1"
          md="3"
          class="spacing pt-9 pr-0"
          v-if="
            !(
              $vuetify.breakpoint.name == 'sm' ||
              $vuetify.breakpoint.name == 'xs'
            )
          "
        >
          <v-select
            v-model="worker"
            :items="workerDataMap"
            item-text="Description"
            item-value="id"
            label="nach Fahrer sortieren"
            data-cy="worker"
            clearable
            chips
            dense
            outlined
            persistent-hint
          ></v-select>
        </v-col>
      </v-row>
      <div class="flex-grow-1"></div>
      <v-btn
        fab
        small
        @click="setDates('back')"
        class="mr-2"
        color="primary"
        dark
        ><v-icon right> arrow_back_ios</v-icon></v-btn
      >
      <v-btn
        fab
        small
        @click="setDates('forward')"
        class="mr-1"
        color="primary"
        dark
        ><v-icon>arrow_forward_ios</v-icon></v-btn
      >

      <v-btn-toggle
        v-model="toggle_exclusive_view_mode"
        mandatory
        dense
        outlined
        class="pa-3"
        v-if="
          !(
            $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'
          )
        "
        ><v-btn>
          Tag
        </v-btn>
        <v-btn>
          3 Tage
        </v-btn>
        <v-btn>
          Woche
        </v-btn>
        <v-btn>
          Monat
        </v-btn>
        <v-btn>
          Jahr
        </v-btn>
      </v-btn-toggle>
      <v-divider inset vertical></v-divider>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="resetScheduler">
            <v-icon>settings_backup_restore</v-icon>
          </v-btn>
        </template>
        <span>Zurücksetzen</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="createEventWithoutPreset" data-cy="createAuftragTable">
            <v-icon>note_add</v-icon>
          </v-btn>
        </template>
        <span>Auftrag anlegen</span>
      </v-tooltip>
    </v-toolbar>
  </header>
</template>

<script>
import singleDateForm from "../../_utils/singleDateForm.vue";
import { mapGetters } from "vuex";

export default {
  props: {
    link: { type: String, default: ".." },
    title: { type: String, default: "onDispatch" }
  },
  components: {
    singleDateForm
  },
  data: () => ({
    schedulerDates: null,
    schedulerDate: new Date().toISOString().substr(0, 10),
    time: "Tag",
    toggle_exclusive_view_mode: 0,
    selectWorker: "",
    selectResource: ""
  }),
  mounted() {
    document.title = this.$props.title;
    this.resetScheduler();
  },
  computed: {
    ...mapGetters([
      "resourcesData",
      "resourcesStatusPending",
      "workerData",
      "workerStatusPending"
    ]),
    resourcesDataMap() {
      return this.resourcesData.map(resource => {
        const Description = resource.name;
        const id = resource.id;
        return Object.assign({}, resource, { Description, id });
      });
    },
    workerDataMap() {
      return this.workerData.map(worker => {
        const Description = worker.name + " - " + worker.short;
        const id = worker.id;
        return Object.assign({}, worker, { Description, id });
      });
    },
    resource: {
      get() {
        return;
      },
      set(val) {
        if (!val) {
          this.selectResource = "";
          this.$emit("changeDate", this.schedulerDates, {
            resource: "",
            worker: this.selectWorker
          });
        } else {
          this.selectResource = val;
          this.$emit("changeDate", this.schedulerDates, {
            resource: val,
            worker: this.selectWorker
          });
        }
      }
    },
    worker: {
      get() {
        return;
      },
      set(val) {
        if (!val) {
          this.selectWorker = "";
          this.$emit("changeDate", this.schedulerDates, {
            resource: this.selectResource,
            worker: ""
          });
        } else {
          this.selectWorker = val;
          this.$emit("changeDate", this.schedulerDates, {
            resource: this.selectResource,
            worker: val
          });
        }
      }
    }
  },
  watch: {
    toggle_exclusive_view_mode(val) {
      this.setDates(val);
    },
  },
  methods: {
    prepareChangeSchedulerDate(val) {
      this.$router
        .push({
          name: "planerTable",
          params: {
            back: 1,
            tab: 2
          }
        })
        .catch(err => {});
      this.changeSchedulerDate(val);
    },
    changeSchedulerDate(val) {
      //set component scheduler dates
      if (this.$route.params.back != 1) {
        const backItem = JSON.parse(this.$route.params.back);
        if (backItem.dates.length > 1) {
          this.schedulerDates = [
            new Date(backItem.dates[0]),
            new Date(backItem.dates[1])
          ];
          this.schedulerDate = backItem.dates[1].substr(0, 10);
        } else {
          //reset dates if no date is present
          this.schedulerDates = [
            new Date(val.setHours(0, 0, 0, 0)),
            new Date(val.setHours(23, 59, 59, 999))
          ];
          this.schedulerDate = new Date().toISOString().substr(0, 10);
        }
        //this.resource = backItem.resource;
        //this.worker = backItem.worker;

        this.$emit("changeDate", this.schedulerDates, {
          resource: "",
          worker: ""
        });
      } else {
        this.schedulerDates = [
          new Date(val.setHours(0, 0, 0, 0)),
          new Date(val.setHours(23, 59, 59, 999))
        ];
        //emit nem values to table
        this.$emit("changeDate", this.schedulerDates, {
          resource: this.selectResource,
          worker: this.selectWorker
        });
      }
    },
    exitDialogEvent() {
      //relaod data after event was edited / added
      this.$store.dispatch(
        "queryItemsEventsWithDates",
        {
          start: this.schedulerDates[0],
          end: this.schedulerDates[1]
        },
        {
          resource: this.selectResource,
          worker: this.selectWorker
        }
      );
    },
    createEventWithoutPreset() {
      const data = {
        eventDate: this.schedulerDates[0],
        rowId: this.selectResource
      };
      this.$router.push({
        name: "eventForm",
        params: {
          id: JSON.stringify({ data }),
          mode: "preset",
          back: JSON.stringify({
            to: "planerTable",
            data: {
              dates: this.schedulerDates,
              resource: this.selectResource,
              worker: this.selectWorker,
              back: 1,
              tab: 2
            }
          })
        }
      });
    },
    editEvent(event) {
      //only allow event edits when event is not green "Abgeschlossen"
      if (event.eventColor != "green" && event.status != "fertig") {
        this.$router.push({
          name: "eventForm",
          params: {
            id: event.id,
            mode: "edit",
            back: JSON.stringify({
              to: "planerTable",
              data: {
                dates: this.schedulerDates,
                resource: this.selectResource,
                worker: this.selectWorker
              }
            })
          }
        });
      } else {
        this.$store.dispatch("triggerUniversalAlert", {
          text:
            "Der Lieferschein wurde abgeschlossen und kann nicht mehr bearbeitet werden.",
          heading: "Fehler - Lieferschein bearbeiten",
          type: "warning"
        });
      }
    },
    resetScheduler() {
      this.$store.dispatch("queryItemsEvents");
      this.$store.dispatch("queryItemsWorker");
      //reset worker / vehicle picker
      this.selectWorker = "";
      this.selectResource = "";

      this.toggle_exclusive_view_mode = 0;
      //reset scheduler dates to prevent datepicker errors
      this.changeSchedulerDate(new Date());
    },
    reloadDates() {
      this.$emit("changeDate", this.schedulerDates, {
        resource: this.selectResource,
        worker: this.selectWorker
      });
    },
    setDates(val) {
      let mode = val;
      let today = new Date();
      let start = new Date(today.setHours(0, 0, 0, 0));
      this.schedulerDate = new Date(this.schedulerDate);
      let end;
      if (val == "back" || val == "forward") {
        val = this.toggle_exclusive_view_mode;
      }
      switch (val) {
        case 0:
          //heute
          if (mode == "back") {
            start = new Date(
              this.schedulerDates[0].setDate(this.schedulerDate.getDate() - 1)
            );
            end = new Date(
              this.schedulerDates[1].setDate(this.schedulerDate.getDate() - 1)
            );
          } else if (mode == "forward") {
            start = new Date(
              this.schedulerDates[0].setDate(this.schedulerDate.getDate() + 1)
            );
            end = new Date(
              this.schedulerDates[1].setDate(this.schedulerDate.getDate() + 1)
            );
          } else {
            end = new Date(today.setHours(23, 59, 59, 999));
          }
          this.schedulerDate = end;
          break;
        case 1:
          //3 tage
          if (mode == "back") {
            start = new Date(
              this.schedulerDates[0].setDate(this.schedulerDate.getDate() - 3)
            );
            end = new Date(
              this.schedulerDates[1].setDate(this.schedulerDate.getDate() - 3)
            );
          } else if (mode == "forward") {
            start = new Date(
              this.schedulerDates[0].setDate(this.schedulerDate.getDate() + 3)
            );
            end = new Date(
              this.schedulerDates[1].setDate(this.schedulerDate.getDate() + 3)
            );
          } else {
            end = new Date(
              new Date(today.setDate(today.getDate() + 2)).setHours(
                23,
                59,
                59,
                999
              )
            );
          }
          this.schedulerDate = start;
          break;
        case 2:
          //woche
          if (mode == "back") {
            start = new Date(
              this.schedulerDates[0].setDate(
                this.schedulerDates[0].getDate() - 7
              )
            );
            end = new Date(
              this.schedulerDates[1].setDate(
                this.schedulerDates[1].getDate() - 7
              )
            );
          } else if (mode == "forward") {
            start = new Date(
              this.schedulerDates[0].setDate(
                this.schedulerDates[0].getDate() + 7
              )
            );
            end = new Date(
              this.schedulerDates[1].setDate(
                this.schedulerDates[1].getDate() + 7
              )
            );
          } else {
            end = new Date(
              new Date(today.setDate(today.getDate() + 7)).setHours(
                23,
                59,
                59,
                999
              )
            );
          }
          this.schedulerDate = start;
          break;
        case 3:
          //monat
          if (mode == "back") {
            start = new Date(
              this.schedulerDates[0].setMonth(
                this.schedulerDates[0].getMonth() - 1
              )
            );
            end = new Date(
              this.schedulerDates[1].setMonth(
                this.schedulerDates[1].getMonth() - 1
              )
            );
          } else if (mode == "forward") {
            start = new Date(
              this.schedulerDates[0].setMonth(
                this.schedulerDates[0].getMonth() + 1
              )
            );
            end = new Date(
              this.schedulerDates[1].setMonth(
                this.schedulerDates[1].getMonth() + 1
              )
            );
          } else {
            end = new Date(
              new Date(today.setMonth(today.getMonth() + 1)).setHours(
                23,
                59,
                59,
                999
              )
            );
          }
          this.schedulerDate = start;
          break;
        case 4:
          //jahr
          if (mode == "back") {
            start = new Date(
              this.schedulerDates[0].setMonth(
                this.schedulerDates[0].getMonth() - 12
              )
            );
            end = new Date(
              this.schedulerDates[1].setMonth(
                this.schedulerDates[1].getMonth() - 12
              )
            );
          } else if (mode == "forward") {
            start = new Date(
              this.schedulerDates[0].setMonth(
                this.schedulerDates[0].getMonth() + 12
              )
            );
            end = new Date(
              this.schedulerDates[1].setMonth(
                this.schedulerDates[1].getMonth() + 12
              )
            );
          } else {
            end = new Date(
              new Date(today.setMonth(today.getMonth() + 12)).setHours(
                23,
                59,
                59,
                999
              )
            );
          }
          this.schedulerDate = start;
          break;
      }
      this.schedulerDates = [start, end];
      this.$emit("changeDate", this.schedulerDates, {
        resource: this.selectResource,
        worker: this.selectWorker
      });
    }
  }
};
</script>
