<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="dateRangeText"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="dateRangeText"
        :label="label"
        data-cy="dateRangeText"
        :prepend-icon="prependIcon"
        readonly
        :rules="requieredRule"
        v-on="on"
        outlined
        dense
      ></v-text-field>
    </template>
    <v-date-picker v-model="dates" locale="de-de" first-day-of-week="1" range>
      <v-spacer></v-spacer>
      <v-btn text color="secondary" @click="menu = false">Abbrechen</v-btn>
      <v-btn text color="secondary" @click="$refs.menu.save(dateRangeText)">OK</v-btn>
    </v-date-picker>
  </v-menu>
</template>
<script>
export default {
  props: ["value", "label", "prependIcon"],
  data: () => ({
    menu: false,
    valid: true,
    count: 0,
    requieredRule: [(v) => !!v || "Dieses Feld wird benötigt"],
  }),

  computed: {
    dateRangeText: {
      get() {
        // adapt date formatting to all filling states of the picker array
        if (this.dates[0] && this.dates[1]) {
          return this.formatDate(this.dates[0]) + " - " + this.formatDate(this.dates[1]);
        } else if (this.dates[0] && !this.dates[1]) {
          return this.formatDate(this.dates[0]) + " - ";
        } else {
          return " - " + this.formatDate(this.dates[1]);
        }
      },
      set(val) {
        this.$emit("input", [new Date(this.dates[0]), new Date(this.dates[1])]);
      },
    },
    dates: {
      get() {
        if (Array.isArray(this.value)) {
          if (this.value[0] && this.value[1]) {
            return [
              new Date(this.value[0]).toISOString().substr(0, 10),
              new Date(this.value[1]).toISOString().substr(0, 10),
            ];
          } else {
            return [
              new Date().toISOString().substr(0, 10),
              new Date().toISOString().substr(0, 10),
            ];
          }
        } else {
          return [
            new Date().toISOString().substr(0, 10),
            new Date().toISOString().substr(0, 10),
          ];
        }
      },
      set(val) {
        if (this.count == 0) {
          this.$emit("input", [new Date(val), null]);
          this.count = 1;
        } else {
          this.$emit("input", [this.value[0], new Date(val)]);
          this.count = 0;
        }
      },
    },
  },

  methods: {
    formatDate(date) {
      if (date.split("-").length < 3) {
        let dayFirst = date.slice(0, 1);
        let monthFirst = date.slice(3, 4);
        if (dayFirst == 0 || monthFirst == 0) {
          let year = date.slice(-4);
          let withoutYear = date.slice(0, 6);
          withoutYear = withoutYear.replace(/0/g, "");
          date = withoutYear + year;
        }
        return date;
      } else {
        const [year, month, day] = date.split("-");
        return `${day}.${month}.${year}`;
      }
    },
  },
};
</script>
