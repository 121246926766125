<template>
  <div>
    <v-list flat subheader three-line>
      <v-subheader><h4>Prozess-Einstellungen</h4></v-subheader>
    </v-list>
    <v-list-item>
      <v-list-item-content>
        <v-container>
          <v-card class="mx-auto" max-width="900">
            <v-container>
              <p>Lieferschein-Einstellungen</p>
            </v-container>
            <v-form class="pa-4" ref="form" lazy-validation>
              <p>Lieferscheine automatisch erstellen</p>
              <h5>
                Ist diese Option aktiviert, werden Lieferscheine welche durch
                den Kunden in der Fahrer App unterschrieben wurden automatisch
                erzeugt und mit dem Status Entwurf abgespeichert.
              </h5>
              <br />
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <p style="margin-top: 25px">
                    Lieferscheine bei Abschluss automatisch erstellen
                  </p>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-switch
                    v-model="createDeliveryNote"
                    @change="changeCreateDeliveryNote"
                  ></v-switch>
                </v-col>
              </v-row>
              <hr />
              <br />
              <br />
              <p>Lieferscheine automatisch versenden</p>
              <h5>
                Ist diese Option aktiviert, werden Lieferscheine nach der
                Erstellung automatisch an die im Kundenkontakt hinterlegte Email
                versendet.
              </h5>
              <br />
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <p style="margin-top: 25px">
                    Lieferscheine bei Abschluss automatisch versenden
                  </p>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-switch
                    v-bind:disabled="!createDeliveryNote"
                    v-model="deliveryNoteSendMail"
                    @change="changeSendMail"
                  ></v-switch>
                </v-col>
              </v-row>
              <hr />
              <br />
              <br />
              <p>Lieferscheine mit Anhang automatisch versenden</p>
              <h5>
                Gibt an ob Lieferscheine mit Foto-Anhang ebenfalls automatisch
                versendet werden sollen. Wenn diese Option deaktiviert ist,
                werden Lieferscheine mit Foto-Anhang als Entwurf gespeichert und
                nicht sofort versendet.
              </h5>
              <br />
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <p style="margin-top: 25px">
                    Lieferscheine bei Abschluss automatisch versenden
                  </p>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-switch
                    v-bind:disabled="!createDeliveryNote"
                    v-model="autoSendAttachment"
                    @change="changeSendAttachment"
                  ></v-switch>
                </v-col>
              </v-row>
              <hr />
              <br />
              <br />
              <p>Fahrzeugkennung auf Lieferschein anzeigen</p>
              <h5>
                Gibt an, ob das Nummernschild des Fahrzeugs auf der
                Lieferscheinvorlage angezeigt werden soll.
              </h5>
              <br />
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <p style="margin-top: 25px">
                    Nummernschild auf Lieferschein anzeigen
                  </p>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-switch
                    v-model="showVehicleOnDeliveryNote"
                    @change="changeShowVehicle"
                  ></v-switch>
                </v-col>
              </v-row>
              <hr />
              <br />
              <br />
              <p>Auftragsort auf Lieferschein anzeigen</p>
              <h5>
                Gibt an, ob der Auftragsort des Auftrags auf der
                Lieferscheinvorlage angezeigt werden soll.
              </h5>
              <br />
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <p style="margin-top: 25px">
                    Auftragsort auf Lieferschein anzeigen
                  </p>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-switch
                    v-model="showLocationOnDeliveryNote"
                    @change="changeShowLocation"
                  ></v-switch>
                </v-col>
              </v-row>
              <hr />
              <br />
              <br />
              <p>Fahrer auf Lieferschein anzeigen</p>
              <h5>
                Gibt an, ob der Fahrer des Fahrzeugs auf der
                Lieferscheinvorlage angezeigt werden soll.
              </h5>
              <br />
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <p style="margin-top: 25px">
                    Fahrer auf Lieferschein anzeigen
                  </p>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-switch
                    v-model="showWorkerOnDeliveryNote"
                    @change="changeShowWorker"
                  ></v-switch>
                </v-col>
              </v-row>
              <hr />
              <br />
              <br />
              <p>Zeitstempel zur Signatur anzeigen</p>
              <h5>
                Gibt an, ob der Zeitstempel bei der Signatur auf Lieferscheinen
                angezeigt werden soll.
              </h5>
              <br />
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <p style="margin-top: 25px">
                    Zeitstempel anzeigen
                  </p>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-switch
                    v-model="signatureTimestamp"
                    @change="changeSignatureTimestamp"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-container>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  data: () => ({
    deliveryNoteSendMail: false,
    createDeliveryNote: false,
    useAdvancedInvoiceTable: false,
    autoSendAttachment: false,
    showVehicleOnDeliveryNote: false,
    showLocationOnDeliveryNote: false,
    showWorkerOnDeliveryNote: false,
    signatureTimestamp: false,
  }),
  computed: {
    ...mapGetters(["backendServer"]),
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };
      axios
        .get(this.backendServer + "settings", config)
        .then((response) => {
          for (let setting of response.data.data) {
            if (setting.name == "autoSendDeliveryMail") {
              this.deliveryNoteSendMail = setting.value;
            } else if (setting.name == "autoCreateDeliveryNote") {
              this.createDeliveryNote = setting.value;
            } else if (setting.name == "useAdvancedInvoiceTable") {
              this.useAdvancedInvoiceTable = setting.value;
            } else if (setting.name == "autoSendAttachment") {
              this.autoSendAttachment = setting.value;
            } else if (setting.name == "showVehicleOnDeliveryNote") {
              this.showVehicleOnDeliveryNote = setting.value;
            } else if (setting.name == "showLocationOnDeliveryNote") {
              this.showLocationOnDeliveryNote = setting.value;
            } else if (setting.name == "showWorkerOnDeliveryNote") {
              this.showWorkerOnDeliveryNote = setting.value;
            } else if (setting.name == "signatureTimestamp") {
              this.signatureTimestamp = setting.value;
            }
          }
        })
        .catch((err) => {});
    },
    changeSendMail() {
      let vm = this;
      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };

      let putURL = this.backendServer + "settings/autoSendDeliveryMail";

      axios
        .put(putURL, { value: this.deliveryNoteSendMail }, config)
        .then((response) => {
          vm.$store.dispatch("triggerUniversalAlert", {
            text: "Einstellung wurde erfolgreich aktualisiert",
            heading: "Erfolgreich aktualisiert",
            type: "info",
          });
        })
        .catch((err) => {
          vm.$store.dispatch("triggerUniversalAlert", {
            text:
              "Es ist ein unerwarteter Fehler aufgetreten bitte kontaktieren Sie den Support",
            heading: "Unerwarteter Fehler",
            type: "error",
          });
        });
    },
    changeShowVehicle() {
      let vm = this;
      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };

      let putURL = this.backendServer + "settings/showVehicleOnDeliveryNote";

      axios
        .put(putURL, { value: this.showVehicleOnDeliveryNote }, config)
        .then((response) => {
          vm.$store.dispatch("triggerUniversalAlert", {
            text: "Einstellung wurde erfolgreich aktualisiert",
            heading: "Erfolgreich aktualisiert",
            type: "info",
          });
        })
        .catch((err) => {
          vm.$store.dispatch("triggerUniversalAlert", {
            text:
              "Es ist ein unerwarteter Fehler aufgetreten bitte kontaktieren Sie den Support",
            heading: "Unerwarteter Fehler",
            type: "error",
          });
        });
    },
    changeShowLocation() {
      let vm = this;
      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };

      let putURL = this.backendServer + "settings/showLocationOnDeliveryNote";

      axios
        .put(putURL, { value: this.showLocationOnDeliveryNote }, config)
        .then((response) => {
          vm.$store.dispatch("triggerUniversalAlert", {
            text: "Einstellung wurde erfolgreich aktualisiert",
            heading: "Erfolgreich aktualisiert",
            type: "info",
          });
        })
        .catch((err) => {
          vm.$store.dispatch("triggerUniversalAlert", {
            text:
              "Es ist ein unerwarteter Fehler aufgetreten bitte kontaktieren Sie den Support",
            heading: "Unerwarteter Fehler",
            type: "error",
          });
        });
    },
    changeShowWorker() {
      let vm = this;
      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };

      let putURL = this.backendServer + "settings/showWorkerOnDeliveryNote";

      axios
        .put(putURL, { value: this.showWorkerOnDeliveryNote }, config)
        .then((response) => {
          vm.$store.dispatch("triggerUniversalAlert", {
            text: "Einstellung wurde erfolgreich aktualisiert",
            heading: "Erfolgreich aktualisiert",
            type: "info",
          });
        })
        .catch((err) => {
          vm.$store.dispatch("triggerUniversalAlert", {
            text:
              "Es ist ein unerwarteter Fehler aufgetreten bitte kontaktieren Sie den Support",
            heading: "Unerwarteter Fehler",
            type: "error",
          });
        });
    },
    changeSendAttachment() {
      let vm = this;
      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };

      let putURL = this.backendServer + "settings/autoSendAttachment";

      axios
        .put(putURL, { value: this.autoSendAttachment }, config)
        .then((response) => {
          vm.$store.dispatch("triggerUniversalAlert", {
            text: "Einstellung wurde erfolgreich aktualisiert",
            heading: "Erfolgreich aktualisiert",
            type: "info",
          });
        })
        .catch((err) => {
          vm.$store.dispatch("triggerUniversalAlert", {
            text:
              "Es ist ein unerwarteter Fehler aufgetreten bitte kontaktieren Sie den Support",
            heading: "Unerwarteter Fehler",
            type: "error",
          });
        });
    },
    changeCreateDeliveryNote() {
      let vm = this;
      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };

      let putURL = this.backendServer + "settings/autoCreateDeliveryNote";

      axios
        .put(putURL, { value: this.createDeliveryNote }, config)
        .then((response) => {
          //deactivate autoSendMail if autoCreate mail is deactivated to ensure a logical user experience
          if (!vm.createDeliveryNote) {
            let putURL = this.backendServer + "settings/autoSendDeliveryMail";

            axios
              .put(putURL, { value: false }, config)
              .then((response) => {
                vm.deliveryNoteSendMail = false;
                vm.$store.dispatch("triggerUniversalAlert", {
                  text: "Einstellung wurde erfolgreich aktualisiert",
                  heading: "Erfolgreich aktualisiert",
                  type: "info",
                });
              })
              .catch((err) => {
                vm.$store.dispatch("triggerUniversalAlert", {
                  text:
                    "Es ist ein unerwarteter Fehler aufgetreten bitte kontaktieren Sie den Support",
                  heading: "Unerwarteter Fehler",
                  type: "error",
                });
              });

            axios
              .put(
                this.backendServer + "settings/autoSendAttachment",
                { value: false },
                config
              )
              .then(() => {
                vm.autoSendAttachment = false;
              })
              .catch(() => {});
          } else {
            vm.$store.dispatch("triggerUniversalAlert", {
              text: "Einstellung wurde erfolgreich aktualisiert",
              heading: "Erfolgreich aktualisiert",
              type: "info",
            });
          }
        })
        .catch((err) => {
          vm.$store.dispatch("triggerUniversalAlert", {
            text:
              "Es ist ein unerwarteter Fehler aufgetreten bitte kontaktieren Sie den Support",
            heading: "Unerwarteter Fehler",
            type: "error",
          });
        });
    },
    changeSignatureTimestamp() {
      let vm = this;
      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };

      let putURL = this.backendServer + "settings/signatureTimestamp";

      axios
        .put(putURL, { value: this.signatureTimestamp }, config)
        .then((response) => {
          vm.$store.dispatch("triggerUniversalAlert", {
            text: "Einstellung wurde erfolgreich aktualisiert",
            heading: "Erfolgreich aktualisiert",
            type: "info",
          });
        })
        .catch((err) => {
          vm.$store.dispatch("triggerUniversalAlert", {
            text:
              "Es ist ein unerwarteter Fehler aufgetreten bitte kontaktieren Sie den Support",
            heading: "Unerwarteter Fehler",
            type: "error",
          });
        });
    },
  },
};
</script>
