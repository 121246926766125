import { StoreUtil } from "../utils";

const mutations = {
  SET_ACTIVITY_LIST(state, payload) {
    state.activityList = StoreUtil.updateState(state.activityList, payload);
  },
  SET_PAGINATION_ACTIVITY(state, payload) {
    state.pagination = payload;
  },
  SET_TOTAL_ITEMS_ACTIVITY(state, payload) {
    state.pagination.totalItems = payload;
  }
};

export default mutations;
