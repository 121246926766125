<template>
  <v-tabs v-model="activeTab">
      <v-tab v-for="tab of tabs" :key="tab.id" :to="tab.route" exact>
        {{ tab.name }}
      </v-tab>

      <v-tabs-items v-for="tab of tabs" :id="tab.route" :key="tab.id" :value="tab.route" :transition="false" :reverse-transition="false" :touchless="true">
        <router-view></router-view>
      </v-tabs-items>
    </v-tabs>
</template>
<script>
export default {
  data() {
    return {
      activeTab: this.$route.params.tab,
      tabs: [
        { id: 1, name: "Plantafel", route: `/planer/1/planer/1/1/1/1` },
        { id: 2, name: "Tabelle", route: `/planer/2/tabelle/1` }
      ]
    };
  }
};
</script>