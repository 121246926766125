import { StoreUtil } from "../utils";
const mutations = {
  SET_NET_REVENUE_DATA(state, payload) {
    state.netRevenueData = StoreUtil.updateState(
      state.netRevenueData,
      payload
    );
  },
  SET_BRUT_REVENUE_DATA(state, payload){
    state.brutRevenueData = StoreUtil.updateState(
      state.brutRevenueData,
      payload
    );
  },
  SET_WORKLOAD_DATA(state, payload){
    state.workloadData = StoreUtil.updateState(
      state.workloadData,
      payload
    );
  },
  SET_REVENUE_CONTACT_DATA(state, payload) {
    state.revenueContactData = StoreUtil.updateState(
      state.revenueContactData,
      payload
    );
  }
};

export default mutations;
