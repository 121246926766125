import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"ma-3",attrs:{"height":"100%"}},[_c(VCardTitle,[_vm._v("Hilfe erhalten")]),_c('p',{staticClass:"text-left ma-4"},[_vm._v(" Schnelle und kompetente Hilfe direkt von unseren Mitarbeitern ")]),_c(VList,[_c(VListItem,{on:{"click":_vm.openLinkDoku}},[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-email")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("onDispatch Dokumentation")]),_c(VListItemSubtitle,[_vm._v("Hier erhalten Sie Informationen zu allen Funktionen")])],1),_c(VListItemIcon,[_c(VIcon,[_vm._v("launch")])],1)],1),_c(VListItem,{on:{"click":_vm.openLinkHelp}},[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("library_books")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("onDispatch Online-Hilfe")]),_c(VListItemSubtitle,[_vm._v("Bei allen Anliegen helfen wir Ihnen hier weiter")]),_c(VListItemSubtitle)],1),_c(VListItemIcon,[_c(VIcon,[_vm._v("launch")])],1)],1),_c(VDivider,{attrs:{"inset":""}}),_c(VListItem,{on:{"click":_vm.openLinkEmail}},[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("help")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("support@ondispatch.de")]),_c(VListItemSubtitle,[_vm._v("Direkt Hilfe per Email erhalten")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }