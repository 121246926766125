<template>
  <div>
    <v-data-table
      :headers="headers"
      :options.sync="pagination"
      :server-items-length="pagination.totalItems"
      :loading="invoiceStatusPending"
      :items="invoiceListGetter.invoiceList.data"
      @click:row="clickedRow"
      :footer-props="{
        itemsPerPageOptions: pagination.rowsPerPageItems,
        itemsPerPageText: 'Zeilen pro Seite'
      }"
      class="pa-5"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Rechnungen</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
            v-model="pagination.search"
            label="Suche..."
            data-cy="search"
            single-line
            hide-details
            dense
            clearable
          ></v-text-field>
          <v-btn text icon color="secondary">
            <v-icon>search</v-icon>
          </v-btn>
          <div class="flex-grow-1"></div>
          <v-btn
            color="secondary"
            dark
            class="mb-2"
            to="rechnungen/edit/0/new"
            data-cy="newInvoice"
            >Neue Rechnung</v-btn
          >
        </v-toolbar>
      </template>
      <template v-slot:item.iconStatus="{ item }">
        <div v-if="item.status == 'Entwurf'">
          <v-icon color="grey darken-2">assignment</v-icon>
          {{ item.status }}
        </div>
        <div v-if="item.status == 'Versendet'">
          <v-icon color="secondary">send</v-icon>
          {{ item.status }}
        </div>
        <div v-if="item.status == 'Storniert'">
          <v-icon color="red darken-2">clear</v-icon>
          {{ item.status }}
        </div>
        <div v-if="item.status == 'Exportiert'">
          <v-icon color="green darken-2">mdi-clipboard-check</v-icon>
          {{ item.status }}
        </div>
      </template>
      <template v-slot:item.recipient="{ item }">
        <!-- display only the first row of the Adress Field -->
        {{
          item.address
            .split("/p>")[0]
            .slice(3)
            .slice(0, -1)
        }}
      </template>
      <template v-slot:item.formattedSumNetto="{ item }">
        {{
          item.netTotal
            .toFixed(2)
            .toString()
            .replace(".", ",")
        }} EUR
      </template>
      <template v-slot:item.formattedSumBrutto="{ item }">
        {{
          item.brutTotal
            .toFixed(2)
            .toString()
            .replace(".", ",")
        }} EUR
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip
          bottom
          v-if="
            (item.status === 'Versendet' || item.status === 'Entwurf') &&
              licenseData.data[0].value.modules.sevdesk
          "
        >
          <template v-slot:activator="{ on }">
            <v-icon
              medium
              class="mr-2"
              v-on="on"
              @click.stop="exportInvoice(item)"
              >send</v-icon
            >
          </template>
          <span>Rechnungsexport zu Sevdesk</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }" v-if="item.status == 'Entwurf'">
            <v-icon
              class="mr-2"
              v-on="on"
              @click.stop="openMailDialog(item)"
              data-cy="sendMail"
              >email</v-icon
            >
          </template>
          <span>Dokument per Email versenden</span>
        </v-tooltip>
        <v-tooltip bottom v-if="item.status != 'Versendet'">
          <template v-slot:activator="{ on }">
            <v-icon
              medium
              class="mr-2"
              v-on="on"
              @click.stop="editItem(item)"
              :disabled="item.status === 'Exportiert'"
              data-cy="edit"
              >edit</v-icon
            >
          </template>
          <span>Bearbeiten</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              medium
              class="mr-2"
              v-on="on"
              @click.stop="clickedRow(item)"
              data-cy="preview"
              >preview</v-icon
            >
          </template>
          <span>Detailansicht / Vorschau</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>keine Daten vorhanden</template>
    </v-data-table>
    <v-dialog v-model="loadPdf" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          PDF wird erstellt...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import { mapFields } from "vuex-map-fields";
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  components: {
    Loading
  },
  data: () => ({
    menu: false,
    snackbar: {
      visible: false,
      text: "Snackbar ..."
    },
    singleExpand: false,
    expanded: [],
    headers: [
      {
        text: "Status",
        align: "left",
        sortable: false,
        value: "iconStatus"
      },
      {
        text: "Rechnungsnummer",
        value: "formattedInvoiceNumber",
        sortable: false
      },
      { text: "Empfänger / Kunde", value: "recipient", sortable: false },
      { text: "Betreff", value: "header", sortable: false },
      { text: "Ansprechpartner", value: "contactPerson", sortable: false },
      {
        text: "Rechnungsdatum",
        value: "formattedInvoiceDate",
        sortable: false
      },
      { text: "Betrag (netto)", value: "formattedSumNetto", sortable: false },
      { text: "Betrag (brutto)", value: "formattedSumBrutto", sortable: false },
      { text: "", value: "action", align: "right", sortable: false }
    ],
    states: ["Entwurf", "Versandt"],
    valid: true,
    requieredRule: [v => !!v || "Dieses Feld wird benötigt"],
    deleteDialog: false,
    toDeleteItem: null,
    loadPdf: false,
    header: "",
    subTablePdf: null
  }),
  computed: {
    pagination: {
      get: function() {
        return this.invoiceListGetter.pagination;
      },
      set: function(value) {
        this.$store.commit("SET_PAGINATION_INVOICES", value);
        this.$store.dispatch("getInvoiceList");
      }
    },
    ...mapGetters([
      "invoiceListGetter",
      "invoiceStatusPending",
      "backendServer",
      "licenseData"
    ])
  },
  created() {
    this.$store.dispatch("getLicenseData");
  },
  watch: {
    expanded(val) {
      let vm = this;
      // load pdf data when section is expandet
      if (val[0]) {
        this.getBase64PDF(val[0]).then(function(result) {
          vm.subTablePdf = "data:application/pdf;base64," + result;
        });
      }
    }
  },
  methods: {
    clickedRow(value) {
      this.$router.push({
        name: "documentDetails",
        params: { id: value.id, from: "rechnungen" }
      });
    },
    editItem(item) {
      this.$router.push({ path: "rechnungen/edit/" + item.id+"/edit" });
    },
    openDeleteDialog(item) {
      this.toDeleteItem = item;
      this.deleteDialog = true;
    },
    openMailDialog(item) {
      const header = "Rechnung RE-" + item.invoiceNumber;
      this.$store.dispatch("getInvoiceText").then(res => {
        this.$router.push({
          name: "sendEmail",
          params: {
            document: JSON.stringify(item),
            doctype: "invoice",
            defaultText: res.value.mail,
            header: JSON.stringify(header)
          }
        });
      });
    },
    deleteItem() {
      let vm = this;
      this.$store.dispatch("deleteInvoice", this.toDeleteItem).then(result => {
        vm.deleteDialog = false;
        vm.toDeleteItem = null;
      });
    },

    close() {
      this.dialog = false;
      this.expanded = [];
    },

    createPDF(item) {
      //create pdf for download
      this.loadPdf = true;
      this.getBase64PDF(item).then(function(result) {
        const linkSource = `data:application/pdf;base64,${result}`;
        const downloadLink = document.createElement("a");
        const fileName = "Rechnung-" + item.invoiceNumber + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });
    },
    getBase64PDF(item) {
      let vm = this;
      let postUrl = this.backendServer + "invoice/" + item.id + "/base64";
      let config = {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      };
      return axios
        .get(postUrl, config)
        .then(function(response) {
          vm.loadPdf = false;
          return response.data.pdf;
        })
        .catch(function(e) {
          vm.$store.dispatch("triggerError", e);
          vm.loadPdf = false;
        });
    },
    exportInvoice(item) {
      let vm = this;
      let postUrl = this.backendServer + "invoice/export/sevdesk/" + item.id;
      let config = {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      };
      return axios
        .post(postUrl, item, config)
        .then(function(response) {
          if ((response.status = 204)) {
            vm.$store.dispatch(
              "triggerSnackbar",
              "Rechnung erfolgreich zu Sevdesk exportiert"
            );
            vm.$store.dispatch("getInvoiceList");
          }
        })
        .catch(function(e) {
          vm.$store.dispatch("triggerError", e);
        });
    }
  }
};
</script>
<style></style>
