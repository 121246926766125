<template>
  <div>
    <v-navigation-drawer v-model="drawer" width="230px" app bottom permanent    expand-on-hover>
      <v-list dense>
        <v-row align="center">
          <v-col cols="12" class="text-center">
            <v-list-item class="px-2">
              <v-list-item-avatar>
                <v-img
                  src="../../assets/img/96x96logoNew.png"
                  alt="Logo"
                ></v-img>
              </v-list-item-avatar>
            </v-list-item>
          </v-col>
        </v-row>
        <template v-for="item in items">
          <v-row v-if="item.heading" :key="item.heading" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
            </v-col>
            <v-col cols="6" class="text-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-col>
          </v-row>
          <v-list-group
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.icon"
            append-icon
            :id="item.name"
          >
            <template v-slot:activator>
              <v-list-item :id="item.name" class="withSubmenu">
                <v-list-item-content
                  @click="navigateTo(item.route, 'noTab')"
                  active-class="highlighted"
                  :class="
                    '/' + item.route === $route.path ? 'highlighted' : 'notehi'
                  "
                >
                  <v-list-item-title
                    style="text-allign: start;"
                    class="v-list-item-title-color"
                  >
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              @click="navigateTo(child.route, 'noTab')"
              active-class="highlighted"
              :class="'/' + child.route === $route.path ? 'highlighted' : ''"
              :style="cssProps"
              :id="item.name"
            >
              <v-list-item-action v-if="child.icon" :id="item.name">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="v-list-item-title-color">
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
              <v-btn
                text
                icon
                color="grey lighten-2"
                @click.stop="navigateTo(child.route, 'newTab')"
                :id="item.name"
              >
                <v-icon>library_add</v-icon>
              </v-btn>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            :key="item.text"
            @click="navigateTo(item.route, 'noTab')"
            active-class="highlighted"
            :class="'/' + item.route === $route.path ? 'highlighted' : ''"
            :id="item.name"
          >
            <v-list-item-action :id="item.name">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="v-list-item-title-color">
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
            <v-btn
              text
              icon
              color="grey lighten-2"
              @click.stop="navigateTo(item.route, 'newTab')"
              :id="item.name"
            >
              <v-icon>library_add</v-icon>
            </v-btn>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <!-- vuex controlled default error-->
    <div class="text-center">
      <v-snackbar
        v-model="error.visible"
        :timeout="4000"
        left
        color="#ff5252"
        >{{ error.message }}</v-snackbar
      >
      <v-snackbar
        v-model="snackbar.visible"
        :timeout="4000"
        id="success"
        left
        color="secondary"
        >{{ snackbar.message }}</v-snackbar
      >
    </div>
    <!-- vuex controlled default alert dialog-->
    <v-row justify="center">
      <v-dialog v-model="universalAlert.visible" persistent max-width="550">
        <v-card>
          <v-card-title class="headline">{{
            universalAlert.heading
          }}</v-card-title>
          <v-card-text>
            <v-alert dense border="left" :type="universalAlert.type">
              {{ universalAlert.text }}
            </v-alert>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="$store.dispatch('closeUniversalAlert')"
              >Zurück</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["drawer"],
  data: () => ({
    drawer: null,
    items: [
      { icon: "dashboard", text: "Dashboard", route: "Dashboard" },
      {
        icon: "view_list",
        text: "Planer",
        model: false,
        route: "planer/1/planer/1/1/1/1",
        children: [
          { text: "Plantafel", route: "planer/1/planer/1/1/1/1" },
          { text: "Tabelle", route: "planer/2/tabelle/1" }
        ]
      },
      {
        icon: "file_copy",
        text: "Dokumente",
        model: false,
        route: "documents/1/lieferscheine",
        children: [
          { text: "Lieferscheine", route: "documents/1/lieferscheine" },
          { text: "Rechnungen", route: "documents/2/rechnungen" },
          { text: "Angebote", route: "documents/3/angebote" }
        ]
      },
      {
        icon: "group",
        "icon-alt": "group",
        text: "Kontakte",
        model: false,
        route: "contacts/1",
        children: [
          { text: "Übersicht", route: "contacts/1" },
          { text: "Kunden", route: "contacts/2/kunde" },
          { text: "Lieferanten", route: "contacts/3/lieferant" },
          { text: "Partner", route: "contacts/4/partner" },
          { text: "Interessenten", route: "contacts/5/interessent" }
        ]
      },
      {
        icon: "local_gas_station",
        text: "Fuhrpark",
        model: false,
        route: "fleet/1",
      },
      {
        icon: "mdi-clipboard-text-multiple",
        text: "Artikelverwaltung",
        route: "inventory"
      },
      { icon: "mdi-ballot", text: "Projekte", route: "projects" },
      { icon: "show_chart", text: "Analytics", route: "Analytics" },
      {
        icon: "settings",
        text: "Einstellungen",
        model: false,
        route: "companyData",
        children: [
          { text: "Benutzer", route: "Users" },
          { text: "Stammdaten", route: "companyData" },
          { text: "Datev-Export", route: "datev" },
          { text: "Lizenz", route: "license" },
          { text: "Daten-Import", route: "import" },
          { text: "Prozess-Einstellungen", route: "process" },
          { text: "Buchhaltung", route: "accounting" }
        ]
      }
    ]
  }),
  computed: {
    ...mapGetters(["error", "snackbar", "universalAlert"]),
    cssProps() {
      return {
        "--primary-color": this.$vuetify.theme.defaults.light.primary
      };
    },
    compDrawer: {
      get() {
        if(this.drawerVal == null) {
          return this.drawer;
        } else {
          return this.drawerVal;
        }
      },
      set(val) {
        this.drawerVal = val;
      }
    }
  },
  methods: {
    navigateTo(destination, modus) {
      if (modus == "newTab") {
        let href = window.location.origin + "/#" + destination;
        window.open(href, "_blank");
      } else {
        this.$router.replace({ path: "/" + destination });
      }
    }
  }
};
</script>
<style scoped>
.divider {
  border-top: 1px solid#e8e8e8;
}

.v-list-item-title-color {
  color: #717171;
}

.highlighted .v-icon {
  color: var(--primary-color);
}

.withSubmenu {
  padding: 0px;
}
</style>
