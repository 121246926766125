<template>
  <div>
    <br />
    <hr />
    <br />
    <p v-if="contact">
      Kundenspezifische Preise werden verwendet (falls vorhanden)
    </p>
    <p v-if="!contact">
      Nach dem auswählen eines Kunden werden kundenspezifische Preise angezeigt
    </p>
    <v-row>
      <v-expansion-panels
        v-bind:key="item.posId"
        v-for="item in inputVal"
        v-model="panel[item.posId]"
      >
        <v-expansion-panel class="ma-3">
          <v-expansion-panel-header>
            <v-row>
              <div class="pt-5 subtitle-1">
                <b>{{ item.posId }}.</b>
              </div>

              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-if="!item.inventory"
                  v-model="item.name"
                  label="Bezeichnung"
                  data-cy="inventory"
                  :rules="requieredRule"
                  dense
                  outlined
                  @click.stop=""
                ></v-text-field>
                <v-autocomplete
                  v-if="item.inventory"
                  v-model="item.name"
                  :items="inventoryDataMap"
                  :loading="inventoryStatusPending"
                  hide-no-data
                  item-text="Description"
                  item-value="id"
                  label="Artikel"
                  data-cy="name"
                  prepend-icon="mdi-database-search"
                  :rules="requieredRule"
                  dense
                  outlined
                  @input="updateItem(item)"
                  @click.stop=""
                ></v-autocomplete>
              </v-col>
              <div v-if="!onlyInventoryItems">
                <div v-if="!item.inventory" class="pr-4 pt-3">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        color="primary"
                        data-cy="useInventory"
                        @click="item.inventory = true"
                        v-on="on"
                      >
                        <v-icon>mdi-database-search</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ artTooltip }}</span>
                  </v-tooltip>
                </div>
                <div v-if="item.inventory" class="pr-4 pt-3">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        color="primary"
                        @click="item.inventory = false"
                        v-on="on"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </template>
                    <span>Artikelverwaltung schließen</span>
                  </v-tooltip>
                </div>
              </div>
              <!--div v-if="item.inventory" class="pr-4 pt-3">
                <v-btn icon color="green" @click="item.inventory = false">
                  <v-icon>mdi-tree</v-icon>
                </v-btn>
              </div-->
              <v-col v-if="prices" cols="12" sm="6" md="1">
                <v-text-field
                  v-model.number="item.quantity"
                  label="Menge"
                  data-cy="quantity"
                  :rules="requieredRule"
                  dense
                  outlined
                  type="Number"
                  :disabled="item.inventory && !onlyInventoryItems"
                  @click.stop=""
                ></v-text-field>
              </v-col>
              <v-col v-if="!prices" cols="12" sm="6" md="2">
                <v-text-field
                  v-model.number="item.quantity"
                  label="Menge"
                  data-cy="quantity"
                  :rules="requieredRule"
                  dense
                  outlined
                  type="Number"
                  :disabled="item.inventory && !onlyInventoryItems"
                  @click.stop=""
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="2">
                <v-select
                  v-model="item.unity"
                  :items="units"
                  label="Einheit"
                  data-cy="unity"
                  :rules="requieredRule"
                  dense
                  outlined
                  :disabled="item.inventory"
                  @click.stop=""
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="2">
                <v-text-field
                  v-if="prices"
                  v-model.number="item.price"
                  label="Preis"
                  :rules="priceRule"
                  data-cy="price"
                  dense
                  outlined
                  type="Number"
                  append-icon="euro_symbol"
                  :disabled="item.inventory"
                  @click.stop=""
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="1">
                <v-text-field
                  v-if="prices"
                  v-model.number="item.taxRate"
                  label="USt. in %"
                  data-cy="taxRate"
                  :rules="priceRule"
                  dense
                  outlined
                  type="Number"
                  :disabled="item.inventory"
                  @click.stop=""
                ></v-text-field>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-col cols="12" sm="6" md="12">
              <v-textarea
                v-model="item.description"
                label="Beschreibung"
                data-cy="description"
                dense
                outlined
                rows="2"
                row-height="25"
                :disabled="item.inventory"
                @click.stop=""
              ></v-textarea>
            </v-col>
            <v-row>
              <v-col cols="12" sm="6" md="1">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon @click="deleteEventPos(item.posId)" v-on="on">
                      <v-icon>mdi-delete-circle</v-icon>
                    </v-btn>
                  </template>
                  <span>Artikel entfernen</span>
                </v-tooltip>
              </v-col>
              <v-col v-if="optionalPos" cols="12" sm="6" md="3">
                <v-switch
                  v-model="item.optional"
                  label="Optionale Position"
                ></v-switch>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-row>
      <v-col cols="12" sm="1" md="2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              @click="addEventPos"
              pl-4
              v-on="on"
              data-cy="addEventPos"
            >
              <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
          </template>
          <span>Artikel hinzufügen</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <hr />
    <br />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["value", "prices", "contact", "optionalPos", "onlyInventoryItems"],
  data: () => ({
    panel: [false],
    requieredRule: [(v) => !!v || "Dieses Feld wird benötigt"],
    priceRule: [(v) => v.toString().length >= 1 || "Dieses Feld wird benötigt"],
    artTooltip: "Artikel exkl. kundenspezifische Artikel",
  }),
  mounted() {
    this.reloadArticles();
  },
  computed: {
    ...mapGetters([
      "units",
      "inventoryDataGetter",
      "inventoryStatusPending",
      "defaultMwst",
    ]),
    inputVal: {
      get() {
        // on edit set posIds once
        let vm = this;
        if (!this.value[0].posId) {
          this.value.forEach(function(element, index) {
            //element.price != null ? element.price : element.price = 0
            element.taxRate != null
              ? element.taxRate
              : (element.taxRate = vm.defaultMwst);
            element.posId = index + 1;
          });
        }
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    inventoryDataMap() {
      return this.createInventoryDataMap();
    },
  },
  watch: {
    contact: {
      handler(val) {
        this.reloadArticles(val);
        this.getIndivItemsContactPrices(val);
      },
    },
  },
  methods: {
    addEventPos() {
      if (this.prices) {
        //with prices
        this.inputVal.push({
          name: "",
          description: "",
          quantity: 1,
          unity: "Stk",
          price: 0.0,
          taxRate: this.defaultMwst,
          posId: this.inputVal.length + 1,
        });
      } else if (this.onlyInventoryItems) {
        //preselected option inventory items
        this.inputVal.push({
          name: "",
          description: "",
          quantity: 1,
          unity: "Stk",
          posId: this.inputVal.length + 1,
          inventory: true,
        });
      } else {
        //without prices
        this.inputVal.push({
          name: "",
          description: "",
          quantity: 1,
          unity: "Stk",
          posId: this.inputVal.length + 1,
        });
      }
      this.panel[this.inputVal.length + 1] = false;
    },
    deleteEventPos(id) {
      if (this.inputVal.length > 1) {
        this.inputVal.splice(id - 1, 1);
        this.renewIndexOfArray();
      }
    },
    renewIndexOfArray() {
      let i = 1;
      this.inputVal.forEach((item) => {
        item.posId = i;
        i++;
      });
    },
    createInventoryDataMap() {
      return this.inventoryDataGetter.map((item) => {
        let Description =
          item.objectId +
          " - " +
          item.name +
          " - " +
          item.price +
          "€ / " +
          item.unity;
        item.customCondition
          ? (Description = Description + " (indiv. Preis)")
          : "";
        const id = item.id;
        return Object.assign({}, item, {
          Description,
          id,
        });
      });
    },
    reloadArticles(val) {
      if (this.contact) {
        this.artTooltip = "Artikel inkl. kundenspezifische Artikel";
      } else {
        this.artTooltip = "Artikel exkl. kundenspezifische Artikel";
      }
      this.$store.dispatch("getAllInventory", val);
    },
    // reload prices from articles with customer conditions
    getIndivItemsContactPrices(val) {
      this.$store.dispatch("getAllInventory", val).then((r) => {
        r.forEach((e) => {
          this.inputVal.forEach((elem) => {
            if (e.id === elem.id) {
              elem.price = e.price;
              elem.taxRate = e.taxRate;
            }
          });
        });
      });
    },
    updateItem(val) {
      if (this.onlyInventoryItems) {
        //format data when onlyInventoryItems is selected
        const item = {
          id: val.name,
          contact: this.contact,
        };
        this.$store.dispatch("getSingleInventoryItem", item).then((r) => {
          val = r.id;
        });
        val.inventory = true;
      } else {
        //format data when onlyInventoryItems is not selected
        const item = {
          id: val.name,
          contact: this.contact,
        };
        this.$store.dispatch("getSingleInventoryItem", item).then((r) => {
          val.id = r.id;
          val.name = r.name;
          val.description = r.description;
          val.unity = r.unity;
          val.quantity = r.quantity;
          val.price = r.price;
          val.taxRate = r.taxRate;
        });
        val.inventory = false;
      }
    },
  },
};
</script>

<style scoped>
.remPadding {
  padding-bottom: -12px;
}
</style>
