<template>
  <v-card height="100%"> 
    <v-data-table
      :headers="headers"
      :options.sync="activityListGetter.pagination"
      :server-items-length="activityListGetter.pagination.totalItems"
      :items="activityListGetter.activityList.data"
      :loading="activityStatusPending"
      :sort-by="['createdAt']"
      :sort-desc="['true', 'false']"
      class="ma-3"
      :footer-props="{
        itemsPerPageOptions: activityListGetter.pagination.rowsPerPageItems,
        itemsPerPageText: 'Zeilen pro Seite'
      }"
    >
      <template v-slot:no-data>
        keine Daten vorhanden
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import Loading from "vue-loading-overlay";
import { mapGetters } from "vuex";
export default {
  components: {
    Loading
  },
  data: () => ({
    dialog: false,
    snackbar: {
      visible: false,
      text: "Snackbar ..."
    },
    headers: [
      { text: "Nutzer", align: "left", value: "user.email", sortable: false },
      { text: "Beschreibung", value: "description", sortable: false },
      { text: "Datum", value: "formattedLastModified", sortable: false }
    ],
    editedIndex: -1,
    editedItem: {}
  }),

  computed: {
    pagination: {
      get: function() {
        return this.$store.getters.activityListGetter.pagination;
      },
      set: function(value) {
        this.$store.commit("SET_PAGINATION_ERROR", value);
      }
    },
    ...mapGetters(["activityListGetter", "activityStatusPending"])
  },
  watch: {
    pagination: {
      handler() {
        this.$store.dispatch("getActivityList");
      }
    }
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$store.dispatch("getActivityList");
    }
  }
};
</script>
<style>
.expanded__content {
  box-shadow: none !important;
  background: white !important;
}
</style>
