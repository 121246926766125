import axios from "axios";

const actions = {
  getCustomerList(context, searchKey) {
    context.commit("SET_CUSTOMER_LIST");
    let getUrl = null;
    if (searchKey != null && searchKey != "") {
      getUrl = context.getters.backendServer + "customer?q=" + searchKey + "&page=1&perPage=10";
    } else {
      getUrl = context.getters.backendServer + "customer";
    }
    let config = {
      headers: {
        authorization: localStorage.getItem("token")
      }
    };

    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_CUSTOMER_LIST", response.data.data);
      })
      .catch(function(e) {
        context.commit("SET_CUSTOMER_LIST", e);
        context.dispatch("triggerError", e);
      });
  },

  sendCustomerInviteMail(context, value) {
    let config = {
          headers: {
            Authorization: localStorage.getItem("token")
          }
        };
    let postURL = context.getters.backendServer + "customer/invite/mail";

    return axios.post(
          postURL,
          { recipient: value }, config
        )
        .then((response)=>{
          context.commit("CREATE_SNACKBAR", "Email wurde versendet");
        })  
        .catch((e)=>{
          context.dispatch("triggerError", e);
        });
  },

  addCustomer(context, value) {
    context.commit("SET_CUSTOMER_ADD");
    let postUrl = context.getters.backendServer + "customer/invite/code";
    let config = {
      headers: {
        authorization: localStorage.getItem("token")
      }
    };
    return axios
      .post(postUrl, value, config)
      .then(function(response) {
        context.commit("SET_CUSTOMER_ADD", response.data);
        context.commit("CREATE_SNACKBAR", "Benutzer erfolgreich hinzugefügt");
        context.dispatch("getCustomerList");
        return true;
      })
      .catch(function(e) {
        context.commit("SET_CUSTOMER_ADD", e);
        context.dispatch("triggerError", e);
      });
  },

  deleteCustomer(context, value) {
    context.commit("SET_CUSTOMER_DELETE");
    let postUrl = context.getters.backendServer + "customer/" + value.id;

    let config = {
      headers: {
        authorization: localStorage.getItem("token")
      }
    };

    axios
      .delete(postUrl, config)
      .then(function() {
        context.commit("SET_CUSTOMER_DELETE", "[]");
        context.commit("CREATE_SNACKBAR", "Benutzer erfolgreich gelöscht");
        context.dispatch("getCustomerList");
      })
      .catch(function(e) {
        context.commit("SET_CUSTOMER_DELETE", e);
        context.dispatch("triggerError", e);
      });
  }
};

export default actions;
