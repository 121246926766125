<template>
    <v-card class="ma-4" outlined>
      <v-card-text>
        <div>
          {{ itemTable.category }} &middot; ({{ itemTable.customerNumber }})
        </div>
        <div v-if="(itemTable.type == 'Company')">
          <p class="display-1 text--primary">
            {{ itemTable.name }} {{ itemTable.name2 }}
          </p>
        </div>
        <div v-else>
          <p class="display-1 text--primary">
            {{ itemTable.surname }} {{ itemTable.familyname }}
          </p>
        </div>
        <v-list>
          <div v-if="itemTable.phone != null">
            <v-list-item>
              <v-list-item-action>
                <v-icon>mdi-phone</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ itemTable.phone }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a :href="'tel:' + itemTable.phone" v-on="on"
                      ><v-icon> phone_forwarded</v-icon></a
                    >
                  </template>
                  <span>Nummer in Softphone wählen</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
            <v-divider inset></v-divider>
          </div>

          <div v-if="itemTable.email != null">
            <v-list-item>
              <v-list-item-action>
                <v-icon>email</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ itemTable.email }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a :href="'mailto:' + itemTable.email" v-on="on"
                      ><v-icon> send</v-icon></a
                    >
                  </template>
                  <span>Mail senden</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
            <v-divider inset></v-divider>
          </div>

          <div v-if="itemTable.invoicingEmail">
            <v-list-item>
              <v-list-item-action>
                <v-icon>email</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>Rechnungs-Email: {{ itemTable.invoicingEmail }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a :href="'mailto:' + itemTable.invoicingEmail" v-on="on"
                      ><v-icon> send</v-icon></a
                    >
                  </template>
                  <span>Mail senden</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
            <v-divider inset></v-divider>
          </div>

          <div v-if="itemTable.address.city != null && itemTable.address.country != null">
            <v-list-item>
              <v-list-item-action>
                <v-icon>mdi-map-marker</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title
                  >{{ itemTable.address.street }}, {{ itemTable.address.zip }}
                  {{ itemTable.address.city }}, {{ itemTable.address.country }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-divider inset></v-divider>
          </div>

          <div v-if="itemTable.invoiceAddress.active"> 
            <v-list-item>
              <v-list-item-action>
                <v-icon>mdi-map-marker</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title
                  >Rechnungsadresse: {{ itemTable.invoiceAddress.street }}, {{ itemTable.invoiceAddress.zip }}
                  {{ itemTable.invoiceAddress.city }}, {{ itemTable.invoiceAddress.country }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-divider inset></v-divider>
          </div>

          <div v-if="itemTable.iban != null">
            <v-list-item>
              <v-list-item-action>
                <v-icon>account_balance</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>IBAN: {{ itemTable.iban }} {{ itemTable.bic }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider inset></v-divider>
          </div>

          <div v-if="itemTable.discount != 0 && itemTable.discountTime != null">
            <v-list-item>
              <v-list-item-action>
                <v-icon>receipt</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>Skonto: {{itemTable.discount}}% bei Zahlung innerhalb von {{itemTable.discountTime}} Tagen.</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider inset></v-divider>
          </div>

          <div v-if="itemTable.paymentTarget != 0">
            <v-list-item>
              <v-list-item-action>
                <v-icon>date_range</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>Zahlungsziel: {{itemTable.paymentTarget}} Tage</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider inset></v-divider>
          </div>

          <div v-if="itemTable.description != null">
            <v-list-item>
              <v-list-item-action>
                <v-icon>notes</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ itemTable.description }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider inset></v-divider>
          </div>
        </v-list>
      </v-card-text>
      <!--v-card-actions>
        <v-btn text color="secondary accent-4">Aktion1</v-btn>
        <v-btn text color="secondary accent-4">Aktion2</v-btn>
      </v-card-actions-->
    </v-card>
</template>
<script>
export default {
  props: ["itemTable", "headers"],
  data: () => ({}),
  methods: {}
};
</script>
