<template>
  <td :colspan="headers.length">
    <v-card class="ma-4" outlined>
      <v-card-text>
        <v-toolbar dense flat mb-5>
          <v-toolbar-title> {{ itemTable.header }}</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon class="mr-4" v-on="on" @click="createPDF(itemTable)"
                >picture_as_pdf</v-icon
              >
            </template>
            <span>PDF erstellen</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon class="mr-4" v-on="on" @click="openMailDialog(itemTable)"
                >email</v-icon
              >
            </template>
            <span>Dokument per Email versenden</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                medium
                class="mr-2"
                v-on="on"
                @click.stop="triggerPreviewPdf(itemTable)"
                >preview</v-icon
              >
            </template>
            <span>PDF Vorschau</span>
          </v-tooltip>
        </v-toolbar>
        <v-data-table
          :headers="tableHeaders"
          :items="itemTable.deliveryNotes"
          item-key="id"
          class="pa-5"
        >
          <template v-slot:no-data>
            keine Daten vorhanden
          </template>
          <template v-slot:item.action="{ item }">
            <div v-if="itemTable.status === 'Aktiv'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    medium
                    class="mr-2"
                    v-on="on"
                    @click="openRemoveDeliveryNote(item)"
                    data-cy="deleteDeliveryNote"
                    >close</v-icon
                  >
                </template>
                <span>Lieferschein entfernen</span>
              </v-tooltip>
            </div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon medium class="mr-2" v-on="on" @click="clickedRow(item)"
                  >open_in_new</v-icon
                >
              </template>
              <span>Lieferschein öffnen</span>
            </v-tooltip>
            <v-dialog v-model="removeDeliveryNoteDialog" max-width="500">
              <v-card>
                <v-card-title class="headline"
                  >Lieferschein entfernen</v-card-title
                >
                <v-card-text
                  >Soll der Lieferschein
                  {{ removeDeliveryNoteDialogItem.formattedDeliveryNoteNumber }}
                  entfernt werden?</v-card-text
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="removeDeliveryNoteDialog = false"
                    >Abbrechen</v-btn
                  >
                  <v-btn
                    color="primary"
                    text
                    @click="removeDeliveryNote(removeDeliveryNoteDialogItem)"
                    data-cy="deleteEvent"
                    >Jetzt entfernen</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </td>
</template>
<script>
import pdf from "vue-pdf";
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  components: {
    pdf
  },
  props: ["headers", "itemTable"],
  data: () => ({
    tableHeaders: [
      {
        text: "Lieferscheinnummer",
        value: "formattedDeliveryNoteNumber",
        sortable: false
      },
      { text: "Betreff", value: "header", sortable: false },
      {
        text: "Lieferscheindatum",
        value: "formattedDeliveryNoteDate",
        sortable: false
      },
      { text: "", value: "action", align: "right", sortable: false }
    ],
    removeDeliveryNoteDialog: false,
    removeDeliveryNoteDialogItem: { formattedDeliveryNoteNumber: null }
  }),
  computed: {
    ...mapGetters(["backendServer"])
  },
  methods: {
    createPDF(item) {
      this.$emit("createPDF", item);
    },
    openMailDialog(item) {
      this.$emit("openMailDialog", item);
    },
    openRemoveDeliveryNote(item) {
      this.removeDeliveryNoteDialog = true;
      this.removeDeliveryNoteDialogItem = item;
    },
    removeDeliveryNote(item) {
      let putUrl =
        this.backendServer +
        "project/" +
        this.itemTable.id +
        "/removeDeliveryNote";
      let config = {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      };

      axios
        .put(putUrl, { deliveryNote: item.id }, config)
        .then(response => {
          this.removeDeliveryNoteDialog = false;
          this.removeDeliveryNoteDialogItem = {
            formattedDeliveryNoteNumber: null
          };
          this.$emit("reload");
          this.$store.dispatch("triggerUniversalAlert", {
            text:
              "Der Lieferschein wurde erfolgreich aus dem Auftrag entfernt.",
            heading: "Lieferschein Entfernt",
            type: "info"
          });
        })
        .catch(err => {
          vm.$store.dispatch("triggerError", err);
        });
    },
    triggerPreviewPdf(item) {
      this.$router.push({
        name: "documentDetails",
        params: { id: item.id, from: "projekte" }
      });
    },
    clickedRow(value) {
      let routeData = this.$router.resolve({
        name: "documentDetails",
        params: { id: value.id, from: "lieferscheine" }
      });
      window.open(routeData.href, '_blank');
    }
  }
};
</script>
