import axios from "axios";
const actions = {
  getInventoryList(context, search) {
    context.commit("SET_ALL_INVENTORY");
    const { page, itemsPerPage } = context.state.inventoryData.pagination;
    let getUrl =
      context.getters.backendServer +
      "inventory?page=" +
      page +
      "&perPage=" +
      itemsPerPage;
    if (search) {
      if (search.key != null && search.key != "") {
        getUrl =
          context.getters.backendServer +
          "inventory?page=" +
          page +
          "&perPage=" +
          itemsPerPage +
          "&q=" +
          search.key;
        context.commit("RESET_PAGINATION_PAGES_INVENTORY");
      }

      search.contact ? (getUrl += "&contact=" + search.contact) : "";
    }

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_ALL_INVENTORY", response.data.data);
        context.commit(
          "SET_TOTAL_INVENTORY_ALL",
          parseInt(response.data.count)
        );
      })
      .catch(function(e) {
        context.commit("SET_ALL_INVENTORY", e);
        context.dispatch("triggerError", e);
      });
  },

  getAllInventory(context, contact) {
    context.commit("SET_ALL_INVENTORY");
    let getUrl;
    if (contact) {
      getUrl = context.getters.backendServer + "inventory?contact=" + contact;
    } else {
      getUrl = context.getters.backendServer + "inventory";
    }

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    return axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_ALL_INVENTORY", response.data.data);
        return response.data.data;
      })
      .catch(function(e) {
        context.commit("SET_ALL_INVENTORY", e);
        context.dispatch("triggerError", e);
      });
  },

  getSingleInventoryItem(context, item) {
    let getUrl;
    if (item.contact) {
      getUrl = context.getters.backendServer +
        "inventory/" +
        item.id +
        "?contact=" +
        item.contact;
    } else {
      getUrl = context.getters.backendServer + "inventory/" + item.id;
    }
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    return axios
      .get(getUrl, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },

  addInventoryItem(context, value) {
    context.commit("SET_INVENTORY_ADD");
    let postUrl = context.getters.backendServer + "inventory";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    return axios
      .post(postUrl, value, config)
      .then(function(response) {
        context.commit("SET_INVENTORY_ADD", response.data);
        context.commit(
          "CREATE_SNACKBAR",
          "Artikel Nr. " + response.data.objectId + " erfolgreich hinzugefügt"
        );
        //context.dispatch("getInventoryList");
        return response.data;
      })
      .catch(function(e) {
        context.commit("SET_Inventory_ADD", e);
        context.dispatch("triggerError", e);
      });
  },

  deleteInventoryItem(context, value) {
    context.commit("SET_INVENTORY_DELETE");
    let postUrl = context.getters.backendServer + "inventory/" + value.id;

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .delete(postUrl, config)
      .then(function() {
        context.commit("SET_INVENTORY_DELETE", "[]");
        context.commit(
          "CREATE_SNACKBAR",
          "Artikel Nr. " + value.objectId + " erfolgreich gelöscht"
        );
        context.dispatch("getInventoryList");
      })
      .catch(function(e) {
        context.commit("SET_INVENTORY_DELETE", e);
        context.dispatch("triggerError", e);
      });
  },

  updateInventory(context, value) {
    context.commit("SET_INVENTORY_UPDATE");

    let putUrl = context.getters.backendServer + "inventory/" + value.id;

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    value.formattedBirthday = null;
    axios
      .put(putUrl, value, config)
      .then(function(response) {
        context.commit("SET_INVENTORY_UPDATE", response.data);
        context.commit(
          "CREATE_SNACKBAR",
          "Artikel Nr. " + response.data.objectId + " erfolgreich aktualisiert"
        );
        //context.dispatch("getInventoryList");
      })
      .catch(function(e) {
        context.commit("SET_INVENTORY_UPDATE", e);
        context.dispatch("triggerError", e);
      });
  }
};

export default actions;
