<template>
  <div>
    <v-list flat subheader three-line>
      <v-list-item>
        <v-list-item-content>
          <v-container>
            <v-card class="mx-auto" max-width="900">
              <v-form class="pa-4" ref="form" lazy-validation>
                <p>Lieferscheine</p>
                <h5>
                  Die folgenden Texte werden bei allen Lieferscheinen als
                  Standardtexte gesetzt.
                </h5>
                <br />
                <v-row>
                  <v-col cols="12" sm="6" md="12">
                    <p>Kopf-Text</p>
                    <quill-editor
                      v-model="item.deliveryNote.value.head"
                      :options="editorOption"
                    >
                    </quill-editor>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <p>Fuß-Text</p>
                    <quill-editor
                      v-model="item.deliveryNote.value.foot"
                      :options="editorOption"
                    >
                    </quill-editor>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <p>Email-Text</p>
                    <quill-editor
                      v-model="item.deliveryNote.value.mail"
                      :options="editorOption"
                    >
                    </quill-editor>
                  </v-col>
                </v-row>
              </v-form>
              <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="secondary" text @click="save"
                  >Speichern</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-container>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-container>
            <v-card class="mx-auto" max-width="900">
              <v-form class="pa-4" ref="form" lazy-validation>
                <p>Rechnungen</p>
                <h5>
                  Die folgenden Texte werden bei allen Rechnungen als
                  Standardtexte gesetzt.
                </h5>
                <br />
                <v-row>
                  <v-col cols="12" sm="6" md="12">
                    <p>Kopf-Text</p>
                    <quill-editor
                      v-model="item.invoice.value.head"
                      :options="editorOption"
                    >
                    </quill-editor>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <p>Fuß-Text</p>
                    <quill-editor
                      v-model="item.invoice.value.foot"
                      :options="editorOption"
                    >
                    </quill-editor>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <p>Email-Text</p>
                    <quill-editor
                      v-model="item.invoice.value.mail"
                      :options="editorOption"
                    >
                    </quill-editor>
                  </v-col>
                </v-row>
              </v-form>
              <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="secondary" text @click="save"
                  >Speichern</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-container>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-container>
            <v-card class="mx-auto" max-width="900">
              <v-form class="pa-4" ref="form" lazy-validation>
                <p>Angebote</p>
                <h5>
                  Die folgenden Texte werden bei allen Angeboten als
                  Standardtexte gesetzt.
                </h5>
                <br />
                <v-row>
                  <v-col cols="12" sm="6" md="12">
                    <p>Kopf-Text</p>
                    <quill-editor
                      v-model="item.offer.value.head"
                      :options="editorOption"
                    >
                    </quill-editor>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <p>Fuß-Text</p>
                    <quill-editor
                      v-model="item.offer.value.foot"
                      :options="editorOption"
                    >
                    </quill-editor>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <p>Email-Text</p>
                    <quill-editor
                      v-model="item.offer.value.mail"
                      :options="editorOption"
                    >
                    </quill-editor>
                  </v-col>
                </v-row>
              </v-form>
              <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="secondary" text @click="save"
                  >Speichern</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-container>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
import VueQuillEditor, { Quill } from "vue-quill-editor";
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize-module";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);

export default {
  data: () => ({
    item: {
      deliveryNote: {},
      invoice: {},
      offer: {}
    },
    editorOption: {
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"], // toggled buttons
          ["blockquote"],

          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }], // superscript/subscript
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          [{ direction: "rtl" }], // text direction

          [{ size: [] }], // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ font: [] }],
          [{ align: [] }],

          ["clean"],
          [("link", "image")]
        ],
        history: {
          delay: 1000,
          maxStack: 50,
          userOnly: false
        },
        imageDrop: true,
        imageResize: true
      }
    }
  }),

  created() {
    this.initialize();
  },

  computed: {
    ...mapGetters([])
  },
  methods: {
    save() {
      let vm = this;
      this.$store.dispatch("updateDeliveryNoteText", this.item.deliveryNote.value);
      this.$store.dispatch("updateInvoiceText", this.item.invoice.value);
      this.$store.dispatch("updateOfferText", this.item.offer.value);
    },
    initialize() {
      this.$store.dispatch("getDeliveryNoteText").then(res => {
        this.item.deliveryNote = res;
      });
      this.$store.dispatch("getInvoiceText").then(res => {
        this.item.invoice = res;
      });
      this.$store.dispatch("getOfferText").then(res => {
        this.item.offer = res;
      });
    }
  }
};
</script>
