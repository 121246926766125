<template>
  <div>
    <v-list flat subheader three-line>
      <v-list-item>
        <v-list-item-content>
          <v-container>
            <v-card class="mx-auto" max-width="900">
              <v-form class="pa-10" ref="form" lazy-validation>
                <p>Vordefinierte Lieferscheinpositionen festlegen</p>
                <h5>
                  Die folgenden Lieferscheinpositionen werden bei allen
                  Aufträgen standardmäßig vorausgewählt.
                </h5>
                <br />
                <v-row>
                  <itemPosForm v-model="presetPos" :prices="false" />
                </v-row>
              </v-form>
              <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="secondary" text @click="save">Speichern</v-btn>
              </v-card-actions>
            </v-card>
          </v-container>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
import itemPosForm from "../../components/documents/itemPosForm.vue";

export default {
  components: {
    itemPosForm,
  },
  data: () => ({
    presetPos: [
      {
        name: "",
        description: "",
        quantity: 1,
        unity: "Stk",
        posId: 1,
        price: 0.0,
        taxRate: 0,
      },
    ],
  }),

  created() {
    this.initialize();
  },

  computed: {
    ...mapGetters([]),
  },
  methods: {
    save() {
      this.$store.dispatch("updateStandardItems", this.presetPos);
    },
    initialize() {
      this.$store.dispatch("getStandardItems").then((res) => {
        if (res.value.length != 0) {
          this.presetPos = res.value;
        }
      });
    },
  },
};
</script>
