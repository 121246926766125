import { StoreUtil } from "../utils";
const mutations = {
  SET_PAGINATION_ALL(state, payload) {
    state.dataAll.pagination = payload;
  },
  RESET_PAGINATION_PAGES_ALL(state) {
    state.dataAll.pagination.page = 1;
    state.dataAll.pagination.itemsPerPage = 10;
  },
  SET_TOTAL_ITEMS_ALL(state, payload) {
    state.dataAll.pagination.totalItems = payload;
  },
  SET_NEXT_CONTACT_ID(state, payload) {
    state.dataContact.nextContactId = payload;
  },
  SET_ALL_LIST(state, payload) {
    state.dataAll.allList = StoreUtil.updateState(
      state.dataAll.allList,
      payload
    );
  },
  SET_ALL_LIST_WITHOUT_PAGINATION(state, payload) {
    state.dataAllPagination.allList = StoreUtil.updateState(
      state.dataAllPagination.allList,
      payload
    );
  },

  SET_PAGINATION_KUNDE(state, payload) {
    state.dataKunde.pagination = payload;
  },
  RESET_PAGINATION_PAGES_KUNDE(state) {
    state.dataKunde.pagination.page = 1;
    state.dataKunde.pagination.itemsPerPage = 10;
  },
  SET_TOTAL_ITEMS_KUNDE(state, payload) {
    state.dataKunde.pagination.totalItems = payload;
  },
  SET_KUNDE_LIST(state, payload) {
    state.dataKunde.kundeList = StoreUtil.updateState(
      state.dataKunde.kundeList,
      payload
    );
  },

  SET_PAGINATION_LIEFERANT(state, payload) {
    state.dataLieferant.pagination = payload;
  },
  RESET_PAGINATION_PAGES_LIEFERANT(state) {
    state.dataLieferant.pagination.page = 1;
    state.dataLieferant.pagination.itemsPerPage = 10;
  },
  SET_TOTAL_ITEMS_LIEFERANT(state, payload) {
    state.dataLieferant.pagination.totalItems = payload;
  },
  SET_LIEFERANT_LIST(state, payload) {
    state.dataLieferant.lieferantList = StoreUtil.updateState(
      state.dataLieferant.lieferantList,
      payload
    );
  },

  SET_PAGINATION_PARTNER(state, payload) {
    state.dataPartner.pagination = payload;
  },
  RESET_PAGINATION_PAGES_PARTNER(state) {
    state.dataPartner.pagination.page = 1;
    state.dataPartner.pagination.itemsPerPage = 10;
  },
  SET_TOTAL_ITEMS_PARTNER(state, payload) {
    state.dataPartner.pagination.totalItems = payload;
  },
  SET_PARTNER_LIST(state, payload) {
    state.dataPartner.partnerList = StoreUtil.updateState(
      state.dataPartner.partnerList,
      payload
    );
  },

  SET_PAGINATION_INTERESSENT(state, payload) {
    state.dataInteressent.pagination = payload;
  },
  RESET_PAGINATION_PAGES_INTERESSENT(state) {
    state.dataInteressent.pagination.page = 1;
    state.dataInteressent.pagination.itemsPerPage = 10;
  },
  SET_TOTAL_ITEMS_INTERESSENT(state, payload) {
    state.dataInteressent.pagination.totalItems = payload;
  },
  SET_INTERESSENT_LIST(state, payload) {
    state.dataInteressent.interessentList = StoreUtil.updateState(
      state.dataInteressent.interessentList,
      payload
    );
  },

  SET_CONTACT_ADD(state, payload) {
    StoreUtil.updateState(state.dataContact.contactList, payload);
  },
  SET_CONTACT_DELETE(state, payload) {
    StoreUtil.updateState(state.dataContact.contactList, payload);
  },
  SET_CONTACT_UPDATE(state, payload) {
    StoreUtil.updateState(state.dataContact.contactList, payload);
  }
};

export default mutations;
