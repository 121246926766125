import { StoreUtil } from "../utils";

const mutations = {
  SET_CUSTOMER_LIST(state, payload) {
    state.customerList = StoreUtil.updateState(state.customerList, payload);
  },
  SET_CUSTOMER_ADD(state, payload) {
    StoreUtil.updateState(state.customerList, payload);
  },
  SET_CUSTOMER_DELETE(state, payload) {
    StoreUtil.updateState(state.customerList, payload);
  },
  SET_CUSTOMER_UPDATE(state, payload) {
    StoreUtil.updateState(state.customerList, payload);
  }
};

export default mutations;
