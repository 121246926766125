<template>
  <div class="mt-4 mx-4">
    <v-list subheader three-line>
      <v-subheader>onDispatch Lizenz</v-subheader>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title
            >onDispatch {{ license.name }} {{ version }}</v-list-item-title
          >
          <v-list-item-subtitle
            >bis zu {{ license.user }} Büro-Benutzer</v-list-item-subtitle
          >
          <v-list-item-subtitle
            >bis zu {{ license.worker }} Fahrer-Benutzer</v-list-item-subtitle
          >
          <v-list-item-subtitle
            >Lizenz für {{ company.name + " " + company.nameAdditional }}</v-list-item-subtitle
          >
          <br />
          <v-list-item-subtitle v-if="license.modules.datev"
            >DATEV-Export aktiviert</v-list-item-subtitle
          >
          <v-list-item-subtitle v-if="license.modules.analytics"
            >Analyics / Auswertungen aktiviert</v-list-item-subtitle
          >
          <v-list-item-subtitle v-if="license.modules.sevdesk"
            >SevDesk Export aktiviert</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <!--v-btn class="ma-2 ml-3" dense color="primary" @click="dialog = true"
        >Weitere Fahrzeugpläze hinzufügen</v-btn
      -->
      <!--v-list-item>
        <v-list-item-content>
          <v-list-item-title>Erweiterungspaket 1</v-list-item-title>
          <v-list-item-subtitle>Beschreibung</v-list-item-subtitle>
          <p>{{ companyData.fullName }}</p>
        </v-list-item-content>
      </v-list-item-->
      <v-list-item>
        <v-list-item-content>
          <h5>
            Sie haben Fragen zu Ihrem gebuchten Tarif oder möchten Ihren Tarif ändern?
          </h5>
          <br />
          <v-row>
            <v-btn
              class="ma-2 ml-3"
              outlined
              dense
              color="primary"
              href="https://doku.ondispatch.de/docs/hilfe/"
              target="_blank"
              >Support kontaktieren</v-btn
            >
            <v-btn
              class="ma-2"
              outlined
              dense
              color="primary"
              href="https://ondispatch.de/preise.html"
              target="_blank"
              >Tarifübersicht</v-btn
            >
          </v-row>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-dialog v-model="dialog" max-width="800">
      <v-card>
        <loading
          :active.sync="inventoryStatusPending"
          :can-cancel="false"
          :is-full-page="true"
        ></loading>
        <v-card-title class="headline">Weitere Fahrzeugpläze hinzufügen</v-card-title>
        <v-card-text>
          Jetzt weitere Fahrzeuge hinzubuchen:
          <br />
          <v-select
            :items="vehicles"
            v-model="vehiclesSelect"
            label="Anzahl zusätzlicher Fahrzeuge"
            data-cy="vehiclesSelect"
          ></v-select>
          Mit dem Bestätigen der Auswahl bestellen Sie unverbindlich die oben genannte
          Zahl an Fahrzeugen. Es gelten die onDispatch AGB´s.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialog = false">Abbrechen</v-btn>
          <v-btn color="secondary" text @click="bookVehicles(vehiclesSelect)"
            >Jetzt Buchen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-divider></v-divider>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    license: {},
    company: {},
    dialog: false,
    vehicles: ["1", "2", "3", "4", "5", "5+"],
    vehiclesSelect: null,
  }),
  computed: {
    ...mapGetters(["companyData", "version", "licenseData"]),
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch("getCompanyData").then((result) => {});
      this.$store.dispatch("getLicenseData").then((result) => {});
    },
    bookVehicles(amount) {
      let item = {};
      this.$store.dispatch("sendViaMail", item);
    },
  },
  watch: {
    licenseData: {
      handler(val) {
        if (val.data[0]) {
          this.license = val.data[0].value;
        }
      },
    },
    companyData: {
      handler(val) {
        if (val.data[0]) {
          this.company = val.data[0].value;
        }
      },
    },
  },
};
</script>
