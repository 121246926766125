<template>
  <v-tabs v-model="activeTab">
      <v-tab v-for="tab of tabs" :key="tab.id" :to="tab.route" exact>
        {{ tab.name }}
      </v-tab>

      <v-tab-item v-for="tab of tabs" :key="tab.id" :value="tab.route" :transition="false" :reverse-transition="false">
        <router-view></router-view>
      </v-tab-item>
    </v-tabs>
</template>
<script>
export default {
  data() {
    return {
      activeTab: this.$route.params.id,
      tabs: [
        { id: 1, name: "Übersicht", route: `/contacts/1` },
        { id: 2, name: "Kunden", route: `/contacts/2/kunde` },
        { id: 3, name: "Lieferanten", route: `/contacts/3/lieferant` },
        { id: 4, name: "Partner", route: `/contacts/4/partner` },
        { id: 5, name: "Interessenten", route: `/contacts/5/interessent` }
      ]
    };
  }
};
</script>