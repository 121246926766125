<template>
  <v-container>
    <v-form class="pa-4" ref="form" v-model="valid" lazy-validation>
      <p>DATEV CSV Schnitstelle Stammdaten</p>
      <h5>
        Um den Export im DATEV CSV Format zu nutzen, müssen Sie hier ihre DATEV
        Mandantennummer sowie Beraternummer eintragen.
        <br />
        Weitere Informationen zu diesen Nummern erhalten Sie von Ihrem
        Buchhalter / Steuerberater.
      </h5>
      <br />
      <v-row>
        <v-col cols="12" sm="12" md="4">
          <v-text-field
            v-model="item.client"
            label="Mandantennummer"
            data-cy="client"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <v-text-field
            v-model="item.consultant"
            label="Beraternummer"
            data-cy="consultant"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-btn color="secondary" text @click="save">Speichern</v-btn>
    </v-form>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    item: {
      client: null,
      consultant: null
    },
    valid: true,
    requieredRule: [v => !!v || "Dieses Feld wird benötigt"]
  }),

  created() {
    this.initialize();
  },

  computed: {
    ...mapGetters(["datevData"])
  },

  watch: {
    datevData: {
      handler(val) {
        if (val.data[0]) {
          this.item = val.data[0].value ? val.data[0].value : this.item;
        }
      }
    }
  },

  methods: {
    save() {
      let vm = this;

      this.$store.dispatch("updateDatevData", this.item).then(result => {});
    },
    initialize() {
      this.$store.dispatch("getDatevData").then(result => {});
    }
  }
};
</script>
