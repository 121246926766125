<template>
  <div class="my-5">
    <v-row align="center" justify="center">
      <v-col cols="12" xs="12" sm="6" md="5">
        <v-card>
          <v-card-title>Umsatz</v-card-title>
          <v-card-text>
            <revenuechart style="max-height: 600px"></revenuechart>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="5">
        <v-card>
          <v-card-title>Auslastung Maschinen</v-card-title>
          <v-card-text>
            <workloadtable style="max-height: 600px"></workloadtable>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" xs="12" sm="6" md="5">
        <v-card>
          <v-card-title>Umsatz nach Kunden</v-card-title>
          <v-card-text>
            <revenuecontactchart style="max-height: 600px"></revenuecontactchart>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="5">
        
      </v-col>
    </v-row>
  </div>
</template>
<script>
import revenuechart from './../components/analytics/revenuechart.vue';
import workloadtable from './../components/analytics/workloadtable.vue';
import revenuecontactchart from './../components/analytics/revenuecontactchart.vue';
export default {
  data: () => ({}),
  components: {
    revenuechart,
    workloadtable,
    revenuecontactchart
  }
};
</script>