<template>
  <!-- Custom prevent data loss dialog -->
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="550">
      <v-card>
        <v-card-title class="headline"> Ungespeicherte Daten </v-card-title>
        <v-card-text>
          <v-alert dense border="left" type="error">
            Achtung. Manche Änderungen wurden noch nicht gespeichert. Möchten Sie diese
            Änderungen wirklich verwerfen?
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="cancel(false)"
            >Zurück zur Bearbeitung</v-btn
          >
          <v-btn color="error" text @click="cancel(true)">Änderungen verwerfen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  components: {},
  props: ["dialog"],
  data: () => ({}),
  methods: {
    cancel(val) {
      if (val) {
        this.$emit("dismissData", true);
      } else {
        this.$emit("dismissData", false);
      }
      this.dialog = false;
    },
  },
};
</script>
