<template>
  <v-data-table
    :headers="headers"
    :options.sync="machineryListGetter.pagination"
    :server-items-length="machineryListGetter.pagination.totalItems"
    :loading="machineryStatusPending"
    :items="machineryListGetter.machineryList.data"
    :footer-props="{
      itemsPerPageOptions: machineryListGetter.pagination.rowsPerPageItems,
      itemsPerPageText: 'Zeilen pro Seite',
    }"
    class="pa-5"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Maschinen</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-text-field
          v-model="machineryListGetter.pagination.search"
          label="Suche..."
          data-cy="search"
          single-line
          hide-details
          dense
          @keyup.enter.native="searchData"
        ></v-text-field>
        <v-btn text icon color="secondary" @click="searchData">
          <v-icon>search</v-icon>
        </v-btn>
        <div class="flex-grow-1"></div>
        <v-dialog v-model="dialog" max-width="800px">
          <template v-slot:activator="{ on }">
            <v-btn color="secondary" dark class="mb-2" v-on="on">Neue Maschine</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        :items="vehicles"
                        label="Fahrzeugtyp"
                        data-cy="type"
                        v-model="editedItem.type"
                        :rules="requieredRule"
                        dense
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.license"
                        label="Kennzeichen"
                        data-cy="license"
                        :rules="requieredRule"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="5">
                      <v-text-field
                        v-model="editedItem.manufacturer"
                        label="Hersteller"
                        data-cy="manufacturer"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.name"
                        label="Bezeichnung (wird im Planer angezeigt)"
                        data-cy="name"
                        :rules="requieredRule"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-tabs>
                    <v-tab>Details</v-tab>
                    <v-tab-item>
                      <v-col cols="12" sm="6" md="12">
                        <v-textarea
                          v-model="editedItem.description"
                          label="Beschreibung"
                          data-cy="description"
                          rows="2"
                          dense
                          outlined
                        ></v-textarea>
                      </v-col>
                    </v-tab-item>
                    <v-tab>Versicherung</v-tab>
                    <v-tab-item>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="editedItem.insurance"
                            label="Versicherung"
                            data-cy="insurance"
                            dense
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="editedItem.insuranceType"
                            label="Versicherungstarif"
                            data-cy="insuranceType"
                            dense
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                  </v-tabs>
                  <!--v-col cols="12" sm="6" md="12">
                      <v-text-field
                        v-model="editedItem.events"
                        label="Termine"
                        data-cy="events"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col-->
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn color="secondary" text @click="close">Abbrechen</v-btn>
              <v-btn color="secondary" text @click="save">Speichern</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <!--template v-slot:item.events="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div v-on="on">
            {{ item.events[0].type }} {{ item.events[0].date }}
            <v-icon>expand_more</v-icon>
          </div>
        </template>
        Weitere Termine
        <hr />
        <ul v-for="date in item.events" v-bind:key="date.license">
          <li>{{ date.type }} - {{ date.date }}</li>
        </ul>
      </v-tooltip>
    </template-->
    <template v-slot:item.maintenanceContract="{ item }">
      <v-icon class="mr-2" v-if="item.maintenanceContract === true">check</v-icon>
      <v-icon v-else>clear</v-icon>
    </template>
    <template v-slot:item.displayScheduler="{ item }">
      <v-switch v-model="item.display" @change="changePlanerStatus(item)"></v-switch>
    </template>
    <template v-slot:item.action="{ item }">
      <v-dialog v-model="deleteDialog" max-width="500">
        <v-card>
          <loading
            :active.sync="machineryStatusPending"
            :can-cancel="false"
            :is-full-page="true"
          ></loading>
          <v-card-title class="headline">Datensatz löschen?</v-card-title>
          <v-card-text>
            Soll der Datensatz {{ item.id }} wirklich gelöscht werden?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="deleteDialog = false">Abbrechen</v-btn>
            <v-btn color="secondary" text @click="deleteItem(item)">Jetzt löschen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon medium class="mr-2" v-on="on" @click="editItem(item)">edit</v-icon>
        </template>
        <span>Bearbeiten</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon medium v-on="on" @click="openDeleteDialog(item)">delete</v-icon>
        </template>
        <span>Löschen</span>
      </v-tooltip>
    </template>
    <template v-slot:no-data>
      <v-btn color="secondary" @click="initialize">Reset</v-btn>
    </template>
    <template v-slot:no-data> keine Daten vorhanden </template>
  </v-data-table>
</template>
<script>
import Loading from "vue-loading-overlay";
import { mapGetters } from "vuex";
export default {
  components: {
    Loading,
  },
  data: () => ({
    menu: false,
    loading: false,
    snackbar: {
      visible: false,
      text: "Snackbar ...",
    },
    dialog: false,
    headers: [
      {
        text: "Kennzeichen",
        align: "left",
        sortable: false,
        value: "license",
      },
      { text: "Bezeichnung (Planer)", value: "name", sortable: false },
      { text: "Maschinentyp", value: "type", sortable: false },
      { text: "Hersteller", value: "manufacturer", sortable: false },
      { text: "Infos", value: "description", sortable: false },
      { text: "Versicherung", value: "insurance", sortable: false },
      { text: "Versicherungstarif", value: "insuranceType", sortable: false },
      //{ text: "Termine (nächster Termin)", value: "events", sortable: false },
      {
        text: "Sichtbar in Planer",
        value: "displayScheduler",
        sortable: false,
      },
      { text: "", value: "action", align: "right", sortable: false },
    ],
    data: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      type: "",
      license: "",
      manufacturer: "",
      notes: "",
      insurance: "",
      insuranceType: "",
      events: [],
    },
    vehicles: [
      "Kipper",
      "Tieflader",
      "Container",
      "Kehrmaschine",
      "Mobilkran",
      "Sonstiges",
    ],
    valid: true,
    requieredRule: [(v) => !!v || "Dieses Feld wird benötigt"],
    deleteDialog: false,
    deletItem: null,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Neue Maschine" : "Maschine bearbeiten";
    },
    pagination: {
      get: function () {
        return this.$store.getters.machineryListGetter.pagination;
      },
      set: function (value) {
        this.$store.commit("SET_PAGINATION_MACHINERY", value);
      },
    },
    ...mapGetters(["machineryListGetter", "machineryStatusPending"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    pagination: {
      handler() {
        this.$store.dispatch("getMachineryList");
      },
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$store.dispatch("getMachineryList");
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.editedIndex = 1;
      this.dialog = true;
    },
    openDeleteDialog(item) {
      this.deletItem = item;
      this.deleteDialog = true;
    },
    deleteItem() {
      this.$store.dispatch("deleteMachinery", this.deletItem).then((result) => {
        this.deleteDialog = false;
        this.deletItem = null;
      });
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
      this.$refs.form.reset();
    },

    save() {
      let vm = this;
      if (this.editedIndex > -1) {
        if (vm.$refs.form.validate()) {
          vm.$store.dispatch("updateMachinery", vm.editedItem).then((result) => {
            vm.close();
          });
        }
      } else {
        if (vm.$refs.form.validate()) {
          vm.$store.dispatch("addMachinery", vm.editedItem).then((result) => {
            vm.close();
          });
        }
      }
    },
    changePlanerStatus(item) {
      this.$store.dispatch("setVisibleInSchedulerMachinery", item);
    },
    searchData() {
      this.$store.dispatch("getMachineryList");
    },
  },
};
</script>
<style>
.expanded__content {
  box-shadow: none !important;
  background: white !important;
}
</style>
