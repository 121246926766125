import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDataTable,{staticClass:"pa-5",attrs:{"headers":_vm.headers,"options":_vm.errorListGetter.pagination,"server-items-length":_vm.errorListGetter.pagination.totalItems,"items":_vm.errorListGetter.errorList.data,"loading":_vm.errorStatusPending,"search":_vm.search,"footer-props":{
    itemsPerPageOptions: _vm.errorListGetter.pagination.rowsPerPageItems,
    itemsPerPageText: 'Zeilen pro Seite'
  }},on:{"update:options":function($event){return _vm.$set(_vm.errorListGetter, "pagination", $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":"","color":"white"}},[_c(VToolbarTitle,[_vm._v("Fehlermeldungen")])],1)]},proxy:true},{key:"no-data",fn:function(){return [_vm._v(" keine Daten vorhanden ")]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }