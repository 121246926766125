<template>
  <v-app id="inspire">
    <v-main>
      <div class="text-center">
        <v-snackbar
          v-model="error.visible"
          :timeout="4000"
          left
          color="#ff5252"
          >{{ error.message }}</v-snackbar
        >
        <v-snackbar
          v-model="snackbar.visible"
          :timeout="4000"
          left
          color="secondary"
          >{{ snackbar.message }}</v-snackbar
        >
      </div>
      <loading
        :active.sync="loading"
        :can-cancel="false"
        :is-full-page="true"
      ></loading>
      <v-container class="background fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="6" md="4" xl="3">
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>onDispatch Login</v-toolbar-title>
                <div class="flex-grow-1"></div>
              </v-toolbar>
              <v-card-text>
                <v-form class="pt-3" ref="form" lazy-validation>
                  <v-text-field
                    data-cy="username"
                    label="Login"
                    prepend-icon="person"
                    type="text"
                    v-model="login.email"
                    dense
                    outlined
                    :rules="emailRule"
                  ></v-text-field>

                  <v-text-field
                    data-cy="password"
                    label="Password"
                    prepend-icon="lock"
                    v-model="login.password"
                    :type="show ? 'text' : 'password'"
                    @click:append="show = !show"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    dense
                    outlined
                    :rules="requieredRule"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions class="pr-4 pl-4 pb-4 pt-1">
                <v-btn v-on:click="auth" block color="secondary">Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <loginAppFooter />
  </v-app>
</template>

<script>
import Loading from "vue-loading-overlay";
import { mapGetters } from "vuex";
import "vue-loading-overlay/dist/vue-loading.css";
import loginAppFooter from "../components/styling/loginFooter.vue";
import Vuex from "vuex";
export default {
  data: () => ({
    drawer: null,
    login: {
      email: "",
      password: ""
    },
    show: false,
    requieredRule: [v => !!v || "Dieses Feld wird benötigt"],
    emailRule: [
      v =>
        !v ||
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) ||
        "Bitte verwende eine gültige Email"
    ]
  }),
  components: {
    Loading,
    loginAppFooter
  },
  computed: {
    ...mapGetters(["loading", "loggedIn", "error", "snackbar"])
  },
  methods: {
    auth() {
      let vm = this;
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch("login", {
            email: this.login.email.trim(),
            password: this.login.password.trim()
          })
          .then(r => {
            r ? vm.$router.push("dashboard") : vm.$router.push("login");
          });
      }
    }
  }
};
</script>
<style scoped>
.background {
  background-color: #f3f3fa;
}
</style>
