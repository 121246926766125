import axios from "axios";

const actions = {
  getActivityList(context) {
    context.commit("SET_ACTIVITY_LIST");
    const { page, itemsPerPage } = context.state.pagination;
    let getUrl = context.getters.backendServer + "logs/activity?page=" + page + "&perPage=" + itemsPerPage;
    let config = {
      headers: {
        authorization: localStorage.getItem("token")
      }
    };

    axios
      .get(getUrl, config)
      .then(function (response) {
        context.commit("SET_ACTIVITY_LIST", response.data.data);
        context.commit("SET_TOTAL_ITEMS_ACTIVITY", response.data.count);
      })
      .catch(function (e) {
        context.dispatch('triggerError', e);
        context.commit("SET_ACTIVITY_LIST", e);
      });
  },
  getActivityFromId(context, objectId) {
    let getUrl = context.getters.backendServer + "logs/activity?id=" + objectId;
    let config = {
      headers: {
        authorization: localStorage.getItem("token")
      }
    };

    return axios
      .get(getUrl, config)
      .then(function (response) {
       return response.data.data;
      })
      .catch(function (e) {
        context.dispatch('triggerError', e);
      });
  }
};

export default actions;
