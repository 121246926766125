<template>
  <div>
    <v-data-table
      :headers="headers"
      :options.sync="interessentListGetter.pagination"
      :server-items-length="interessentListGetter.pagination.totalItems"
      :loading="interessentStatusPending"
      :items="interessentListGetter.interessentList.data"
      :single-expand="singleExpand"
      :expanded="expanded"
      :footer-props="{
        itemsPerPageOptions: interessentListGetter.pagination.rowsPerPageItems,
        itemsPerPageText: 'Zeilen pro Seite',
      }"
      item-key="customerNumber"
      @click:row="clickedRow"
      show-expand
      class="pa-5"
    >
      <template v-slot:item.kunde="{ item }">
        <div v-if="item.category == 'Kunde'">
          <v-icon color="grey">attach_money</v-icon>
        </div>
        <div v-if="item.category == 'Lieferant'">
          <v-icon color="grey">local_shipping</v-icon>
        </div>
        <div v-if="item.category == 'Partner'">
          <v-icon color="grey">person</v-icon>
        </div>
        <div v-if="item.category == 'Interessent'">
          <v-icon color="grey">mood</v-icon>
        </div>
      </template>
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Interessenten</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
            v-model="interessentListGetter.pagination.search"
            label="Suche..."
            id="search-interessent"
            single-line
            hide-details
            dense
            clearable
            @keyup.enter.native="searchData"
          ></v-text-field>
          <v-btn text icon color="secondary" @click="searchData">
            <v-icon>search</v-icon>
          </v-btn>
          <div class="flex-grow-1"></div>
          <v-btn color="secondary" dark outlined class="mb-2 mr-4" to="/import"
            >Kontakte importieren</v-btn
          >
          <v-dialog v-model="dialog" max-width="800px" persistent>
            <template v-slot:activator="{ on }">
              <v-btn color="secondary" dark class="mb-2" v-on="on">Neuer Kontakt</v-btn>
            </template>
            <div v-if="editedItem == null">
              <contactEdit
                method="new"
                contactType="Interessent"
                formTitle="Kontakt erstellen"
                @close="close"
                ref="contactEdit"
              ></contactEdit>
            </div>
            <div v-else>
              <contactEdit
                method="edit"
                :editItem="editedItem"
                contactType="Interessent"
                formTitle="Kontakt bearbeiten"
                @close="close"
                ref="contactEdit"
              ></contactEdit>
            </div>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.type="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-if="item.type == 'Person'" v-on="on">person</v-icon>
          </template>
          <span>Person</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-if="item.type == 'Company'" v-on="on">domain</v-icon>
          </template>
          <span>Unternehmen</span>
        </v-tooltip>
      </template>
      <template v-slot:item.formattedName="{ item }">
        <div v-if="item.type == 'Person'">
          {{ item.surname + " " }} {{ item.familyname + " " }}
          {{ item.name ? item.name + " " : "" }} {{ item.name2 ? item.name2 : "" }}
        </div>
        <div v-if="item.type == 'Company'">
          {{ item.name ? item.name + " " : "" }} {{ item.name2 ? item.name2 : "" }}
        </div>
      </template>
      <template v-slot:item.data-cy="{ item }">
        <div v-if="item.type == 'Company'">{{ item.name }} {{ item.name2 }}</div>
        <div v-else>{{ item.surname }} {{ item.familyname }}</div>
      </template>
      <template v-slot:item.address="{ item }">
        {{ item.address.street }} {{ item.address.zip }} {{ item.address.city }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-dialog v-model="deleteDialog" max-width="500">
          <v-card>
            <loading
              :active.sync="interessentStatusPending"
              :can-cancel="false"
              :is-full-page="true"
            ></loading>
            <v-card-title class="headline">Datensatz löschen?</v-card-title>
            <v-card-text>Soll der Kontakt wirklich gelöscht werden?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="deleteDialog = false"
                >Abbrechen</v-btn
              >
              <v-btn color="secondary" text @click="deleteItem()">Jetzt löschen</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon medium class="mr-2" v-on="on" @click="editItem(item)">edit</v-icon>
          </template>
          <span>Bearbeiten</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon medium v-on="on" @click="openDeleteDialog(item)">delete</v-icon>
          </template>
          <span>Löschen</span>
        </v-tooltip>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <contactSubTable :itemTable="item" :headers="headers"></contactSubTable>
      </template>
      <template v-slot:no-data> keine Daten vorhanden </template>
    </v-data-table>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import { mapGetters } from "vuex";
import contactEdit from "../createContact/contactEdit.vue";
import contactSubTable from "./contactSubTable.vue";
export default {
  components: {
    Loading,
    contactEdit,
    contactSubTable,
  },
  data: () => ({
    expanded: [],
    singleExpand: false,
    menu: false,
    snackbar: {
      visible: false,
      text: "Snackbar ...",
    },
    dialog: false,
    headers: [
      { text: "Typ", value: "type", align: "left", sortable: false },
      { text: "Kundennummer", value: "customerNumber", sortable: false },
      { text: "Name", value: "formattedName", sortable: false },
      { text: "Adresse", value: "address", sortable: false },
      { text: "", value: "action", align: "right", sortable: false },
    ],
    editedItem: null,
    deleteDialog: false,
    toDeleteItem: null,
  }),

  computed: {
    pagination: {
      get: function () {
        return this.$store.getters.interessentListGetter.pagination;
      },
      set: function (value) {
        this.$store.commit("SET_PAGINATION_INTERESSENT", value);
      },
    },
    //CHANGE
    ...mapGetters(["interessentListGetter", "interessentStatusPending"]),
  },
  watch: {
    pagination: {
      handler() {
        this.$store.dispatch("getInteressentList");
      },
    },
    dialog(val) {
      val || this.$refs.contactEdit.resetData();
      val || this.close();
    },
  },

  methods: {
    clickedRow(value) {
      let test = [];
      test.push(value);
      if (test[0] === this.expanded[0]) {
        this.expanded = [];
      } else {
        this.expanded = [];
        this.expanded.push(value);
      }
    },
    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    openDeleteDialog(item) {
      this.toDeleteItem = item;
      this.deleteDialog = true;
    },
    deleteItem() {
      let vm = this;
      this.$store.dispatch("deleteContact", this.toDeleteItem).then((result) => {
        vm.deleteDialog = false;
        vm.toDeleteItem = null;
      });
    },
    close() {
      this.dialog = false;
    },
    searchData() {
      this.$store.dispatch("getInteressentList");
    },
  },
};
</script>
<style>
.expanded__content {
  box-shadow: none !important;
  background: white !important;
}
</style>
