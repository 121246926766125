<template>
  <div ma-5>
    <v-form ref="form" v-model="valid" lazy-validation>
      <div v-if="inputVal.type == 'Company'">
        <br />
        <v-row>
          <v-col class="d-flex" cols="12" sm="3" md="4">
            <v-select
              :rules="requieredRule"
              :items="customerType"
              v-model="computedCategory"
              label="Kundentyp"
              data-cy="computedCategory"
              formattedEndDate
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <!--v-text-field
              disabled
              v-model="customerNumber"
              label="customerNumber"
              data-cy="customerNumber"
              outlined
              dense
            ></v-text-field-->
          </v-col>
          <v-col cols="12" sm="6" md="7">
            <v-text-field
              :rules="requieredRule"
              v-model="inputVal.name"
              label="Name der Organisation"
              data-cy="name"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="5">
            <v-text-field
              v-model="inputVal.name2"
              label="Namenszusatz"
              data-cy="name2"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
      <div v-if="inputVal.type == 'Person'">
        <br />
        <v-row>
          <v-col class="d-flex" cols="12" sm="3" md="4">
            <v-select
              :rules="requieredRule"
              :items="customerType"
              v-model="computedCategory"
              label="Kundentyp"
              data-cy="computedCategory"
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <!--v-text-field
              disabled
              v-model="inputVal.customerNumber"
              label="Kundennummer"
              data-cy="customerNumber"
              outlined
              dense
            ></v-text-field-->
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex" cols="12" sm="3" md="4">
            <v-select
              :items="gender"
              item-text="name"
              item-value="value"
              v-model="inputVal.gender"
              label="Anrede"
              data-cy="gender"
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="inputVal.titel"
              label="Titel / Bezeichnung"
              data-cy="titel"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="7">
            <v-text-field
              :rules="requieredRule"
              v-model="inputVal.surname"
              label="Vorname"
              data-cy="surname"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="5">
            <v-text-field
              :rules="requieredRule"
              v-model="inputVal.familyname"
              label="Nachname"
              data-cy="familyname"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="7">
            <v-text-field
              v-model="inputVal.name"
              label="Name der Organisation"
              data-cy="name"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="5">
            <v-text-field
              v-model="inputVal.name2"
              label="Namenszusatz"
              data-cy="name2"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
      <v-divider></v-divider>
      <v-tabs>
        <v-tab>Adresse</v-tab>
        <v-tab-item>
          <br />
          <v-row>
            <v-col cols="12" sm="6" md="8">
              <v-text-field
                v-model="inputVal.address.street"
                label="Straße / Hausnr."
                data-cy="street"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="method === 'new'">
            <v-col cols="12" sm="6" md="8">
              <v-autocomplete
                label="Ort (Plz / Stadt / Bundesland)"
                data-cy="preFormatAddress"
                v-model="preFormatAddress"
                :items="zipMap"
                item-text="Description"
                item-value="adressData"
                dense
                outlined
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row v-if="method === 'edit'">
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="inputVal.address.city"
                label="Stadt"
                data-cy="city"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <v-text-field
                v-model="inputVal.address.zip"
                label="PLZ"
                data-cy="zip"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex" cols="12" sm="6" md="8">
              <v-autocomplete
                label="Land"
                data-cy="country"
                v-model="inputVal.address.country"
                :items="countries"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab data-cy="invoiceTab">Rechnung</v-tab>
        <v-tab-item>
          <br />
          <v-switch
            v-model="inputVal.invoiceAddress.active"
            label="Rechnungsadresse verwenden"
          ></v-switch>
          <v-row>
            <v-col cols="12" sm="6" md="8">
              <v-text-field
                v-model="inputVal.invoiceAddress.street"
                label="Straße / Hausnr."
                data-cy="street"
                outlined
                dense
                :disabled="!inputVal.invoiceAddress.active"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="method === 'new'">
            <v-col cols="12" sm="6" md="8">
              <v-autocomplete
                label="Ort (Plz / Stadt / Bundesland)"
                data-cy="preFormatInvoiceAddress"
                v-model="preFormatInvoiceAddress"
                :items="zipMap"
                item-text="Description"
                item-value="adressData"
                dense
                outlined
                :disabled="!inputVal.invoiceAddress.active"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row v-if="method === 'edit'">
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="inputVal.invoiceAddress.city"
                label="Stadt"
                data-cy="city"
                outlined
                dense
                :disabled="!inputVal.invoiceAddress.active"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <v-text-field
                v-model="inputVal.invoiceAddress.zip"
                label="PLZ"
                data-cy="zip"
                outlined
                dense
                :disabled="!inputVal.invoiceAddress.active"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex" cols="12" sm="6" md="8">
              <v-autocomplete
                label="Land"
                data-cy="country"
                v-model="inputVal.invoiceAddress.country"
                :items="countries"
                outlined
                dense
                :disabled="!inputVal.invoiceAddress.active"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <hr />
          <p style="padding-top: 10px;">Konditionen:</p>
          <v-row>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                v-model.number="inputVal.discountTime"
                type="Number"
                label="Skonto Tage"
                data-cy="discountTime"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                v-model.number="inputVal.discount"
                type="Number"
                label="Skonto Prozent"
                data-cy="discount"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model.number="inputVal.paymentTarget"
                type="Number"
                label="Zahlungsziel in Tagen"
                data-cy="paymentTarget"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <hr />
          <p style="padding-top: 10px;">
            Email für ausgehende Rechnungen (überschreibt bestehende
            Email-Adresse):
          </p>
          <v-row>
            <v-col cols="12" sm="6" md="8">
              <v-text-field
                v-model="inputVal.invoicingEmail"
                label="Rechnungs-Email"
                data-cy="invoicingEmail"
                type="email"
                outlined
                dense
                prepend-icon="email"
                :disabled="!inputVal.email"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab data-cy="paymentTab">Zahlungsinformationen</v-tab>
        <v-tab-item>
          <br />
          <v-row>
            <v-col cols="12" sm="6" md="8">
              <v-text-field
                v-model="inputVal.bankAccount"
                label="Kontoinhaber"
                data-cy="bankAccount"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="5">
              <v-text-field
                v-model="inputVal.iban"
                label="IBAN"
                data-cy="iban"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                v-model="inputVal.bic"
                label="BIC"
                data-cy="bic"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="inputVal.taxNumber"
                label="Steuernummer"
                data-cy="taxNumber"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="inputVal.ustId"
                label="USt-ID"
                data-cy="ustId"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab data-cy="detailsTab">Details</v-tab>
        <v-tab-item>
          <br />
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="inputVal.phone"
                label="Telefonnummer"
                data-cy="phone"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="inputVal.mobile"
                label="Handynummer"
                data-cy="mobile"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="inputVal.fax"
                label="Fax"
                data-cy="fax"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="inputVal.email"
                label="Email"
                data-cy="email"
                outlined
                dense
                prepend-icon="email"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="inputVal.website"
                label="Webseite"
                data-cy="website"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab data-cy="moreTab">mehr...</v-tab>
        <v-tab-item>
          <br />
          <v-row>
            <v-col cols="12" md="12">
              <v-textarea
                label="Beschreibung"
                data-cy="description"
                v-model="inputVal.description"
                outlined
                dense
              ></v-textarea>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
    </v-form>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapGetters } from "vuex";
export default {
  props: ["value", "method", "item", "contactType"],
  data: () => ({
    valid: true,
    customerType: ["Kunde", "Lieferant", "Partner", "Interessent"],
    gender: [
      { name: "Herr", value: "m" },
      { name: "Frau", value: "w" },
      { name: "Sonstiges", value: "empty" }
    ],
    valid: true,
    requieredRule: [v => !!v || "Dieses Feld wird benötigt"],
    preFormatAddress: null,
    preFormatInvoiceAddress: null
  }),
  created() {},
  computed: {
    ...mapGetters(["countries", "zipCodes"]),
    //...mapFields(["item"],
    zipMap() {
      return this.zipCodes.map(zip => {
        const Description = zip.Plz + " - " + zip.Ort + " - " + zip.Bundesland;
        let adressData = zip;
        if (adressData != null) {
          return Object.assign({}, zip, { Description, adressData });
        }
      });
    },
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    computedCategory: {
      get() {
        if (!this.inputVal.category) {
          switch (this.$route.params.id) {
            case "1":
              this.inputVal.category = "";
              return "";
              break;
            case "2":
              this.inputVal.category = "Kunde";
              return "Kunde";
              break;
            case "3":
              this.inputVal.category = "Lieferant";
              return "Lieferant";
              break;
            case "4":
              this.inputVal.category = "Partner";
              return "Partner";
              break;
            case "5":
              this.inputVal.category = "Interessent";
              return "Interessent";
              break;
          }
        }
        return this.inputVal.category;
      },
      set(val) {
        this.inputVal.category = val;
      }
    }
  },
  watch: {
    immediate: true,
    preFormatAddress: {
      handler(val) {
        if (val) {
          this.inputVal.address.city = val.Ort;
          this.inputVal.address.zip = val.Plz;
          this.inputVal.address.country = "Deutschland";
        }
      },
      deep: true
    },
    preFormatInvoiceAddress: {
      handler(val) {
        if (val) {
          this.inputVal.invoiceAddress.city = val.Ort;
          this.inputVal.invoiceAddress.zip = val.Plz;
          this.inputVal.invoiceAddress.country = "Deutschland";
        }
      },
      deep: true
    }
  },
  methods: {
    validateForm() {
      if (this.$refs.form.validate()) {
        return true;
      }
    }
  }
};
</script>
