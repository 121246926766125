<template>
  <v-card class="ma-3" height="100%">
    <v-card-title>Hilfe erhalten</v-card-title>
    <p class="text-left ma-4">
      Schnelle und kompetente Hilfe direkt von unseren Mitarbeitern
    </p>
    <v-list>
      <!--v-list-item @click="">
        <v-list-item-icon>
          <v-icon color="indigo">mdi-phone</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>(650) 555-1234</v-list-item-title>
          <v-list-item-subtitle>Mobile</v-list-item-subtitle>
        </v-list-item-content>

      </v-list-item>

      <v-divider inset></v-divider-->

      <v-list-item @click="openLinkDoku">
        <v-list-item-icon>
          <v-icon color="primary">mdi-email</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>onDispatch Dokumentation</v-list-item-title>
          <v-list-item-subtitle
            >Hier erhalten Sie Informationen zu allen Funktionen</v-list-item-subtitle
          >
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon>launch</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item @click="openLinkHelp">
        <v-list-item-icon>
          <v-icon color="primary">library_books</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>onDispatch Online-Hilfe</v-list-item-title>
          <v-list-item-subtitle
            >Bei allen Anliegen helfen wir Ihnen hier weiter</v-list-item-subtitle
          >
          <v-list-item-subtitle></v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon>launch</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-divider inset></v-divider>
      <v-list-item @click="openLinkEmail">
        <v-list-item-icon>
          <v-icon color="primary">help</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>support@ondispatch.de</v-list-item-title>
          <v-list-item-subtitle>Direkt Hilfe per Email erhalten</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>
<script>
export default {
  data: () => ({}),
  methods: {
    openLinkDoku() {
      window.open("https://doku.ondispatch.de/docs/uebersicht/", "_blank");
    },
    openLinkHelp() {
      window.open("https://doku.ondispatch.de/docs/hilfe/", "_blank");
    },
    openLinkEmail() {
      location.href = "mailto:support@ondispatch.de";
    },
  },
};
</script>
