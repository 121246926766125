import axios from "axios";
const actions = {
  getProjectList(context, searchKey) {
    context.commit("SET_ALL_PROJECT");
    const { page, itemsPerPage } = context.state.projectData.pagination;
    let getUrl;
    if (searchKey != null && searchKey != "") {
      getUrl =
        context.getters.backendServer +
        "project?page=" +
        page +
        "&perPage=" +
        itemsPerPage +
        "&q=" +
        searchKey;
      context.commit("RESET_PAGINATION_PAGES_PROJECT");
    } else {
      getUrl =
        context.getters.backendServer +
        "project?page=" +
        page +
        "&perPage=" +
        itemsPerPage;
    }
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_ALL_PROJECT", response.data.data);
        context.commit("SET_TOTAL_PROJECT_ALL", parseInt(response.data.count));
      })
      .catch(function(e) {
        context.commit("SET_PROJECT_LIST", e);
        context.dispatch("triggerError", e);
      });
  },

  getAllProjectList(context) {
    context.commit("SET_ALL_PROJECT");
    let getUrl = context.getters.backendServer + "project?status=Aktiv";

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_ALL_PROJECT", response.data.data);
      })
      .catch(function(e) {
        context.commit("SET_PROJECT_LIST", e);
        context.dispatch("triggerError", e);
      });
  },

  getSingleProject(context, id) {
    let getUrl = context.getters.backendServer + "project/" + id;
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    return axios
      .get(getUrl, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },

  addProject(context, value) {
    context.commit("SET_PROJECT_ADD");
    let postUrl = context.getters.backendServer + "project";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    return axios
      .post(postUrl, value, config)
      .then(function(response) {
        context.commit("SET_PROJECT_ADD", response.data);
        context.commit(
          "CREATE_SNACKBAR",
          "Projekt Nr. " + response.data.objectId + " erfolgreich hinzugefügt"
        );
        context.dispatch("getProjectList");
        return response.data;
      })
      .catch(function(e) {
        context.commit("SET_Project_ADD", e);
        context.dispatch("triggerError", e);
      });
  },

  deleteProject(context, value) {
    context.commit("SET_PROJECT_DELETE");
    let postUrl = context.getters.backendServer + "project/" + value.id;

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .delete(postUrl, config)
      .then(function() {
        context.commit("SET_PROJECT_DELETE", "[]");
        context.commit(
          "CREATE_SNACKBAR",
          "Projekt Nr. " + value.objectId + " erfolgreich gelöscht"
        );
        context.dispatch("getProjectList");
      })
      .catch(function(e) {
        context.commit("SET_PROJECT_DELETE", e);
        //check if error contains message, project cant be deletet due to delivery notes
        if (e.message == "Request failed with status code 400") {
          context.dispatch("triggerUniversalAlert", {
            text:
              "Diese Aktion kann nicht durchgeführt werden, da noch Lieferscheine in diesem Projekt hinterlegt sind. Entfernen Sie alle Lieferscheine um das Projekt zu löschen.",
            heading: "Löschen des Projektes fehlgeschlagen",
            type: "warning"
          });
        } else {
          context.dispatch("triggerError", e);
        }
      });
  },

  updateProject(context, value) {
    context.commit("SET_PROJECT_UPDATE");

    let putUrl = context.getters.backendServer + "project/" + value.id;

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    value.formattedBirthday = null;
    axios
      .put(putUrl, value, config)
      .then(function(response) {
        context.commit("SET_PROJECT_UPDATE", response.data);
        context.commit(
          "CREATE_SNACKBAR",
          "Projekt Nr. " + response.data.objectId + " erfolgreich aktualisiert"
        );
        context.dispatch("getProjectList");
      })
      .catch(function(e) {
        context.commit("SET_PROJECT_UPDATE", e);
        context.dispatch("triggerError", e);
      });
  },

  addDeliveryNoteToProject(context, value) {
    let putUrl = context.getters.backendServer + "project/" + value.projectId + "/addDeliveryNote";

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    return axios
      .put(putUrl, value, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  }
};

export default actions;
