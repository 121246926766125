import axios from "axios";
const actions = {
  getVehiclesList(context) {
    context.commit("SET_VEHICLES_LIST");
    const { page, itemsPerPage, search } = context.state.dataVehicles.pagination;
    let getUrl = null;
    if (search != null && search != "") {
      getUrl =
        context.getters.backendServer +
        "vehicle?page=" +
        page +
        "&perPage=" +
        itemsPerPage +
        "&q=" +
        search;
      context.commit("RESET_PAGINATION_PAGES_VEHICLES");
    } else {
      getUrl =
        context.getters.backendServer +
        "vehicle?page=" +
        page +
        "&perPage=" +
        itemsPerPage;
    }
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_VEHICLES_LIST", response.data.data);
        context.commit("SET_TOTAL_ITEMS_VEHICLES", response.data.count);
      })
      .catch(function(e) {
        context.commit("SET_VEHICLES_LIST", e);
        context.dispatch("triggerError", e);
      });
  },

  getMachineryList(context) {
    context.commit("SET_MACHINERY_LIST");
    const { page, itemsPerPage, search } = context.state.dataMachinery.pagination;
    let getUrl = null;
    if (search != null && search != "") {
      getUrl =
        context.getters.backendServer +
        "machinery?page=" +
        page +
        "&perPage=" +
        itemsPerPage +
        "&q=" +
        search;
      context.commit("RESET_PAGINATION_PAGES_MACHINERY");
    } else {
      getUrl =
        context.getters.backendServer +
        "machinery?page=" +
        page +
        "&perPage=" +
        itemsPerPage;
    }
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_MACHINERY_LIST", response.data.data);
        context.commit("SET_TOTAL_ITEMS_MACHINERY", response.data.count);
      })
      .catch(function(e) {
        context.commit("SET_MACHINERY_LIST", e);
        context.dispatch("triggerError", e);
      });
  },

  addVehicles(context, value) {
    context.commit("SET_VEHICLES_ADD");
    let postUrl = context.getters.backendServer + "vehicle";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    return axios
      .post(postUrl, value, config)
      .then(function(response) {
        context.commit("SET_VEHICLES_ADD", response);
        context.commit("CREATE_SNACKBAR", "Fahrzeug erfolgreich hinzugefügt");
        context.dispatch("getVehiclesList");
        return true;
      })
      .catch(function(e) {
        context.commit("SET_VEHICLES_ADD", e);
        context.dispatch("triggerError", e);
      });
  },

  addMachinery(context, value) {
    context.commit("SET_MACHINERY_ADD");
    let postUrl = context.getters.backendServer + "machinery";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    return axios
      .post(postUrl, value, config)
      .then(function(response) {
        context.commit("SET_MACHINERY_ADD", response);
        context.commit("CREATE_SNACKBAR", "Fahrzeug erfolgreich hinzugefügt");
        context.dispatch("getMachineryList");
        return true;
      })
      .catch(function(e) {
        context.commit("SET_MACHINERY_ADD", e);
        context.dispatch("triggerError", e);
      });
  },

  updateVehicles(context, value) {
    context.commit("SET_VEHICLES_UPDATE");

    let postUrl = context.getters.backendServer + "vehicle/" + value._id;

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .put(postUrl, value, config)
      .then(function(response) {
        context.commit("SET_VEHICLES_UPDATE", response.data);
        context.commit(
          "CREATE_SNACKBAR",
          "Fahrzeug " + response.data.chassisNumber + " erfolgreich verändert"
        );
        context.dispatch("getVehiclesList");
      })
      .catch(function(e) {
        context.commit("SET_VEHICLES_UPDATE", e);
        context.dispatch("triggerError", e);
      });
  },

  updateMachinery(context, value) {
    context.commit("SET_MACHINERY_UPDATE");

    let postUrl = context.getters.backendServer + "machinery/" + value._id;

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .put(postUrl, value, config)
      .then(function(response) {
        context.commit("SET_MACHINERY_UPDATE", response.data);
        context.commit(
          "CREATE_SNACKBAR",
          "Fahrzeug " + response.data.license + " erfolgreich verändert"
        );
        context.dispatch("getMachineryList");
      })
      .catch(function(e) {
        context.commit("SET_MACHINERY_UPDATE", e);
        context.dispatch("triggerError", e);
      });
  },

  deleteVehicles(context, value) {
    context.commit("SET_VEHICLES_DELETE");
    let postUrl = context.getters.backendServer + "vehicle/" + value._id;

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .delete(postUrl, config)
      .then(function() {
        context.commit("SET_VEHICLES_DELETE", "[]");
        context.commit("CREATE_SNACKBAR", "Fahrzeug erfolgreich gelöscht");
        context.dispatch("getVehiclesList");
      })
      .catch(function(e) {
        context.commit("SET_VEHICLES_DELETE", e);
        context.dispatch("triggerError", e);
      });
  },

  deleteMachinery(context, value) {
    context.commit("SET_MACHINERY_DELETE");
    let postUrl = context.getters.backendServer + "machinery/" + value._id;

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    axios
      .delete(postUrl, config)
      .then(function() {
        context.commit("SET_MACHINERY_DELETE", "[]");
        context.commit("CREATE_SNACKBAR", "Maschine erfolgreich gelöscht");
        context.dispatch("getMachineryList");
      })
      .catch(function(e) {
        context.commit("SET_MACHINERY_DELETE", e);
        context.dispatch("triggerError", e);
      });
  },
  setVisibleInSchedulerVehicle(context, value) {
    context.commit("SET_VISIBLE_IN_SCHEDULER_VEHICLE");

    let postUrl =
      context.getters.backendServer + "vehicle/" + value._id + "/display";

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    let params = {
      display: value.display
    };
    axios
      .put(postUrl, params, config)
      .then(function(response) {
        context.commit("SET_VISIBLE_IN_SCHEDULER_VEHICLE", response.data);
        if (response.data.displayTo.length > 0) {
          context.commit("CREATE_SNACKBAR", "Fahrzeug in Plantafel sichtbar");
        } else {
          context.commit("CREATE_SNACKBAR", "Fahrzeug in Plantafel verborgen");
        }
        context.dispatch("getVehiclesList");
      })
      .catch(function(e) {
        context.commit("SET_VISIBLE_IN_SCHEDULER_VEHICLE", e);
        context.dispatch("triggerError", e);
      });
  },
  setVisibleInSchedulerMachinery(context, value) {
    context.commit("SET_VISIBLE_IN_SCHEDULER_MACHINERY");

    let postUrl =
      context.getters.backendServer + "machinery/" + value._id + "/display";

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    let params = {
      display: value.display
    };
    axios
      .put(postUrl, params, config)
      .then(function(response) {
        context.commit("SET_VISIBLE_IN_SCHEDULER_MACHINERY", response.data);
        if (response.data.displayTo.length > 0) {
          context.commit("CREATE_SNACKBAR", "Maschine in Scheduler sichtbar");
        } else {
          context.commit("CREATE_SNACKBAR", "Maschine in Scheduler verborgen");
        }
        context.dispatch("getMachineryList");
      })
      .catch(function(e) {
        context.commit("SET_VISIBLE_IN_SCHEDULER_MACHINERY", e);
        context.dispatch("triggerError", e);
      });
  }
};

export default actions;
